import { ListBlockedUsersResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_pb';

import { SettingsBlockedUser } from '@/pages/settings/store/settings-state';

export const BLOCKED_USERS_LIMIT = 10;

export function getLastBlockedUserPosition(users: SettingsBlockedUser[]): string {
  if (users.length) {
    return users[users.length - 1].userPosition;
  }
  return '';
}

export function getBlockedUsersFrom(response: ListBlockedUsersResponse): SettingsBlockedUser[] {
  const users: SettingsBlockedUser[] = [];
  const position = response.pagination?.next;

  response.items.forEach((user) => {
    const avatar = user.avatar;
    users.push({
      id: user.id,
      name: user.name,
      isVerified: false,
      age: user.age,
      avatar: {
        photoId: avatar?.photoId ?? '',
        isDummy: avatar?.isDummy ?? false,
      },
      isOnline: user.isOnline,
      isBlocked: user.isBlocked,
      isDeleted: user.isDeleted,
      locationName: user.locationName,
      latestOnlineDuration: {
        seconds: user.latestOnlineDuration?.seconds ?? 0n,
        nanos: user.latestOnlineDuration?.nanos ?? 0,
      },
      latestOnlineTime: {
        seconds: user.latestOnlineTime?.seconds ?? 0n,
        nanos: user.latestOnlineTime?.nanos ?? 0,
      },
      userPosition: position ?? '',
    });
  });

  return users;
}
