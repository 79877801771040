import { createPromiseClient } from '@connectrpc/connect';
import { CompatibilityAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_connect';
import {
  ActivateCompatibilityStatusRequest,
  ActivateCompatibilityStatusResponse,
  BatchGetCompatibilityStatusesRequest,
  BatchGetCompatibilityStatusesResponse,
  BatchGetSexualCompatibilityStatusesRequest,
  BatchGetSexualCompatibilityStatusesResponse,
  GetCompatibilityStatusRequest,
  GetCompatibilityStatusResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';

const client = createPromiseClient(CompatibilityAPI, getGrpcWebTransport());

export function activateCompatibilityStatus(
  userId: string,
): Promise<ActivateCompatibilityStatusResponse> {
  const request = new ActivateCompatibilityStatusRequest({
    userId,
  });

  return client.activateCompatibilityStatus(request);
}

export function getCompatibilityStatus(userId: string): Promise<GetCompatibilityStatusResponse> {
  const request = new GetCompatibilityStatusRequest({
    userId,
  });

  return client.getCompatibilityStatus(request);
}

export function batchGetSexualCompatibilityStatuses(
  userIdList: string[],
): Promise<BatchGetSexualCompatibilityStatusesResponse> {
  const request = new BatchGetSexualCompatibilityStatusesRequest({
    userIds: userIdList,
  });

  return client.batchGetSexualCompatibilityStatuses(request);
}

export function batchGetCompatibilityStatuses(
  userIdList: string[],
): Promise<BatchGetCompatibilityStatusesResponse> {
  const request = new BatchGetCompatibilityStatusesRequest({
    userIds: userIdList,
  });

  return client.batchGetCompatibilityStatuses(request);
}

export const compatibilityApi = {
  activateCompatibilityStatus,
  getCompatibilityStatus,
  batchGetSexualCompatibilityStatuses,
  batchGetCompatibilityStatuses,
};
