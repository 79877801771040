import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';

import { setProlongationTimeLastShown } from '@/components/prolongation-modal/helpers/prolongation/prolongation';
import { useProlongationModal } from '@/components/prolongation-modal/hooks/use-prolongation-modal/use-prolongation-modal';
import { getSubscriptionDateExpiresFormatted } from '@/helpers/subscription/subscription-type/subscription-type';
import { getNextPackage } from '@/pages/payment/helpers/package/package';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { pushRealtimeTrackingCounter } from '@/services/realtime-tracking/realtime-tracking';
import { dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

type UseSelfArg = {
  close: () => void;
  subscription: PaymentStateSubscription;
};
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const dateExpiresFormatted = getSubscriptionDateExpiresFormatted(arg.subscription.subscription);

  // Hook ----------------------------------------------------------------------
  const prolongationModal = useProlongationModal(arg);

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function subscribePackageNext(): void {
    let packageNext: ExtractClassFields<PremiumPackage> | undefined;
    if (arg.subscription.currentPackage) {
      packageNext = getNextPackage(arg.subscription.currentPackage);
    }

    if (!packageNext && arg.subscription.nextPackage) {
      packageNext = getNextPackage(arg.subscription.nextPackage);
    }

    if (packageNext) {
      dispatch('actionPaymentChangeSubscriptionPackage', {
        id: packageNext.id,
        offerId: packageNext.offer?.id ?? '',
      }).finally(() => {
        arg.close();
      });
    }
  }

  function init() {
    prolongationModal.init();
    setProlongationTimeLastShown();
    pushRealtimeTrackingCounter('show-prolongation-modal', {
      type: 'prolongation-cancelled-modal',
    });
  }

  return {
    dateExpiresFormatted,
    prolongationModal,
    subscribePackageNext,
    init,
  };
}
