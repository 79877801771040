import { GetProfileResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_api_pb';

import {
  ProfileStateFieldSettings,
  ProfileStateFieldSettingsName,
  ProfileStateProfile,
} from '@/pages/profile/store/profile-state';
import {
  SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
} from '@/settings';

export function extractProfileFrom(response: GetProfileResponse): ProfileStateProfile {
  const profile = response.profile;

  if (!profile) {
    throw Error('profile is absent');
  }

  const location = profile.location;

  const fieldSettings: ProfileStateFieldSettings[] = [];
  response.fieldSettings.forEach((field) => {
    fieldSettings.push({
      fieldName: field.fieldName as ProfileStateFieldSettingsName,
      isLocked: field.isLocked,
    });
  });

  return {
    id: profile.userId,
    gender: profile.gender,
    about: profile.about,
    sphereOfWork: profile.sphereOfWork,
    position: profile.position,
    interests: profile.interests,
    birthPlace: profile.birthPlace,
    education: profile.education,
    religion: profile.religion,
    sexualOrientation: profile.sexualOrientation,
    maritalStatus: profile.maritalStatus,
    ethnicity: profile.ethnicity,
    heightValue: profile.height?.value ?? 0,
    bodyType: profile.bodyType,
    hairColor: profile.hairColor,
    children: profile.children,
    drinking: profile.drinking,
    smoking: profile.smoking,
    location: {
      latitude: location?.latitude ?? SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
      longitude: location?.longitude ?? SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
      city: location?.city ?? '',
      country: location?.country ?? '',
      state: location?.state ?? '',
    },
    distance: response.distance,
    isLocationUpdateAllowed: profile.isLocationUpdateAllowed,
    isLocationDetectionAllowed: profile.isLocationDetectionAllowed,
    photos: profile.photos,
    name: profile.name,
    age: profile.age,
    matchGender: profile.matchGender,
    lookingFor: profile.lookingFor,
    isVerified: response.isVerified,
    isOnline: response.isOnline,
    isBlocked: response.isBlocked,
    isLiked: response.isLiked,
    isPhotoRequested: response.isPhotoRequested,
    isDeleted: response.isDeleted,
    hasBlockedByUser: response.hasBlockedUser,
    fieldSettings,
    chatId: response.dialogId,
    sport: profile.sport,
    eyeColor: profile.eyeColor,
    educationalInstitution: profile.educationalInstitution,
    verifiedByList: profile.verifiedBy,
  };
}
