import firebase from 'firebase/app';

// import 'firebase/analytics';
import 'firebase/messaging';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { initServiceWorker } from '@/services/service-worker/service-worker';
import { dispatch } from '@/store/store-helper';

const VAPID_PUBLIC_KEY =
  'BNdzJ0gVb_6P5hG4iI2QuC0gWrZCSBTuCRonY_cPFYHOBKHM0m4OO4_YYbyacOX8CI4AsKo74NlrAHhT8ZN4Ta0';

const config = {
  apiKey: 'AIzaSyA1AvRLwizhBjhR2EpA1xDHDfeHxA9_EWw',
  authDomain: 'gdx-push-ef050.firebaseapp.com',
  projectId: 'gdx-push-ef050',
  storageBucket: 'gdx-push-ef050.appspot.com',
  messagingSenderId: '962056622509',
  appId: '1:962056622509:web:d1480f0c9d0c1fad821124',
  // measurementId: 'G-75NT8YL4Q9',
};

// WebPushes is not working on iOS & webview browsers.
export function isWebPushAvailableOnDevice() {
  return typeof Notification !== 'undefined';
}

export function isNotificationPermissionGranted() {
  return isWebPushAvailableOnDevice() && Notification.permission === 'granted';
}

export function isNotificationPermissionDefault() {
  return isWebPushAvailableOnDevice() && Notification.permission === 'default';
}

export async function initFirebase(): Promise<void> {
  firebase.initializeApp(config);
  // firebase.analytics();
  try {
    let serviceWorkerRegistration: ServiceWorkerRegistration | null = null;
    try {
      serviceWorkerRegistration = await initServiceWorker();
    } catch (err) {
      console.log(err);
    }

    if (serviceWorkerRegistration !== null) {
      const isPermissionWasDefault = isNotificationPermissionDefault();

      const token = await firebase.messaging().getToken({
        vapidKey: VAPID_PUBLIC_KEY,
        serviceWorkerRegistration,
      });

      dispatch('actionCommonRegisterPushToken', token);

      customEventTrigger(
        {
          event: 'web-push-modal',
          action: isPermissionWasDefault ? 'success' : 'post-success',
        },
        {
          trackAllLabels: true,
        },
      );
      console.log(token);
    }
  } catch (err) {
    customEventTrigger(
      {
        event: 'web-push-modal',
        action: 'fail',
      },
      {
        trackAllLabels: true,
      },
    );
    console.log('FCM: There is an error', err);
  }
}
