import { getAccessToken } from '@/helpers/auth/auth';
import { SETTINGS_BLUR_IMAGE_INTENSITY, SETTINGS_MAX_IMAGE_SIZE_BYTES } from '@/settings';

const IMAGE_REST_ENDPOINT = `https://${import.meta.env.VUE_APP_IMAGE_HOST}`;

export type ImageToken = {
  imageId: string;
};

type ImageSize = {
  width: number;
  height: number;
};

type ImageGroup = 'avatar' | 'chat';
export type ImageGroupRatio = '' | 'avatar-square' | 'avatar-portrait' | 'chat-square';

export type ImageFormat = 'png' | 'webp' | 'jpeg';

export async function resolveImageResponseData(response: Response): Promise<ImageToken> {
  const data = await response.json();

  return {
    imageId: data.file_id,
  };
}

// user - front camera
// environment - back camera
export type ImageCapture = 'user' | 'environment';

export function selectImage(capture?: ImageCapture): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const elt = document.createElement('input');
    // elt.style.display = 'none';
    elt.setAttribute('type', 'file');
    elt.classList.add('input-file-hidden');
    // elt.setAttribute('accept', 'image/*');
    elt.addEventListener('change', (e: Event) => {
      document.body.removeChild(elt);
      const { files } = e.currentTarget as HTMLInputElement;
      if (files?.length) {
        const [file] = files;

        if (isImageAllowed(file)) {
          resolve(file);
        } else {
          reject(new Error('Too big image size or wrong format, please, try another one.'));
        }
      } else {
        reject(new Error("Can't select file"));
      }
    });

    if (capture) {
      elt.setAttribute('capture', capture);
    }

    // Image should be inside DOM, otherwise it won't work in Safari (iOS).
    document.body.appendChild(elt);
    elt.click();
  });
}

function isImageAllowed(file: File) {
  return (
    file.size <= SETTINGS_MAX_IMAGE_SIZE_BYTES &&
    ['image/png', 'image/jpeg', 'image/webp'].includes(file.type)
  );
}

export function uploadImage(blob: Blob, group?: ImageGroup): Promise<ImageToken> {
  const form = new FormData();
  form.append('file', blob, 'image.webp');

  if (group) {
    form.append('group', group);
  }

  return fetch(`${IMAGE_REST_ENDPOINT}/image`, {
    method: 'POST',
    body: form,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      Authorization: getAccessToken(),
    },
  }).then(resolveImageResponseData);
}

export function uploadImageRemote(file_url: string, group?: ImageGroup): Promise<ImageToken> {
  return fetch(`${IMAGE_REST_ENDPOINT}/image/remote`, {
    method: 'POST',
    body: JSON.stringify({
      group,
      file_url,
    }),
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAccessToken(),
    },
  }).then(resolveImageResponseData);
}

function getImageSize(size: ImageSize, isBlurred: boolean): ImageSize {
  let { width, height } = size;
  if (isBlurred) {
    width = Math.round(width / SETTINGS_BLUR_IMAGE_INTENSITY);
    height = Math.round(height / SETTINGS_BLUR_IMAGE_INTENSITY);
  }
  return { width, height };
}

const IMAGE_CACHE_KEY = 'c1_';

// Returns image url like this: https://localhost:11001/image/1_200x200.webp
export function getImageUrl(
  imageId: string,
  size?: ImageSize,
  isBlurred = false,
  format: ImageFormat = 'jpeg',
  groupRatio: ImageGroupRatio = '',
): string {
  let sizePart = '';
  let ratioPart = '';

  if (size) {
    const { width, height } = getImageSize(size, isBlurred);
    sizePart = `_${width}x${height}`;
  }

  if (groupRatio) {
    ratioPart = `-${groupRatio}`;
  }

  return `${IMAGE_REST_ENDPOINT}/image/${IMAGE_CACHE_KEY}${imageId}${ratioPart}${sizePart}.${format}`;
}

export function getImageSrcSet(
  imageId: string,
  size?: ImageSize,
  isBlurred = false,
  format: ImageFormat = 'jpeg',
  groupRatio: ImageGroupRatio = '',
): string {
  const srcset: string[] = [];
  const x1 = getImageUrl(imageId, size, isBlurred, format, groupRatio);
  srcset.push(`${x1} 1x`);

  if (size) {
    const x2 = getImageUrl(
      imageId,
      {
        width: size.width * 2,
        height: size.height * 2,
      },
      isBlurred,
      format,
      groupRatio,
    );
    srcset.push(`${x2} 2x`);

    const x3 = getImageUrl(
      imageId,
      {
        width: size.width * 3,
        height: size.height * 3,
      },
      isBlurred,
      format,
      groupRatio,
    );
    srcset.push(`${x3} 3x`);
  }

  return srcset.join(', ');
}

export function getImageBlobFromUrl(imageUrl: string): Promise<Blob | null> {
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(resolve);
      } else {
        reject(Error("Can't get 2d context."));
      }
    });

    img.crossOrigin = 'anonymous';
    img.src = imageUrl;
  });
}

type ImageMetadata = {
  hash: string;
};

export function getImageMetadata(imageUrl: string): Promise<ImageMetadata> {
  return fetch(imageUrl + '/meta', {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .then(({ metadata }) => {
      return metadata;
    });
}
