import { LIKE_COUNTER_CACHE_KEY } from '@/helpers/like/like-const';
import { getCache, removeCache, setCache } from '@/helpers/persistent-cache';
import { isSignupRoute, router } from '@/router';
import { setSentryUserId } from '@/services/sentry/sentry';
import { CACHE_KEY_PERSISTED_STATE } from '@/settings';

const ACCESS_TOKEN_CACHE_KEY = 'token';
const REFRESH_TOKEN_CACHE_KEY = 'refresh_token';
const USER_ID_CACHE_KEY = 'user_id';
const SEARCH_PAGE_VISITED_KEY = 'search_page_visited';

let accessToken = '';
const accessTokenFromCache = getCache(ACCESS_TOKEN_CACHE_KEY);
if (accessTokenFromCache !== null) {
  accessToken = accessTokenFromCache;
}

let refreshToken = '';
const refreshTokenFromCache = getCache(REFRESH_TOKEN_CACHE_KEY);
if (refreshTokenFromCache !== null) {
  refreshToken = refreshTokenFromCache;
}

export function removeUserAuthData() {
  accessToken = '';
  refreshToken = '';
  removeCache(ACCESS_TOKEN_CACHE_KEY);
  removeCache(REFRESH_TOKEN_CACHE_KEY);
  removeCache(USER_ID_CACHE_KEY);
  removeCache(SEARCH_PAGE_VISITED_KEY);
  removeCache(LIKE_COUNTER_CACHE_KEY);
  removeCache(CACHE_KEY_PERSISTED_STATE);
}

export function setAccessToken(t: string) {
  accessToken = t;
  setCache(ACCESS_TOKEN_CACHE_KEY, t);
}

export function setRefreshToken(t: string) {
  refreshToken = t;
  setCache(REFRESH_TOKEN_CACHE_KEY, t);
}

export function setUserAuthData({
  accessToken,
  refreshToken,
  userId,
}: {
  accessToken: string;
  refreshToken: string;
  userId: string;
}) {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setCache(USER_ID_CACHE_KEY, userId);

  // Set user id for tracing into sentry.
  setSentryUserId(userId);
}

export function getAccessToken(): string {
  return accessToken;
}

export function hasAccessToken(): boolean {
  return !!getAccessToken();
}

export function getRefreshToken(): string {
  return refreshToken;
}

export function hasRefreshToken(): boolean {
  return !!getRefreshToken();
}

export function getUserId(): string {
  return getCache(USER_ID_CACHE_KEY) ?? '';
}

export function hasUserId(): boolean {
  return getUserId() !== '';
}

export function isUserLoggedIn(): boolean {
  return hasUserId() && hasRefreshToken() && hasAccessToken();
}

export function setSearchPageVisited(): void {
  setCache(SEARCH_PAGE_VISITED_KEY, '1');
}

export function isSearchPageVisited(): boolean {
  return getCache(SEARCH_PAGE_VISITED_KEY) !== null;
}

export function subscribeRouteUntilAuth(callback: () => void): void {
  const unsubscribe: () => void = router.beforeEach((to) => {
    // Exclude all `.isGuest` routes because startNotificationLogic requires
    // to check splits which are not accessible from guest routes.
    if (!isSignupRoute(to) && !to.meta.isGuest) {
      callback();
      unsubscribe();
    }
  });
}
