import { groupConfigs } from '@/services/notification-manager/helpers/config/config';
import { applyImmediateRules } from '@/services/notification-manager/helpers/rule/rule';
import { startNotificationTimer } from '@/services/notification-manager/helpers/timer/timer';
import {
  NotificationManagerIncoming,
  NotificationManagerIncomingNotification,
} from '@/services/notification-manager/helpers/type/type';
import { sentryCaptureMessage } from '@/services/sentry/sentry';

const groupIncomingNotifications: NotificationManagerIncoming[] = [];

export function pushNotificationManager(
  notification: NotificationManagerIncomingNotification,
): void {
  const groupConfig = groupConfigs.find((group) =>
    group.notificationNames.includes(notification.name),
  );

  if (!groupConfig) {
    sentryCaptureMessage({
      message: 'NOTIFICATION_MANAGER: groupConfig not found',
    });
    return;
  }

  let groupIncoming = groupIncomingNotifications.find(
    (group) => group.groupName === groupConfig.groupName,
  );

  if (!groupIncoming) {
    groupIncoming = {
      groupName: groupConfig.groupName,
      notifications: [notification],
      notificationTimeoutIds: {},
    };
    groupIncomingNotifications.push(groupIncoming);
  } else {
    groupIncoming.notifications.push(notification);
    applyImmediateRules(groupIncoming, groupConfig);
  }

  startNotificationTimer(groupIncoming, groupConfig, notification);
}
