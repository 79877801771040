<template>
  <PurchaseSliderLayout>
    <template #default>
      <div class="slider-item swiper-slide slide_infinity_likes">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.unlimitedLikes.header') }}</div>
          {{ t('slider.unlimitedLikes.content') }}
        </div>
        <div class="slider-icon">
          <FontIcon class="icon-top_profile" />
        </div>
      </div>
      <div class="slider-item swiper-slide slide_search">
        <div class="slider-text">
          <div class="slider_billing-title">
            {{ t('advancedSearchChatRequestHeader') }}
          </div>
          {{ t('advancedSearchChatRequestContent') }}
        </div>
        <div class="slider-icon">
          <FontIcon class="icon-instant-chat_solid" />
        </div>
      </div>
      <!--      <div class="slider-item swiper-slide slide_unlock">-->
      <!--        <div class="slider-text">-->
      <!--          <div class="slider_billing-title">{{ t('slider.unblockUsers.header') }}</div>-->
      <!--          {{ t('slider.unblockUsers.content') }}-->
      <!--        </div>-->
      <!--        <div class="slider-icon">-->
      <!--          <FontIcon class="icon-key_solid" />-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="slider-item swiper-slide slide_infinty_messages">-->
      <!--        <div class="slider-text">-->
      <!--          <div class="slider_billing-title">-->
      <!--            {{ t('slider.unlimitedMessages.header') }}-->
      <!--          </div>-->
      <!--          {{ t('slider.unlimitedMessages.content') }}-->
      <!--        </div>-->
      <!--        <div class="slider-icon">-->
      <!--          <FontIcon class="icon-mma_solid" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="slider-item swiper-slide slide_trash">
        <div class="slider-text">
          <div class="slider_billing-title">
            {{ t('slider.deleteOwnMessages.header') }}
          </div>
          {{ t('slider.deleteOwnMessages.content') }}
        </div>
        <div class="slider-icon">
          <FontIcon class="icon-fire_solid" />
        </div>
      </div>
      <!--      <div class="slider-item swiper-slide slide_premium">-->
      <!--        <div class="slider-text">-->
      <!--          <div class="slider_billing-title">-->
      <!--            {{ t('slider.advancedInformation.header') }}-->
      <!--          </div>-->
      <!--          {{ t('slider.advancedInformation.content') }}-->
      <!--        </div>-->
      <!--        <div class="slider-icon">-->
      <!--          <FontIcon class="icon-liked_check_solid" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="slider-item swiper-slide slide_profile">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.activity.header') }}</div>
          {{ t('slider.activity.content') }}
        </div>
        <div class="slider-icon">
          <FontIcon class="icon-premium_solid" />
        </div>
      </div>
      <div class="slider-item swiper-slide slide_profile">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.ads.header') }}</div>
          {{ t('slider.ads.content') }}
        </div>
        <div class="slider-icon">
          <FontIcon class="icon-ads_solid" />
        </div>
      </div>
    </template>
  </PurchaseSliderLayout>
</template>

<script lang="ts" setup>
import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import PurchaseSliderLayout from '@/pages/payment/components/layout/components/purchase-slider-layout/PurchaseSliderLayout.vue';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });
</script>
