import { GetSexualCompatibilityStatusResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_pb';

import { SexualCompatibilityState } from '@/components/sexual-compatibility/store/sexual-compatibility-state';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationSexualCompatibilitySetStatus: {
      compatibility: ExtractClassFields<GetSexualCompatibilityStatusResponse>;
      idUser: string;
    };
  }
}

export function mutationSexualCompatibilitySetStatus(
  state: SexualCompatibilityState,
  {
    compatibility,
    idUser,
  }: {
    compatibility: ExtractClassFields<GetSexualCompatibilityStatusResponse>;
    idUser: string;
  },
) {
  state.compatibilityStatuses[idUser] = compatibility;
}
