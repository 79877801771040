import { computed, ref } from 'vue';

import { paymentPurchase } from '@/components/payment/helpers/payment-method/payment-method';
import { getPackageProlongationOffer } from '@/components/prolongation-modal/helpers/prolongation/prolongation';
import {
  getSubscriptionDaysLeft,
  getSubscriptionTierName,
} from '@/helpers/subscription/subscription-type/subscription-type';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import { getNextPackage } from '@/pages/payment/helpers/package/package';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { PACKAGE_PREMIUM_DEFAULT_1_MONTH_ID } from '@/settings';
import { dispatch } from '@/store/store-helper';

type UseProlongationModalArg = {
  close: () => void;
  subscription: PaymentStateSubscription;
};
export function useProlongationModal(arg: UseProlongationModalArg) {
  // Ref -----------------------------------------------------------------------
  const tierName = getSubscriptionTierName(arg.subscription.subscription);
  const profileShort = ref<MyProfileStateShort>();

  // Computed ------------------------------------------------------------------
  const daysLeft = computed<number>(() => {
    if (arg.subscription) {
      return getSubscriptionDaysLeft(arg.subscription.subscription);
    }

    return 0;
  });

  // Method --------------------------------------------------------------------
  function purchasePackageNext(): void {
    const packageCurrent = arg.subscription?.currentPackage;
    if (packageCurrent) {
      const packageNext = getNextPackage(packageCurrent);
      if (packageNext) {
        if (packageNext.id === PACKAGE_PREMIUM_DEFAULT_1_MONTH_ID) {
          if (packageNext.offer?.id === undefined) {
            packageNext.offer = getPackageProlongationOffer(packageNext);
          }
        }
        paymentPurchase({
          pkg: packageNext,
        });
        return;
      }
    }

    arg.close();
  }

  function fetchProfileShort() {
    return dispatch('actionMyProfileGetShort');
  }

  async function init() {
    profileShort.value = await fetchProfileShort();
  }

  return {
    close: arg.close,
    tierName,
    profileShort,
    daysLeft,
    purchasePackageNext,
    init,
  };
}
