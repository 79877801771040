import { UseSnackButton } from '@/components/snack-button/hooks/use-snack-button/type/type';
import { useSearchPremium } from '@/pages/search/components/search-grid/components/search-filter-modal/hooks/use-search-premium/use-search-premium';

export function useSnackButtonSearch(): UseSnackButton {
  const { unlock } = useSearchPremium();

  const action = (): void => {
    unlock();
  };

  const { areFieldsLocked } = useSearchPremium();

  return {
    action,
    isVisible: areFieldsLocked,
  };
}
