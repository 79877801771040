import { Subscription } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/subscription_pb';

import { pushRoute } from '@/router';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { commit, dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export async function renewSubscription(): Promise<void> {
  try {
    await dispatch('actionPaymentActivateSubscription');
    commit('mutationPaymentSetSubscriptionRenewStatus', true);
    pushRoute('settingsSubscriptionRenewSuccess');
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to activate subscription',
      captureContext: { extra: { error } },
    });
  }
}

// Whether the user had paid for the subscription earlier.
export function isSubscriptionPayer(subscription: ExtractClassFields<Subscription>): boolean {
  return subscription.packageId !== '';
}
