import { I18n } from '@/services/i18n';

type Message = {
  howOpenGoogleChrome: string;
  inOrderChromeText1: string;
  inOrderChromeText2: string;
  activationEmail: string;
  whyActivateAccount: string;
  gotIt: string;
};

const messages: I18n<Message> = {
  en: {
    howOpenGoogleChrome: 'How can I open Google Chrome?',
    activationEmail:
      'We would like to make sure that you are a real person and have sent you an email. Please, follow the link in the email and it will get you back to MeetMyAge using your default browser.',
    whyActivateAccount: 'Why should I activate my account?',
    inOrderChromeText1:
      'In order to access the full version of <strong>MeetMyAge</strong>, you should tap the tripple dot button',
    inOrderChromeText2:
      'in the top right corner of the screen and select the “Open in Chrome” option',
    gotIt: 'Got it',
  },
  es: {
    howOpenGoogleChrome: '¿Cómo puedo abrir Google Chrome?',
    activationEmail:
      'Nos gustaría asegurarnos de que eres una persona real y te hemos enviado un correo electrónico. Por favor, sigue el enlace en el correo y te llevará de vuelta a MeetMyAge usando tu navegador predeterminado.',
    whyActivateAccount: '¿Por qué debo activar mi cuenta?',
    inOrderChromeText1:
      'Para acceder a la versión completa de <strong>MeetMyAge</strong>, debes tocar el botón de tres puntos',
    inOrderChromeText2:
      'en la esquina superior derecha de la pantalla y seleccionar la opción “Abrir en Chrome”',
    gotIt: 'Entendido',
  },
};

export { messages };
