<template>
  <div
    class="max-w-screen-xs mx-auto text-center h-14 flex items-center justify-center bg-white w-full"
  >
    <a
      href="#"
      data-test-id="btn_close"
      class="absolute left-6 text-dark-100"
      @click.prevent="emit('back')"
      ><i class="icon icon-arrow_left"></i
    ></a>
    <span class="text-tag font-medium text-dark">{{ t('bestPlan') }}</span>
  </div>

  <div class="bg-white flex-grow px-6">
    <PremiumTimer />

    <!-- Premium items start -->
    <div v-if="packageSelected">
      <PremiumItem
        v-model="packageSelected"
        :pkg="packageSelected"
        class="mb-4"
        @click="emit('select', 'package')"
      />
    </div>
    <!-- Premium items end -->

    <!-- Benefit start -->
    <div class="flex gap-7 items-center justify-between w-full py-4 border-t border-t-dark-400">
      <p class="text-strongcaption font-medium text-dark flex text-wrap">
        {{ t('helpBook') }}
      </p>

      <div class="cursor-pointer" @click="emit('select', 'gift')">
        <SvgIcon name="gift" class="w-[64px] h-[64px]" />
      </div>
    </div>

    <PremiumBenefitList />
    <!-- Benefit end -->
  </div>

  <div
    class="fixed max-w-screen-xs mx-auto left-0 right-0 bottom-0 h-[72px] bg-white px-6 pt-2 border-t"
  >
    <PurchaseContinue
      :is-autorefill-visible="false"
      btn-class="!bg-premium text-white"
      @select="emit('select', 'continue')"
      @next="emit('next')"
    >
      <template #continue> {{ t('continue') }} </template>
    </PurchaseContinue>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, PropType, Ref } from 'vue';

import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import PremiumBenefitList from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-checkout/components/premium-benefit-list/PremiumBenefitList.vue';
import PremiumItem from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/components/premium-item/PremiumItem.vue';
import PremiumTimer from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-timer/PremiumTimer.vue';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { useModel } from '@/hooks/use-model/use-model';
import PurchaseContinue from '@/pages/payment/components/layout/components/purchase-continue/PurchaseContinue.vue';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  packageSelected: {
    type: Object as PropType<PaymentStatePackagePremium>,
    required: true,
  },
});

const emit = defineEmits(['next', 'back', 'select', 'update:packageSelected']);

const { t } = useI18n({ messages });

const packageSelected: Ref<PaymentStatePackagePremium> = useModel(props, 'packageSelected', emit);

onMounted(() => {
  customEventTrigger({
    event: 'page_show',
    name: 'payment_flow',
    step: 'add',
  });
});
</script>
