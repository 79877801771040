import { I18n } from '@/services/i18n';

type Message = {
  cardAdded: string;
  profileVerified: string;
  activated: string;
};

const messages: I18n<Message> = {
  en: {
    cardAdded: 'Credit card was added successfully',
    profileVerified: 'Your profile is verified now',
    activated: '{tier} was activated',
  },
  es: {
    cardAdded: 'La tarjeta de crédito se ha añadido correctamente',
    profileVerified: 'Tu perfil está verificado ahora',
    activated: 'Se activó el {tier}',
  },
};

export { messages };
