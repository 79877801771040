import { Dialog } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { ActionContext } from 'vuex';

import { getUserId } from '@/helpers/auth/auth';
import {
  ChatState,
  ChatStateDialogPreview,
  ChatStateDialogUser,
  ChatStateDialogMessage,
  ChatStateDialogMessageContent,
} from '@/pages/chat/store/chat-state';
import {
  CHAT_LIMIT,
  getDialogFrom,
  getDialogsFrom,
  getMessagesFrom,
  getMessagesPosition,
  getMyMessageFrom,
  MESSAGES_LIMIT,
  resolveDialog,
} from '@/pages/chat/store/helpers/chat-helper';
import {
  getDialog,
  listDialogMessages,
  listDialogs,
  getGifts,
  ListMatches,
  makeDialogRead,
  sendMessage,
} from '@/services/api/chat/chat-api';
import { getUniqueId } from '@/services/api/common/common-api';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { commit, ActionFunction } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

type ActionChatSendMessagePayload = {
  chatId: string;
  content: ChatStateDialogMessageContent;
};

type ActionChatGetDialogsPayload = {
  userId: string;
  onlyActual: boolean;
};

declare module '@/store/store-helper' {
  interface Action {
    actionChatListMatches: ActionFunction<never, ExtractClassFields<Dialog>[]>;
    actionChatGetDialogs: ActionFunction<ActionChatGetDialogsPayload, ChatStateDialogPreview[]>;
    actionChatGetDialogUser: ActionFunction<
      {
        chatId: string;
        userId: string;
      },
      {
        dialogUser: ChatStateDialogUser;
        dialog: ChatStateDialogPreview;
      }
    >;
    actionChatGetMessages: ActionFunction<
      {
        chatId: string;
        userId: string;
      },
      ChatStateDialogMessage[]
    >;
    actionChatGetGifts: ActionFunction<never, ExtractClassFields<DialogGift>[]>;
    actionChatSendMessage: ActionFunction<ActionChatSendMessagePayload, ChatStateDialogMessage>;
    actionChatMakeDialogRead: ActionFunction<string, void>;
  }
}

export async function actionChatGetDialogs(
  { state }: ActionContext<ChatState, unknown>,
  { userId, onlyActual }: ActionChatGetDialogsPayload,
) {
  const result = await listDialogs({
    userId,
    dialogsFrom: state.lastDialogPosition,
    limit: CHAT_LIMIT,
    onlyActual,
  });

  const dialogs = getDialogsFrom(result);
  const lastDialogPosition = result.pagination?.next;
  commit('mutationChatAddDialogs', dialogs);
  commit('mutationChatSetLastDialogPosition', lastDialogPosition ?? '');

  return dialogs;
}

export async function actionChatListMatches({ state }: ActionContext<ChatState, unknown>) {
  try {
    const result = await ListMatches({
      matchesFrom: state.lastMatchPosition,
      limit: CHAT_LIMIT,
    });
    const matches = result.items;
    const position = result.pagination?.next ?? '';
    commit('mutationChatAddMatches', { matches, position });

    return matches;
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to load matches',
      captureContext: { extra: { error } },
    });
  }
}

export async function actionChatGetDialogUser(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ChatState, unknown>,
  { chatId, userId }: { chatId: string; userId: string },
) {
  const result = await getDialog({
    chatId,
    userId,
  });

  const dialogUser = getDialogFrom(result);
  const dialog = resolveDialog(result.dialog!);
  commit('mutationChatAddDialogUser', { chatId, dialogUser });
  commit('mutationChatAddDialogs', [dialog]);

  return {
    dialogUser,
    dialog,
  };
}

export async function actionChatGetMessages(
  { state }: ActionContext<ChatState, unknown>,
  { chatId, userId }: { chatId: string; userId: string },
) {
  const messagesFrom = getMessagesPosition(state.messages[chatId] || []);
  const result = await listDialogMessages({
    userId,
    chatId,
    messagesFrom,
    limit: MESSAGES_LIMIT,
  });

  const messages = getMessagesFrom(result);
  commit('mutationChatAddMessages', { chatId, messages });

  return messages;
}

export async function actionChatGetGifts() {
  const result = await getGifts();
  const gifts = result.gifts;
  commit('mutationChatAddGifts', gifts);

  return gifts;
}

export async function actionChatSendMessage(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ChatState, unknown>,
  { chatId, content }: ActionChatSendMessagePayload,
) {
  const userId = getUserId();
  const id = await getUniqueId(userId);
  await sendMessage({
    chatId,
    message: content,
    messageId: id,
    userId,
  });
  const message = getMyMessageFrom(content, id);
  commit('mutationChatAddMessage', {
    chatId,
    message,
  });

  return message;
}

export async function actionChatMakeDialogRead(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ChatState, unknown>,
  dialogId: string,
) {
  const userId = getUserId();

  await makeDialogRead(dialogId, userId);
  commit('mutationChatMakeDialogRead', dialogId);
}
