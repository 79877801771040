import { NotificationManagerGroup } from '@/services/notification-manager/helpers/type/type';

export const groupConfigs: NotificationManagerGroup[] = [
  {
    groupName: 'astro-romantic',
    notificationNames: ['astro', 'romantic'],
    rules: [
      {
        ruleName: 'combine',
        apply: 'show',
      },
    ],
  },
  {
    groupName: 'prioritized-and-stacked',
    notificationNames: ['visit', 'like', 'incoming-match', 'power-like', 'chat-message'],
    rules: [
      {
        ruleName: 'prioritize',
        apply: 'immediate',
        ruleOptions: {
          prioritizeNotificationNames: [
            'visit',
            'like',
            'incoming-match',
            'power-like',
            'chat-message',
          ],
        },
      },
      {
        ruleName: 'stack',
        apply: 'show',
        ruleOptions: {
          stackNotificationNames: ['visit', 'like', 'incoming-match', 'power-like', 'chat-message'],
        },
      },
    ],
  },
];
