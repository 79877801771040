type MemoizeOptions = {
  // Remove cache on resolved `Promise`
  removeCacheOnResolved?: boolean;

  // Cache TTL in ms ( cache will be removed after this time ). Default: 0 (no TTL)
  cacheTtlMs?: number;
};

const map = new Map();

/**
 * Memoizes the result of a function with given arguments.
 *
 * @param {Function} fn - The function to be memoized.
 * @param {Object} options - Optional options for memoization behavior.
 * @param {boolean} options.removeCacheOnResolved - If true, removes the cache entry when the result resolves (for promises).
 * @param {number} options.cacheTtlMs - Time-to-live for cache entries in milliseconds.
 *
 * @returns {Function} - The memoized function.
 */
export function memoize<T, A extends unknown[]>(fn: (...args: A) => T, options?: MemoizeOptions) {
  return function (this: unknown, ...args: A) {
    const ARGS_KEY = JSON.stringify(args);
    let argsMap: Map<unknown, unknown>;

    if (map.has(fn)) {
      argsMap = map.get(fn);
      if (argsMap && argsMap.has(ARGS_KEY)) {
        return argsMap.get(ARGS_KEY) as T;
      }
    } else {
      argsMap = new Map();
      map.set(fn, argsMap);
    }

    const res = fn.apply(this, args);
    if (res instanceof Promise && options?.removeCacheOnResolved) {
      res.finally(() => {
        argsMap.delete(ARGS_KEY);
      });
    }
    argsMap.set(ARGS_KEY, res);

    if (options?.cacheTtlMs) {
      setTimeout(() => {
        argsMap.delete(ARGS_KEY);
      }, options.cacheTtlMs);
    }

    return res as T;
  };
}

/**
 * Remove all cache entries if call without params
 *
 * @param fn - remove concrete fn if specified
 * @param args - remove concrete parameter cache if specified
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoizeClear<T extends (...args: any[]) => any>(fn?: T, ...args: unknown[]) {
  if (fn) {
    if (args?.length) {
      const argsMap = map.get(fn);
      if (argsMap) {
        const ARGS_KEY = JSON.stringify(args);
        argsMap.delete(ARGS_KEY);
      }
    } else {
      map.delete(fn);
    }
  } else {
    map.clear();
  }
}

/**
 * Caches the result of a function based on its arguments and key.
 * This fn can be used in cases when link to function instance can be changed
 * but you want to cache results based on arguments.
 *
 * @param {Function} fn - The function to memoize.
 * @param {MemoizeOptions} [options] - Optional settings for memoization.
 * @returns {Function} - The memoized function.
 */
export function memoizeFromArgs<T, A extends unknown[]>(
  fn: (...args: A) => T,
  options?: MemoizeOptions,
) {
  return function (this: unknown, ...args: A) {
    const ARGS_KEY = JSON.stringify(args);

    if (map.has(ARGS_KEY)) {
      return map.get(ARGS_KEY);
    }

    const res = fn.apply(this, args);

    if (res instanceof Promise && options?.removeCacheOnResolved) {
      res.finally(() => {
        map.delete(ARGS_KEY);
      });
    }
    map.set(ARGS_KEY, res);

    if (options?.cacheTtlMs) {
      setTimeout(() => {
        map.delete(ARGS_KEY);
      }, options.cacheTtlMs);
    }

    return res as T;
  };
}

export function memoizeClearAll() {
  map.clear();
}
