import { getCache, setCache } from '@/helpers/persistent-cache';

const CACHE_KEY = 'last_login_timestamp';
const DAY_IN_MS = 86_400_000;

function getCurrentTimestamp(): number {
  return new Date().getTime();
}

export function getLastLoginTimestamp(): number {
  const timeFromCache = getCache(CACHE_KEY);

  if (!timeFromCache) {
    const currentTime = getCurrentTimestamp();
    setCache(CACHE_KEY, currentTime.toString());
    return currentTime;
  }

  return parseInt(timeFromCache, 10);
}

export function hasFullDayPassed(timestamp: number): boolean {
  const diff = getCurrentTimestamp() - timestamp;
  return diff > DAY_IN_MS;
}

export function setLastLoginTimestamp(): void {
  const timestamp = getLastLoginTimestamp();

  if (hasFullDayPassed(timestamp)) {
    setCache(CACHE_KEY, getCurrentTimestamp().toString());
  }
}

export function updateLastLoginTimestamp(): void {
  setCache(CACHE_KEY, getCurrentTimestamp().toString());
}
