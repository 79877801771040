<template>
  <ModalPortal :background-scroll="false">
    <div
      data-test-id="power-match-modal"
      class="modal-dialog modal-dialog-full h-full modal_match power"
    >
      <div class="modal-content h-full">
        <div class="modal-body text-center flex flex-col">
          <Close @click="close"></Close>

          <div class="mt-auto mb-auto">
            <div class="modal_match-title">{{ t('chatRequest') }}</div>
            <div class="modal_match-img_group">
              <div class="modal_match-icon_center">
                <FontIcon class="icon-instant-chat_solid" />
              </div>
              <div class="modal_match-img modal_match-img_my">
                <RetinaPhoto
                  :photo-id="match.myAvatar?.id ?? ''"
                  :width="120"
                  :height="120"
                  img-width="100%"
                  alt="user's photo"
                ></RetinaPhoto>
              </div>
              <div class="modal_match-img modal_match-img_friend">
                <RetinaPhoto
                  :photo-id="match.avatar?.id ?? ''"
                  :width="120"
                  :height="120"
                  img-width="100%"
                  alt="user's photo"
                ></RetinaPhoto>
              </div>
            </div>
            <div class="modal_match-info">
              <span v-html="t('greetChatRequest', { name: match.name })"></span>
            </div>
            <div class="modal_match-btn mb-4">
              <button type="button" class="btn btn-light mb-0" @click="goChatPage">
                {{ t('startChat') }}
              </button>
            </div>
          </div>
          <ChatFooterIcebreaker
            class="power-ice-breaker !block rounded-lg"
            :ice-breakers="iceBreakers"
            @select="applyIceBreaker"
          />
        </div>
      </div>
    </div>
  </ModalPortal>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import Close from '@/components/layout/components/header/components/close/Close.vue';
import ModalPortal from '@/components/modal/ModalPortal.vue';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import ChatFooterIcebreaker from '@/pages/chat/components/chat-view/components/chat-footer/components/chat-footer-icebreaker/ChatFooterIcebreaker.vue';
import { useChatFooter } from '@/pages/chat/components/chat-view/components/chat-footer/hooks/use-chat-footer/use-chat-footer';
import { useStoreChatIceBreakers } from '@/pages/chat/store/hooks/use-store-chat-ice-breakers/use-store-chat-ice-breakers';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { MatchStateNotification } from '@/store/modules/possibility/possibility-state';

import { messages } from './i18n';

const props = defineProps({
  match: {
    required: true,
    type: Object as PropType<MatchStateNotification>,
  },
});

const emit = defineEmits(['close']);
const { t } = useI18n({ messages });
const iceBreakers = ref<string[]>([]);

function close() {
  emit('close');
}

function goChatPage() {
  const chatId = props.match.dialogId;
  pushRoute('chatView', { chatId });
  close();
}

async function loadIcebrakers(): Promise<void> {
  const { load, iceBreakers: ice } = useStoreChatIceBreakers(props.match?.userId);
  await load();
  iceBreakers.value = ice.value;
}

loadIcebrakers();

function applyIceBreaker(icebraker: string): void {
  sendTextMessage({
    messageText: icebraker,
    dialogId: props.match.dialogId,
  });
  pushRoute('chatView', { chatId: props.match.dialogId });
  emit('close');
}

const { sendTextMessage } = useChatFooter(() => ({}));
</script>

<style src="./style.scss" lang="scss" scoped></style>
