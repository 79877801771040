import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { useModal } from '@/services/modal/modal';
import { dispatch } from '@/store/store-helper';

import PremiumGoldPromoModal from '../../PremiumGoldPromoModal.vue';

export async function showPremiumGoldModal(payload: { source: PromoShowPurchaseSource }) {
  const subscription = await dispatch('actionPaymentGetSubscriptionStatus');
  const packageCurrent = subscription.currentPackage;

  const packages = await dispatch('actionPaymentListPremiumGoldPackages');
  let packageGold: PaymentStatePackagePremium | undefined = undefined;

  if (packageCurrent) {
    packageGold = packages.find((pkg) => pkg.label === packageCurrent.label);
  }

  if (!packageGold) {
    packageGold = packages[0];
  }

  if (packages.length) {
    const { showModal } = useModal(() => ({
      component: PremiumGoldPromoModal,
      props: {
        // Assume that package1 is for 1 month. TODO recheck
        pkg: packageGold,
      },
    }));

    showModal();
    trackPaymentPromo({
      type: 'premium',
      popup: 'premium-gold-promo',
      from: payload.source,
    });
  }
}
