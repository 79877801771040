import { createPromiseClient } from '@connectrpc/connect';
import { ReadAPIService } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_connect';
import { GetUniqueIdRequest } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';

const client = createPromiseClient(ReadAPIService, getGrpcWebTransport());

export async function getUniqueId(userId: string): Promise<string> {
  const request = new GetUniqueIdRequest({
    userId,
  });

  const response = await client.getUniqueId(request);

  return response.id;
}
