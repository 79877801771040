<template>
  <div :class="['notification-activity_avatar', `notification-activity_avatar_${form}`]">
    <div class="notification-activity_avatar_overflow">
      <RetinaPhoto
        :width="photoWidth"
        :height="photoHeight"
        :photo-id="photoId"
        :is-blurred="isLocked"
      />
    </div>
    <div v-if="profile?.isOnline" class="notification-activity_is_online"></div>
  </div>
</template>

<script lang="ts" setup>
import { SearchProfile } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/search_pb';
import { computed, PropType } from 'vue';

import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { ExtractClassFields } from '@/type';

const prop = defineProps({
  profile: {
    type: Object as PropType<ExtractClassFields<SearchProfile>>,
    required: false,
    default: () => ({}),
  },
  photoId: {
    type: String,
    required: true,
  },
  form: {
    type: String as PropType<'rounded' | 'square'>,
    default: 'square',
  },
  isLocked: {
    type: Boolean,
  },
});

const photoWidth = computed<number>(() => (prop.form === 'rounded' ? 60 : 74));
const photoHeight = computed<number>(() => (prop.form === 'rounded' ? 60 : 96));
</script>
