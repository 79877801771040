import { pushRealtimeTrackingCounter } from '@/services/realtime-tracking/realtime-tracking';

export function trackWebPushReferrer() {
  if (document.referrer.indexOf('sw.js') === -1) {
    return;
  }

  pushRealtimeTrackingCounter('webpush-referrer', {}, 1, {
    sendOnce: true,
  });
}
