import { NotificationCategory } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/connection/v1/notification_pb';

import { triggerNotificationUserIntro } from './helpers/notification-user-intro/notification-user-intro';

export function useNotificationActivity(): {
  trigger: (category: NotificationCategory) => void;
} {
  function trigger(category: NotificationCategory) {
    switch (category) {
      case NotificationCategory.USER_INTRO:
      default:
        triggerNotificationUserIntro(category);
    }
  }

  return {
    trigger,
  };
}
