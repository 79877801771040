import { ConnectError, Interceptor, Code } from '@connectrpc/connect';

import { getAccessToken, getRefreshToken, removeUserAuthData } from '@/helpers/auth/auth';
import { memoizeFromArgs } from '@/helpers/memoize/memoize';
import { navigate } from '@/helpers/navigator/navigator';
import {
  getRefreshTokenPromise,
  removeRefreshTokenPromise,
  updateRefreshToken,
} from '@/helpers/token/token';

export type GrpcMetadata = Partial<{
  Authorization: string;
  'Landing-Url': string;
  Rr: string;
  splitId?: string;
}>;

export const handleInterceptorAuth: Interceptor = (next) => async (req) => {
  if (req.method.name === 'SignIn') {
    return next(req);
  }

  function makeRequest() {
    req.header.set('Authorization', getAccessToken());

    const nextMemoized = memoizeFromArgs(next, { removeCacheOnResolved: true });

    return nextMemoized(req);
  }

  function redirectToSignin() {
    removeUserAuthData();
    import('@/router').then(({ resolveRoute }) => {
      navigate(resolveRoute('signin').fullPath);
    });
  }

  try {
    return await makeRequest();
  } catch (e) {
    const connectErr = ConnectError.from(e);
    if (connectErr.code === Code.Unauthenticated || connectErr.code === Code.PermissionDenied) {
      // Check if refreshToken exists
      const refreshToken = getRefreshToken();
      if (refreshToken !== '') {
        // Check if refresh token is refreshing by another request
        let promise = getRefreshTokenPromise();
        if (promise === null) {
          // If it's first request with access token expiration - run refresh
          promise = updateRefreshToken(refreshToken).catch(() => {
            // Case if refreshToken is not valid
            // Redirect on fail just for the first request to prevent multiple redirects
            removeRefreshTokenPromise();
            redirectToSignin();
          });
        }

        // Wait until we get new access token
        await promise;

        // Repeat request
        return makeRequest().finally(() => {
          removeRefreshTokenPromise();
        });
      }
      // Case if refreshToken is not exists
      redirectToSignin();
    } else if (connectErr.code === Code.Unknown) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          makeRequest().then(resolve).catch(reject);
        }, 500);
      });
    }

    // Throw other errors further
    throw e;
  }
};
