export function getGeolocationPosition(cacheSeconds = 3600) {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(Error('Geolocation is not supported by your browser'));
    } else {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        // cache geo position for 1 hour ( value in milliseconds )
        maximumAge: cacheSeconds * 1000,
        timeout: 5000,
        enableHighAccuracy: false,
      });
    }
  });
}

type Country = {
  isoCodeAlpha2: string;
  isoCodeAlpha3: string;
  name: string;
  geoNameId: string;
};

type City = {
  geoNameId: string;
  name: string;
};

type Location = {
  latitude: number;
  longitude: number;
};

type State = {
  geoNameId: string;
  isoCode: string;
  name: string;
};

export type Geo = {
  country: Country;
  city: City;
  location: Location;
  state: State;
};

export function fetchGeo(): Promise<Geo> {
  return fetch(import.meta.env.VUE_APP_UTIL_URL + '/locate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((response) => response.json());
}
