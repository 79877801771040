import { reactive, ref } from 'vue';

export type Rating = 'poor' | 'great' | 'wow';

type UseSelfArg = {
  //
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const rating = ref<Rating>();

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function setRating(value: Rating) {
    rating.value = value;
  }

  async function init() {
    //
  }

  return reactive({
    rating,
    setRating,
    init,
  });
}
