import * as realtimeTracking from '@project-gd-x/shared-web/src/helpers/realtime-tracking/realtime-tracking';
import { PushRealtimeTrackingOptions } from '@project-gd-x/shared-web/src/helpers/realtime-tracking/realtime-tracking';

import { sentryCaptureMessage } from '@/services/sentry/sentry';

export function initRealtimeTracking() {
  realtimeTracking.initRealtimeTracking({
    endpointUrl: import.meta.env.VUE_APP_REALTIME_TRACKING_URL as string,
  });
}

export function pushRealtimeTrackingCounter(
  eventName: string,
  labels: Record<string, string> = {},
  value = 1,
  options?: PushRealtimeTrackingOptions,
) {
  if (import.meta.env.MODE === 'test') {
    return Promise.resolve();
  }

  return realtimeTracking
    .pushRealtimeTrackingCounter(eventName, labels, value, options)
    .then((res) => {
      if (res && res.status !== 200) {
        res.text().then((text) => {
          sentryCaptureMessage({
            message: `REALTIME_TRACKING_ERROR: ${eventName}: ${text}`,
          });
        });
      }
    });
}
