import { computed } from 'vue';

import { UseSnackButton } from '@/components/snack-button/hooks/use-snack-button/type/type';
import WebViewModal from '@/components/web-view/web-view-modal/WebViewModal.vue';
import { useModal } from '@/services/modal/modal';
import { commit, getStore } from '@/store/store-helper';

export function useSnackButtonWebView(emit: (prop: 'hide', arg: boolean) => void): UseSnackButton {
  const action = (): void => {
    const { showModal, hideModal } = useModal(() => ({
      component: WebViewModal,
      on: {
        close: () => hideModal(),
      },
    }));

    showModal();
  };

  const close = (): void => {
    commit('mutationCommonSetWebViewBarAvailability', false);
    emit('hide', true);
  };

  const isVisible = computed((): boolean => {
    return getStore().state.common.webView.isBarAvailable;
  });

  return {
    isVisible,
    action,
    close,
  };
}
