<template>
  <PurchaseLayout
    v-if="selected"
    class="modal_credits premium"
    :pkg="selected"
    @select="select"
    @close="close"
    @next="purchasePremium"
  >
    <template #title>{{ t('header') }}</template>

    <template #slider-content>
      <div class="explanatory_slider no-bg">
        <div class="explanatory_slider-avatar">
          <RetinaImage
            class="img"
            :image-id="profile.avatar.id"
            :width="72"
            :height="72"
            format="png"
          />
        </div>
        <PurchaseSliderSubscription></PurchaseSliderSubscription>
      </div>
    </template>

    <template #default>
      <div class="billng_tab flex justify-center">
        <div class="billng_tab-item active !w-6/12">
          <div class="billng_tab-title !text-tag !font-medium">{{ t('premium') }}</div>
        </div>
        <div class="billng_tab-item !w-6/12" @click="goPremiumGoldPage">
          <div class="billng_tab-title !text-tag !font-medium">{{ t('premiumGold') }}</div>
        </div>
      </div>

      <div class="toggle_section" :class="{ 'toggle-open': isActive }">
        <PurchasePremiumItemDiscount
          v-for="pkg in packages"
          :key="pkg.id"
          v-model="selected"
          :pkg="pkg"
          :packages="packages"
        />
        <div class="toggle_section-panel flex justify-center">
          <span class="toggle_section-btn" @click="toggleSection">
            <template v-if="isActive">
              {{ t('fewerPlans') }} <FontIcon class="icon-chevron_top_solid" />
            </template>
            <template v-else>
              {{ t('morePlans') }} <FontIcon class="icon-chevron_down_solid" />
            </template>
          </span>
        </div>
      </div>

      <div v-if="selected?.price?.taxAmount" class="m-6 text-caption text-dark-200 text-center">
        {{ t('tax', { packageSelectedPriceFormatted, packageSelectedNextPriceFormatted }) }}
      </div>
    </template>
  </PurchaseLayout>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, ref } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import { payWithCard } from '@/components/payment/helpers/payment-method/payment-method';
import RetinaImage from '@/components/retina-image/RetinaImage.vue';
import { getFormattedCurrency } from '@/helpers/number';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import PurchaseSliderSubscription from '@/pages/payment/components/layout/components/purchase-slider-subscription/PurchaseSliderSubscription.vue';
import PurchaseLayout from '@/pages/payment/components/layout/PurchaseLayout.vue';
import PurchasePremiumItemDiscount from '@/pages/payment/components/purchase/components/purchase-premium/components/purchase-premium-item-discount/PurchasePremiumItemDiscount.vue';
import { getNextPackagePrice, getPackagePrice } from '@/pages/payment/helpers/package/package';
import { PaymentStateCard, PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { pushRoute, replacePrevRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { dispatch, getState } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const emit = defineEmits(['select', 'close']);

const { t } = useI18n({ messages });

const selected = ref<ExtractClassFields<PremiumPackage>>();
const status = ref<PaymentStateSubscription>();
const isActive = ref(false);

const packageSelectedPriceFormatted = computed(() => {
  if (selected.value) {
    const price = getPackagePrice(selected.value);
    return getFormattedCurrency(price.totalAmount, price.currency);
  }
  return '';
});

const packageSelectedNextPriceFormatted = computed(() => {
  if (selected.value) {
    const price = getNextPackagePrice(selected.value);
    return getFormattedCurrency(price.totalAmount, price.currency);
  }
  return '';
});

const packages = computed(() => {
  return getState().payment.premiumPackages;
});

const defaultPaymentCard = computed<PaymentStateCard>(() => {
  return getState().payment.paymentCards[0];
});

const profile = computed<MyProfileStateShort>(() => {
  return getState().myProfile.short;
});

function purchasePremium() {
  payWithCard({
    cardId: defaultPaymentCard.value?.id ?? '',
    pkg: selected.value!,
  });
}

function select() {
  emit('select', selected.value);
}

function close() {
  emit('close');
}

function goPremiumGoldPage() {
  replacePrevRoute('paymentPurchasePremiumGold');
}

function toggleSection() {
  isActive.value = !isActive.value;
}

Promise.all([
  dispatch('actionPaymentListPremiumPackages'),
  dispatch('actionPaymentGetSubscriptionStatus'),
]).then(([, statusArg]) => {
  status.value = statusArg as PaymentStateSubscription;

  // Second package should be selected.
  [, selected.value] = packages.value;

  const isInactive = status.value?.subscription.state.case === 'inactive';
  const expireDateSec = Number(status.value?.subscription.state.value?.expiresTime?.seconds);

  if (isInactive && expireDateSec > 0) {
    replacePrevRoute('paymentSubscription');
  }

  if (status.value?.subscription?.isActive) {
    pushRoute('search');
  }

  dispatch('actionMyProfileGetShort');
});
</script>

<style lang="scss" src="./style.scss"></style>
