<template>
  <ModalMenu
    :background-scroll="false"
    class-name="welcome_trial upsale_credits"
    data-test-id="popup_trial_up"
  >
    <template #title>
      <a href="#" class="web_push_modal-close" data-test-id="btn_close_up" @click.prevent="close">
        <FontIcon class="icon-close" />
      </a>
      <div class="header-title mb-4" data-test-id="popup_trial_up_title">
        {{ t('exclusiveOneTimeOffer') }}
      </div>
    </template>
    <template #body>
      <div class="flex justify-center mb-1" data-test-id="avatar_trial_up">
        <div class="rounded_img-inner brd-warning">
          <div class="rounded_img-body">
            <RetinaPhoto
              :photo-id="profile.avatar.id"
              :width="80"
              :height="80"
              group-ratio="avatar-square"
            ></RetinaPhoto>
          </div>
          <div class="rounded_img-icon">
            <SvgIcon name="coin" :width="32" :height="32" />
          </div>
        </div>
      </div>
      <div class="upsale_credits-title" data-test-id="popup_trial_up_extra_title">
        <span class="color-premium mb-2 d-block">
          {{ t('congratulations') }}<br />{{ profile.name }}!
        </span>
        <span
          class="color-gray-1 d-block text-button1 font-semibold"
          v-html="t('youHaveSuccessfullyTopped')"
        ></span>
      </div>
    </template>

    <h4 class="color-premium mb-2 d-block w-full" data-test-id="slider_title_trial_up">
      {{ t('subscriptionAdvantages') }}:
    </h4>
    <PurchaseSliderSubscription data-test-id="slider_trial_up" />

    <PurchaseContinue
      selected="card"
      :pkg="regularTrialPkg"
      :is-default-payment-card-visible="false"
      class="last-panel"
      data-test-id="subscribe_btn_trial_up"
      @next="purchaseTrialRegular"
      @select="purchaseTrialRegular"
      ><template #continue>{{ t('subscribeForJust', { price: regularTrialPrice }) }}</template>
    </PurchaseContinue>
  </ModalMenu>
</template>
<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>
<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, onUnmounted, PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import ModalMenu from '@/components/modal/modal-menu/ModalMenu.vue';
import { paymentPurchase } from '@/components/payment/helpers/payment-method/payment-method';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { getFormattedCurrency } from '@/helpers/number';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import PurchaseContinue from '@/pages/payment/components/layout/components/purchase-continue/PurchaseContinue.vue';
import PurchaseSliderSubscription from '@/pages/payment/components/layout/components/purchase-slider-subscription/PurchaseSliderSubscription.vue';
import { setTrialUpsaleSeenCache } from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-upsale-modal/helpers/trial-upsale/trial-upsale';
import { useI18n } from '@/services/i18n';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { dispatch, getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkgOne: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
  pkgTwo: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const profile = computed((): MyProfileStateShort => {
  return getStore().state.myProfile.short;
});

const { t } = useI18n({ messages });

const regularTrialPrice = computed<string>(() => {
  return getFormattedCurrency(
    regularTrialPkg.value.price?.totalAmount ?? 0n,
    regularTrialPkg.value.price?.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
});

const freeTrialPkg = computed<ExtractClassFields<PremiumPackage>>(() => {
  return [props.pkgOne, props.pkgTwo].find(
    (pkg: ExtractClassFields<PremiumPackage>) => Number(pkg.price) === 0,
  ) as ExtractClassFields<PremiumPackage>;
});

const regularTrialPkg = computed<ExtractClassFields<PremiumPackage>>(() => {
  // todo:change trial id after backend is ready on dev
  return [props.pkgOne, props.pkgTwo].find(
    (pkg: ExtractClassFields<PremiumPackage>) => Number(pkg.price) !== 0,
  ) as ExtractClassFields<PremiumPackage>;
});

function close(): void {
  emit('close');
  completeOffer();
}

function completeOffer(): void {
  setTrialUpsaleSeenCache();
  dispatch('actionPaymentCompleteOffers', [freeTrialPkg.value?.id, regularTrialPkg.value?.id]);
}

function purchaseTrialRegular(): void {
  paymentPurchase({
    pkg: regularTrialPkg.value,
  });
}

function trackShowTrialOnetimeOffer(): void {
  trackPaymentPromo({
    from: 'payment-success',
    popup: 'premium',
    type: 'premium',
  });
}

onUnmounted(() => {
  completeOffer();
});

dispatch('actionPaymentListPaymentCards');
dispatch('actionMyProfileGetShort');
trackShowTrialOnetimeOffer();
</script>

<style lang="scss" src="./style.scss"></style>
