import { reactive, ref } from 'vue';

import { Rating } from '@/components/trustpilot-modal/components/trustpilot-prereview/hooks/use-self/use-self';
import { myProfileApi } from '@/services/api/my-profile/my-profile-api';

type UseSelfArg = {
  //
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const url = ref('');
  const rating = ref<Rating>();

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function setRating(value: Rating) {
    rating.value = value;

    if (value === 'wow') {
      myProfileApi.submitTrustPilotReview();
    }
  }

  async function fetchReviewFormUrl() {
    url.value = (await myProfileApi.getTrustPilotReviewFormURL()).url;
  }

  async function init() {
    await fetchReviewFormUrl();
  }

  return reactive({
    url,
    rating,
    setRating,
    init,
  });
}
