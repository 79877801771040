import { GetCompatibilityStatusResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_pb';
import { ActionContext } from 'vuex';

import { CompatibilityState } from '@/components/astrology/store/compatibility-state';
import {
  activateCompatibilityStatus,
  getCompatibilityStatus,
} from '@/services/api/compatibility/compatibility-api';
import { commit, ActionFunction } from '@/store/store-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionCompatibilityActivate: ActionFunction<string, void>;
    actionCompatibilityGetStatus: ActionFunction<string, GetCompatibilityStatusResponse>;
  }
}

export async function actionCompatibilityGetStatus(
  {
    // eslint-disable-next-line
  state,
  }: ActionContext<CompatibilityState, unknown>,
  idUser: string,
): Promise<GetCompatibilityStatusResponse> {
  const response = await getCompatibilityStatus(idUser);

  commit('mutationCompatibilitySetStatus', {
    compatibility: response,
    idUser,
  });

  return response;
}

export async function actionCompatibilityActivate(
  {
    // eslint-disable-next-line
    state,
  }: ActionContext<CompatibilityState, unknown>,
  idUser: string,
): Promise<void> {
  await activateCompatibilityStatus(idUser);
}
