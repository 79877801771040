import Swiper from 'swiper';
import { computed, reactive } from 'vue';

import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { getUniqNumber } from '@/helpers/number';
import { useSplitHideGeo } from '@/services/growth-book/hooks/use-split-hide-geo/use-split-hide-geo';

type UseSelfArg = {
  feature?: SubscriptionFeature;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const id = getUniqNumber();

  const splitHideGeo = useSplitHideGeo();

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  const slideInitial = computed(() => {
    const featureList: SubscriptionFeature[] = [
      SubscriptionFeature.ADVANCED_INFORMATION,
      SubscriptionFeature.UNLIMITED_LIKES,
      SubscriptionFeature.ADVANCED_SEARCH,
      SubscriptionFeature.UNLIMITED_MESSAGES,
    ];

    if (splitHideGeo.isV2) {
      featureList.splice(1, 0, SubscriptionFeature.UNLOCK_LOCATION);
    }

    const index = featureList.indexOf(arg.feature as SubscriptionFeature);
    const messageIndex = featureList.indexOf(SubscriptionFeature.UNLIMITED_MESSAGES);

    return index === -1 ? messageIndex : index;
  });

  // Method --------------------------------------------------------------------
  function initSwiper() {
    new Swiper(`.swiper-gallery-container${id}`, {
      loop: false,
      initialSlide: slideInitial.value,
      pagination: {
        el: `.swiper-gallery-pagination${id}`,
        type: 'bullets',
        clickable: true,
        bulletClass: 'bullet',
        bulletActiveClass: 'active',
        renderBullet(index, className) {
          return `<div class="h-1.5 w-1.5 bg-white opacity-40 rounded-full ${className}"></div>`;
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });
  }

  async function init() {
    await splitHideGeo.init();
    initSwiper();
  }

  return reactive({
    id,
    init,
  });
}
