import TextPage from '@/pages/text-page/TextPage.vue';
import { GetTextPageArgumentPageId } from '@/services/api/page/page-api';
import { useModal } from '@/services/modal/modal';

export function getTextPageModal() {
  return useModal((pageId: GetTextPageArgumentPageId) => ({
    component: TextPage,
    props: {
      isModal: true,
      pageId,
    },
  }));
}
