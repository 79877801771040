import { commit } from '@/store/store-helper';

export function toggleActivityFavicon(hasActivity: boolean) {
  const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (link) {
    if (hasActivity) {
      link.href = '/meetmyage/message/favicon.ico';
    } else {
      link.href = '/meetmyage/favicon.ico';
    }
  }

  commit('mutationCommonSetHasActivity', hasActivity);
}
