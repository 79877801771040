<template>
  <ProlongationLayout
    v-if="prolongationModal.profileShort"
    :days-left="self.daysLeftNoMore7"
    :profile-short="prolongationModal.profileShort"
    @close="self.close"
  >
    <template #heading>
      <div class="text-headline6 font-semibold text-center mb-2">
        {{ t('expiring', { days: self.daysLeftNoMore7 }) }}
      </div>
    </template>

    <template #description>
      <div class="text-tag font-medium color-gray-1 text-center mb-6">
        {{
          t('getBonus', {
            bonus: self.creditsBonusCount,
            price: self.packageNextPriceFormatted,
          })
        }}
      </div>
    </template>

    <template #snippet>
      <div class="text-dark-200 text-overline font-medium leading-3 text-center mt-4">
        <span
          v-html="t('youCanCancelYourSubscription', { price: self.packageNextPriceFormatted })"
        ></span>
        <a
          href="#"
          class="text-dark-200 text-overline font-medium underline"
          @click.prevent="self.showMembershipModal()"
          >{{ t('membershipAndRefundPolicy') }}</a
        >.
      </div>
    </template>

    <PurchaseCreditsItemDiscount
      v-if="self.packageEarlyUpgrade"
      class="mt-5 mb-5 !py-2"
      :pkg="self.packageEarlyUpgrade"
      :model-value="self.packageEarlyUpgrade"
      :icon="4"
      :is-bonus="true"
    />

    <template #button-action>
      <LoaderButton
        :loading-duration-ms="self.hasCardList ? 0 : 2000"
        class="text-button1 font-semibold h-12 py-3 px-6 mb-5 rounded block w-full text-white mt-[32px] bg-accent shadow-lg shadow-accent/30"
        @click="prolongationModal.purchasePackageNext"
      >
        {{ t('getGift') }}
      </LoaderButton>
    </template>
  </ProlongationLayout>
</template>
<script lang="ts">
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>
<script lang="ts" setup>
import { PropType, reactive } from 'vue';

import LoaderButton from '@/components/inputs/loader-button/LoaderButton.vue';
import { useSelf } from '@/components/prolongation-modal/components/prolongation-early-upgrade-modal/hooks/use-self/use-self';
import ProlongationLayout from '@/components/prolongation-modal/components/prolongation-layout/ProlongationLayout.vue';
import PurchaseCreditsItemDiscount from '@/pages/payment/components/purchase/components/purchase-credits/components/purchase-credits-item-discount/PurchaseCreditsItemDiscount.vue';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });

const props = defineProps({
  subscription: {
    type: Object as PropType<PaymentStateSubscription>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const self = reactive(
  useSelf({
    subscription: props.subscription,
    close() {
      emit('close');
    },
  }),
);
const prolongationModal = reactive(self.prolongationModal);

self.init();
</script>
