<template>
  <ProlongationLayout
    v-if="prolongationModal.profileShort"
    :profile-short="prolongationModal.profileShort"
    :days-left="prolongationModal.daysLeft"
    @close="prolongationModal.close"
  >
    <template #heading>
      <div class="text-headline6 font-semibold text-center mb-2" data-test-id="canceled_title">
        {{ t('cancelled', { tier: prolongationModal.tierName }) }}
      </div>
    </template>
    <template #description>
      <div
        class="text-tag font-medium color-gray-1 text-center mb-6"
        data-test-id="canceled_subtitle"
      >
        {{ t('wouldYouLikeToTakeCancelled') }}
      </div>
    </template>
    <template #button-action>
      <button
        class="btn btn-block btn-send"
        data-test-id="canceled_reactivate_btn"
        @click="self.subscribePackageNext()"
      >
        {{ t('reactivate') }}
      </button>
      <div class="text-overline text-center color-gray-1 mt-6" data-test-id="canceled_exp_date">
        {{ t('premiumStillActive', { date: self.dateExpiresFormatted }) }}
      </div>
    </template>
  </ProlongationLayout>
</template>
<script lang="ts" setup>
import { PropType, reactive } from 'vue';

import { useSelf } from '@/components/prolongation-modal/components/prolongation-cancelled-modal/hooks/use-self/use-self';
import ProlongationLayout from '@/components/prolongation-modal/components/prolongation-layout/ProlongationLayout.vue';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  subscription: {
    type: Object as PropType<PaymentStateSubscription>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const self = reactive(
  useSelf({
    subscription: props.subscription,
    close() {
      emit('close');
    },
  }),
);
const prolongationModal = reactive(self.prolongationModal);

self.init();
</script>

<style scoped></style>
