import { computed, reactive, ref } from 'vue';

import { getGrowthBookFeatureValue, GrowthBookFeatures } from '@/services/growth-book/growth-book';

export function useSplitHideGeo() {
  const variant = ref<GrowthBookFeatures['hide-geo']>('def');
  const isInited = ref(false);

  const isV2 = computed(() => variant.value === 'v2');

  async function init() {
    try {
      variant.value = await getGrowthBookFeatureValue('hide-geo', 'def');
    } finally {
      isInited.value = true;
    }
  }

  return reactive({
    variant,
    isInited,
    init,
    isV2,
  });
}
