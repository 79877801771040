import {
  handlePowerLikeFailEvent,
  handlePowerLikeSuccessEvent,
} from '@/components/power-like/helpers/power-like-websocket/helpers/power-like-websocket-handlers/power-like-websocket-handlers';
import { NotificationHandler } from '@/services/websocket';
import { dispatch } from '@/store/store-helper';

export const powerLikeNotificationHandler: NotificationHandler = async (notification) => {
  switch (notification.notification.case) {
    case 'sendPowerLikeSuccess':
      handlePowerLikeSuccessEvent(notification.notification.value);
      break;

    case 'sendPowerLikeFail':
      handlePowerLikeFailEvent(notification.notification.value);
      break;

    case 'powerLikeAccepted':
      dispatch('actionProfileGetConnectionState', notification.notification.value.userId);
      break;

    case 'powerLikeDeclined':
      dispatch('actionProfileGetConnectionState', notification.notification.value.userId);
      break;
  }
};
