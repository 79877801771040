import { I18n } from '@/services/i18n';

type Message = {
  hint: string;
};

const messages: I18n<Message> = {
  en: {
    hint: 'Sending one of these ice-breakers might cause some interesting conversation',
  },
  es: {
    hint: 'Enviar uno de estos rompehielos podría causar una conversación interesante',
  },
};

export { messages };
