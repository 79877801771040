export const CACHE_PREFIX = 'gdx_';

export function setSessionCache(key: string, value: string) {
  sessionStorage.setItem(CACHE_PREFIX + key, value);
}

export function getSessionCache(key: string): string | null {
  return sessionStorage.getItem(CACHE_PREFIX + key);
}

export function removeSessionCache(key: string): void {
  sessionStorage.removeItem(CACHE_PREFIX + key);
}

export function hasSessionCache(key: string): boolean {
  return getSessionCache(key) !== null;
}
