<template>
  <div
    data-id="notification-match"
    class="alert-info flex power-like"
    @click="goChatPage(info.dialogId)"
  >
    <div class="alert-info-icon upload flex items-center justify-center">
      <div class="img-section">
        <FontIcon class="icon-instant-chat_solid" />
      </div>
    </div>
    <div class="alert-info-body grow flex items-center">
      <div class="alert-info-section">
        {{ t('match') }}
      </div>
    </div>
    <div class="alert-info-right">
      <div class="alert-info-right_link color-red-5">
        {{ t('chat') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import { NotificationDataMatch } from '@/components/notification/helpers/notification-shower/notification-shower';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  info: {
    type: Object as PropType<NotificationDataMatch>,
    required: true,
  },
});

const { t } = useI18n({ messages });

function goChatPage(chatId: string): void {
  pushRoute('chatView', { chatId });
  customEventTrigger({
    event: 'notification_power_like_click',
  });
}
</script>
