import * as actions from './common-actions';
import * as mutations from './common-mutations';
import { CommonState, state } from './common-state';

const getters = {
  getterCommonAreLikesAvailable(state: CommonState): boolean {
    return state.index.likesLimitRemainCount > 0 || state.index.isPremiumActive;
  },
};

export const common = {
  state,
  mutations,
  actions,
  getters,
};
