import { I18n } from '@/services/i18n';

type Message = {
  newLike: string;
  youHaveSomeThings: string;
  meet: string;
};

const messages: I18n<Message> = {
  en: {
    newLike: 'New like from {name}',
    youHaveSomeThings: 'You have some things in common. How about paying a visit to their profile?',
    meet: 'Meet {name}',
  },
  es: {
    newLike: 'Nuevo "me gusta" de {name}',
    youHaveSomeThings: 'Tienen algunas cosas en común. ¿Qué tal una visita a su perfil?',
    meet: 'Conoce a {name}',
  },
};

export { messages };
