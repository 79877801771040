<template>
  <PaymentMethodList
    v-if="page === 'list'"
    :pkg="pkg"
    :cards="parsedCards"
    :is-subscription="isSubscription"
    @back="back"
    @add="setPage('add')"
  />
  <PaymentMethodAddPaypalFirst
    v-else
    :pkg="pkg"
    :is-google-pay-visible="isGooglePayVisible"
    :is-apple-pay-visible="isApplePayVisible"
    :is-subscription="isSubscription"
    @back="backAdd"
  />
</template>
<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>
<script lang="ts" setup>
import { computed, PropType, ref, unref } from 'vue';

import PaymentMethodAddPaypalFirst from '@/pages/payment/components/payment-method/components/payment-method-add-paypal-first/PaymentMethodAddPaypalFirst.vue';
import PaymentMethodList from '@/pages/payment/components/payment-method/components/payment-method-list/PaymentMethodList.vue';
import { PaymentStateCard, PaymentStatePackage } from '@/pages/payment/store/payment-state';

type Page = 'list' | 'add';

const props = defineProps({
  isGooglePayVisible: {
    type: Boolean,
    default: true,
  },
  isApplePayVisible: {
    type: Boolean,
    default: true,
  },
  cards: {
    type: Array as PropType<PaymentStateCard[]>,
    required: true,
  },
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    required: true,
  },
  isSubscription: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['back', 'close']);

const page = ref<Page>('add');

const parsedCards = computed<PaymentStateCard[]>(() => {
  return unref(props.cards);
});

const hasCards = computed<boolean>(() => {
  return parsedCards.value.length > 0;
});

function back() {
  emit('back');
  emit('close');
}

function backAdd() {
  if (hasCards.value) {
    setPage('list');
  } else {
    back();
  }
}

function setPage(pageNew: Page) {
  page.value = pageNew;
}

if (hasCards.value) {
  setPage('list');
} else {
  setPage('add');
}
</script>
