import { createPromiseClient } from '@connectrpc/connect';
import { ProfileAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/profile/v1/profile_api_connect';
import {
  GetProfilePreviewRequest,
  GetProfilePreviewResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/profile/v1/profile_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';

const client = createPromiseClient(ProfileAPI, getGrpcWebTransport());

export function getProfilePreview(userId: string): Promise<GetProfilePreviewResponse> {
  const request = new GetProfilePreviewRequest({
    userId,
  });

  return client.getProfilePreview(request);
}
