// key: From, value: To
import { RouteName } from '@/router';

const disabledRoutes: Map<RouteName, RouteName> = new Map([
  ['search', 'signupFilledInMatch'],
  ['signupFilledInMatch', 'signupMyProfileSteps'],
  ['signupMyProfileSteps', 'signupAccountSteps'],
]);

export function isNextRestricted(fromName: RouteName, toName: RouteName): boolean {
  return disabledRoutes.get(fromName) === toName;
}
