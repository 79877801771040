import { AccountStatus } from '@project-gd-x/dating-site-contracts/src/gen/gdx/common/v1/profile_pb';
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { FeatureType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/market/v1/package_pb';

import { assignObject } from '@/helpers/assign/assign';
import { PaymentStateBooster } from '@/pages/payment/store/payment-state';
import { ExtractClassFields } from '@/type';

import {
  CommonState,
  CommonStateCreditsFeatures,
  CommonStateIndex,
  CommonStatePromoModal,
} from './common-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationCommonSetState: CommonStateIndex;
    mutationCommonDecreaseRemainingLikesCount: never;
    mutationCommonSetStateNewLikesCount: number;
    mutationCommonSetStateNewChatMessagesCount: number;
    mutationCommonShowPromoModal: Partial<CommonStatePromoModal>;
    mutationCommonShowBoosterPromoModal: PaymentStateBooster;
    mutationCommonMarkBoosterPromoModalShown: never;
    mutationCommonHideBoosterPromoModal: never;
    mutationCommonSetPromoModalType: PromoType;
    mutationCommonSetPromoModalPackages: ExtractClassFields<PremiumPackage>[];
    mutationCommonSetHasActivity: boolean;
    mutationCommonSetWebViewBarAvailability: boolean;
    mutationCommonSetWebViewModalVisibility: boolean;
    mutationCommonSetAccountStatus: AccountStatus;
    mutationCommonSetCreditsFeatures: CommonStateCreditsFeatures;
    mutationCommonDecreaseFeature: FeatureType;
    mutationCommonSetIsPremiumActive: boolean;
  }
}

export function mutationCommonSetState(state: CommonState, payload: CommonStateIndex): void {
  assignObject(state.index, payload);
}

export function mutationCommonSetStateNewLikesCount(
  state: CommonState,
  newLikesCount: number,
): void {
  state.index.newLikesCount = newLikesCount;
}

export function mutationCommonSetStateNewChatMessagesCount(
  state: CommonState,
  newChatMessagesCount: number,
): void {
  state.index.newChatMessagesCount = newChatMessagesCount;
}

export function mutationCommonDecreaseRemainingLikesCount(state: CommonState): void {
  if (state.index.likesLimitRemainCount > 0) {
    state.index.likesLimitRemainCount -= 1;
  }
}

export function mutationCommonShowPromoModal(
  state: CommonState,
  payload: Partial<CommonStatePromoModal>,
): void {
  state.promoModal.isBackgroundScrollable = false;
  state.promoModal.isModalVisible = true;
  state.promoModal = { ...state.promoModal, ...payload };
}

export function mutationCommonShowBoosterPromoModal(
  state: CommonState,
  booster: PaymentStateBooster,
): void {
  state.boosterPromoModal.isModalVisible = true;
  state.boosterPromoModal.shownTimeMs = +new Date();
  state.boosterPromoModal.booster = booster;
}

export function mutationCommonMarkBoosterPromoModalShown(state: CommonState): void {
  state.boosterPromoModal.shownTimeMs = +new Date();
}

export function mutationCommonHideBoosterPromoModal(state: CommonState): void {
  state.boosterPromoModal.isModalVisible = false;
}

export function mutationCommonSetPromoModalType(state: CommonState, type: PromoType): void {
  state.promoModal.type = type;
}

export function mutationCommonSetPromoModalPackages(
  state: CommonState,
  packages: ExtractClassFields<PremiumPackage>[],
): void {
  state.promoModal.packages = packages;
}

export function mutationCommonSetHasActivity(state: CommonState, hasNewActivity: boolean): void {
  state.index.hasNewActivity = hasNewActivity;
}

export function mutationCommonSetWebViewBarAvailability(
  state: CommonState,
  isBarAvailable: boolean,
): void {
  state.webView.isBarAvailable = isBarAvailable;
}

export function mutationCommonSetWebViewModalVisibility(
  state: CommonState,
  isModalVisible: boolean,
): void {
  state.webView.isModalVisible = isModalVisible;
}

export function mutationCommonSetAccountStatus(
  state: CommonState,
  accountStatus: AccountStatus,
): void {
  state.index.status = accountStatus;
}

export function mutationCommonSetCreditsFeatures(
  state: CommonState,
  creditsFeatures: CommonStateCreditsFeatures,
): void {
  state.creditsFeatures = creditsFeatures;
}

export function mutationCommonDecreaseFeature(state: CommonState, feature: FeatureType): void {
  const count = state.creditsFeatures[feature];
  if (count) {
    state.creditsFeatures[feature] = count - 1;
  }
}

export function mutationCommonSetIsPremiumActive(
  state: CommonState,
  isPremiumActive: boolean,
): void {
  state.index.isPremiumActive = isPremiumActive;
}
