import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { getState, getStore } from '@/store/store-helper';

export function hasPremium(): boolean {
  // `isPremiumActive` updates every time you call `actionPaymentGetSubscriptionStatus`
  return getState().common.index.isPremiumActive;
}

export function updatePremiumActive({ subscription }: PaymentStateSubscription) {
  const premiumSeconds = Number(subscription.expiresDuration?.seconds ?? 0);
  if (premiumSeconds > 0) {
    getStore().commit('mutationCommonSetIsPremiumActive', true);
  }
}
