import {
  GetFullProfileResponse,
  GetShortProfileResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';

import { MyProfileStateFull, MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import {
  SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
} from '@/settings';

export function getShortProfileFrom(short: GetShortProfileResponse): MyProfileStateShort {
  const avatar = short.avatar;

  return {
    id: short.id,
    avatar: {
      id: avatar?.id ?? '',
      hasBlur: avatar?.hasBlur ?? false,
      isDummy: avatar?.isDummy ?? false,
      isDeclined: avatar?.isDeclined ?? false,
      declineReason: avatar?.declineReason ?? '',
      tags: avatar?.tags ?? [],
    },
    name: short.name,
    age: short.age,
    creditsCount: short.creditsCount,
    boosterEndsDurationMs: Number(short.boosterEndsDuration?.seconds ?? 0) * 1000,
    boosterEndsTimeMs: Number(short.boosterEndsTime?.seconds ?? 0) * 1000,
    zodiac: short.zodiac ?? '',
    topProfileMode: short.topProfileMode,
  };
}

export function getFullProfileFrom(response: GetFullProfileResponse): MyProfileStateFull {
  const profile = response.profile;

  if (!profile) {
    throw Error('profile is absent');
  }

  const location = profile.location;
  const matchLocation = profile.matchLocation;

  return {
    id: profile.userId,
    gender: profile.gender,
    about: profile.about,
    sphereOfWork: profile.sphereOfWork,
    position: profile.position,
    interests: profile.interests,
    birthPlace: profile.birthPlace,
    education: profile.education,
    religion: profile.religion,
    sexualOrientation: profile.sexualOrientation,
    maritalStatus: profile.maritalStatus,
    ethnicity: profile.ethnicity,
    heightValue: profile.height?.value ?? 0,
    bodyType: profile.bodyType,
    hairColor: profile.hairColor,
    children: profile.children,
    drinking: profile.drinking,
    smoking: profile.smoking,
    location: {
      latitude: location?.latitude ?? SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
      longitude: location?.longitude ?? SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
      city: location?.city ?? '',
      country: location?.country ?? '',
      state: location?.state ?? '',
    },
    matchLocation: {
      latitude: matchLocation?.latitude ?? SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
      longitude: matchLocation?.longitude ?? SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
      city: matchLocation?.city ?? '',
      country: matchLocation?.country ?? '',
      state: matchLocation?.state ?? '',
    },
    isLocationUpdateAllowed: profile.isLocationUpdateAllowed,
    isLocationDetectionAllowed: profile.isLocationDetectionAllowed,
    photos: profile.photos,
    name: profile.name,
    age: profile.age,
    matchGender: profile.matchGender,
    lookingFor: profile.lookingFor,
    eyeColor: profile.eyeColor,
    sport: profile.sport,
    educationalInstitution: profile.educationalInstitution,
    zodiac: profile.zodiac ?? '',
    timeCreated: Number(profile.createdTime?.seconds ?? Math.round(new Date().getTime() / 1000)),
  };
}
