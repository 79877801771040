import { ProfileGender } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import {
  SignupStateMyProfile,
  SignupState,
  SignupStateLocation,
} from '@/pages/signup/store/signup-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationSignupSetMatchGender: ProfileGender;
    mutationSignupSetMyEmail: string;
    mutationSignupSetMyPassword: string;
    mutationSignupSetMyProfileField: MyProfileMutation;
    mutationSignupSetMyLocation: SignupStateLocation;
    mutationSignupSetIsLocationDetectionAllowed: boolean;
    mutationSignupSetIsLocationUpdateAllowed: boolean;
    mutationSignupAddMyPhoto: string;
    mutationSignupDeleteMyPhoto: string;
    mutationSignupSetIsEngagementLikeUsed: boolean;
  }
}

export type MyProfileMutation = Partial<SignupStateMyProfile>;

export function mutationSignupSetMatchGender(state: SignupState, payload: ProfileGender) {
  state.matchProfile.gender = payload;
}

export function mutationSignupSetMyEmail(state: SignupState, payload: string) {
  state.email = payload;
}

export function mutationSignupSetMyPassword(state: SignupState, payload: string) {
  state.password = payload;
}

export function mutationSignupSetMyLocation(state: SignupState, payload: SignupStateLocation) {
  state.location = payload;
}

export function mutationSignupSetIsLocationDetectionAllowed(state: SignupState, payload: boolean) {
  state.isLocationDetectionAllowed = payload;
}

export function mutationSignupSetIsLocationUpdateAllowed(state: SignupState, payload: boolean) {
  state.isLocationUpdateAllowed = payload;
}

export function mutationSignupSetMyProfileField(state: SignupState, payload: MyProfileMutation) {
  Object.keys(payload).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.myProfile[key] = payload[key];
  });
}

export function mutationSignupAddMyPhoto(state: SignupState, photoId: string) {
  state.photoIds.push(photoId);
}

export function mutationSignupDeleteMyPhoto(state: SignupState, photoId: string) {
  if (state.photoIds.length) {
    const index = state.photoIds.findIndex((id) => id === photoId);
    if (index !== -1) {
      state.photoIds.splice(index, 1);
    }
  }
}

export function mutationSignupSetIsEngagementLikeUsed(
  state: SignupState,
  isEngagementLikeUsed: boolean,
) {
  state.isEngagementLikeUsed = isEngagementLikeUsed;
}
