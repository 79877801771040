<template>
  <ProlongationLayout
    v-if="prolongationModal.profileShort"
    :profile-short="prolongationModal.profileShort"
    :days-left="prolongationModal.daysLeft"
    @close="prolongationModal.close"
  >
    <template #heading>
      <div
        class="text-headline6 font-semibold text-center mb-2"
        v-html="t('expired', { tier: prolongationModal.tierName })"
      ></div>
    </template>
    <template #description>
      <div class="text-tag font-medium color-gray-1 text-center mb-6">
        {{ t('wouldYouLikeToTakeExpired') }}
      </div>
    </template>
    <template #button-action>
      <LoaderButton
        :loading-duration-ms="self.hasCardList ? 0 : 2000"
        class="btn btn-block btn-send"
        @click="prolongationModal.purchasePackageNext"
      >
        {{ t('upgrade') }}
      </LoaderButton>
    </template>
  </ProlongationLayout>
</template>
<script lang="ts" setup>
import { PropType, reactive } from 'vue';

import LoaderButton from '@/components/inputs/loader-button/LoaderButton.vue';
import { useSelf } from '@/components/prolongation-modal/components/prolongation-expired-modal/hooks/use-self/use-self';
import ProlongationLayout from '@/components/prolongation-modal/components/prolongation-layout/ProlongationLayout.vue';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  subscription: {
    type: Object as PropType<PaymentStateSubscription>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const self = reactive(
  useSelf({
    subscription: props.subscription,
    close() {
      emit('close');
    },
  }),
);
const prolongationModal = reactive(self.prolongationModal);

self.init();
</script>

<style scoped></style>
