<template>
  <div class="payment-secure-info">
    <i class="visa-secure">
      <img src="@/assets/img/solid-form/visa_secure.svg?url" width="30" alt="" />
    </i>
    <i class="mcc-id-check">
      <img src="@/assets/img/solid-form/mcc_id_check.svg?url" width="76" alt="" />
    </i>
    <i class="ssl">
      <img src="@/assets/img/solid-form/ssl.svg?url" width="30" alt="" />
    </i>
    <i class="pci-dss">
      <img src="@/assets/img/solid-form/pci_dss.svg?url" width="45" alt="" />
    </i>
    <i class="norton">
      <img src="@/assets/img/solid-form/norton.svg?url" width="45" alt="" />
    </i>
    <i class="mc-affee">
      <img src="@/assets/img/solid-form/mcaffee_secure.svg?url" width="60" alt="" />
    </i>
  </div>
</template>
