import { setCache, getCache } from '@/helpers/persistent-cache';

export const SENT_TEXT_MESSAGES_COUNT = 'sent_text_messages_count';
const COUNT_WHEN_PROMO_GIFT_CAN_BE_SHOWN = 5;

export function getSentMessagesCache(): { [key: string]: number } | undefined {
  const cache = getCache(SENT_TEXT_MESSAGES_COUNT);

  if (cache) {
    return JSON.parse(cache);
  }
}

export function getSentTextMessagesCount(dialogId: string): number {
  return getSentMessagesCache()?.[dialogId] ?? 0;
}

export function incrementMessagesCountInCache(dialogId: string): void {
  const data = { ...getSentMessagesCache(), [dialogId]: getSentTextMessagesCount(dialogId) + 1 };
  setCache(SENT_TEXT_MESSAGES_COUNT, JSON.stringify(data));
}

export function isChatPromoGiftVisible(dialogId: string): boolean {
  if (getSentTextMessagesCount(dialogId) > COUNT_WHEN_PROMO_GIFT_CAN_BE_SHOWN) return false;

  incrementMessagesCountInCache(dialogId);

  return getSentTextMessagesCount(dialogId) === COUNT_WHEN_PROMO_GIFT_CAN_BE_SHOWN;
}
