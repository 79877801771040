<template>
  <template v-if="profile">
    <NotificationActivity :category="'message'" @action="goChatPage">
      <template #icon>
        <NotificationActivityIcon v-if="contentType === 'text'" :name="'chat_solid'" />
        <NotificationActivityIcon v-else-if="contentType === 'gift'" :name="'gift_solid'" />
        <NotificationActivityIcon v-else-if="contentType === 'image'" :name="'photo_solid'" />
      </template>
      <template #avatar>
        <NotificationActivityAvatar
          :is-locked="true"
          :form="'square'"
          :photo-id="profile.avatar?.fileId ?? ''"
        />
      </template>
      <template #header>
        <div v-if="contentType === 'text'">{{ t('messageFrom') }} {{ profile.name }}</div>
        <div v-else-if="contentType === 'gift'">{{ t('giftFrom') }} {{ profile.name }}</div>
        <div v-else-if="contentType === 'image'">{{ t('photoFrom') }} {{ profile.name }}</div>
      </template>
      <template #description>
        <div>
          <div
            v-if="contentType === 'text'"
            class="text-ellipsis overflow-hidden"
            style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical"
          >
            {{ contentText }}
          </div>
          <div v-else-if="contentType === 'gift'">{{ t('iHaveSentGift') }}</div>
          <div v-else-if="contentType === 'image'">{{ t('iHaveSentPhoto') }}</div>
        </div>
      </template>
      <template #action>{{ t('reply') }}</template>
    </NotificationActivity>
  </template>
</template>

<script lang="ts" setup>
import { ProfilePreview } from '@project-gd-x/dating-site-contracts/src/gen/gdx/common/v1/profile_pb';
import { computed, onMounted, PropType, ref } from 'vue';

import NotificationActivityAvatar from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-avatar/NotificationActivityAvatar.vue';
import NotificationActivityIcon from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-icon/NotificationActivityIcon.vue';
import NotificationActivity from '@/components/notification/components/notification-redesign/notification-activity/NotificationActivity.vue';
import { NotificationDataMessage } from '@/components/notification/helpers/notification-shower/notification-shower';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { pushRoute } from '@/router';
import { getProfilePreview } from '@/services/api/root/profile/profile-api';
import { useI18n } from '@/services/i18n';
import { hideAllModals } from '@/services/modal/modal';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const { t } = useI18n({ messages });

const props = defineProps({
  info: {
    type: Object as PropType<NotificationDataMessage>,
    required: true,
  },
});

const profile = ref<ExtractClassFields<ProfilePreview>>();

const contentType = computed(() => {
  return props.info?.message.content?.content.case;
});

const contentText = computed(() => {
  if (props.info?.message.content?.content.case === 'text') {
    return props.info?.message.content?.content.value;
  }

  return '';
});

function goChatPage(): void {
  pushRoute('chatView', {
    chatId: props.info.dialogId,
  });
  hideAllModals();
  customEventTrigger({
    event: 'notification_message_click',
  });
}

getProfilePreview(props.info.message.senderId).then((response) => {
  const result = response.profile;
  if (result) {
    profile.value = result;
  }
});

onMounted(() => {
  customEventTrigger({
    event: 'notification_message_show',
  });
});
</script>
