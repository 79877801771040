import { AppConfig, Component } from 'vue';
import { DynamicModalOptions } from 'vue-final-modal';

import { getApp } from '@/helpers/app/app';
import { router, Routes } from '@/router';
import { sentryCaptureMessage } from '@/services/sentry/sentry';

import DynamicModalContainer from './components/DynamicModalContainer.vue';
import { Bind } from './types';

export type ModalComponent = Component & {
  isPaymentModal?: boolean;
  isForceAllowedModal?: boolean;
};

export const deniedRoutes: (keyof Routes)[] = [
  'paymentSubscription',
  'paymentPurchaseCredits',
  'paymentPurchasePremium',
  'paymentPurchasePremiumGold',
  'settingsPaymentMethod',
  'signupAccountSteps',
  'signupFillOutProfile',
  'signupMyProfile',
  'signupMyProfileSteps',
  'signupFilledInMatch',
  'paymentFail',
  'paymentSuccess',
  'paymentWaiting',
];

const allowedNotifications = [
  'credits-added',
  'subscription-payment-success',
  'subscription-payment-fail',
];

function isDeniedRoute(allowed: boolean): boolean {
  const routeName = router.currentRoute.value.name as keyof Routes;

  if (deniedRoutes.includes(routeName)) {
    return !allowed;
  }

  return false;
}

export function getOpenedModalsNames(): string[] {
  return openedModals.map((value: Component) => getModalName(value));
}

export function getModalName(modal: ModalComponent): string {
  const newObj = Object.entries(modal).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'string') {
        Object.assign(acc, { [key]: value });
      }

      return acc;
    },
    {} as Record<string, string>,
  );

  return JSON.stringify(newObj);
}

export function isModalVisible(modal: ModalComponent | string): boolean {
  const app: AppConfig = getApp();
  const name = typeof modal === 'string' ? modal : getModalName(modal);

  try {
    return app.globalProperties.modalService.get(name).length > 0;
  } catch (error) {
    sentryCaptureMessage({
      message: "App instance is not defined, can't get modal",
      captureContext: { extra: { error } },
    });
  }
  return false;
}

function removeModalFromOpened(modal: ModalComponent) {
  const modalIndex = openedModals.indexOf(modal);

  if (modalIndex !== -1) {
    openedModals.splice(modalIndex, 1);
  }
}

export function hideConcreteModal(modal: ModalComponent | null): void {
  if (!modal) return;

  const app: AppConfig = getApp();
  const name = getModalName(modal);

  try {
    app.globalProperties.modalService.hide(name);
    removeModalFromOpened(modal);
  } catch (error) {
    sentryCaptureMessage({
      message: "App instance is not defined, can't hide modal",
      captureContext: { extra: { error, modalName: name, app } },
    });
  }
}

export function hideAllModals(): void {
  const app: AppConfig = getApp();

  openedModals = [];

  try {
    app.globalProperties.modalService.hideAll();
  } catch (error) {
    sentryCaptureMessage({
      message: "App instance is not defined, can't hide all modals",
      captureContext: { extra: { error, app } },
    });
  }
}

export function hideLastModal(): void {
  if (openedModals.length >= 1) {
    hideConcreteModal(openedModals.slice(-1));
    openedModals.pop();
  }
}

let openedModals: Component[] = [];

export function useModal<T = undefined>(
  fn: (args: T) => DynamicModalOptions & {
    props?: Record<string, unknown>;
    bind?: Bind<string>;
    component: ModalComponent;
  },
) {
  let modalComponent: ModalComponent | null = null;

  function showModal(args: T extends undefined ? void : T) {
    // @ts-expect-error: may be any args
    const modal = fn(args);
    // this param is set into component as a default param
    // see example ./src/pages/payment/components/payment-method/PaymentMethod.vue
    if (isDeniedRoute(!!modal?.component?.isForceAllowedModal)) return;

    if (!modal.bind) {
      modal.bind = {};
    }

    // Copy `props` to `bind` param
    if (modal.props) {
      modal.bind = { ...modal.bind, ...modal.props };
    }

    if (openedModals.includes(modal.component)) {
      return;
    }

    openedModals.push(modal.component);
    // modal.bind.name = getModalName(modal.component);

    const { component, bind = {}, on = {} } = modal;
    const app: AppConfig = getApp();
    modalComponent = component;

    const slots = {
      default: {
        component,
        bind,
        on: {
          ...on,
          close: (...args: unknown[]) => {
            hideConcreteModal(component);

            // Pass `close` event upper
            if (on.close) {
              (on.close as (...args: unknown[]) => void)(...args);
            }
          },
        },
      },
    };

    try {
      const classes = bind.classes ?? [];

      return app.globalProperties.modalService.show({
        component: DynamicModalContainer,
        bind: {
          ...bind,
          classes: ['modal-bg-transparent', 'transparent-overlay', ...classes],
          name: getModalName(modal.component),
          closed: () => {
            removeModalFromOpened(component);
          },
        },
        on,
        slots,
      });
    } catch (error) {
      sentryCaptureMessage({
        message: "App instance is not defined, can't show modal",
        captureContext: { extra: { error } },
      });
    }
  }

  function hideModal() {
    hideConcreteModal(modalComponent);
  }

  return {
    showModal,
    hideModal,
  };
}

export const isNotificationAllowed = (type: string, force = false): boolean => {
  if (force) return true;

  const routeName = router.currentRoute.value.name as keyof Routes;
  const isPurchaseModal: boolean = openedModals.some(
    // this param is set into component as a default param
    // see example ./pages/payment/components/purchase/Purchase.vue
    (item: ModalComponent): boolean => !!item.isPaymentModal,
  );
  const isAllowedFromList = !!allowedNotifications.find((item) => type === item);
  const restrictedRule: boolean = deniedRoutes.includes(routeName) || isPurchaseModal;

  if (restrictedRule) {
    return isAllowedFromList;
  }

  return true;
};
