<template>
  <SubscriptionPromoLayout
    v-if="page === 'offer'"
    background-class="bg-trial-discount slider-off bg-img"
  >
    <template #header-title>{{ t('limitedOffer') }}</template>
    <template #title>
      <div class="modal_default-title">-{{ discountPercent }}%</div>
      <div class="modal_default-sub_title">
        {{ t('expireOffer', { discountPercent }) }}
      </div>
    </template>

    <template #default>
      <div class="bg_white">
        <button type="button" class="btn btn-block btn-info mb-0" @click="setPage('discount')">
          {{ t('takeIt') }}
        </button>
        <button type="button" class="btn btn-small btn-block btn-light mb-0" @click="close">
          {{ t('takeItLater') }}
        </button>
      </div>
    </template>
  </SubscriptionPromoLayout>
  <SubscriptionPromoLayout
    v-else-if="page === 'discount'"
    background-class="bg-trial-discount slider_vertical_mode"
    @close="close"
  >
    <template #header-title>{{ t('limitedOffer') }}</template>

    <template #default>
      <div class="bg_white">
        <div class="trial_package">
          <div
            v-if="first"
            :class="{ active: isSelected(first) }"
            class="trial_package-item"
            @click="setSelected(first)"
          >
            <div v-show="isSelected(first)" class="trial_package-popular">
              {{ first.title }}
            </div>
            <div class="trial_package-period">
              <span class="big">{{ splitLabel(first)[0] }}</span>
              {{ splitLabel(first)[1] }}
            </div>
            <div class="trial_package-price">
              {{ getDiscountedPricePerWeek(first) }}/{{ t('week') }}
            </div>
            <div v-if="getPrice(first).taxAmount" class="text-caption text-dark-200">
              + tax {{ getPricePerWeekTaxFormatted(first) }}
            </div>
            <div class="trial_package-old_price">{{ getPricePerWeek(first) }}/{{ t('week') }}</div>
            <div v-if="first.savingsPercent" class="trial_package-save">
              {{ t('save', { percent: first.savingsPercent }) }}
            </div>
          </div>
          <div
            v-if="third"
            :class="{ active: isSelected(third) }"
            class="trial_package-item"
            @click="setSelected(third)"
          >
            <div v-show="isSelected(third)" class="trial_package-popular">
              {{ third.title }}
            </div>
            <div class="trial_package-period">
              <span class="big">{{ splitLabel(third)[0] }}</span>
              {{ splitLabel(third)[1] }}
            </div>
            <div class="trial_package-price">
              {{ getDiscountedPricePerWeek(third) }}/{{ t('week') }}
            </div>
            <div v-if="getPrice(third).taxAmount" class="text-caption text-dark-200">
              + tax {{ getPricePerWeekTaxFormatted(third) }}
            </div>
            <div class="trial_package-old_price">{{ getPricePerWeek(third) }}/{{ t('week') }}</div>
            <div v-if="third.savingsPercent" class="trial_package-save">
              {{ t('save', { percent: third.savingsPercent }) }}
            </div>
          </div>
          <div
            v-if="second"
            :class="{ active: isSelected(second) }"
            class="trial_package-item"
            @click="setSelected(second)"
          >
            <div v-show="isSelected(second)" class="trial_package-popular">
              {{ second.title }}
            </div>
            <div class="trial_package-period">
              <span class="big">{{ splitLabel(second)[0] }}</span>
              {{ splitLabel(second)[1] }}
            </div>
            <div class="trial_package-price">
              {{ getDiscountedPricePerWeek(second) }}/{{ t('week') }}
            </div>
            <div v-if="getPrice(second).taxAmount" class="text-caption text-dark-200">
              + tax {{ getPricePerWeekTaxFormatted(second) }}
            </div>
            <div class="trial_package-old_price">{{ getPricePerWeek(second) }}/{{ t('week') }}</div>
            <div v-if="second.savingsPercent" class="trial_package-save">
              {{ t('save', { percent: second.savingsPercent }) }}
            </div>
          </div>
        </div>
        <SpinnerButton
          v-if="cardDefault"
          :loading-duration-ms="10000"
          loading-color="#C13063FF"
          class="btn btn-block btn-danger-pink mb-0"
          @click="next"
        >
          {{ t('continue') }}
        </SpinnerButton>
        <button v-else type="button" class="btn btn-block btn-danger-pink mb-0" @click="next">
          {{ t('continue') }}
        </button>
      </div>
    </template>
  </SubscriptionPromoLayout>
</template>

<script lang="ts" setup>
import {
  PremiumPackage,
  Price,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType, ref } from 'vue';

import SpinnerButton from '@/components/inputs/spinner-button/SpinnerButton.vue';
import { hideSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { getFormattedCurrency } from '@/helpers/number';
import SubscriptionPromoLayout from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-layout/SubscriptionPromoLayout.vue';
import { getPackagePricePerWeek } from '@/pages/payment/helpers/package/package';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

type Page = 'offer' | 'discount';

defineProps({
  cardDefault: {
    type: Object as PropType<PaymentStateCard | undefined>,
    required: true,
  },
});

const emit = defineEmits(['next', 'close']);

const { t } = useI18n({ messages });

const selected = ref<ExtractClassFields<PremiumPackage>>();

const page = ref<Page>('offer');

const promo = computed(() => {
  return getStore().state.common.promoModal;
});

const first = computed(() => {
  return promo.value?.packages[2] as ExtractClassFields<PremiumPackage>;
});

const second = computed(() => {
  return promo.value.packages[0] as ExtractClassFields<PremiumPackage>;
});

const third = computed(() => {
  return promo.value.packages[1] as ExtractClassFields<PremiumPackage>;
});

const discountPercent = computed(() => {
  return selected.value?.offer?.discountPercent ?? 0;
});

function splitLabel(pkg: ExtractClassFields<PremiumPackage>) {
  return pkg.label.split(' ');
}

function setPage(pageNew: Page) {
  page.value = pageNew;
}

function getPricePerWeek(pkg: ExtractClassFields<PremiumPackage>): string {
  return getFormattedCurrency(
    pkg.pricePerWeek?.totalAmount ?? 0n,
    pkg.pricePerWeek?.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
}

function getDiscountedPricePerWeek(pkg: ExtractClassFields<PremiumPackage>): string {
  const price = getPackagePricePerWeek(pkg);

  return getFormattedCurrency(price.totalAmount - price.taxAmount, price.currency);
}

function getPricePerWeekTaxFormatted(pkg: ExtractClassFields<PremiumPackage>): string {
  const price = getPackagePricePerWeek(pkg);

  return getFormattedCurrency(price.taxAmount, price.currency);
}

function getPrice(pkg: ExtractClassFields<PremiumPackage>): ExtractClassFields<Price> {
  return getPackagePricePerWeek(pkg);
}

function isSelected(pkg: ExtractClassFields<PremiumPackage>): boolean {
  return selected.value?.id === pkg.id;
}

function setSelected(pkg: ExtractClassFields<PremiumPackage>): void {
  selected.value = pkg;
}

function next(): void {
  emit('next', selected.value);
}

function close(): void {
  hideSubscriptionPromoModal();
  emit('close');
}

selected.value = third.value;
</script>
