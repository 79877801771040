<template>
  <!--  <picture>-->
  <!--    <source> tag is very slow, especially with animations-->
  <!--    <source type="image/webp" :srcset="webpSrcset" />-->
  <img
    v-if="!!imageId"
    :src="src"
    :srcset="srcset"
    :width="width || ''"
    :style="{ width: imgWidth, height: imgHeight }"
    :height="height || ''"
    :alt="alt"
    :class="[{ 'blur-[5px]': isBlurred }, className]"
    loading="lazy"
  />
  <div
    v-else
    :style="{ width: width ? `${width}px` : imgWidth, height: height ? `${height}px` : imgHeight }"
    class="bg-dark-500 rounded-[20px]"
  ></div>
  <!--  </picture>-->
</template>

<script lang="ts" setup>
import { PropType, ref, watch } from 'vue';

import { getImageSrcSet, getImageUrl, ImageFormat, ImageGroupRatio } from '@/services/image';

const props = defineProps({
  imageId: {
    type: String,
    required: true,
  },
  isBlurred: {
    type: Boolean,
    default: false,
  },
  width: {
    type: Number,
    required: false,
    default: 0,
  },
  height: {
    type: Number,
    required: false,
    default: 0,
  },
  alt: {
    type: String,
    required: false,
    default: '',
  },
  format: {
    type: String as PropType<ImageFormat>,
    required: false,
    default: 'jpeg',
  },
  groupRatio: {
    type: String as PropType<ImageGroupRatio>,
    required: false,
    default: '',
  },
  imgWidth: {
    type: String,
    required: false,
    default: '',
  },
  imgHeight: {
    type: String,
    required: false,
    default: '',
  },
  className: {
    type: String,
    required: false,
    default: '',
  },
});

// webpSrcset = '';
const srcset = ref('');
const src = ref('');

function updateImageSrc(): void {
  if (!props.isBlurred) {
    // this.webpSrcset = getImageSrcSet(
    //   this.imageId,
    //   { width: this.width, height: this.height },
    //   this.isBlurred,
    //   'webp',
    //   this.groupRatio
    // );
    srcset.value = getImageSrcSet(
      props.imageId,
      { width: props.width, height: props.height },
      props.isBlurred,
      props.format,
      props.groupRatio,
    );
  }
  src.value = getImageUrl(
    props.imageId,
    { width: props.width, height: props.height },
    props.isBlurred,
    props.format,
    props.groupRatio,
  );
}

updateImageSrc();

watch(
  () => props.imageId,
  () => updateImageSrc(),
);
</script>
