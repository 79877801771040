import { hasCache, setCache } from '@/helpers/persistent-cache';

const KEY = 'welcome_trial_popup_shown';

export function isWelcomeTrialPopupAvailable(): boolean {
  return !hasCache(KEY);
}

export function setWelcomeTrialPopupUnavailable(): void {
  setCache(KEY, '1');
}
