import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { ref } from 'vue';

import { dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export function useStoreChatGifts() {
  const gifts = ref<ExtractClassFields<DialogGift>[]>([]);

  function load() {
    return dispatch('actionChatGetGifts').then((res) => {
      gifts.value.push(...res);
    });
  }

  return {
    load,
    gifts,
  };
}
