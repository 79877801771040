import { computed, reactive, ref } from 'vue';

import { getGrowthBookFeatureValue, GrowthBookFeatures } from '@/services/growth-book/growth-book';

export function useTiBoSplit() {
  const variant = ref<GrowthBookFeatures['ti-bo']>('def');
  const isInited = ref(false);

  const isTiBoSplit = computed(() => ['v2', 'v3', 'v4'].includes(variant.value));

  async function init() {
    try {
      variant.value = await getGrowthBookFeatureValue('ti-bo', 'def');
    } finally {
      isInited.value = true;
    }
  }

  return reactive({
    variant,
    isInited,
    init,
    isTiBoSplit,
  });
}
