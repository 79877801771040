<template>
  {{ t('header') }}
  <span class="text-tag">{{ price }}.</span>

  <span>{{ t('rules') }}</span>

  <a class="form-membership" @click.prevent="emit('open-membership-policy')">
    {{ t('policy') }}
  </a>
</template>

<script lang="ts" setup>
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  price: {
    type: String,
    required: true,
  },
});

const { t } = useI18n({ messages });

const emit = defineEmits(['open-membership-policy']);
</script>
