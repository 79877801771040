import { I18n } from '@/services/i18n';

type Message = {
  beginYourJourney: string;
  welcome: string;
  showLimitedOffers: string;
  meetNewNicePeopleOnline: string;
  next: string;
  free: string;
  rightNowYouAreOn: string;
  freeSubscription: string;
  trial: string;
  premium: string;
  seeBasicProfilesOfOtherSingles: string;
  titleFreeSubscription: string;
  limitedChatting: string;
  limitedLikes: string;
  feature1: string;
  feature2: string;
  feature3: string;
  feature4: string;
  feature5: string;
  feature6: string;
  feature7: string;
  continue: string;
};

const messages: I18n<Message> = {
  en: {
    beginYourJourney: 'Begin your journey at MeetMyAge!',
    welcome: 'Welcome, {name}!',
    showLimitedOffers: 'SHOW LIMITED OFFERS',
    meetNewNicePeopleOnline:
      'Meet new nice people online, make friends and spend quality time together. This is what you need to know right now about our platform...',
    next: 'Next',
    free: 'Free',
    rightNowYouAreOn: 'Right now you are on',
    freeSubscription: 'free subscription',
    trial: 'Premium',
    premium: 'With Premium',
    seeBasicProfilesOfOtherSingles: 'See basic profiles of other singles',
    titleFreeSubscription: 'Free subscription',
    limitedChatting: 'Limited chatting',
    limitedLikes: 'Limited likes',
    feature1: 'See all photos and full profiles of other singles',
    feature2: 'Unlimited likes',
    feature3: 'Advanced filters',
    feature4: 'Unlimited chatting',
    feature5: 'See who likes you',
    feature6: 'Boost your profile',
    feature7: 'No Ads',
    continue: 'Continue',
  },
  es: {
    beginYourJourney: '¡Empieza tu viaje en MeetMyAge!',
    welcome: '¡Bienvenido, {name}!',
    showLimitedOffers: 'MOSTRAR OFERTAS LIMITADAS',
    meetNewNicePeopleOnline:
      'Conoce nuevas personas agradables en línea, haz amigos y pasa tiempo de calidad juntos. Esto es lo que necesitas saber ahora sobre nuestra plataforma...',
    next: 'Siguiente',
    free: 'Gratis',
    rightNowYouAreOn: 'Ahora mismo estás en',
    freeSubscription: 'suscripción gratuita',
    trial: 'Prueba',
    premium: 'Con Premium',
    seeBasicProfilesOfOtherSingles: 'Ver perfiles básicos de otros solteros',
    titleFreeSubscription: 'Suscripción gratuita',
    limitedChatting: 'Chat limitado',
    limitedLikes: 'Me gusta limitados',
    feature1: 'Ver todas las fotos y perfiles completos de otros solteros',
    feature2: 'Likes ilimitados',
    feature3: 'Filtros avanzados',
    feature4: 'Chat ilimitado',
    feature5: 'Veamos a quién le gustas',
    feature6: 'Impulsa tu perfil',
    feature7: 'Sin anuncios',
    continue: 'Continuar',
  },
};

export { messages };
