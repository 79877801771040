import { Conversion } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/conversion/v1/conversion_pb';
import {
  CreditsPackage,
  PackageIdentity,
  PremiumPackage,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import {
  GetCreditsBalanceResponse,
  GetPaymentOrderStatusResponse,
  ListPackagesResponse,
  SetCreditsAutorefillResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_api_pb';
import { PaymentGateway } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { ActionContext } from 'vuex';

import { resolveAdSenseVisibility } from '@/helpers/ad-sense-visibility/ad-sense-visibility';
import { updatePremiumActive } from '@/helpers/feature/feature';
import { memoize } from '@/helpers/memoize/memoize';
import {
  BILLING_HISTORY_LIMIT,
  getBillingHistoryFrom,
  getLastBillingHistoryPosition,
  listBoostersFrom,
  listPaymentCardsFrom,
  OFFBOARDING_PROFILES_LIMIT,
} from '@/pages/payment/store/helpers/payment-helper';
import {
  PaymentActivateSubscription,
  PaymentState,
  PaymentStateBillingHistoryItem,
  PaymentStateBooster,
  PaymentStateCard,
  PaymentStateOffboardingProfile,
  PaymentStateSolidPaymentForm,
  PaymentStateSubscription,
} from '@/pages/payment/store/payment-state';
import { getOffboardingProfiles } from '@/services/api/connection/connection-api';
import { listUndeliveredConversions } from '@/services/api/conversion/conversion';
import {
  activateBooster,
  activateSubscription,
  applyFreePackage,
  changeSubscriptionPackage,
  completeOffers,
  getBillingHistory,
  getCreditsBalance,
  getPaymentOrderStatus,
  getSolidAuthorizeForm,
  getSolidPaymentForm,
  getSubscriptionStatus,
  listBoosters,
  listCreditsPackages,
  listLimitedOfferPackages,
  listOffboardingPackages,
  listPackages,
  listPaymentCards,
  listPaymentMethods,
  listPremiumGoldPackages,
  listPremiumPackages,
  listPromoCreditsPackages,
  payByCardID,
  setCreditsAutorefill,
  setPreferredPaymentCard,
  suspendSubscription,
  unsubscribePremium,
} from '@/services/api/payment/payment-api';
import { commit, dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

// import MethodCase = PaymentMethod.MethodCase;

export type ActionPaymentPayBySolidTokenPayload = {
  cardId: string;
  pkg: ExtractClassFields<PackageIdentity>;
};

declare module '@/store/store-helper' {
  interface Action {
    actionPaymentListPremiumPackages: ActionFunction<never, ExtractClassFields<PremiumPackage>[]>;
    actionPaymentListPremiumGoldPackages: ActionFunction<
      never,
      ExtractClassFields<PremiumPackage>[]
    >;
    actionPaymentListOffboardingPackages: ActionFunction<
      PromoType,
      ExtractClassFields<PremiumPackage>[]
    >;
    actionPaymentListCreditsPackages: ActionFunction<never, ExtractClassFields<CreditsPackage>[]>;
    actionPaymentGetSolidPaymentForm: ActionFunction<
      ExtractClassFields<PackageIdentity>,
      PaymentStateSolidPaymentForm
    >;
    actionPaymentGetSolidAuthorizeForm: ActionFunction<never, PaymentStateSolidPaymentForm>;
    actionPaymentListPromoCreditsPackages: ActionFunction<
      never,
      ExtractClassFields<CreditsPackage>[]
    >;
    actionPaymentPayByCardId: ActionFunction<ActionPaymentPayBySolidTokenPayload, void>;
    actionPaymentUnsubscribePremium: ActionFunction<never, void>;
    actionPaymentGetBillingHistory: ActionFunction<never, PaymentStateBillingHistoryItem[]>;
    actionPaymentGetCreditsBalance: ActionFunction<
      never,
      ExtractClassFields<GetCreditsBalanceResponse>
    >;
    actionPaymentListPaymentMethods: ActionFunction<never, void>;
    actionPaymentListPaymentCards: ActionFunction<never, PaymentStateCard[]>;
    actionPaymentGetSubscriptionStatus: ActionFunction<never, PaymentStateSubscription>;
    actionPaymentChangeSubscriptionPackage: ActionFunction<
      ExtractClassFields<PackageIdentity>,
      void
    >;
    actionPaymentSetPreferredPaymentCard: ActionFunction<string, void>;
    actionPaymentApplyFreePackage: ActionFunction<ExtractClassFields<PackageIdentity>, void>;
    actionPaymentGetOffboardingProfiles: ActionFunction<never, PaymentStateOffboardingProfile[]>;
    actionPaymentListBoosters: ActionFunction<never, PaymentStateBooster[]>;
    actionPaymentActivateBooster: ActionFunction<PaymentStateBooster, void>;
    actionPaymentGetOrderStatus: ActionFunction<string, GetPaymentOrderStatusResponse>;
    actionPaymentSetCreditsAutorefill: ActionFunction<boolean, SetCreditsAutorefillResponse>;
    actionPaymentGetCreditsAutorefill: ActionFunction<never, boolean>;
    actionPaymentSuspendSubscription: ActionFunction<number, void>;
    actionPaymentActivateSubscription: ActionFunction<never, PaymentActivateSubscription>;
    actionPaymentListPackages: ActionFunction<
      ExtractClassFields<PackageIdentity>[],
      ListPackagesResponse
    >;
    actionPaymentGetLastPurchasedPackage: ActionFunction<
      ExtractClassFields<PackageIdentity>,
      ExtractClassFields<PremiumPackage> | ExtractClassFields<CreditsPackage>
    >;
    actionPaymentCompleteOffers: ActionFunction<string[], string[]>;
    actionPaymentListConversions: ActionFunction<never, Conversion[]>;
    actionPaymentListLimitedOfferCreditsPackages: ActionFunction<
      never,
      ExtractClassFields<CreditsPackage>[]
    >;
  }
}

export async function actionPaymentListLimitedOfferCreditsPackages(): Promise<
  ExtractClassFields<CreditsPackage>[]
> {
  const response = await listLimitedOfferPackages();
  return response.creditPackages;
}

export async function actionPaymentListPremiumPackages(): Promise<
  ExtractClassFields<PremiumPackage>[]
> {
  const response = await listPremiumPackages();

  commit('mutationPaymentSetPremiumPackages', response.packages);

  return response.packages;
}

export async function actionPaymentListPremiumGoldPackages(): Promise<
  ExtractClassFields<PremiumPackage>[]
> {
  const response = await listPremiumGoldPackages();
  return response.packages;
}

export async function actionPaymentListCreditsPackages(): Promise<
  ExtractClassFields<CreditsPackage>[]
> {
  const response = await listCreditsPackages();

  commit('mutationPaymentSetCreditsPackages', response.packages);

  return response.packages;
}

export async function actionPaymentListPromoCreditsPackages(): Promise<
  ExtractClassFields<CreditsPackage>[]
> {
  const response = await listPromoCreditsPackages();
  const packages = response.packages;

  commit('mutationPaymentSetPromoCreditsPackages', packages);

  return packages;
}

export async function actionPaymentGetSolidPaymentForm(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  { id, offerId }: ExtractClassFields<PackageIdentity>,
): Promise<PaymentStateSolidPaymentForm> {
  const response = await getSolidPaymentForm({ id, offerId });

  commit('mutationPaymentSetSolidPaymentForm', response);

  return response;
}

export async function actionPaymentGetSolidAuthorizeForm(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
): Promise<PaymentStateSolidPaymentForm> {
  const response = await getSolidAuthorizeForm();

  commit('mutationPaymentSetSolidPaymentForm', response);

  return response;
}

export async function actionPaymentPayByCardId(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  payload: ActionPaymentPayBySolidTokenPayload,
): Promise<void> {
  await payByCardID(payload);
}

export async function actionPaymentUnsubscribePremium(): Promise<void> {
  await unsubscribePremium();
}

export async function actionPaymentGetBillingHistory({
  state,
}: ActionContext<PaymentState, unknown>): Promise<PaymentStateBillingHistoryItem[]> {
  const lastItemPosition = getLastBillingHistoryPosition(state.billingHistory);
  const response = await getBillingHistory({
    billingFrom: lastItemPosition,
    limit: BILLING_HISTORY_LIMIT,
  });
  const history = getBillingHistoryFrom(response);
  commit('mutationPaymentAddBillingHistory', history);

  return history;
}

export async function actionPaymentGetCreditsBalance(): Promise<
  ExtractClassFields<GetCreditsBalanceResponse>
> {
  const response = await getCreditsBalance();

  commit('mutationPaymentSetCreditsCount', Number(response.creditsCount));
  commit('mutationPaymentSetFreeGiftsCount', Number(response.freeGiftsCount));

  return response;
}

export async function actionPaymentListPaymentMethods(): Promise<void> {
  commit('mutationPaymentSetPaymentMethodLoading', true);
  try {
    const response = await listPaymentMethods();
    response.paymentMethods.forEach((method) => {
      if (method.method.case === 'card') {
        commit('mutationPaymentEnableCardMethod');
      } else if (method.method.case === 'solidPaypal') {
        commit('mutationPaymentEnableSolidPaypalMethod');
      } else if (method.method.case === 'googlePay') {
        commit('mutationPaymentEnableGooglePayMethod');
      } else if (method.method.case === 'applePay') {
        commit('mutationPaymentEnableApplePayMethod');
      }
    });
  } finally {
    commit('mutationPaymentSetPaymentMethodLoading', false);
  }
}

export async function actionPaymentListPaymentCards(): Promise<PaymentStateCard[]> {
  const gateways: PaymentGateway[] = [PaymentGateway.SOLID];

  const response = await listPaymentCards(gateways);
  const cards = listPaymentCardsFrom(response);
  commit('mutationPaymentSetPaymentCards', cards);

  return cards;
}

export async function actionPaymentGetSubscriptionStatus(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
): Promise<PaymentStateSubscription> {
  // We call actionPaymentGetSubscriptionStatus multiple time during page render,
  // so we need to cache the result for a short period of time
  const response = await memoize(getSubscriptionStatus, { cacheTtlMs: 1000 })();
  const statusData = {
    ...response,
    status: response.subscription?.state.case,
  } as PaymentStateSubscription;

  commit('mutationPaymentSetSubscriptionStatus', statusData);
  resolveAdSenseVisibility();
  updatePremiumActive(statusData);

  return statusData;
}

export async function actionPaymentSuspendSubscription(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  seconds: number,
): Promise<void> {
  await suspendSubscription(seconds);
}

export async function actionPaymentListPackages(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  ids: ExtractClassFields<PackageIdentity>[],
): Promise<ExtractClassFields<ListPackagesResponse>> {
  return await listPackages(
    ids.map(
      ({ id, offerId }) =>
        new PackageIdentity({
          id,
          offerId,
        }),
    ),
  );
}

export async function actionPaymentGetLastPurchasedPackage(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  identity: ExtractClassFields<PackageIdentity>,
): Promise<ExtractClassFields<PremiumPackage> | ExtractClassFields<CreditsPackage>> {
  const list = await dispatch('actionPaymentListPackages', [identity]);
  // const [lastPurchasedPackage] = list.premiumPackagesList;
  const [lastPurchasedPackage] = list.premiumPackages;

  return lastPurchasedPackage;
}

export async function actionPaymentActivateSubscription(): Promise<PaymentActivateSubscription> {
  return await activateSubscription();
}

export async function actionPaymentChangeSubscriptionPackage(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  identity: ExtractClassFields<PackageIdentity>,
): Promise<void> {
  await changeSubscriptionPackage(identity);
}

export async function actionPaymentSetPreferredPaymentCard(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  cardId: string,
): Promise<void> {
  await setPreferredPaymentCard(cardId);
}

export async function actionPaymentListOffboardingPackages(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  promoType: PromoType,
): Promise<ExtractClassFields<PremiumPackage>[]> {
  const response = await listOffboardingPackages(promoType);
  return response.packages;
}

export async function actionPaymentApplyFreePackage(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  identity: ExtractClassFields<PackageIdentity>,
): Promise<void> {
  await applyFreePackage(identity);
}

export async function actionPaymentGetOffboardingProfiles(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
): Promise<PaymentStateOffboardingProfile[]> {
  const response = await getOffboardingProfiles(OFFBOARDING_PROFILES_LIMIT);
  return response.profiles;
}

export async function actionPaymentListBoosters(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
): Promise<PaymentStateBooster[]> {
  const response = await listBoosters();
  return listBoostersFrom(response);
}

export async function actionPaymentActivateBooster(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  booster: PaymentStateBooster,
): Promise<void> {
  await activateBooster(booster.id);
}

export async function actionPaymentGetOrderStatus(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  orderId: string,
): Promise<GetPaymentOrderStatusResponse> {
  return getPaymentOrderStatus(orderId);
}

export async function actionPaymentSetCreditsAutorefill(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  isEnabled: boolean,
): Promise<SetCreditsAutorefillResponse> {
  return await setCreditsAutorefill(isEnabled);
}

export async function actionPaymentCompleteOffers(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PaymentState, unknown>,
  offersIds: string[],
): Promise<string[]> {
  const response = await completeOffers(offersIds);

  return response.assignedOfferIds;
}

export async function actionPaymentListConversions(): Promise<Conversion[]> {
  const response = await listUndeliveredConversions();

  return response.conversions;
}
