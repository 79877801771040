import { User } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import { GetAccountResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';

import { ExtractClassFields } from '@/type';

export type SettingsStateAccount = ExtractClassFields<GetAccountResponse>;

export type SettingsBlockedUser = Required<
  ExtractClassFields<User> & { userPosition: string; isVerified: boolean }
>;

export type SettingsState = {
  account?: SettingsStateAccount;
  blockedUsers: SettingsBlockedUser[];
};

export const state: SettingsState = {
  account: undefined,
  blockedUsers: [],
};
