<template>
  <div
    v-if="supportButton.isVisible"
    class="fixed z-[100] h-14 w-14 bg-primary text-white flex justify-center items-center shadow-lg"
    :class="self.buttonPositionClassName"
    @click="self.openSupportChat()"
  >
    <SpinnerLoader v-if="self.isSupportChatLoading" />
    <FontIcon v-else class="icon-support_circle !text-[28px]" />
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue';

import { useSelf } from './hooks/use-self/use-self';

const self = reactive(useSelf());

const supportButton = reactive(self.supportButton);

self.init();
</script>
