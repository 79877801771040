<template>
  <div class="modal_default explanatory d-block prolongation_modal premium">
    <div class="modal_default-dialog">
      <div class="modal_default-content">
        <div class="modal_default-body !pb-4">
          <div class="modal_default-trial">
            <div class="modal_default-close">
              <FontIcon class="icon-close" data-test-id="btn_close" @click="emit('close')" />
            </div>
            <div class="explanatory-avatar_group flex justify-center">
              <div class="mx-auto -mt-[55px] w-[144px] flex justify-between">
                <RetinaPhoto
                  v-if="profileShort"
                  class="flex justify-center rounded-full border-4 border-white w-[70px] relative -right-2"
                  :photo-id="profileShort.avatar.id"
                  :width="64"
                  :height="64"
                />
                <div
                  class="rounded-full border-4 border-white w-[72px] h-[72px] flex-none bg-premium-500 relative -left-2"
                >
                  <div class="flex items-center justify-center relative h-full">
                    <FontIcon class="icon-premium_bg text-premium !text-[44px]" />
                    <span
                      class="text-button1 font-semibold text-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      :class="{
                        'text-button2': daysLeftLength === 2,
                        'text-overline pb-1': daysLeftLength === 3,
                      }"
                      >{{ daysLeft }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <slot name="heading"></slot>
            <slot name="description"></slot>
            <slot></slot>
            <div class="explanatory-btn text-center">
              <slot name="button-action"></slot>
            </div>
          </div>
        </div>
      </div>
      <slot name="snippet"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';

const props = defineProps({
  profileShort: {
    type: Object as PropType<MyProfileStateShort>,
    required: true,
  },
  daysLeft: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close']);

const daysLeftLength = computed(() => {
  return props.daysLeft.toString().length;
});
</script>
<style lang="scss" src="./style.scss"></style>
