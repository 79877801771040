<template>
  <div class="alert-info flex report" @click="goSuccessPage">
    <div class="alert-info-icon upload flex items-center justify-center">
      <div class="img-section">
        <i class="icon icon-success"></i>
      </div>
    </div>
    <div class="alert-info-body grow flex items-center">
      <div class="alert-info-section">
        {{ t('header') }}
      </div>
    </div>
    <div class="alert-info-right">
      <i class="icon icon-chevron_right"></i>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { replaceRoute } from '@/router';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });
function goSuccessPage(): void {
  replaceRoute('paymentSuccess');
}
</script>
