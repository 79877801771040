<template>
  <div class="h-full">
    <PurchasePremium v-if="currentPage === 'premium'" @close="close" @select="select" />

    <PurchasePremiumGold
      v-else-if="currentPage === 'premium-gold'"
      @close="close"
      @select="select"
    ></PurchasePremiumGold>

    <PurchaseCredits v-else-if="currentPage === 'credits'" @close="close" @select="select" />

    <PaymentMethod
      v-else-if="currentPage === 'payment' && selectedPackage"
      :cards="cards"
      :pkg="selectedPackage"
      :is-google-pay-visible="true"
      :is-apple-pay-visible="true"
      @back="back"
    />

    <!--    We use v-show here to prevent destroying component every time we switched between pages.-->
    <PurchasePromo
      v-if="purchase === 'promo'"
      v-show="currentPage === 'promo'"
      :cards="cards"
      @select="select"
      @close="hideAllModals"
    />
  </div>
</template>
<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>
<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue';

import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import { usePayment } from '@/components/payment/hooks/use-payment/use-payment';
import { customEventTrigger, PaymentPage } from '@/helpers/gtm-triggers/gtm-triggers';
import PaymentMethod from '@/pages/payment/components/payment-method/PaymentMethod.vue';
import PurchaseCredits from '@/pages/payment/components/purchase/components/purchase-credits/PurchaseCredits.vue';
import PurchasePremium from '@/pages/payment/components/purchase/components/purchase-premium/PurchasePremium.vue';
import PurchasePremiumGold from '@/pages/payment/components/purchase/components/purchase-premium-gold/PurchasePremiumGold.vue';
import PurchasePromo from '@/pages/payment/components/purchase/components/purchase-promo/PurchasePromo.vue';
import { getPackagePrice } from '@/pages/payment/helpers/package/package';
import { paymentGoBack } from '@/pages/payment/helpers/payment-navigation/payment-navigation';
import { PaymentStateCard, PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { replaceRoute } from '@/router';
import { hideAllModals, useModal } from '@/services/modal/modal';
import { commit, dispatch, getStore } from '@/store/store-helper';

const props = defineProps({
  purchase: {
    type: String as PropType<PaymentPage>,
    required: true,
  },
  defaultPackage: {
    type: Object as PropType<PaymentStatePackage | null>,
    required: false,
    default: null,
  },
  paymentSuccess: {
    type: Function as PropType<() => void>,
    default: () => {
      replaceRoute('paymentSuccess');
    },
  },
  paymentFail: {
    type: Function as PropType<() => void>,
    default: () => {
      replaceRoute('paymentFail');
    },
  },
  close: {
    type: Function as PropType<() => void>,
    default: () => {
      paymentGoBack();
    },
  },
});
const emit = defineEmits(['set-page']);
const selectedPackage = ref<PaymentStatePackage | null>(null);
const currentPage = ref<PaymentPage>('premium');

const cards = computed((): PaymentStateCard[] => {
  return getStore().state.payment.paymentCards;
});

function back(): void {
  if (props.purchase === 'payment') {
    props.close();
  } else {
    setPage(props.purchase);
  }
}

function select(pkg: PaymentStatePackage): void {
  selectedPackage.value = pkg;
  commit('mutationPaymentSetLastPurchasedPackage', pkg);
  setPage('payment');
}

function setPage(page: PaymentPage) {
  emit('set-page', page);
  currentPage.value = page;
  if (props.purchase !== 'payment' && page === 'payment' && selectedPackage.value) {
    const price = getPackagePrice(selectedPackage.value);

    // TODO Move to <PaymentMethod /> component !!!!!!!!!!!
    customEventTrigger({
      event: 'payment-show-details',
      sourcePage: props.purchase,
      priceUsd: `${price.totalAmount}`,
      price: `${price.totalAmount}`,
      currency: price.currency,
      packageTitle: selectedPackage.value?.title ?? '',
    });
  }
}

const { showModal, hideModal } = useModal(() => ({
  component: ModalSpinner,
}));

const { listPaymentMethodsPromise } = usePayment({
  paymentSuccess: props.paymentSuccess,
  paymentFail: props.paymentFail,
});

setPage(props.purchase);
showModal();

watch(
  () => props.purchase,
  (page: PaymentPage) => {
    setPage(page);
  },
);

Promise.all([listPaymentMethodsPromise, dispatch('actionPaymentListPaymentCards')]).finally(() => {
  // When we have already selected package and want to show payment form instantly.
  if (props.defaultPackage !== null) {
    select(props.defaultPackage);
  }

  hideModal();
});
</script>

<!-- Include CSS also here, because this component can be used in modal,
 outside of parent one.
 -->
<style lang="scss" src="../../payment.scss"></style>
