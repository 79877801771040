import { getCache, setCache } from '@/helpers/persistent-cache';

const PHOTO_UPLOAD_COUNT_CACHE_KEY = 'puc';

export function increasePhotoUploadCount() {
  setCache(PHOTO_UPLOAD_COUNT_CACHE_KEY, (getPhotoUploadCount() + 1).toString());
}

export function getPhotoUploadCount() {
  const count = getCache(PHOTO_UPLOAD_COUNT_CACHE_KEY);

  return count ? parseInt(count) : 0;
}
