import { computed } from 'vue';

import { UseSnackButton } from '@/components/snack-button/hooks/use-snack-button/type/type';
import { hasPremium } from '@/helpers/feature/feature';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { pushRoute } from '@/router';

export function useSnackButtonPremium(): UseSnackButton {
  const action = (): void => {
    pushRoute('paymentPurchasePremium');
    trackPaymentPromo({
      from: 'search-filters', // Very sensitive source, because if we use snack elsewhere but search filters it will be wrong.
      popup: 'premium',
      type: 'premium',
    });
  };

  const isVisible = computed((): boolean => {
    return !hasPremium();
  });

  return {
    action,
    isVisible,
  };
}
