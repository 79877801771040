import { Plugin } from 'vue';

import './helpers/type/type.d.ts';
import { useI18n } from '@/services/i18n/i18n';

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install: (app: any, options: any) => {
    // inject a globally available $translate() method
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    app.config.globalProperties.$t = function (
      key: string,
      data?: Record<string, string | number>,
    ) {
      try {
        return useI18n(this.$options.i18n).t(key, data);
      } catch (e) {
        console.error(e);

        return key;
      }
    };
  },
} as Plugin;
