import { I18n } from '@/services/i18n';

type Message = {
  youRunOutOfCredits: string;
  getMoreCredits: string;
  automaticallyTopUpMyCredits: string;
  credits: string;
};

const messages: I18n<Message> = {
  en: {
    youRunOutOfCredits: 'You run out of credits',
    getMoreCredits:
      'Get more credits to check your compatibility with other people, send gifts, or boost your profile.',
    automaticallyTopUpMyCredits: 'Autorefill credits next time',
    credits: 'Get more credits',
  },
  es: {
    youRunOutOfCredits: 'Te has quedado sin créditos',
    getMoreCredits:
      'Obtén más créditos para verificar tu compatibilidad con otras personas, enviar regalos o impulsar tu perfil.',
    automaticallyTopUpMyCredits: 'Autorecarga de créditos la próxima vez',
    credits: 'Obtén más créditos',
  },
};

export { messages };
