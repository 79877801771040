<template>
  <div
    :class="{ popular: pkg.isPopular, 'active-popular': isSelected }"
    class="billing_card"
    @click="toggleSelect"
  >
    <div class="billing_card-check">
      <i class="icon icon-success"></i>
    </div>
    <div v-show="pkg.isPopular" class="billing_card-label">{{ t('popular') }}</div>
    <div class="billing_card-img">
      <img
        v-if="icon === 1"
        src="../../../../../../../../assets/img/payments/credits-1.png"
        width="64"
        alt=""
      />
      <img
        v-else-if="icon === 2"
        src="../../../../../../../../assets/img/payments/credits-2.png"
        width="64"
        alt=""
      />
      <img
        v-else-if="icon === 3"
        src="../../../../../../../../assets/img/payments/credits-3.png"
        width="64"
        alt=""
      />
      <img
        v-else-if="icon === 4"
        src="../../../../../../../../assets/img/payments/credits-4.png"
        width="64"
        alt=""
      />
    </div>
    <div class="billing_card-body">
      <div class="billing_card-title">{{ pkg.title }}</div>
      <div class="billing_card-price">
        {{ packagePrice.priceFormatted }}
        <span v-if="packagePrice.price.taxAmount && isSelected"
          >+ tax {{ packagePrice.priceTaxFormatted }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CreditsPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType, reactive } from 'vue';

import { useModel } from '@/hooks/use-model/use-model';
import { usePackagePrice } from '@/pages/payment/hooks/use-package-price/use-package-price';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<ExtractClassFields<CreditsPackage>>,
    required: true,
  },
  icon: {
    type: Number,
    required: true,
  },

  modelValue: {
    type: Object as PropType<ExtractClassFields<CreditsPackage>>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n({ messages });

const selectedModel = useModel<ExtractClassFields<CreditsPackage>>(props, 'modelValue', emit);

const packagePrice = reactive(usePackagePrice(props.pkg));

const isSelected = computed(() => {
  // We check for offer because inside component PurchaseCreditsItemDiscount we
  // render the package with the same id but with offer. So we have no other
  // way to distinguish them.
  return selectedModel.value.id === props.pkg.id && !selectedModel.value.offer;
});

function toggleSelect() {
  selectedModel.value = props.pkg;
}
</script>
