<template>
  <PurchaseSliderLayout :initial-active="initialActive">
    <template #default>
      <div class="slider-item swiper-slide slide_premium">
        <div class="slider-text">
          <div class="slider_billing-title">
            {{ t('slider.advancedInformation.header') }}
          </div>
          {{ t('slider.advancedInformation.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-premium_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_infinity_likes">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.unlimitedLikes.header') }}</div>
          {{ t('slider.unlimitedLikes.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-infinity_likes"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_search">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.advancedSearch.header') }}</div>
          {{ t('slider.advancedSearch.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-search_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_unlock">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.unblockUsers.header') }}</div>
          {{ t('slider.unblockUsers.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-unlock_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_infinty_messages">
        <div class="slider-text">
          <div class="slider_billing-title">
            {{ t('slider.unlimitedMessages.header') }}
          </div>
          {{ t('slider.unlimitedMessages.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-infinty_messages"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_trash">
        <div class="slider-text">
          <div class="slider_billing-title">
            {{ t('slider.deleteOwnMessages.header') }}
          </div>
          {{ t('slider.deleteOwnMessages.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-trash_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_profile">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.activity.header') }}</div>
          {{ t('slider.activity.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-profile_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_profile">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.ads.header') }}</div>
          {{ t('slider.ads.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-ads_solid"></i>
        </div>
      </div>
    </template>
  </PurchaseSliderLayout>
</template>

<script lang="ts" setup>
import PurchaseSliderLayout from '@/pages/payment/components/layout/components/purchase-slider-layout/PurchaseSliderLayout.vue';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  initialActive: {
    type: Number,
    default: 0,
  },
});

const { t } = useI18n({ messages });
</script>
