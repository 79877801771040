<template>
  <template v-if="areMethodsLoaded">
    <PremiumTimer />
    <CostTotal :price="self.price" :saved-amount="self.savedAmount" />

    <PremiumBenefitList class="pt-2 pb-3 mb-[22px] border-b border-dark-400">
      <PremiumBenefit
        :price-new="self.price"
        :price-old="self.priceOld"
        :name="`${pkg?.label ?? ''} ${t('plan')}`"
      />
      <li
        v-if="splitTiBo === 'v4'"
        class="bg-secondary-500 text-strongcaption font-medium text-secondary flex gap-1 items-center py-[6px] px-2 rounded-lg w-fit"
      >
        <FontIcon class="icon-gift !text-[20px]" />
        {{ t('extra') }}
      </li>
    </PremiumBenefitList>
    <div class="payment-express">
      <div v-if="!isSubscription">
        <div class="payment_btn_wrap">
          <div class="payment_btn_wrap-label">{{ t('expressCheckout') }}</div>
          <SolidPaypalButton v-if="self.isPaypalAvailable" :pkg="pkg"></SolidPaypalButton>
        </div>
      </div>
    </div>

    <div class="relative">
      <div class="flex gap-4 mb-5 items-center split-ti-bo">
        <div :id="solidGooglePayElId" class="w-full"></div>
        <div v-if="self.isApplePayAvailable" :id="solidApplePayElId" class="w-full"></div>
      </div>

      <span class="text-tag font-medium text-dark-200 uppercase flex justify-center mb-3">{{
        t('or')
      }}</span>

      <div :id="solidCardElId" class="solid-payment-form-container mb-4"></div>

      <div :id="solidCardElId" class="solid-payment-form-container mb-4"></div>
      <div class="text-caption text-dark-200 mb-3 flex justify-center text-center">
        {{ t('hint') }} {{ self.price }}<br />
        {{ self.legalAddress }}
      </div>
      <div
        class="text-strongcaption font-medium text-success flex items-center justify-center gap-2"
      >
        <i class="icon icon-lock !text-[16px]"></i>{{ t('secureCheckout') }}
      </div>
    </div>
  </template>
  <div v-else>
    <SpinnerLoader />
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue';
import PremiumBenefit from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-checkout/components/premium-benefit/PremiumBenefit.vue';
import PremiumBenefitList from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-checkout/components/premium-benefit-list/PremiumBenefitList.vue';
import PremiumTimer from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-timer/PremiumTimer.vue';
import SolidPaypalButton from '@/components/payment/components/payment-method/components/solid/solid-paypal-button/SolidPaypalButton.vue';
import CostTotal from '@/pages/payment/components/payment-method/components/payment-method-add-paypal-first/components/cost-total/CostTotal.vue';
import { useSelf } from '@/pages/payment/components/payment-method/components/payment-method-add-paypal-first/hooks/use-self/use-self';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { messages } from '../../i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<PaymentStatePackagePremium>,
    required: true,
  },
  solidGooglePayElId: {
    type: String,
    required: true,
  },
  solidApplePayElId: {
    type: String,
    required: true,
  },
  solidCardElId: {
    type: String,
    required: true,
  },
  areMethodsLoaded: {
    type: Boolean,
    required: true,
  },
  isSubscription: {
    type: Boolean,
    default: false,
  },
  splitTiBo: {
    type: String,
    required: true,
  },
});

const { t } = useI18n({ messages });

const self = useSelf({ pkg: props.pkg });
</script>

<style scoped lang="scss" src="../../style.scss"></style>
