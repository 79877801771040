import {
  Dialog,
  DialogMessageView,
  PowerLikeRequest,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import {
  ListDialogMessagesResponse,
  ListDialogsResponse,
  GetDialogResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_pb';
import { DialogMessageContent } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

import { getUserId } from '@/helpers/auth/auth';
import {
  ChatStateDialogPreview,
  ChatStateDialogMessageContent,
  ChatStateDialogUser,
  ChatStateDialogMessage,
  PowerLikeState,
} from '@/pages/chat/store/chat-state';

// import ContentCase = DialogMessageContent.ContentCase;

export const CHAT_LIMIT = 10;
export const MESSAGES_LIMIT = 10;

export function getLastDialogPosition(dialogs: ChatStateDialogPreview[]): string {
  if (dialogs.length) {
    return dialogs[dialogs.length - 1].dialogPosition;
  }
  return '';
}

function getDialogMessageContentFrom(
  messageContent: DialogMessageContent | undefined,
): ChatStateDialogMessageContent | undefined {
  let content: ChatStateDialogMessageContent | undefined;
  if (messageContent) {
    if (messageContent.content.case === 'text') {
      content = {
        type: 'text',
        text: messageContent.content.value,
      };
    } else if (messageContent.content.case === 'image') {
      content = {
        type: 'image',
        imageId: messageContent.content.value.photoId,
      };
    } else if (messageContent.content.case === 'gift') {
      const gift = messageContent.content.value;
      content = {
        type: 'gift',
        giftId: gift.giftId,
        giftPhotoId: gift.photoId,
        giftText: gift.text,
      };
    }
  }

  return content;
}

export function resolveDialog(dialog: Dialog, position = ''): ChatStateDialogPreview {
  const meta = dialog.meta;
  const user = dialog.user;
  const created = dayjs().subtract(Number(meta?.createdAtDuration?.seconds ?? 0), 'second');
  const latestMessage = getDialogMessageContentFrom(dialog.latestMessage?.content);
  const powerLike = dialog.powerLikeRequest;
  const resolvePowerLike = (
    powerLike: PowerLikeRequest | undefined,
  ): PowerLikeState | undefined => {
    if (powerLike) {
      return {
        expiresAt: Number(meta?.expiresAtTime?.seconds ?? 0),
        expireDuration: Number(meta?.expireDuration?.seconds ?? 0),
        messagesCount: dialog.restrictions?.powerLikeMessages ?? 0,
      };
    }

    return;
  };

  return {
    id: dialog.connectionId,
    photoId: user?.avatar?.photoId ?? '',
    userName: user?.name ?? '',
    userAge: user?.age ?? 0,
    isVerified: false,
    isUserOnline: user?.isOnline ?? false,
    isUserDeleted: user?.isDeleted ?? false,
    latestMessage,
    isLatestMessageLocked: dialog.latestMessage?.isLocked ?? false,
    createdTimestampMs: created.valueOf(),
    dialogPosition: position ?? '',
    unreadMessagesCount: meta?.unreadMessagesCount ?? 0,
    latestMessageTimeMs: Number(dialog.latestMessage?.atTime?.seconds ?? 0) * 1000,
    isBlocked: false,
    isBlockedBySubject: false,
    userId: user?.id ?? '',
    powerLike: resolvePowerLike(powerLike),
    readOnly: dialog.restrictions?.readonly ?? false,
  };
}

export function getDialogsFrom(result: ListDialogsResponse): ChatStateDialogPreview[] {
  const dialogs: ChatStateDialogPreview[] = [];
  const pagination = result.pagination;
  const position = pagination?.next;

  result.items.forEach((dialog) => {
    dialogs.push(resolveDialog(dialog, position));
  });

  return dialogs;
}

export function getDialogFrom(result: GetDialogResponse): ChatStateDialogUser {
  const dialog = result.dialog;
  const user = dialog?.user;
  const latestOnlineTimestampMs = dayjs()
    .subtract(Number(user?.latestOnlineDuration?.seconds ?? 0), 'second')
    .valueOf();

  return {
    id: user?.id ?? '',
    avatarId: user?.avatar?.photoId ?? '',
    isOnline: user?.isOnline ?? false,
    latestOnlineTimestampMs,
    isBlockedBySubject: dialog?.isUserBlockedBySubject ?? false,
    isDeleted: user?.isDeleted ?? false,
    isBlocked: user?.isBlocked ?? false,
    name: user?.name ?? '',
    age: user?.age ?? 0,
  };
}

export function getMessagesPosition(messages: ChatStateDialogMessage[]): string {
  if (messages.length) {
    return messages[0].messagePosition;
  }
  return '';
}

export function getPreviewMessageFrom(message: DialogMessageView): ChatStateDialogMessageContent {
  switch (message.content?.content.case) {
    case 'gift':
      return {
        type: 'gift',
        giftId: message.content?.content.value.giftId ?? '',
        giftPhotoId: message.content?.content.value.photoId ?? '',
        giftText: message.content?.content.value.text ?? '',
      };
    case 'image':
      return {
        type: 'image',
        imageId: message.content?.content.value.photoId ?? '',
      };
    case 'text':
    default:
      return {
        type: 'text',
        text: message.content?.content.value ?? '',
      };
  }
}

export function getMessageFrom(
  message: DialogMessageView,
  lastMessagePosition: string,
): ChatStateDialogMessage {
  const createdTimestampMs = dayjs()
    .subtract(Number(message.createdDuration?.seconds ?? 0), 'second')
    .valueOf();

  const content = getDialogMessageContentFrom(message.content);

  return {
    id: message.messageId,
    userIdFrom: message.senderId,
    isNew: message.isNew,
    content,
    createdTimestampMs,
    messagePosition: lastMessagePosition ?? '',
    isSystem: message.isSystem,
    isSuspiciousContent: message.isSuspiciousContent,
    isLocked: message.isLocked ?? false,
  };
}

export function getMessagesFrom(result: ListDialogMessagesResponse): ChatStateDialogMessage[] {
  const messages: ChatStateDialogMessage[] = [];
  const lastMessagePosition = result.pagination?.next;

  result.items.forEach((message) => {
    // Do not display any system message, because we don't have the design for them
    if (!message.isSystem) {
      messages.push(getMessageFrom(message, lastMessagePosition ?? ''));
    }
  });

  return messages;
}

export function getMyMessageFrom(
  content: ChatStateDialogMessageContent,
  messageId: string,
): ChatStateDialogMessage {
  return {
    id: messageId,
    userIdFrom: getUserId(),
    isNew: false,
    content,
    createdTimestampMs: dayjs(new Date()).valueOf(),
    messagePosition: '',
    isSystem: false,
    isSuspiciousContent: false,
    isLocked: false,
  };
}
