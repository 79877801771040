import { renewSubscription } from '@/components/payment/helpers/subscription/subscription';
import { setProlongationTimeLastShown } from '@/components/prolongation-modal/helpers/prolongation/prolongation';
import { useProlongationModal } from '@/components/prolongation-modal/hooks/use-prolongation-modal/use-prolongation-modal';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { pushRealtimeTrackingCounter } from '@/services/realtime-tracking/realtime-tracking';

type UseSelfArg = {
  close: () => void;
  subscription: PaymentStateSubscription;
};
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const prolongationModal = useProlongationModal(arg);

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function unsuspendSubscription(): void {
    renewSubscription();
    arg.close();
  }

  function init() {
    prolongationModal.init();
    setProlongationTimeLastShown();
    pushRealtimeTrackingCounter('show-prolongation-modal', {
      type: 'prolongation-suspended-modal',
    });
  }

  return {
    prolongationModal,
    unsuspendSubscription,
    init,
  };
}
