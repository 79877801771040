import { showSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { isAutotestTrialPromoBackendDisabled } from '@/helpers/autotest/autotest';
import { NotificationHandler } from '@/services/websocket';

export const promoNotificationHandler: NotificationHandler = (notification) => {
  const { pathname } = window.location;
  // We shouldn't show trial promo on signup or offer pages, even backend wants to
  const isNotSignupPage = !/^\/signup/.test(pathname);
  const isNotOfferPage = !/^\/offer/.test(pathname);

  if (
    notification.notification.case === 'showPromos' &&
    isNotSignupPage &&
    isNotOfferPage &&
    !isAutotestTrialPromoBackendDisabled()
  ) {
    showSubscriptionPromoModal(undefined, 'backend');
  }

  if (notification.notification.case === 'featureLimit' && isNotSignupPage && isNotOfferPage) {
    showSubscriptionPromoModal(undefined, 'feature-limit');
  }
};
