import { ref } from 'vue';

import { loadCards } from '@/components/payment/helpers/payment-method/payment-method';
import { setProlongationTimeLastShown } from '@/components/prolongation-modal/helpers/prolongation/prolongation';
import { useProlongationModal } from '@/components/prolongation-modal/hooks/use-prolongation-modal/use-prolongation-modal';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { pushRealtimeTrackingCounter } from '@/services/realtime-tracking/realtime-tracking';

type UseSelfArg = {
  close: () => void;
  subscription: PaymentStateSubscription;
};
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const hasCardList = ref(false);

  // Hook ----------------------------------------------------------------------
  const prolongationModal = useProlongationModal(arg);

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function init() {
    prolongationModal.init();
    setProlongationTimeLastShown();
    pushRealtimeTrackingCounter('show-prolongation-modal', {
      type: 'prolongation-expired-modal',
    });

    loadCards().then((cards) => {
      hasCardList.value = cards.length > 0;
    });
  }

  return {
    hasCardList,
    prolongationModal,
    init,
  };
}
