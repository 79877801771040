import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { Ref } from 'vue';

import { usePackagePrice } from '@/pages/payment/hooks/use-package-price/use-package-price';
import { ExtractClassFields } from '@/type';

type UseSelfArg = {
  pkg: Ref<ExtractClassFields<PremiumPackage>>;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const packagePrice = usePackagePrice(arg.pkg.value);

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function init() {
    //
  }

  return {
    packagePrice,
    init,
  };
}
