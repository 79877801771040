<template>
  <div :class="rootClassName" data-test-id="purchase-continue">
    <div
      v-if="defaultPaymentCard && isDefaultPaymentCardVisible"
      class="selection_card flex justify-between items-center"
      @click="select"
    >
      <div v-if="defaultPaymentCard.brand === 'PayPal'" class="selection_card-name">
        <i class="icon icon-paypal"></i>
        PayPal
      </div>
      <div v-else class="selection_card-name">
        <i class="icon icon-credit_card"></i>
        {{ defaultPaymentCard?.number }}
      </div>
      <i class="icon icon-chevron_right"></i>
    </div>
    <PurchaseAutoTopUp v-if="isAutorefillVisible" />
    <LoaderButton
      v-model:is-loading="isContinueButtonLoadingValue"
      type="button"
      class="btn btn-block mb-0"
      :class="btnClass"
      :loading-duration-ms="10000"
      @click="nextCard"
    >
      <slot name="continue">{{ t('continue') }}</slot>
    </LoaderButton>
  </div>
  <slot name="caption"></slot>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import LoaderButton from '@/components/inputs/loader-button/LoaderButton.vue';
import { useModel } from '@/hooks/use-model/use-model';
import PurchaseAutoTopUp from '@/pages/payment/components/layout/components/purchase-auto-top-up/PurchaseAutoTopUp.vue';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';
import { getState } from '@/store/store-helper';

import { messages } from './i18n';

const props = defineProps({
  isAutorefillVisible: {
    type: Boolean,
    default: false,
  },
  isContinueButtonLoading: {
    type: Boolean,
    default: false,
  },
  isDefaultPaymentCardVisible: {
    type: Boolean,
    default: true,
  },
  btnClass: {
    type: String,
    default: 'btn-primary',
  },
  rootClassName: {
    type: String,
    default: 'shadow-panel-wrap',
  },
});
const emit = defineEmits(['select', 'next', 'update:isContinueButtonLoading']);

const isContinueButtonLoadingValue = useModel<boolean>(props, 'isContinueButtonLoading', emit);

const defaultPaymentCard = computed<PaymentStateCard | undefined>(() => {
  return getState().payment.paymentCards[0];
});

function nextCard(): void {
  // If we have default card, then continue means to buy instantly
  if (defaultPaymentCard.value) {
    emit('next');
  } else {
    select();
  }
}

function select(): void {
  emit('select');
}

const { t } = useI18n({ messages });
</script>
