import ProlongationSuspendedModal from '@/components/prolongation-modal/components/prolongation-suspended-modal/ProlongationSuspendedModal.vue';
import {
  PaymentStateSubscription,
  PaymentStateSubscriptionState,
} from '@/pages/payment/store/payment-state';
import { useModal } from '@/services/modal/modal';

export function showSuspendedModal(subscription: PaymentStateSubscription) {
  useModal(() => ({
    component: ProlongationSuspendedModal,
    props: {
      subscription,
    },
  })).showModal();
}

export function getIsSuspendedModalAvailable(status: PaymentStateSubscriptionState) {
  return status === 'suspended';
}
