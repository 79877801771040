import { ActionContext } from 'vuex';

import { PossibilityPackage } from '@/pages/my-profile/components/my-profile-view/components/my-profile-boosters-grid/components/profile-card/helpers/package-id-helper/package-id-helper';
import { getPackages } from '@/services/api/possibilities/possibilities-api';
import { PossibilityState } from '@/store/modules/possibility/possibility-state';
import { commit, ActionFunction } from '@/store/store-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionPossibilityBatchGetPackages: ActionFunction<PossibilityPackage[], never>;
  }
}

export async function actionPossibilityBatchGetPackages(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<PossibilityState, unknown>,
  list: PossibilityPackage[],
): Promise<void> {
  const response = await getPackages(list);
  const pkgList = response.packages;

  commit('mutationPossibilitySetPackagesList', pkgList);
}
