import {
  MyProfileState,
  MyProfileStateFull,
  MyProfileStateShort,
} from '@/pages/my-profile/store/my-profile-state';

export type MutationMyProfileFullSetFieldPayload = Partial<MyProfileStateFull>;

declare module '@/store/store-helper' {
  interface Mutation {
    mutationMyProfileSetShort: MyProfileStateShort;
    mutationMyProfileSetShortAvatarId: string;
    mutationMyProfileFullSetField: MutationMyProfileFullSetFieldPayload;
    mutationMyProfileSetFull: MyProfileStateFull;
    mutationMyProfileFullAddPhoto: string;
    mutationMyProfileFullDeletePhoto: string;
    mutationMyProfileFullSetAvatar: string;
    mutationMyProfileShortChangeBoosterEndsDuration: number;
  }
}

export function mutationMyProfileSetShort(state: MyProfileState, profile: MyProfileStateShort) {
  state.short = profile;
}

export function mutationMyProfileSetShortAvatarId(state: MyProfileState, avatarId: string) {
  state.short.avatar.id = avatarId;
}

export function mutationMyProfileFullSetField(
  state: MyProfileState,
  payload: MutationMyProfileFullSetFieldPayload,
) {
  Object.keys(payload).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.full[key] = payload[key];
  });
}

export function mutationMyProfileFullAddPhoto(state: MyProfileState, photoId: string) {
  if (state.full) {
    state.full.photos.push({
      id: photoId,
      hasBlur: false,
      declineReason: '',
      isDeclined: false,
      isDummy: false,
      tags: [],
    });
  }
}

export function mutationMyProfileFullSetAvatar(state: MyProfileState, photoId: string) {
  if (state.full) {
    const { photos } = state.full;
    const index = photos.findIndex((photo) => photo.id === photoId);
    if (index !== -1) {
      const [photo] = photos.splice(index, 1);
      photos.unshift(photo);
    }
  }
}

export function mutationMyProfileSetFull(state: MyProfileState, profile: MyProfileStateFull) {
  state.full = profile;
}

export function mutationMyProfileFullDeletePhoto(state: MyProfileState, photoId: string) {
  if (state.full) {
    const index = state.full.photos.findIndex((photo) => photo.id === photoId);
    if (index !== -1) {
      state.full.photos.splice(index, 1);
    }
  }
}

export function mutationMyProfileShortChangeBoosterEndsDuration(
  state: MyProfileState,
  durationMs: number,
) {
  state.short.boosterEndsDurationMs += durationMs;
}
