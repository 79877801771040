<template>
  <ul class="flex flex-col gap-2">
    <slot></slot>
    <PremiumBenefit name='The book "Online Dating Mastery".' price-old="$60" price-new="$0" />
    <li class="flex flex-col gap-2">
      <span class="flex w-full text-caption text-dark-100">Special chapters:</span>
      <ul class="flex flex-col gap-2 ml-4">
        <PremiumBenefit
          v-for="book in bookList"
          :key="book.id"
          :name="book.name"
          :price-old="book.priceOld"
          :price-new="book.priceNew"
        />
      </ul>
    </li>
  </ul>
</template>

<script setup lang="ts">
import PremiumBenefit from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-checkout/components/premium-benefit/PremiumBenefit.vue';

type Book = {
  id: number;
  name: string;
  priceOld: string;
  priceNew: string;
};

const bookList: Book[] = [
  { name: '“Decoding their essence”', priceOld: '$9.99', priceNew: '$0', id: 1 },
  { name: '“First kiss compass”', priceOld: '$9.99', priceNew: '$0', id: 2 },
  { name: '“Cringe-worthy flirting mistakes”', priceOld: '$9.99', priceNew: '$0', id: 3 },
  { name: '“Test: what is your flirting style?”', priceOld: '$9.99', priceNew: '$0', id: 4 },
];
</script>

<style scoped lang="scss"></style>
