import { PossibilityType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';

import { I18n } from '@/services/i18n';

type Message = Record<PossibilityType, string>;

const messages: I18n<Message> = {
  en: {
    0: 'invalid possibility',
    1: 'Gift is added',
    2: 'Top Profile added',
    3: 'Top Profile+ added',
    4: 'Astro Compatibility added',
    5: 'Chat request added',
    6: 'Romantic Compatibility added',
    7: 'Chat request added',
  },
  es: {
    0: 'posibilidad inválida',
    1: 'Regalo añadido',
    2: 'Perfil superior añadido',
    3: 'Perfil superior+ añadido',
    4: 'Compatibilidad astrológica añadida',
    5: 'Solicitud de chat añadida',
    6: 'Compatibilidad romántica añadida',
    7: 'Solicitud de chat añadida',
  },
};

export { messages };
