import { CreditsPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, ComputedRef } from 'vue';

import { debounce } from '@/helpers/you-dont-need-lodash/you-dont-need-lodash';
import { pushRoute } from '@/router';
import { dispatch, getState } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

const isVisible = computed<boolean>(() => !!pkg.value);
const pkg = computed<ExtractClassFields<CreditsPackage>>(
  () => getState().payment.promoCreditsPackages[0],
);

function purchase(): void {
  pushRoute('paymentPurchaseCredits');
}

export function useLimitedOfferCreditsPack(): {
  isVisible: ComputedRef<boolean>;
  purchase: () => void;
  pkg: ComputedRef<ExtractClassFields<CreditsPackage>>;
} {
  const debounced = debounce(() => {
    if (!pkg.value) {
      dispatch('actionPaymentListPromoCreditsPackages');
    }
  }, 500);

  debounced();

  return {
    isVisible,
    purchase,
    pkg,
  };
}
