import { I18n } from '@/services/i18n';

type Message = {
  secureCheckout: string;
  creditCard: string;
  info: string;
  yourCreditCardWillBeBilled: string;
};

const messages: I18n<Message> = {
  en: {
    secureCheckout: 'Secure checkout',
    creditCard: 'Credit card',
    info: 'We will not be charging your card  without your permission',
    yourCreditCardWillBeBilled: 'Your Credit Card will be billed as BILLING.MEETMYAGE.COM',
  },
  es: {
    secureCheckout: 'Pago seguro',
    creditCard: 'Tarjeta de crédito',
    info: 'No cargaremos a tu tarjeta sin tu permiso',
    yourCreditCardWillBeBilled: 'Tu tarjeta de crédito será cobrada como BILLING.MEETMYAGE.COM',
  },
};

export { messages };
