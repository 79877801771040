import { I18n } from '@/services/i18n';

type Message = {
  error: string;
};

const messages: I18n<Message> = {
  en: {
    error: "We couldn't load this page.<br>Please, try again later.",
  },
  es: {
    error: 'No pudimos cargar esta página.<br>Por favor, inténtalo de nuevo más tarde.',
  },
};

export { messages };
