<template>
  <div data-id="notification-request-photo-uploaded" class="alert-info flex" @click="goPage">
    <div class="alert-info-icon upload flex items-center justify-center">
      <div class="alert-info-img">
        <RetinaPhoto
          :photo-id="info.avatar.id"
          :is-blurred="false"
          :width="48"
          :height="48"
          group-ratio="avatar-square"
        ></RetinaPhoto>
      </div>
      <i class="icon icon-photo_solid success"></i>
    </div>
    <div class="alert-info-body grow flex items-center">
      {{ info.name }}, {{ info.age }} {{ t('userUploadedPhoto') }}
    </div>
    <span class="color-primary alert-info-right_link">{{ t('view') }}</span>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import { NotificationDataRequestPhotoUploaded } from '@/components/notification/helpers/notification-shower/notification-shower';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  info: {
    type: Object as PropType<NotificationDataRequestPhotoUploaded>,
    required: true,
  },
});

const { t } = useI18n({ messages });

function goPage(): void {
  pushRoute('profileView', { userId: props.info.userId });
}
</script>
