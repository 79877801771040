import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  action: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Astro compatibility with...',
    description: '{name} checked their astrological compatibility with you',
    action: 'Visit profile',
  },
  es: {
    header: 'Compatibilidad astrológica con...',
    description: '{name} verificó su compatibilidad astrológica contigo',
    action: 'Visitar perfil',
  },
};

export { messages };
