import * as actions from '@/pages/signup/store/signup-actions';
import * as mutations from '@/pages/signup/store/signup-mutations';
import { state } from '@/pages/signup/store/signup-state';

export const signupPersisted = ['signup'];

export const signup = {
  state,
  mutations,
  actions,
};
