import { createPromiseClient } from '@connectrpc/connect';
import { PossibilitiesAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_api_connect';
import {
  PurchasePossibilityRequest,
  PurchasePossibilityResponse,
  GetPackageRequest,
  GetPackageResponse,
  BatchGetPackagesResponse,
  BatchGetPackagesRequest,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_api_pb';
import { Purchase } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';

import { PossibilityPackage } from '@/pages/my-profile/components/my-profile-view/components/my-profile-boosters-grid/components/profile-card/helpers/package-id-helper/package-id-helper';
import { getGrpcWebTransport } from '@/services/api/grpc-api';

const client = createPromiseClient(PossibilitiesAPI, getGrpcWebTransport());

export function purchasePossibility(id: PossibilityPackage): Promise<PurchasePossibilityResponse> {
  const purchase = new Purchase({
    packageId: id,
  });
  const request = new PurchasePossibilityRequest({
    purchase,
  });

  return client.purchasePossibility(request);
}

export function getPackage(id: PossibilityPackage): Promise<GetPackageResponse> {
  const request = new GetPackageRequest({
    packageId: id,
  });

  return client.getPackage(request);
}

export function getPackages(list: PossibilityPackage[]): Promise<BatchGetPackagesResponse> {
  const request = new BatchGetPackagesRequest({
    packageIds: list,
  });

  return client.batchGetPackages(request);
}
