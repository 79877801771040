import {
  customEventTrigger,
  PromoShowPurchaseSource,
  TrackPaymentClickMethod,
  TrackPaymentClickType,
  TrackPaymentEventPopup,
  TrackPaymentEventType,
  TrackPaymentFlowStep,
  TrackPaymentResultType,
} from '@/helpers/gtm-triggers/gtm-triggers';
import { generateUniqueId } from '@/helpers/number';
import {
  getSessionCache,
  removeSessionCache,
  setSessionCache,
} from '@/helpers/session-cache/session-cache';
import { RouteName, router } from '@/router';

const ID_CACHE_KEY = 'track_id';

export function getChainId(): string {
  const id = getSessionCache(ID_CACHE_KEY);
  if (!id) {
    return createChainId();
  }

  return id;
}

export function removeChainId(): void {
  removeSessionCache(ID_CACHE_KEY);
}

function createChainId(): string {
  const id = generateUniqueId();
  setSessionCache(ID_CACHE_KEY, id);
  return id;
}

export function trackPaymentPromo(payload: {
  type: TrackPaymentEventType;
  from: PromoShowPurchaseSource;
  popup: TrackPaymentEventPopup;
}) {
  const chainId = createChainId();

  customEventTrigger({
    event: 'promo',
    chainId,
    type: payload.type,
    popup: payload.popup,
    from: payload.from,
  });
}

export function trackPaymentFlow(payload: { step: TrackPaymentFlowStep }) {
  const chainId = getChainId();

  customEventTrigger({
    event: 'payment-flow',
    chainId,
    ...payload,
  });
}

export function trackPaymentClick(payload: { method: TrackPaymentClickMethod }) {
  const chainId = getChainId();
  const path = window.location.pathname;
  const type: TrackPaymentClickType =
    (router.currentRoute.value.name as RouteName) === 'paymentFail' ? 'fail-page' : 'regular-flow';

  customEventTrigger({
    event: 'payment-click',
    chainId,
    type,
    path,
    ...payload,
  });
}

export function trackPaymentSubmit(payload: { method: TrackPaymentClickMethod }) {
  const chainId = getChainId();
  const path = window.location.pathname;

  customEventTrigger({
    event: 'payment-submit',
    chainId,
    path,
    ...payload,
  });
}

export function trackPaymentResult(payload: { type: TrackPaymentResultType; orderId: string }) {
  const chainId = getChainId();

  customEventTrigger({
    event: 'payment-result',
    chainId,
    ...payload,
  });

  removeChainId();
}
