import { Timestamp } from '@bufbuild/protobuf';
import {
  Offer,
  PremiumPackage,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { Subscription } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/subscription_pb';

import { isSubscriptionPayer } from '@/components/payment/helpers/subscription/subscription';
import {
  getIsCancelledModalAvailable,
  showCancelledModal,
} from '@/components/prolongation-modal/components/prolongation-cancelled-modal/helpers/cancelled-modal/cancelled-modal';
import {
  getIsEarlyUpgradeModalAvailable,
  showEarlyUpgradeModal,
} from '@/components/prolongation-modal/components/prolongation-early-upgrade-modal/helpers/early-upgrade-modal/early-upgrade-modal';
import {
  getIsExpiredModalAvailable,
  showExpiredModal,
} from '@/components/prolongation-modal/components/prolongation-expired-modal/helpers/expired-modal/expired-modal';
import {
  getIsSuspendedModalAvailable,
  showSuspendedModal,
} from '@/components/prolongation-modal/components/prolongation-suspended-modal/helpers/suspended-modal/suspended-modal';
import { isSearchPageVisited } from '@/helpers/auth/auth';
import { getCache, setCache } from '@/helpers/persistent-cache';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { listProfileTags } from '@/services/api/my-profile/my-profile-api';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { PACKAGE_PREMIUM_OFFER_EARLY_UPGRADE_ID } from '@/settings';
import { ExtractClassFields } from '@/type';

const CACHE_KEY = 'prolongation-last-shown-time';

type GetIsProlongationModalAvailableArg = {
  hasTimeRestriction: boolean;
  subscription: ExtractClassFields<Subscription>;
};
export function getIsProlongationModalAvailable({
  hasTimeRestriction,
  subscription,
}: GetIsProlongationModalAvailableArg): boolean {
  if (hasTimeRestriction) {
    const lastShownMs = +(getCache(CACHE_KEY) ?? 0);
    const delayMs = 6 * 60 * 60 * 1000;
    const nowMs = +new Date();

    if (nowMs - lastShownMs < delayMs) {
      return false;
    }
  }

  return isSubscriptionPayer(subscription);
}

async function getIsProlongationModalPreconditionPassed(): Promise<boolean> {
  if (!isSearchPageVisited()) {
    return false;
  }

  try {
    const tagList = (await listProfileTags()).tags;

    // Disable modal for 'refunder' due to this task
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-3536/Disable-the-Reactivate-pop-up-to-refunders
    if (tagList.includes('refunder')) {
      return false;
    }
  } catch {
    return false;
  }

  return true;
}

type ProlongationModalName = 'early-upgrade' | 'expired' | 'suspended' | 'cancelled' | '';

type GetProlongationModalArg = {
  hasTimeRestriction: boolean;
  subscription: PaymentStateSubscription;
};

export async function getProlongationModalName({
  hasTimeRestriction,
  subscription: { subscription, status },
}: GetProlongationModalArg): Promise<ProlongationModalName> {
  try {
    if (!(await getIsProlongationModalPreconditionPassed())) {
      return '';
    }

    if (
      await getIsEarlyUpgradeModalAvailable({
        hasTimeRestriction,
        subscription,
      })
    ) {
      return 'early-upgrade';
    }

    if (
      !getIsProlongationModalAvailable({
        hasTimeRestriction,
        subscription,
      })
    ) {
      return '';
    }

    if (getIsExpiredModalAvailable()) {
      return 'expired';
    }

    if (getIsCancelledModalAvailable(status)) {
      return 'cancelled';
    }

    if (getIsSuspendedModalAvailable(status)) {
      return 'suspended';
    }
  } catch {
    return '';
  }

  return '';
}

type ShowProlongationModalArg = {
  hasTimeRestriction: boolean;
  subscription: PaymentStateSubscription;
};
export async function showProlongationModal({
  subscription,
  hasTimeRestriction,
}: ShowProlongationModalArg): Promise<void> {
  try {
    const modalName = await getProlongationModalName({
      hasTimeRestriction,
      subscription,
    });

    switch (modalName) {
      case 'early-upgrade':
        showEarlyUpgradeModal(subscription);
        break;

      case 'expired':
        showExpiredModal(subscription);
        break;

      case 'cancelled':
        showCancelledModal(subscription);
        break;

      case 'suspended':
        showSuspendedModal(subscription);
        break;
    }
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to show prolongation modal',
      captureContext: {
        extra: { error },
      },
    });
  }
}

export function getPackageProlongationOffer(
  pkg: ExtractClassFields<PremiumPackage>,
): ExtractClassFields<Offer> {
  return new Offer({
    id: PACKAGE_PREMIUM_OFFER_EARLY_UPGRADE_ID,
    isFree: false,
    price: pkg.price,
    pricePerWeek: pkg.pricePerWeek,
    discountPercent: 0,
    expiresAtTime: new Timestamp({
      seconds: 0n,
      nanos: 0,
    }),
    expiresDuration: new Timestamp({
      seconds: 0n,
      nanos: 0,
    }),
    type: PromoType.INVALID,
  });
}

export function setProlongationTimeLastShown() {
  const nowMs = +new Date();
  setCache(CACHE_KEY, nowMs.toString());
}
