import { ref } from 'vue';

import { listIceBreakers } from '@/services/api/chat/chat-api';

export function useStoreChatIceBreakers(userId: string) {
  const iceBreakers = ref<string[]>([]);

  function load() {
    return listIceBreakers(userId).then((res) => {
      iceBreakers.value.push(...res.iceBreakers);
      return iceBreakers.value;
    });
  }

  return {
    load,
    iceBreakers,
  };
}
