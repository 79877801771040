import { Ref } from 'vue';

import {
  UseSnackButton,
  UseSnackType,
} from '@/components/snack-button/hooks/use-snack-button/type/type';
import { useSnackButtonCredits } from '@/components/snack-button/hooks/use-snack-button-credits/use-snack-button-credits';

import { useSnackButtonModeration } from '../use-snack-button-moderation/use-snack-button-moderation';
import { useSnackButtonPremium } from '../use-snack-button-premium/use-snack-button-premium';
import { useSnackButtonPremiumGold } from '../use-snack-button-premium-gold/use-snack-button-premium-gold';
import { useSnackButtonSearch } from '../use-snack-button-search/use-snack-button-search';
import { useSnackButtonWebView } from '../use-snack-button-web-view/use-snack-button-web-view';

type Emit = (prop: 'hide', arg: boolean) => void;

const useSnackButtonInfoMock = (emit: Emit) => ({
  action: () => ({}),
  close: () => emit('hide', true),
  isVisible: { value: true } as Ref<boolean>,
});

export function useSnackButton(hook: UseSnackType, emit: Emit): UseSnackButton {
  switch (hook) {
    case 'web-view':
      return useSnackButtonWebView(emit);

    case 'premium':
      return useSnackButtonPremium();

    case 'premium-gold':
      return useSnackButtonPremiumGold(emit);

    case 'credits':
      return useSnackButtonCredits(emit);

    case 'moderation':
      return useSnackButtonModeration();

    case 'info':
      return useSnackButtonInfoMock(emit);

    case 'search':
    default:
      return useSnackButtonSearch();
  }
}
