import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  action: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Check romantic compatibility',
    description: 'Was it a spark between you two? Get all the answers in a single tap.',
    action: 'Check compatibility',
  },
  es: {
    header: 'Verifica compatibilidad romántica',
    description:
      '¿Hubo una chispa entre ustedes dos? Obtén todas las respuestas con un solo toque.',
    action: 'Verificar compatibilidad',
  },
};

export { messages };
