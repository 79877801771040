<template>
  <a
    href="#"
    class="icon icon-back text-dark-100 hover:text-dark-100"
    data-test-id="btn_back"
    @click.prevent
  >
    <i class="icon icon-arrow_left"></i>
  </a>
</template>
