import { reactive } from 'vue';

import TextPage from '@/pages/text-page/TextPage.vue';
import { useModal } from '@/services/modal/modal';

type UseSelfArg = {
  //
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const membershipModal = useModal(() => ({
    component: TextPage,
    props: {
      isModal: true,
      pageId: 'membership',
    },
  }));

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function init() {
    //
  }

  return reactive({
    membershipModal,
    init,
  });
}
