import dayjs from 'dayjs';
import { computed, reactive } from 'vue';

import { isUserLoggedIn } from '@/helpers/auth/auth';
import { router } from '@/router';

export type TimerName =
  | 'softBlock'
  | 'notificationRestriction'
  | 'limitedOfferCredits'
  | 'freeGift'
  | `freeGift_${string}`
  | `power_like_${string}`
  | 'retentionReward'
  | 'trial-welcome'
  | 'ntf';

// Global interval ID, because it's more efficient to have 1 interval for all timers.
export let intervalId: number;

export type Timer = {
  secondsLeft: number;
  expiresAtSeconds: number;
};
export type TimersCache = Record<TimerName, { expiresAtSeconds: number }>;

export const timers = reactive<Record<TimerName, Timer>>({} as Record<TimerName, Timer>);
export let sharedTimer: TimersCache | null = {} as TimersCache;

export function saveTimers(): void {
  Object.keys(timers).forEach((name) => {
    if (sharedTimer) {
      sharedTimer[name as TimerName] = {
        expiresAtSeconds: dayjs().unix() + timers[name as TimerName].secondsLeft,
      };
    }
  });
}

export function loadTimers(): void {
  if (sharedTimer) {
    Object.keys(sharedTimer).forEach((key) => {
      const expiresAtSeconds =
        (sharedTimer && sharedTimer[key as TimerName]?.expiresAtSeconds) ?? 0;
      let secondsLeft = expiresAtSeconds - dayjs().unix();

      if (secondsLeft < 0) {
        secondsLeft = 0;
      }

      timers[key as TimerName] = {
        secondsLeft,
        expiresAtSeconds,
      };
    });
  }
}

export const tick = (): void => {
  let isAnyTimerAlive = false;

  Object.values(timers).forEach((timer) => {
    if (timer.secondsLeft > 0) {
      timer.secondsLeft = timer.expiresAtSeconds - dayjs().unix();
      isAnyTimerAlive = true;
    }
  });

  // Stop setInterval if there is not active timers.
  if (!isAnyTimerAlive && intervalId) {
    clearInterval(intervalId);
    intervalId = 0;
  }
};

export function startTimersInterval(): void {
  if (!intervalId) {
    intervalId = window.setInterval(tick, 1000);
  }
}

export function removeTimerByName(key: TimerName): void {
  if (sharedTimer) {
    delete sharedTimer[key];
  }
}

export const removeTimers = (): void => {
  sharedTimer = null;
};

export function checkIfUserLoggedIn(unsubscribe: () => void) {
  // Exclude all `.isGuest` routes because startNotificationLogic requires
  // to check splits which are not accessible from guest routes.
  if (!isUserLoggedIn()) {
    removeTimers();
    unsubscribe();
  }
}

export function clearTimerWhenUserLogsOut(): void {
  if (isUserLoggedIn()) {
    const unsubscribe: () => void = router.beforeEach(() => checkIfUserLoggedIn(unsubscribe));
  }
}

export function useTimer(timerName: TimerName) {
  // Load all timers from localStorage ( it's needed if page has been reloaded )
  loadTimers();

  /**
   * @param secondsLeft - timer duration in seconds
   * @param isForced - if we want to recreate current timer
   */
  function startTimer(secondsLeft: number, isForced = false): Timer {
    if (!timers[timerName] || isForced) {
      timers[timerName] = {
        secondsLeft,
        expiresAtSeconds: dayjs().unix() + secondsLeft,
      };
      saveTimers();
    }
    startTimersInterval();
    clearTimerWhenUserLogsOut();

    return timers[timerName];
  }

  const timeLeftFormatted = computed((): string => {
    const duration = dayjs.duration(timerSecondsLeft.value, 'seconds');
    const hours = Math.floor(duration.asHours());
    const result = hours + duration.format(':mm:ss');

    return result;
  });

  const timerSecondsLeft = computed(() => {
    const secondsLeft = timers[timerName]?.secondsLeft ?? 0;

    return secondsLeft > 0 ? secondsLeft : 0;
  });

  return {
    startTimer,
    timerSecondsLeft,
    timeLeftFormatted,
  };
}
