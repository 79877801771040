<template>
  <div class="notification-activity" :class="renderClasses[content.notificationType].bgClass">
    <NotificationClose @click="emit('close')" />
    <div class="notification-activity_wrapper">
      <div class="mr-2">
        <FontIcon :class="renderClasses[content.notificationType].iconClass" />
      </div>
      <div class="notification-activity_txt">
        <div class="notification-activity_title">
          {{ t('header.' + content.notificationType, { count: content.stackCount.toString() }) }}
        </div>
      </div>
    </div>
    <NotificationActivityButton @action="action">{{
      t('actionButtonText')
    }}</NotificationActivityButton>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import NotificationActivityButton from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-button/NotificationActivityButton.vue';
import NotificationClose from '@/components/notification/components/notification-redesign/notification-close/NotificationClose.vue';
import { NotificationDataStackActivity } from '@/components/notification/helpers/notification-shower/notification-shower';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

type RenderClasses = {
  bgClass: string;
  iconClass: string;
};

const props = defineProps({
  content: {
    type: Object as PropType<NotificationDataStackActivity>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const renderClasses: Record<NotificationDataStackActivity['notificationType'], RenderClasses> = {
  visit: {
    bgClass: 'bg-primary',
    iconClass: 'icon-eye_solid',
  },
  like: {
    bgClass: 'bg-secondary',
    iconClass: 'icon-like_solid',
  },
  'incoming-match': {
    bgClass: 'bg-secondary',
    iconClass: 'icon-like_solid',
  },
  'power-like': {
    bgClass: 'bg-powerlike',
    iconClass: 'icon-instant-chat_solid',
  },
  'chat-message': {
    bgClass: 'bg-primary',
    iconClass: 'icon-chat_solid',
  },
};

function action(): void {
  customEventTrigger(
    {
      event: `notification_stack_activity_click`,
      notificationType: props.content.notificationType,
    },
    { trackAllLabels: true },
  );
  props.content.action();
}

onMounted(() => {
  customEventTrigger(
    {
      event: `notification_stack_activity_show`,
      notificationType: props.content.notificationType,
    },
    { trackAllLabels: true },
  );
});
</script>
