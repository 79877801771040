import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { computed, ref } from 'vue';

import { getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export function useMatchChat({ goChatPage }: { goChatPage: () => void }) {
  const footerRef = ref<
    HTMLDivElement & {
      sendGiftMessage: (giftId: string, userId: string) => void;
    }
  >();

  const freeGiftsCount = computed(() => {
    return getStore().state.payment.freeGiftsCount;
  });

  function sendGiftMessage(giftId: string, userId: string) {
    footerRef.value?.sendGiftMessage(giftId, userId);
    goChatPage();
  }

  function isFreeGift(gift: ExtractClassFields<DialogGift>): boolean {
    return (gift.isFree && !gift.availableInDuration?.seconds) || freeGiftsCount.value > 0;
  }

  return {
    footerRef,
    sendGiftMessage,
    isFreeGift,
  };
}
