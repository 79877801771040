import { Code, ConnectError } from '@connectrpc/connect';

import { goBackOrGo, replaceRoute } from '@/router';
import { commit } from '@/store/store-helper';

export function handlePayError(e: any) {
  const connectErr = ConnectError.from(e);
  switch (connectErr.code) {
    // If user already has subscription.
    case Code.AlreadyExists:
      goBackOrGo('search');
      break;

    // If user's token has been expired or errored.
    case Code.FailedPrecondition:
      commit('mutationPaymentSetPaymentFail', {
        code: `${Code.FailedPrecondition}`,
        message: 'This card token has expired.',
        recommendation: 'Please, try another card',
        declinedRetryAttempt: 0,
      });
      replaceRoute('paymentFail');
      break;

    default:
      break;
  }
}
