<template>
  <div>
    <RadioInput
      v-for="card in cards"
      :key="card.id"
      v-model="selectedCardId"
      :value="card.id"
      name="method"
    >
      <div class="card-wrapper" data-test-id="card_item">
        <div class="card-wrapper-img">
          <SvgIcon
            v-if="card.brand === 'MASTERCARD'"
            name="mastercard-black"
            class="credit-card-list-icon"
            :width="24"
          />
          <SvgIcon
            v-else-if="card.brand === 'VISA'"
            name="visa-black"
            class="credit-card-list-icon"
            :width="24"
          />
          <SvgIcon
            v-else-if="card.brand === 'DINERS CLUB'"
            name="dinnerclub-light"
            class="credit-card-list-icon"
            :width="24"
          />
          <SvgIcon
            v-else-if="card.brand === 'DISCOVER'"
            name="discover-light"
            class="credit-card-list-icon"
            :width="24"
          />
          <SvgIcon
            v-else-if="card.brand === 'AMEX'"
            name="americanexpress-light"
            class="credit-card-list-icon"
            :width="24"
          />
          <SvgIcon
            v-else-if="card.brand === 'MAESTRO'"
            name="maestro-light"
            class="credit-card-list-icon"
            :width="24"
          />
          <i v-else-if="card.brand === 'PayPal'" class="icon icon-paypal" alt="PayPal" />
          <SvgIcon v-else name="simple-card-light" class="credit-card-list-icon" :width="24" />
        </div>
        <div class="card-wrapper-number">
          <template v-if="card.brand === 'PayPal'">PayPal</template>
          <template v-else>• • • • • {{ card.number }}</template>
        </div>
      </div>
    </RadioInput>

    <SecondaryBlockAddButton data-test-id="add_card_btn" @click="addMethod">
      {{ t('add') }}
    </SecondaryBlockAddButton>

    <div v-if="isButton" class="card-footer" data-test-id="save_btn_footer">
      <LoaderButton
        class="btn btn-primary btn-block"
        :disabled="selectedCardId === ''"
        @click="next"
      >
        <slot name="button"></slot>
      </LoaderButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue';

import SecondaryBlockAddButton from '@/components/inputs/button/SecondaryBlockAddButton.vue';
import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import LoaderButton from '@/components/inputs/loader-button/LoaderButton.vue';
import RadioInput from '@/components/inputs/radio/RadioInput.vue';
import { PaymentStateCard, PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';
import { commit } from '@/store/store-helper';

import { messages } from './i18n';

const emit = defineEmits(['next', 'add']);

const selectedCardId = ref('');

const { t } = useI18n({ messages });
const props = defineProps({
  isButton: {
    type: Boolean,
    default: true,
  },
  cards: {
    type: Array as PropType<PaymentStateCard[]>,
    required: true,
  },
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    required: false,
    default: () => ({}),
  },
});

const selectedCard = computed<PaymentStateCard | undefined>(() =>
  props.cards.find((card) => card.id === selectedCardId.value),
);

function next(): void {
  emit('next', selectedCard.value);
  commit('mutationPaymentSetDefaultCard', selectedCard.value as PaymentStateCard);
}

function addMethod(): void {
  emit('add');
}

function setSelectedCard(): void {
  // Make first card selected by default.
  if (props.cards.length > 0) {
    selectedCardId.value = props.cards[0].id;
  }
}

setSelectedCard();
</script>
