import { ref } from 'vue';

import { fetchGeo, Geo } from '@/services/geolocation';

export function useCountry() {
  const country = ref<Geo>();

  async function load() {
    country.value = await fetchGeo();
    return country.value;
  }

  return {
    country,
    load,
  };
}
