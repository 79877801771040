import { GetSexualCompatibilityStatusResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_pb';
import { ActionContext } from 'vuex';

import { SexualCompatibilityState } from '@/components/sexual-compatibility/store/sexual-compatibility-state';
import {
  activateSexualCompatibilityStatus,
  getSexualCompatibilityStatus,
} from '@/services/api/sexual-compatibility/sexual-compatibility-api';
import { commit, ActionFunction } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Action {
    actionSexualCompatibilityActivate: ActionFunction<string, void>;
    actionSexualCompatibilityGetStatus: ActionFunction<
      string,
      ExtractClassFields<GetSexualCompatibilityStatusResponse>
    >;
  }
}

export async function actionSexualCompatibilityGetStatus(
  {
    // eslint-disable-next-line
  state,
  }: ActionContext<SexualCompatibilityState, unknown>,
  idUser: string,
): Promise<ExtractClassFields<GetSexualCompatibilityStatusResponse>> {
  const response = await getSexualCompatibilityStatus(idUser);

  commit('mutationSexualCompatibilitySetStatus', {
    compatibility: response,
    idUser,
  });

  return response;
}

export async function actionSexualCompatibilityActivate(
  {
    // eslint-disable-next-line
    state,
  }: ActionContext<SexualCompatibilityState, unknown>,
  idUser: string,
): Promise<void> {
  await activateSexualCompatibilityStatus(idUser);
}
