<template>
  <div data-id="notification-new-version" class="alert-info flex match">
    <div class="alert-info-icon upload flex justify-center">
      <div class="img-section">
        <i class="icon icon-report"></i>
      </div>
    </div>
    <div class="alert-info-body grow flex items-center">
      <div class="alert-info-section text-center">
        {{ t('text') }}
        <br />
        <br />
        <PrimaryBlockButton @click="refresh">{{ t('refresh') }}</PrimaryBlockButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PrimaryBlockButton from '@/components/inputs/button/PrimaryBlockButton.vue';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });

function refresh() {
  window.location.reload();
}
</script>
