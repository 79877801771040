import { I18n } from '@/services/i18n';

type Message = {
  sub1: string;
  text1: string;
  sub2: string;
  text2: string;
  sub3: string;
  text3: string;
  sub4: string;
  text4: string;
  sub5: string;
  text5: string;
  sub6: string;
  text6: string;
  sub7: string;
  text7: string;
  sub8: string;
  text8: string;
};

const messages: I18n<Message> = {
  en: {
    sub1: 'Advanced information',
    text1: 'See all photos and full <br />profiles of other singles',
    sub2: 'Unlimited Likes',
    text2: 'Now you can send only <br />3&nbsp;likes daily',
    sub3: 'Advanced filters',
    text3: 'Use unlimited filters to find <br />your type of person',
    sub4: 'Unlimited chatting',
    text4: 'No limits on chatting and <br />messaging',
    sub5: 'See who likes you',
    text5: 'Your Activity is blurred, unlock <br />it to meet everyone',
    sub6: 'Boost your profile',
    text6: 'Boost your profile and get X5 <br />visits and X2 likes',
    sub7: 'No Ads',
    text7: 'Tired of ads? Upgrade and <br />don’t get bothered by <br />advertising',
    sub8: 'Unlock location',
    text8: "You can't see the location <br />on profiles right now",
  },
  es: {
    sub1: 'Información avanzada',
    text1: 'Ver todas las fotos y perfiles <br />completos de otros solteros',
    sub2: 'Likes ilimitados',
    text2: 'Ahora solo puedes enviar <br />3 likes al día',
    sub3: 'Filtros avanzados',
    text3: 'Usa filtros ilimitados para <br />encontrar tu tipo de persona',
    sub4: 'Chat ilimitado',
    text4: 'Sin límites en el chat y <br />mensajería',
    sub5: 'Ver quién te gusta',
    text5: 'Tu actividad está borrosa, <br />desbloquéala para conocer a <br />todos',
    sub6: 'Impulsa tu perfil',
    text6: 'Impulsa tu perfil y obtén X5 <br />visitas y X2 likes',
    sub7: 'Sin anuncios',
    text7: '¿Cansado de los anuncios? <br />Actualiza y no te molestes <br />con la publicidad',
    sub8: 'Desbloquear ubicación',
    text8: 'No puedes ver la ubicación <br />en los perfiles en este momento',
  },
};

export { messages };
