<template>
  <icon
    :style="{ width: width && `${width}px`, height: height && `${height}px` }"
    :class="['svg-icon flex items-center justify-center flex-col', `ui-icon__display-${display}`]"
  />
</template>

<script lang="ts" setup>
import { defineAsyncComponent, PropType } from 'vue';

import { IconType, DisplayType } from './types';

const props = defineProps({
  name: {
    type: String as PropType<IconType>,
    required: true,
  },
  width: {
    type: [String, Number],
    required: false,
    default: '',
  },
  height: {
    type: [String, Number],
    required: false,
    default: '',
  },
  display: {
    type: String as PropType<DisplayType>,
    required: false,
    default: 'inline',
  },
});

// const iconPath = ref('');

const icon = defineAsyncComponent(() => import(`./icons/${props.name}.svg`));

async function init() {
  try {
    // let icon = await import(`./icons/${props.name}.svg`);
    //
    // if (icon.default) {
    //   icon = icon.default;
    // }
    //
    // iconPath.value = icon;
  } catch (e) {
    console.log(`No icon with such name '${props.name}'`, e);
  }
}

init();
</script>
