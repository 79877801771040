<template>
  <div
    class="gift_list-img"
    :class="{ 'gray-scale': gift.isFree && isTimerVisible }"
    @click="gift.isFree && isTimerVisible ? openGiftModal() : sendGift()"
  >
    <RetinaImage :image-id="gift.photoId" :width="72" :height="72" format="png" alt="gift" />
    <div class="gift_list-text">
      <template v-if="areFreeGiftsAvailable"> {{ t('free') }} </template>
      <template v-else>
        <div v-if="gift.isFree && !isTimerVisible" class="gift_list-free_timer">
          {{ t('free') }}
        </div>
        <template v-else>
          <div v-if="gift.isFree && isTimerVisible">
            <span class="gift_list-price">{{ timeLeftFormatted }}</span>
          </div>
          <template v-else>
            <SvgIcon name="coin" :width="10" />
            <span class="gift_list-price">{{ gift.priceCredits }}</span>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { computed, onUnmounted, PropType } from 'vue';

import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import RetinaImage from '@/components/retina-image/RetinaImage.vue';
import { hasPremium } from '@/helpers/feature/feature';
import { removeTimerByName, useTimer } from '@/hooks/use-timer/use-timer';
import { useI18n } from '@/services/i18n';
import { getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  gift: {
    required: true,
    type: Object as PropType<ExtractClassFields<DialogGift>>,
  },
});

const emit = defineEmits(['open-gift-modal', 'send-gift']);

const isTimerVisible = computed(() => {
  return (
    !!props.gift?.availableInDuration &&
    !hasPremium() &&
    !getStore().state.common.index.isPremiumGoldActive
  );
});

const areFreeGiftsAvailable = computed(() => {
  return getStore().state.payment.freeGiftsCount > 0;
});

function openGiftModal() {
  emit('open-gift-modal', props.gift);
}

function sendGift() {
  emit('send-gift', props.gift);
}

const { startTimer, timeLeftFormatted } = useTimer(`freeGift_${props.gift.id}`);

startTimer(Number(props.gift.availableInDuration?.seconds) || 0);
onUnmounted(() => {
  removeTimerByName(`freeGift_${props.gift.id}`);
});

const { t } = useI18n({ messages });
</script>
