import { I18n } from '@/services/i18n';

type Message = {
  paySafe: string;
  policy: string;
  continue: string;
  bestPlan: string;
  helpBook: string;
};

const messages: I18n<Message> = {
  en: {
    paySafe: 'Pay safe and secure',
    policy: 'Membership and Refund Policy',
    continue: 'Continue',
    bestPlan: 'Your best plan',
    helpBook:
      'Based on your profile, we added this book to help you succeed in achieving your goal:',
  },
  es: {
    paySafe: 'Pague de forma segura y protegida',
    policy: 'Política de Membresía y Reembolso',
    continue: 'Continuar',
    bestPlan: 'Tu mejor plan',
    helpBook:
      'Basándonos en tu perfil, hemos añadido este libro para ayudarte a alcanzar tu objetivo:',
  },
};

export { messages };
