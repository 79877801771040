import { ref } from 'vue';

export type ButtonPosition = 'top-right' | 'bottom-right';

const isVisible = ref(false);
const buttonPosition = ref<ButtonPosition>('top-right');
export function useSupportBtn() {
  function show(buttonPositionArg: ButtonPosition) {
    isVisible.value = true;
    buttonPosition.value = buttonPositionArg;
  }

  function hide() {
    isVisible.value = false;
  }

  return {
    isVisible,
    buttonPosition,
    show,
    hide,
  };
}
