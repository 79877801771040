<template>
  <PurchaseSliderLayout :initial-active="initialActive" class="slider_mod_1">
    <template #default>
      <div class="slider-item swiper-slide slide_premium">
        <div class="slider-text">
          <div class="slider_billing-title color-premium">
            {{ t('slider.knowYourMatch.header') }}
          </div>
          {{ t('slider.knowYourMatch.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-premium_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_red">
        <div class="slider-text">
          <div class="slider_billing-title color-red">
            {{ t('slider.getUnlimitedLikes.header') }}
          </div>
          {{ t('slider.getUnlimitedLikes.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-infinity_likes"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_primary">
        <div class="slider-text">
          <div class="slider_billing-title color-primary">
            {{ t('slider.meetYourType.header') }}
          </div>
          {{ t('slider.meetYourType.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-search_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_warning">
        <div class="slider-text">
          <div class="slider_billing-title small-txt color-warning">
            {{ t('slider.seeWhoChecksYouOut.header') }}
          </div>
          {{ t('slider.seeWhoChecksYouOut.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-eye_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_premium">
        <div class="slider-text">
          <div class="slider_billing-title color-premium">
            {{ t('slider.chatWithoutLimits.header') }}
          </div>
          {{ t('slider.chatWithoutLimits.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-infinty_messages"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_pink">
        <div class="slider-text">
          <div class="slider_billing-title color-pink">
            {{ t('slider.noMoreSecrets.header') }}
          </div>
          {{ t('slider.noMoreSecrets.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-sexual"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_green">
        <div class="slider-text">
          <div class="slider_billing-title color-green">
            {{ t('slider.neverMissTheHit.header') }}
          </div>
          {{ t('slider.neverMissTheHit.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-history_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide slide_premium">
        <div class="slider-text">
          <div class="slider_billing-title color-premium">
            {{ t('slider.ads.header') }}
          </div>
          {{ t('slider.ads.content') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-ads_solid"></i>
        </div>
      </div>
    </template>
  </PurchaseSliderLayout>
</template>

<script lang="ts" setup>
import PurchaseSliderLayout from '@/pages/payment/components/layout/components/purchase-slider-layout/PurchaseSliderLayout.vue';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });

defineProps({
  initialActive: {
    type: Number,
    default: 0,
  },
});
</script>
