import Notifications from '@kyvg/vue3-notification';
import { createApp } from 'vue';
import { vfmPlugin } from 'vue-final-modal';

import { layoutNotificationHandler } from '@/components/layout/helpers/layout-websocket/layout-websocket';
import { matchNotificationHandler } from '@/components/match-modal/helpers/match-websocket/match-websocket';
import { notificationNotificationHandler } from '@/components/notification/helpers/notification-websocket/notification-websocket';
import { powerLikeNotificationHandler } from '@/components/power-like/helpers/power-like-websocket/power-like-websocket';
import { promoNotificationHandler } from '@/components/subscription-promo-modal/helpers/promo-websocket/promo-websocket';
import { setApp } from '@/helpers/app/app';
import { hasUserId } from '@/helpers/auth/auth';
import { webCrawlerAuthResolver } from '@/helpers/auth/helper/web-crawler-auth-resolver/web-crawler-auth-resolver';
import { initAnalytics } from '@/helpers/gtm-triggers/gtm-triggers';
import { isLocalhost } from '@/helpers/location/location';
import { chatNotificationHandler } from '@/pages/chat/helpers/chat-websocket/chat-websocket';
import { paymentNotificationHandler } from '@/pages/payment/helpers/payment-websocket/payment-websocket';
import { profileNotificationHandler } from '@/pages/profile/helpers/profile-websocket/profile-websocket';
import { retentionNotificationHandler } from '@/pages/retention/retention-notification-handler/retention-notification-handler';
import {
  initRouterBeforeEach,
  initRouterOnError,
} from '@/router/helpers/router-guards/router-guards';
import { router } from '@/router/router';
import { initFirebase, isNotificationPermissionGranted } from '@/services/firebase/firebase';
import { initGrowthBook } from '@/services/growth-book/growth-book';
import { setLocaleFromCache } from '@/services/i18n';
import i18nPlugin from '@/services/i18n/i18n-plugin';
import { initRealtimeTracking } from '@/services/realtime-tracking/realtime-tracking';
import { initSentry, sentryCaptureMessage } from '@/services/sentry/sentry';
import { initServiceWorker } from '@/services/service-worker/service-worker';
import { addNotificationHandler } from '@/services/websocket';
import { setStore } from '@/store/store-helper';

import App from './App.vue';
import { createStore } from './store/store';

import './assets/style/app.scss';

setLocaleFromCache();

addNotificationHandler(chatNotificationHandler);
addNotificationHandler(matchNotificationHandler);
addNotificationHandler(paymentNotificationHandler);
addNotificationHandler(notificationNotificationHandler);
addNotificationHandler(layoutNotificationHandler);
addNotificationHandler(promoNotificationHandler);
addNotificationHandler(retentionNotificationHandler);
addNotificationHandler(powerLikeNotificationHandler);
addNotificationHandler(profileNotificationHandler);

initServiceWorker().then(() => {
  // Init firebase if user has granted notifications permissions
  // in order to resubscribe user's fcm token in case when it expired.
  // Also we have to be sure that this user is logged in ( has user id )
  if (hasUserId() && isNotificationPermissionGranted()) {
    initFirebase();
  }
});

export const app = createApp(App);

app.config.errorHandler = (error, instance, info) => {
  sentryCaptureMessage({
    message: 'Vue app error: ' + (error as Error).message,
    captureContext: { extra: { error } },
  });
  console.warn('Vue app error', error, instance, info);
};

app.use(i18nPlugin);

try {
  webCrawlerAuthResolver();
} catch (error) {
  sentryCaptureMessage({
    message: 'Failed to resolve web crawler auth data from cookie',
    captureContext: { extra: { error } },
  });
}

if (!isLocalhost()) {
  initSentry(app, router);
  initAnalytics();
}

const store = createStore();
app.use(store);
setStore(store);

app.use(router);
initRouterBeforeEach();
initRouterOnError();
initRealtimeTracking();
initGrowthBook();

app.use(
  vfmPlugin({
    key: 'modalService',
    componentName: 'VueFinalModal',
    dynamicContainerName: 'ModalsContainer',
  }),
);
setApp(app.config);
app.use(Notifications, {
  componentName: 'Notifications',
});

app.mount('#app');
