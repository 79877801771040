import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  modalText: string;
  becomeStar: string;
  hour: string;
  hours: string;
  minutes: string;
  forJust: string;
  credits: string;
  notEnough: string;
  success: string;
  explain: string;
  buyMore: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Boost your profile to become more popular!',
    modalText:
      'The peak hours are 6 to 10 pm. Move up to the top for {duration} by using a "{title}" popularity booster to get noticed far more often.',
    becomeStar: 'Become a star',
    hour: 'hour',
    hours: 'hours',
    minutes: 'minutes',
    forJust: 'For just',
    credits: 'credits',
    notEnough: 'Not enough credits',
    success: 'You successfully purchased credits!',
    explain:
      "Now you have {creditsCount} credits. But '{title}' costs {price} credits. It means that you still need to buy {lack} more credits to increase your popularity.",
    buyMore: 'Buy more credits',
  },
  es: {
    header: '¡Impulsa tu perfil para ser más popular!',
    modalText:
      'Las horas pico son de 6 a 10 pm. Sube a la cima por {duration} usando un impulsador de popularidad "{title}" para que te noten con más frecuencia.',
    becomeStar: 'Conviértete en una estrella',
    hour: 'hora',
    hours: 'horas',
    minutes: 'minutos',
    forJust: 'Por solo',
    credits: 'créditos',
    notEnough: 'No tienes suficientes créditos',
    success: '¡Has comprado créditos exitosamente!',
    explain:
      "Ahora tienes {creditsCount} créditos. Pero '{title}' cuesta {price} créditos. Eso significa que aún necesitas comprar {lack} créditos más para aumentar tu popularidad.",
    buyMore: 'Compra más créditos',
  },
};

export { messages };
