<template>
  <slot
    v-bind="{
      solidCardElId: self.solidCardElId,
      solidGooglePayElId: self.solidGooglePayElId,
      solidApplePayElId: self.solidApplePayElId,
    }"
  >
    <div
      v-show="isGooglePayEnabled && self.isGooglePayAvailable"
      :id="self.solidGooglePayElId"
    ></div>
    <div v-show="isApplePayEnabled && self.isApplePayAvailable" :id="self.solidApplePayElId"></div>
    <div v-show="isCardEnabled" :id="self.solidCardElId" class="solid-payment-form-container"></div>
  </slot>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, PropType, reactive } from 'vue';

import { GooglePayBtnType } from '@/components/payment/components/payment-method/components/solid/solid-payment-method/helpers/self/self';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';

import { useSelf } from './hooks/use-self/use-self';

const props = defineProps({
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    default: () => ({}),
  },
  isCardEnabled: {
    type: Boolean,
    default: true,
  },
  isGooglePayEnabled: {
    type: Boolean,
    default: true,
  },
  isApplePayEnabled: {
    type: Boolean,
    default: true,
  },
  isAuthorizeCardToken: {
    type: Boolean,
    default: false,
  },
  googlePayBtnType: {
    type: String as PropType<GooglePayBtnType>,
    default: 'pay',
  },
});

const self = reactive(
  useSelf({
    pkg: props.pkg,
    isCardEnabled: props.isCardEnabled,
    isGooglePayEnabled: props.isGooglePayEnabled,
    isApplePayEnabled: props.isApplePayEnabled,
    isAuthorizeCardToken: props.isAuthorizeCardToken,
    googlePayBtnType: props.googlePayBtnType,
  }),
);

onBeforeUnmount(() => {
  self.destroy();
});

onMounted(() => {
  self.init();
});
</script>

<style lang="scss">
.split-ti-bo {
  .gpay-card-info-container-fill {
    height: 40px !important;
    margin-top: 16px !important;
  }

  .apple-pay-button {
    margin-top: 16px !important;
    height: 40px !important;
  }
}

.gpay-card-info-container-fill {
  height: 40px !important;
  margin-top: 12px;
}

.apple-pay-button {
  margin-top: 16px;
  height: 40px !important;
}

.solid-payment-form-container > iframe {
  width: 100%;
  border: 0;
  left: 0 !important;
  //min-height: 352px;
}
</style>
