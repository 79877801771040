import {
  Subscription,
  SubType,
  Tier,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/subscription_pb';
import dayjs from 'dayjs';

import { useI18n } from '@/services/i18n';
import { SETTINGS_DATE_FORMAT } from '@/settings';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

function isSubscriptionTierSubtype(
  subscription: ExtractClassFields<Subscription> | undefined,
  tier: Tier,
  subType: SubType,
): boolean {
  if (subscription) {
    const hasPackageId = !!subscription.packageId.length;
    return subscription.tier === tier && subscription.subType === subType && hasPackageId;
  }

  return false;
}

export function isSubscriptionTrial(subscription?: ExtractClassFields<Subscription>): boolean {
  return isSubscriptionTierSubtype(subscription, Tier.PREMIUM, SubType.TRIAL);
}

export function isSubscriptionPremium(subscription?: ExtractClassFields<Subscription>): boolean {
  return isSubscriptionTierSubtype(subscription, Tier.PREMIUM, SubType.DEFAULT);
}

export function isSubscriptionPremiumGold(
  subscription?: ExtractClassFields<Subscription>,
): boolean {
  return isSubscriptionTierSubtype(subscription, Tier.GOLD, SubType.DEFAULT);
}

export function isSubscriptionEmpty(subscription?: ExtractClassFields<Subscription>): boolean {
  return isSubscriptionTierSubtype(subscription, Tier.GOLD, SubType.DEFAULT);
}

export function getSubscriptionTierName(subscription: ExtractClassFields<Subscription>): string {
  const { t } = useI18n({ messages });

  if (isSubscriptionTrial(subscription)) {
    return t('trial');
  } else if (isSubscriptionPremium(subscription)) {
    return t('premium');
  } else if (isSubscriptionPremiumGold(subscription)) {
    return t('premiumGold');
  } else return '';
}

export function getSubscriptionDaysLeft(subscription: ExtractClassFields<Subscription>) {
  const secondsLeft = Number(subscription?.expiresDuration?.seconds) || 0;
  let daysLeft = Math.ceil(dayjs.duration({ seconds: secondsLeft }).asDays());

  if (daysLeft < 0) {
    daysLeft = 0;
  }

  return daysLeft;
}

export function getSubscriptionDateExpiresFormatted(
  subscription: ExtractClassFields<Subscription>,
) {
  const secondsExpires = Number(subscription?.expiresTime?.seconds) || 0;
  return dayjs.unix(secondsExpires).format(SETTINGS_DATE_FORMAT);
}
