import * as actions from './payment-actions';
import * as mutations from './payment-mutations';
import { state } from './payment-state';

export const paymentPersisted = [
  'payment.paymentSuccess',
  'payment.paymentFail',
  'payment.lastPurchasedPackage',
];

export const payment = {
  state,
  mutations,
  actions,
};
