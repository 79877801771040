import { I18n } from '@/services/i18n';

type Message = {
  title: string;
  ice1: string;
  ice2: string;
  ice3: string;
};

const messages: I18n<Message> = {
  en: {
    title: 'There is a hit!',
    ice1: 'Can I play with your imagination? 😜😜😜😜',
    ice2: 'What do you think about tattoos? 😳',
    ice3: "You're single. I'm single. Coincidence? I don't think so 😜😜",
  },
  es: {
    title: '¡Hay un match!',
    ice1: '¿Puedo jugar con tu imaginación? 😜😜😜😜',
    ice2: '¿Qué piensas sobre los tatuajes? 😳',
    ice3: 'Tú estás soltero. Yo estoy soltero. ¿Coincidencia? No lo creo 😜😜',
  },
};

export { messages };
