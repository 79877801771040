import { NotificationCategory } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/connection/v1/notification_pb';

import { showNotification } from '@/components/notification/helpers/notification-shower/notification-shower';
import { subscribeRouteUntilAuth } from '@/helpers/auth/auth';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { getCache, setCache } from '@/helpers/persistent-cache';
import { pushRoute } from '@/router';
import { notificationProfileRequest } from '@/services/api/connection/connection-api';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const CACHE_KEY_NOTIFICATION_PROFILE = 'notification_activity_profile';
const { t } = useI18n({ messages });
// 4 minutes in ms
const ms = 240_000;

async function showNotificationUserIntro(category: NotificationCategory): Promise<void> {
  const request = await notificationProfileRequest(category);
  const profile = request.profile!;
  const photoId = profile?.avatar?.id ?? '';
  const notificationCategory = 'default-dark';
  const click = () => {
    pushRoute('profileView', { userId: profile?.id ?? '' });
  };

  showNotification({
    type: 'mutual-activity',
    category: notificationCategory,
    photoId,
    isLocked: true,
    avatarForm: 'square',
    header: t('header', { userName: profile.name }),
    description: t('description'),
    actionText: t('action', { userName: profile.name }),
    iconName: 'like_solid',
    action: {
      click,
    },
  });

  customEventTrigger({
    event: 'intro_notification_show',
  });

  setCache(CACHE_KEY_NOTIFICATION_PROFILE, new Date().getTime().toString());
  setTimeout(() => startNotificationLogic(category), ms);
}

function showNotificationFirstTime(): void {
  const twoMinutesMs = 120_000;

  setTimeout(() => showNotificationUserIntro(NotificationCategory.USER_INTRO), twoMinutesMs);
}

async function startNotificationLogic(category: NotificationCategory): Promise<void> {
  const cache = getCache(CACHE_KEY_NOTIFICATION_PROFILE);

  if (cache) {
    const diff = +new Date() - +cache;
    const oneMinuteMs = 60_000;

    if (diff >= ms) {
      setTimeout(() => showNotificationUserIntro(category), oneMinuteMs);
    }
  } else {
    showNotificationFirstTime();
  }
}

export async function triggerNotificationUserIntro(category: NotificationCategory): Promise<void> {
  subscribeRouteUntilAuth(() => startNotificationLogic(category));
}
