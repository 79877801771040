<template>
  <PurchaseLayout
    class="modal_credits"
    :pkg="selected"
    :is-autorefill-visible="true"
    @next="purchaseCredits"
    @select="select"
    @close="close"
  >
    <template #slider-content>
      <PurchaseSliderCredits></PurchaseSliderCredits>
    </template>

    <template #default>
      <div class="pt-6"></div>

      <template v-if="limitedOfferCreditsPack.pkg">
        <PurchaseCreditsItemDiscount
          :key="'promo' + limitedOfferCreditsPack.pkg?.id"
          v-model="selected"
          :pkg="limitedOfferCreditsPack.pkg"
          :icon="getPackageIcon(limitedOfferCreditsPack.pkg || packages[0])"
          :is-bonus="false"
        />
      </template>
      <div v-for="pkg in packages" :key="pkg.id">
        <PurchaseCreditsItem
          :key="pkg.id"
          v-model="selected"
          :pkg="pkg"
          :icon="getPackageIcon(pkg)"
        />
      </div>
    </template>
  </PurchaseLayout>
</template>

<script lang="ts" setup>
import { CreditsPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, reactive, ref } from 'vue';

import { payWithCard } from '@/components/payment/helpers/payment-method/payment-method';
import { removeChainId } from '@/helpers/track/track-payment/track-payment';
import PurchaseSliderCredits from '@/pages/payment/components/layout/components/purchase-slider-credits/PurchaseSliderCredits.vue';
import PurchaseLayout from '@/pages/payment/components/layout/PurchaseLayout.vue';
import PurchaseCreditsItemDiscount from '@/pages/payment/components/purchase/components/purchase-credits/components/purchase-credits-item-discount/PurchaseCreditsItemDiscount.vue';
import { useLimitedOfferCreditsPack } from '@/pages/payment/components/purchase/components/purchase-credits/hooks/use-limited-offer-credits-pack/use-limited-offer-credits-pack';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { dispatch, getState } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import PurchaseCreditsItem from './components/purchase-credits-item/PurchaseCreditsItem.vue';

const emit = defineEmits(['select', 'close']);

const selected = ref<ExtractClassFields<CreditsPackage>>({
  id: '',
  isPopular: false,
  creditsCount: 0n,
  title: '',
  price: {
    baseAmount: 0n,
    discountAmount: 0n,
    taxAmount: 0n,
    totalAmount: 0n,
    currency: SETTINGS_DEFAULT_CURRENCY,
  },
});

// const promoPackage = ref<ExtractClassFields<CreditsPackage>>();

const defaultPaymentCard = computed<PaymentStateCard>(() => {
  return getState().payment.paymentCards[0];
});

const packages = computed<ExtractClassFields<CreditsPackage>[]>(() => {
  return getState().payment.creditsPackages;
});

function purchaseCredits() {
  payWithCard({
    cardId: defaultPaymentCard.value?.id ?? '',
    pkg: selected.value,
  });
}

function getPackageIcon(pkg: ExtractClassFields<CreditsPackage>): number {
  const pkgs = [...packages.value];
  const ids = pkgs
    .sort((a, b) => {
      return Number(a.creditsCount - b.creditsCount);
    })
    .map((pkg) => pkg.id);

  let index = ids.indexOf(pkg.id);
  if (index === -1) {
    index = 3;
  }

  return index + 1;
}

function select() {
  emit('select', selected.value);
}

function close() {
  emit('close');
}

// We have to reset chainId for payment tracking, because credits have no
// tracking for credits popups configured properly. It should be removed
// when we add credits tracking.
removeChainId();

dispatch('actionPaymentListCreditsPackages').then(() => {
  [selected.value] = packages.value;
});

const limitedOfferCreditsPack = reactive(useLimitedOfferCreditsPack());
</script>
