import { ref } from 'vue';

export function useMatchTooltipHint() {
  const hint = ref<string>('');

  setTimeout(() => {
    hint.value = 'ice-breaker';
  }, 2000);

  return {
    hint,
  };
}
