import { ActionContext } from 'vuex';

import { commit, ActionFunction } from '@/store/store-helper';

import {
  getLastSearchPosition,
  getSearchCriteria,
  getSearchResult,
  SearchResult,
} from './helpers/search-helper';
import { SearchState } from './search-state';

declare module '@/store/store-helper' {
  interface Action {
    actionSearch: ActionFunction<boolean, SearchResult>;
  }
}

export async function actionSearch(
  { state }: ActionContext<SearchState, unknown>,
  isInitial = true,
): Promise<SearchResult> {
  let position = '';
  if (!isInitial) {
    position = getLastSearchPosition(state.profiles);
  }

  const result = await getSearchResult(position, getSearchCriteria(state.filter));

  commit('mutationSearchAddProfiles', result.profiles);
  commit('mutationSearchSetFilter', result.filter);

  return result;
}
