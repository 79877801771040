import { commit, dispatch, getStore } from '@/store/store-helper';

let isLoading = false;
export async function showBoosterPromoModal(): Promise<void> {
  const { boosterPromoModal } = getStore().state.common;
  const dateMs = +new Date();
  const timeElapsedMs = dateMs - boosterPromoModal.shownTimeMs;
  if (!boosterPromoModal.isModalVisible && !isLoading && timeElapsedMs >= 5 * 60 * 1000) {
    isLoading = true;
    const [[booster], profile] = await Promise.all([
      dispatch('actionPaymentListBoosters'),
      dispatch('actionMyProfileGetShort'),
    ]);

    if (booster && profile.boosterEndsDurationMs <= 0) {
      commit('mutationCommonShowBoosterPromoModal', booster);
    } else {
      // if user has booster, mark modal as shown in order to recheck if later.
      // It prevents requests spam in network.
      commit('mutationCommonMarkBoosterPromoModalShown');
    }

    isLoading = false;
  }
}

export function hideBoosterPromoModal(): void {
  if (getStore().state.common.boosterPromoModal.isModalVisible) {
    commit('mutationCommonHideBoosterPromoModal');
  }
}
