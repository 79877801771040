import { createPromiseClient } from '@connectrpc/connect';
import { ActionConnection } from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/actions_pb';
import { CommandsAPIService } from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/commands_api_connect';
import {
  PerformRetentionActionCommandRequest,
  PerformRetentionActionCommandResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/commands_api_pb';
import { PerformRetentionActionCommand } from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/commands_pb';
import { RewardsAPIService } from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/rewards_api_connect';
import {
  ClaimRewardRequest,
  ClaimRewardResponse,
  GetRewardsRequest,
  GetRewardsResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/rewards_api_pb';
import { PromotionType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/rewards_pb';

import { getUserId } from '@/helpers/auth/auth';
import { getGrpcWebTransport } from '@/services/api/grpc-api';

const retentionServiceClient = createPromiseClient(CommandsAPIService, getGrpcWebTransport());
const rewardsServiceClient = createPromiseClient(RewardsAPIService, getGrpcWebTransport());

export function getRewards(promotionType: PromotionType): Promise<GetRewardsResponse> {
  const request = new GetRewardsRequest({
    promotionType,
    userId: getUserId(),
  });

  return rewardsServiceClient.getRewards(request);
}

export function claimReward(type: PromotionType, stageId: number): Promise<ClaimRewardResponse> {
  const request = new ClaimRewardRequest({
    promotionType: type,
    userId: getUserId(),
    stageId,
  });

  return rewardsServiceClient.claimReward(request);
}

export function performRetentionAction({
  userId,
  connectionId,
  actionId,
}: {
  userId: string;
  connectionId: string;
  actionId: string;
}): Promise<PerformRetentionActionCommandResponse> {
  const connection = new ActionConnection({
    connectionId,
  });

  const command = new PerformRetentionActionCommand({
    userId,
    actionId,
  });

  command.action.case = 'connection';
  command.action.value = connection;

  const request = new PerformRetentionActionCommandRequest({
    command,
  });

  return retentionServiceClient.performRetentionActionCommand(request);
}
