<template>
  <div class="custom-control custom-radio btn-input">
    <input
      :id="id"
      v-model="checked"
      :name="name"
      :value="value"
      type="radio"
      class="custom-control-input"
    />
    <label :for="id" class="custom-control-label">
      <slot></slot>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { getUniqNumber } from '@/helpers/number';
import { useModel } from '@/hooks/use-model/use-model';

const props = defineProps({
  name: {
    required: true,
    type: String,
  },

  modelValue: {
    required: false,
    type: [Number, Boolean, String],
    default: '',
  },

  value: {
    required: true,
    type: [Number, Boolean, String],
  },
});

const emit = defineEmits(['update:modelValue']);

const id = `radio${getUniqNumber()}`;

const checked = useModel<number | boolean | string>(props, 'modelValue', emit);
</script>
