<template>
  <div data-id="notification-booster-activated" class="alert-info flex warning">
    <div class="alert-info-icon upload flex items-center justify-center">
      <div class="img-section">
        <i class="icon icon-top_profile"></i>
      </div>
    </div>
    <div class="alert-info-body grow flex items-center">
      <div class="alert-info-section">
        {{ t('header') }}
      </div>
    </div>
    <div class="alert-info-right">
      <i class="icon icon-close"></i>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });
</script>
