import { AppConfig } from 'vue';

let app: AppConfig;

export function setApp(vueApp: AppConfig): void {
  if (!app) {
    app = vueApp;
  }
}

export function getApp(): AppConfig {
  return app;
}
