import { CreditsPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { computed, ref } from 'vue';

import { loadCards } from '@/components/payment/helpers/payment-method/payment-method';
import { setEarlyUpgradeModalTimeLastShown } from '@/components/prolongation-modal/components/prolongation-early-upgrade-modal/helpers/early-upgrade-modal/early-upgrade-modal';
import { useProlongationModal } from '@/components/prolongation-modal/hooks/use-prolongation-modal/use-prolongation-modal';
import { getFormattedCurrency } from '@/helpers/number';
import { getNextPackage, getPackagePrice } from '@/pages/payment/helpers/package/package';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { getTextPageModal } from '@/pages/text-page/helpers/text-page/text-page';
import { pushRealtimeTrackingCounter } from '@/services/realtime-tracking/realtime-tracking';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

type UseSelfArg = {
  close: () => void;
  subscription: PaymentStateSubscription;
};
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const packageEarlyUpgrade = ref<ExtractClassFields<CreditsPackage>>();
  const textPageModal = getTextPageModal();
  const hasCardList = ref(false);

  // Hook ----------------------------------------------------------------------
  const prolongationModal = useProlongationModal({
    close,
    subscription: arg.subscription,
  });

  // Computed ------------------------------------------------------------------
  const packageNextPriceFormatted = computed<string>(() => {
    const packageCurrent = arg.subscription.currentPackage;
    if (packageCurrent) {
      const packageNext = getNextPackage(packageCurrent);

      const price = getPackagePrice(packageNext);
      return getFormattedCurrency(price.totalAmount, price.currency);
    } else {
      return '';
    }
  });

  const creditsBonusCount = computed<number>(() => {
    return Number(packageEarlyUpgrade?.value?.creditsCount) || 0;
  });

  const daysLeftNoMore7 = computed<number>(() => {
    const { daysLeft } = prolongationModal;
    return daysLeft.value > 7 ? 7 : daysLeft.value;
  });

  // Method --------------------------------------------------------------------
  function close() {
    arg.close();
    setEarlyUpgradeModalTimeLastShown();
  }

  function purchasePackageNext(): void {
    prolongationModal.purchasePackageNext();
    setEarlyUpgradeModalTimeLastShown();
  }

  async function fetchPackageEarlyUpgrade() {
    try {
      const packageCreditsList = await dispatch('actionPaymentListLimitedOfferCreditsPackages');
      return packageCreditsList.find((pkg) => {
        return pkg?.offer?.type === PromoType.EARLY_PREM_BONUS;
      });
    } catch (error) {
      sentryCaptureMessage({
        message: 'Failed to load limited offer credits package for early premium promo',
        captureContext: { extra: { error } },
      });
    }
  }

  function showMembershipModal() {
    textPageModal.showModal('membership');
  }

  function init() {
    fetchPackageEarlyUpgrade().then((packageEarlyUpgradeArg) => {
      packageEarlyUpgrade.value = packageEarlyUpgradeArg;
    });

    prolongationModal.init();

    loadCards().then((cards) => {
      hasCardList.value = cards.length > 0;
    });

    pushRealtimeTrackingCounter('show-prolongation-modal', {
      type: 'prolongation-early-upgrade-modal',
    });
  }

  return {
    packageEarlyUpgrade,
    hasCardList,
    prolongationModal,
    packageNextPriceFormatted,
    creditsBonusCount,
    daysLeftNoMore7,
    close,
    purchasePackageNext,
    showMembershipModal,
    init,
  };
}
