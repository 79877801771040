<template>
  <div
    class="h-full bg-premium flex flex-col relative overflow-auto"
    data-test-id="purchase-premium-ntf-modal"
  >
    <PremiumPaywall
      v-if="self.page === 'paywall'"
      v-model:package-selected="self.packageSelected"
      :feature="feature"
      :package-list="self.packageList"
      @close="emit('close')"
      @select="self.select"
      @next="self.nextPage"
    />
    <PremiumCheckout
      v-else-if="self.page === 'checkout'"
      v-model:package-selected="self.packageSelected"
      :source="source"
      @back="self.back"
      @select="self.select"
      @next="self.nextPage"
    />
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import PremiumCheckout from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-checkout/PremiumCheckout.vue';
import PremiumPaywall from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/PremiumPaywall.vue';
import { useSelf } from '@/components/ntf-modal/components/ntf-premium-v2/hooks/use-self/use-self';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  source: {
    type: String as PropType<PromoShowPurchaseSource>,
    required: true,
  },
});

const emit = defineEmits(['next', 'close', 'select']);

const self = useSelf({
  source: props.source,
  handleNext(packageSelected) {
    emit('next', packageSelected);
  },
  handleClose() {
    emit('close');
  },
  handleSelect(packageSelected) {
    emit('select', packageSelected);
  },
});

self.init();
</script>
