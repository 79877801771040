import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  poor: string;
  great: string;
  wow: string;
  notNow: string;
  submit: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Did you enjoy MeetMyAge?',
    poor: 'Poor',
    great: 'Great',
    wow: 'Wow',
    notNow: 'Not Now',
    submit: 'Submit',
  },
  es: {
    header: '¿Disfrutaste MeetMyAge?',
    poor: 'Pobre',
    great: 'Genial',
    wow: 'Wow',
    notNow: 'Ahora no',
    submit: 'Enviar',
  },
};

export { messages };
