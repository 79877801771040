<template>
  <ModalMenu
    :background-scroll="false"
    :is-teleport-enabled="false"
    class-name="web_push_modal"
    data-test-id="web_push_modal"
  >
    <template #title>
      <a
        href="#"
        data-test-id="btn_close"
        class="web_push_modal-close"
        @click.prevent="self.close()"
        ><i class="icon icon-close"></i
      ></a>

      <div class="web_push_modal-avatar relative">
        <div class="overflow-hidden rounded-full">
          <RetinaPhoto :photo-id="userAvatarId" :width="80" :height="80" />
        </div>
        <div class="web_push_modal-icon">
          <LikeFontIcon class-name="icon-like_solid" />
        </div>
      </div>
      {{ t('header') }}
    </template>

    <template #text>
      <div class="web_push_modal-content">
        {{ t('modalText', { userName }) }}
      </div>

      <button type="button" class="btn btn-primary btn-block" @click="self.subscribe()">
        {{ t('ok') }}
      </button>

      <button
        type="button"
        class="btn btn-light btn-small w-full mt-2 web_push_modal-btn_cancel"
        @click.prevent="self.close()"
      >
        {{ t('cancel') }}
      </button>
    </template>
  </ModalMenu>
</template>

<script lang="ts" setup>
import LikeFontIcon from '@/components/inputs/icon/font-icon/LikeFontIcon.vue';
import ModalMenu from '@/components/modal/modal-menu/ModalMenu.vue';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { useSelf } from '@/components/web-push-modal/hooks/use-self/use-self';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });

defineProps({
  userName: {
    type: String,
    required: true,
  },
  userAvatarId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const self = useSelf({
  close: () => {
    emit('close');
  },
});

self.init();
</script>

<style lang="scss" scoped src="./style.scss"></style>
