import { ProfilePhoto } from '@project-gd-x/dating-site-contracts/src/gen/gdx/common/v1/profile_pb';

import { ExtractClassFields } from '@/type';

export type ActivityItemData =
  | ActivityItemDataVisit
  | ActivityItemDataLike
  | ActivityItemDataUnmatch
  | ActivityItemDataRequestPhoto
  | ActivityItemDataRequestPhotoUploaded
  | ActivityItemDataSexualCompatibility
  | ActivityItemDataPowerLike
  | ActivityItemDataAstrologyCompatibility;

export type ActivityItemDataVisit = {
  type: 'visit';
  isLiked: boolean;
  dialogId: string;
};

export type ActivityItemDataLike = {
  type: 'like';
  isLiked: boolean;
  dialogId: string;
};

export type ActivityItemDataUnmatch = {
  type: 'unmatch';
};

export type ActivityItemDataRequestPhoto = {
  type: 'request-photo';
};

export type ActivityItemDataRequestPhotoUploaded = {
  type: 'request-photo-uploaded';
};

export type ActivityItemDataSexualCompatibility = {
  type: 'request-sexual-compatibility';
};

export type ActivityItemDataAstrologyCompatibility = {
  type: 'request-astrology-compatibility';
};

export type ActivityItemDataPowerLike = {
  type: 'power-like';
};

export type ActivityItem<
  T extends ActivityItemData =
    | ActivityItemDataVisit
    | ActivityItemDataLike
    | ActivityItemDataUnmatch
    | ActivityItemDataRequestPhoto
    | ActivityItemDataRequestPhotoUploaded
    | ActivityItemDataSexualCompatibility
    | ActivityItemDataAstrologyCompatibility
    | ActivityItemDataPowerLike,
> = {
  listPosition: string;
  id: string;
  createdTimeMs: number;
  userId: string;
  avatar: ExtractClassFields<ProfilePhoto>;
  name: string;
  age: number;
  isOnline: boolean;
  isLocked: boolean;
  locationName: string;
  data: T;
};

export type ActivityState = {
  incoming: ActivityItem[];
  outgoing: ActivityItem[];
  hasUnreadActivities: boolean;
};

export const state: ActivityState = {
  incoming: [],
  outgoing: [],
  hasUnreadActivities: false,
};
