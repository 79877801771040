import { isUserLoggedIn } from '@/helpers/auth/auth';
import { hasPremium } from '@/helpers/feature/feature';
import SubscriptionRenew from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-renew/SubscriptionRenew.vue';
import { isModalVisible, useModal } from '@/services/modal/modal';
import { dispatch, getStore } from '@/store/store-helper';

export async function resolveSubscriptionRenewBlockVisibility(): Promise<void> {
  const { showModal } = useModal(() => ({
    component: SubscriptionRenew,
  }));

  if (!isUserLoggedIn()) return;

  const { subscriptionStatus } = getStore().state.payment;

  await dispatch('actionPaymentGetSubscriptionStatus');

  if (
    subscriptionStatus?.status === 'suspended' &&
    !hasPremium() &&
    !getStore().state.payment.isRenewed
  ) {
    if (!isModalVisible(SubscriptionRenew)) {
      showModal();
    }
  }
}
