import { initializeMirror } from '@/helpers/mirror/mirror';
import { MirrorAbilities, MirrorConfig } from '@/helpers/mirror/type';

const config: MirrorConfig = {
  id: 'meetmyage',
  projectName: 'MeetMyAge',
  themeClass: 'theme-meetmyage',
  logoTxtSvgName: 'logo-txt',
  logoTxtWhiteSvgName: 'logo-white',
  logoShortSvgName: 'logo-act',
};

const abilities: MirrorAbilities = {
  searchPage: 'search',
};

initializeMirror(config, abilities);
