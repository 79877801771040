function buttonColor(): string {
  return '#00909e !important';
}

export function formStyles() {
  return {
    card_number: {
      label: {
        color: '#00202c !important',
        'font-size': '12px !important',
        'line-height': '18px !important',
        'letter-spacing': '0.01em !important',
        transform: 'initial !important',
        'font-weight': '400 !important',
      },
      input: {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        background: 'transparent',
        'margin-top': '8px',
        'border-color': '#dfe4e9 !important',
        'border-radius': '4px !important',
        padding: '11px !important',
        'font-size': '15px !important',
        'line-height': '24px !important',
        height: 'auto !important',
        'box-shadow': 'none !important',
      },
      '.input-block': {
        input: {
          'padding-left': '49px !important',
        },
        '.brand-icon': {
          top: '48px !important',
          left: '11px !important',
        },
      },
      '.error': {
        input: {
          'border-color': '#ff5b5a !important',
        },
      },
      '.error-text': {
        position: 'absolute',
        bottom: '-18px',
        'font-size': '11px',
        color: '#ff5b5a',
      },
    },
    expiry_date: {
      margin: '6px 0 !important',
      label: {
        color: '#00202c !important',
        'font-size': '12px !important',
        'line-height': '18px !important',
        'letter-spacing': '0.01em !important',
        transform: 'initial !important',
        'font-weight': '400 !important',
      },
      input: {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        'margin-top': '8px',
        'border-color': '#dfe4e9 !important',
        'border-radius': '4px !important',
        padding: '11px !important',
        'font-size': '15px !important',
        'line-height': '24px !important',
        height: 'auto !important',
        'box-shadow': 'none !important',
      },
      '.error-text': {
        display: 'none',
      },
      '.error': {
        input: {
          'border-color': '#ff5b5a !important',
        },
      },
    },
    card_cvv: {
      margin: '6px 0 !important',
      label: {
        color: '#00202c !important',
        'font-size': '12px !important',
        'line-height': '18px !important',
        'letter-spacing': '0.01em !important',
        transform: 'initial !important',
        'font-weight': '400 !important',
      },
      input: {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        'margin-top': '8px',
        'border-color': '#dfe4e9 !important',
        'border-radius': '4px !important',
        padding: '11px !important',
        'font-size': '15px !important',
        'line-height': '24px !important',
        height: 'auto !important',
        'box-shadow': 'none !important',
      },
      '.tooltip-icon': {
        top: '48px !important',
        right: '13px',
        '::before': {
          background: 'none !important',
          border: '1px solid #637282',
          width: '14px',
          height: '14px',
          color: '#637282 !important',
          'font-weight': '500',
          'font-size': '10px',
          'line-height': '14px',
        },
      },
      '.error-text': {
        display: 'none',
      },
      '.error': {
        input: {
          'border-color': '#ff5b5a !important',
        },
      },
    },
    card_holder: {
      label: {
        color: '#00202c !important',
        'font-size': '12px !important',
        'line-height': '18px !important',
        'letter-spacing': '0.01em !important',
        transform: 'initial !important',
        'font-weight': '400 !important',
      },
      input: {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        'margin-top': '8px',
        'border-color': '#dfe4e9 !important',
        'border-radius': '4px !important',
        padding: '11px !important',
        'font-size': '15px !important',
        'line-height': '24px !important',
        height: 'auto !important',
        'box-shadow': 'none !important',
      },
      '.error': {
        input: {
          'border-color': '#ff5b5a !important',
        },
      },
      '.error-text': {
        position: 'absolute',
        bottom: '-18px',
        'font-size': '11px',
        color: '#ff5b5a',
      },
    },
    zip_code: {
      'margin-top': '16px !important',

      label: {
        color: '#00202c !important',
        'font-size': '12px !important',
        'line-height': '18px !important',
        'letter-spacing': '0.01em !important',
        display: 'block',
        transform: 'initial !important',
        'font-weight': '400 !important',
      },
      input: {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        'margin-top': '8px',
        'border-color': '#dfe4e9 !important',
        'border-radius': '4px !important',
        padding: '11px !important',
        'font-size': '15px !important',
        'line-height': '24px !important',
        height: 'auto !important',
        'box-shadow': 'none !important',
      },
      '.tooltip-icon': {
        top: '37px !important',
        right: '13px',
        '::before': {
          background: 'none !important',
          border: '1px solid #637282',
          width: '14px',
          height: '14px',
          color: '#637282 !important',
          'font-weight': '500',
          'font-size': '10px',
          'line-height': '14px',
        },
      },
      '.error': {
        input: {
          'border-color': '#ff5b5a !important',
        },
      },
      '.error-text': {
        position: 'absolute',
        bottom: '-18px',
        'font-size': '11px',
        color: '#ff5b5a',
      },
    },
    submit_button: {
      'text-transform': 'uppercase',
      'background-color': buttonColor(),
      'margin-top': '22px !important',
      color: '#fff',
      'font-size': '15px',
      'font-weight': '600 !important',
      'line-height': '20px',
      'letter-spacing': '0.015em',
      height: 'auto !important',
      padding: '14px !important',
      'border-radius': '4px',
      '.title-icon': {
        display: 'inline-block',
      },
      '.title': {
        ':before': {
          display: 'none !important',
        },
      },
      ':disabled': {
        'background-color': '#dfe4e9 !important',
        color: '#95a2af',
        height: '48px !important',
      },
    },
    'secure-info': {
      width: '5em',
      'flex-wrap': 'wrap',
      'justify-content': 'space-between',

      i: {
        margin: '0 5px',
        width: '5.6em',
      },
    },
    form_body: {
      'secure-info': {
        width: '5em',
        'flex-wrap': 'wrap',
        'justify-content': 'space-between',

        i: {
          margin: '0 5px',
          width: '5.6em',
        },
      },
    },
  };
}
