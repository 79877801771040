import { isUserLoggedIn } from '@/helpers/auth/auth';
import { getCache, setCache } from '@/helpers/persistent-cache';
import { dispatch } from '@/store/store-helper';

const COOLDOWN_MS = 6 * 6300 * 1000;
const CACHE_KEY = 'cameback_last_ms';

export function trackCameBack(url?: string) {
  const lastMs = +(getCache(CACHE_KEY) ?? 0);

  if (Date.now() - lastMs >= COOLDOWN_MS && isUserLoggedIn()) {
    dispatch('actionCommonStoreClientEventCameBack', url ?? window.location.href);
    setCache(CACHE_KEY, Date.now().toString());
  }
}
