import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  paymentDetails: string;
  terms: string;
  choose: string;
  creditCard: string;
  or: string;
  expressCheckout: string;
  expires: string;
  min: string;
  sec: string;
  total: string;
  introductoryDiscount: string;
  justSaved: string;
  extra: string;
  plan: string;
  secureCheckout: string;
  hint: string;
  safeCheckout: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Payment methods',
    paymentDetails: 'Payment details',
    terms: 'Please, accept the terms first.',
    choose: 'Choose your payment method:',
    creditCard: 'Credit card',
    or: 'or',
    expressCheckout: 'Express checkout',
    expires: 'Discount expires in',
    min: 'min',
    sec: 'sec',
    total: 'Total',
    introductoryDiscount: 'Introductory discount',
    justSaved: 'You just saved',
    extra: 'Extra rewards:',
    plan: 'plan',
    secureCheckout: 'Secure checkout',
    hint: 'You will be charged',
    safeCheckout: 'Safe checkout',
  },
  es: {
    header: 'Métodos de pago',
    paymentDetails: 'Detalles de pago',
    terms: 'Por favor, acepta los términos primero.',
    choose: 'Elige tu método de pago:',
    creditCard: 'Tarjeta de crédito',
    or: 'o',
    expressCheckout: 'Pago rápido',
    expires: 'El descuento expira en',
    min: 'min',
    sec: 'seg',
    total: 'Total',
    introductoryDiscount: 'Descuento introductorio',
    justSaved: 'Acabas de guardar',
    extra: 'Recompensas adicionales:',
    plan: 'plan',
    secureCheckout: 'Pago seguro',
    hint: 'Se te realizará un cargo',
    safeCheckout: 'Pago seguro',
  },
};

export { messages };
