<template>
  <NotificationActivity :category="'request'" @action="goPage" @close="close">
    <template #icon>
      <NotificationActivityIcon :name="'photo_solid'" />
    </template>
    <template #avatar>
      <NotificationActivityAvatar :is-locked="true" :form="'square'" :photo-id="info.avatar.id" />
    </template>
    <template #header>{{ t('yourPhotoWasRequested') }}</template>
    <template #description> {{ info.name }} {{ t('wantsToSeeWhatYouLook') }} </template>
    <template #action>{{ t('uploadPhoto') }}</template>
  </NotificationActivity>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import NotificationActivityAvatar from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-avatar/NotificationActivityAvatar.vue';
import NotificationActivityIcon from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-icon/NotificationActivityIcon.vue';
import NotificationActivity from '@/components/notification/components/notification-redesign/notification-activity/NotificationActivity.vue';
import { NotificationDataRequestPhoto } from '@/components/notification/helpers/notification-shower/notification-shower';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  info: {
    type: Object as PropType<NotificationDataRequestPhoto>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

function goPage(): void {
  pushRoute('profileView', { userId: props.info.userId });
}

function close(): void {
  emit('close');
}
</script>
