import { I18n } from '@/services/i18n';

type Message = {
  premiumExpired: string;
  hello: string;
  continue: string;
  renew: string;
};

const messages: I18n<Message> = {
  en: {
    premiumExpired: 'Premium subscription has expired.',
    hello: 'Hello, {name}!',
    continue:
      'Renew your subscription to use MeetMyAge to its fullest and regain all the Premium features.',
    renew: 'Renew',
  },
  es: {
    premiumExpired: 'La suscripción Premium ha expirado.',
    hello: '¡Hola, {name}!',
    continue:
      'Renueva tu suscripción para usar MeetMyAge al máximo y recuperar todas las funciones Premium.',
    renew: 'Renovar',
  },
};

export { messages };
