import { Dialog } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';

import {
  ChatState,
  ChatStateDialogMessage,
  ChatStateDialogMessageContent,
  ChatStateDialogPreview,
  ChatStateDialogUser,
} from '@/pages/chat/store/chat-state';
import { ExtractClassFields } from '@/type';

type MutationChatAddDialogUserPayload = {
  chatId: string;
  dialogUser: ChatStateDialogUser;
};

type MutationChatAddMessagesPayload = {
  chatId: string;
  messages: ChatStateDialogMessage[];
};

type MutationChatAddMessagePayload = {
  chatId: string;
  message: ChatStateDialogMessage;
};

type MutationChatDraftsMessagePayload = {
  dialogId: string;
  message: string;
};

type MutationChatReadOnly = {
  dialogId: string;
  value: boolean;
};

type MutationChatDeleteMessagesPayload = {
  chatId: string;
  messageIds: string[];
};

type MutationChatSuspiciousContentReportedPayload = {
  dialogId: string;
  messageId: string;
};

declare module '@/store/store-helper' {
  interface Mutation {
    mutationChatAddMatches: { matches: ExtractClassFields<Dialog>[]; position: string };
    mutationChatDecrementFreeMessagesCount: string;
    mutationChatSetLastDialogPosition: string;
    mutationChatAddDialogs: ChatStateDialogPreview[];
    mutationChatAddLastMessage: { chatId: string; message: ChatStateDialogMessageContent };
    mutationChatAddDialogUser: MutationChatAddDialogUserPayload;
    mutationChatAddMessages: MutationChatAddMessagesPayload;
    mutationChatAddMessage: MutationChatAddMessagePayload;
    mutationChatClearMessages: string;
    mutationChatAddGifts: ExtractClassFields<DialogGift>[];
    mutationChatDeleteDialogs: string[];
    mutationChatClearDialogs: never;
    mutationChatRemoveMatches: never;
    mutationChatDeleteMessages: MutationChatDeleteMessagesPayload;
    mutationChatSuspiciousContentReported: MutationChatSuspiciousContentReportedPayload;
    mutationChatMakeDialogRead: string;
    mutationChatAddDraftsMessage: MutationChatDraftsMessagePayload;
    mutationChatAddActiveDraftsMessage: MutationChatDraftsMessagePayload;
    mutationChatRemoveMatchIsNew: string;
    mutationChatSetDialogReadOnly: MutationChatReadOnly;
  }
}

export function mutationChatSetDialogReadOnly(state: ChatState, payload: MutationChatReadOnly) {
  const dialog = state.dialogs.find((dialog) => dialog.id === payload.dialogId);

  if (dialog) {
    dialog.readOnly = payload.value;
  }
}

export function mutationChatRemoveMatchIsNew(state: ChatState, dialogId: string) {
  const match = state.matches.find((match) => match.connectionId === dialogId);

  if (match?.meta) {
    match.meta.isNew = false;
  }
}

export function mutationChatRemoveMatches(state: ChatState) {
  state.matches = [];
  state.lastMatchPosition = '';
}

export function mutationChatAddMatches(
  state: ChatState,
  payload: { matches: ExtractClassFields<Dialog>[]; position: string },
) {
  const filtered = payload.matches.filter(
    (match) => !state.matches.some((item) => item.connectionId === match.connectionId),
  );

  state.matches = [...state.matches, ...filtered];
  state.lastMatchPosition = payload.position;
}

export function mutationChatAddLastMessage(
  state: ChatState,
  payload: { chatId: string; message: ChatStateDialogMessageContent },
) {
  const dialog = state.dialogs.find(
    (dialog: ChatStateDialogPreview) => dialog.id === payload.chatId,
  );

  if (dialog) {
    dialog.latestMessage = payload.message;
    dialog.unreadMessagesCount += 1;
  }
}

export function mutationChatDecrementFreeMessagesCount(state: ChatState, chatId: string) {
  const dialog = state.dialogs.find((dialog: ChatStateDialogPreview) => dialog.id === chatId);

  if (dialog?.powerLike?.messagesCount) {
    const count = dialog.powerLike.messagesCount;

    dialog.powerLike.messagesCount = count > 0 ? count - 1 : count;
  }
}

export function mutationChatSetLastDialogPosition(state: ChatState, position: string) {
  state.lastDialogPosition = position;
}

export function mutationChatAddDialogs(state: ChatState, dialogs: ChatStateDialogPreview[]) {
  state.dialogs = dialogs.reduce((acc, item) => {
    const index = acc.findIndex((dialog) => dialog.id === item.id);

    if (index >= 0) {
      acc.splice(index, 1, item);
    } else {
      acc.push(item);
    }

    return acc;
  }, state.dialogs);
}

export function mutationChatAddDialogUser(
  state: ChatState,
  { chatId, dialogUser }: MutationChatAddDialogUserPayload,
) {
  // Vue.set(state.dialogUsers, chatId, dialogUser);
  state.dialogUsers[chatId] = dialogUser;
}

export function mutationChatAddMessages(
  state: ChatState,
  { chatId, messages }: MutationChatAddMessagesPayload,
) {
  if (!state.messages[chatId]) {
    // Vue.set(state.messages, chatId, []);
    state.messages[chatId] = [];
  }

  const stateMessages = state.messages[chatId];
  messages.forEach((message) => {
    const index = stateMessages.findIndex((stateMessage) => stateMessage.id === message.id);
    // Try to replace messages with the same id to messages from a server, or push them otherwise.
    if (index !== -1) {
      stateMessages[index] = message;
    } else {
      stateMessages.unshift(message);
    }
  });
}

export function mutationChatAddMessage(
  state: ChatState,
  { chatId, message }: MutationChatAddMessagePayload,
) {
  if (!state.messages[chatId]) {
    // Vue.set(state.messages, chatId, []);
    state.messages[chatId] = [];
  }
  const messages = state.messages[chatId];
  const index = messages.findIndex((m) => m.id === message.id);

  // if we already have message with provided id
  if (index !== -1) {
    messages[index] = message;
  } else {
    messages.push(message);
  }
}

export function mutationChatClearMessages(state: ChatState, chatId: string) {
  // Vue.set(state.messages, chatId, []);
  state.messages[chatId] = [];
}

export function mutationChatAddGifts(state: ChatState, gifts: ExtractClassFields<DialogGift>[]) {
  state.gifts = gifts;
}

export function mutationChatDeleteDialogs(state: ChatState, chatIds: string[]) {
  state.dialogs = state.dialogs.filter(
    (dialog) => chatIds.findIndex((id) => id === dialog.id) === -1,
  );
}

export function mutationChatDeleteMessages(
  state: ChatState,
  { chatId, messageIds }: MutationChatDeleteMessagesPayload,
): void {
  if (state.messages[chatId]) {
    state.messages[chatId] = state.messages[chatId].filter(
      (message) => messageIds.findIndex((id) => id === message.id) === -1,
    );
  }
}

export function mutationChatSuspiciousContentReported(
  state: ChatState,
  { dialogId, messageId }: MutationChatSuspiciousContentReportedPayload,
): void {
  const messages = state.messages[dialogId];

  if (messages) {
    const message = messages.find((msg) => msg.id === messageId);

    if (message) {
      message.isSuspiciousContent = true;
    }
  }
}

export function mutationChatMakeDialogRead(state: ChatState, dialogId: string) {
  const dialog = state.dialogs.find((dialog) => dialog.id === dialogId);
  if (dialog) {
    dialog.unreadMessagesCount = 0;
  }
}

export function mutationChatClearDialogs(state: ChatState) {
  state.dialogs = [];
  state.lastDialogPosition = '';
}

export function mutationChatAddDraftsMessage(
  state: ChatState,
  { dialogId, message }: MutationChatDraftsMessagePayload,
) {
  // Vue.set(state.drafts, dialogId, message);
  state.drafts[dialogId] = message;
  state.activeDrafts[dialogId] = '';
}

export function mutationChatAddActiveDraftsMessage(
  state: ChatState,
  { dialogId, message }: MutationChatDraftsMessagePayload,
) {
  // Vue.set(state.activeDrafts, dialogId, message);
  state.activeDrafts[dialogId] = message;
}
