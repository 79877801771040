import TrustpilotModal from '@/components/trustpilot-modal/TrustpilotModal.vue';
import { useModal } from '@/services/modal/modal';

let modal: {
  showModal: any;
  hideModal: any;
} | null = null;

export function show() {
  modal = useModal(() => ({
    component: TrustpilotModal,
  }));

  modal.showModal();
}

export function hide() {
  if (modal) {
    modal.hideModal();
    modal = null;
  }
}

export const trustpilotModal = {
  show,
  hide,
};
