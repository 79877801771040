import {
  GetBillingHistoryResponse,
  ListBoostersResponse,
  ListPaymentCardsResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_api_pb';

import {
  PaymentStateBillingHistoryItem,
  PaymentStateBillingHistoryItemType,
  PaymentStateBooster,
  PaymentStateCard,
  PaymentStateCardBrand,
  PaymentStateCardSource,
} from '@/pages/payment/store/payment-state';

export const BILLING_HISTORY_LIMIT = 10;
export const OFFBOARDING_PROFILES_LIMIT = 4;

export function getBillingHistoryFrom(
  response: GetBillingHistoryResponse,
): PaymentStateBillingHistoryItem[] {
  const history: PaymentStateBillingHistoryItem[] = [];
  response.historyItems.forEach((item) => {
    history.push({
      type: item.type as PaymentStateBillingHistoryItemType,
      title: item.title,
      description: item.description,
      billingPosition: item.billingPosition,
      createdTimeMs: Number(item.createdTime?.seconds ?? 0) * 1000,
    });
  });

  return history;
}

export function getLastBillingHistoryPosition(history: PaymentStateBillingHistoryItem[]): string {
  if (history.length) {
    return history[history.length - 1].billingPosition;
  }
  return '';
}

export function listPaymentCardsFrom(response: ListPaymentCardsResponse): PaymentStateCard[] {
  const cards: PaymentStateCard[] = [];
  response.cards.forEach((card) => {
    cards.push({
      id: card.id,
      number: card.number,
      brand: card.brand as PaymentStateCardBrand,
      source: card.source as PaymentStateCardSource,
      gateway: card.gateway,
    });
  });

  return cards;
}

export function listBoostersFrom(response: ListBoostersResponse): PaymentStateBooster[] {
  const boosters: PaymentStateBooster[] = [];
  response.boosters.forEach((booster) => {
    boosters.push({
      id: booster.id,
      description: booster.description,
      priceCredits: booster.priceCredits,
      title: booster.title,
      totalDurationMs: Number(booster.totalDuration?.seconds ?? 0) * 1000,
      type: booster.type,
    });
  });

  return boosters;
}
