import {
  PremiumPackage,
  Price,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';

import { getFormattedCurrency } from '@/helpers/number';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { ExtractClassFields } from '@/type';

export function getPackagePrice(pkg: PaymentStatePackage): ExtractClassFields<Price> {
  return pkg?.offer?.price || pkg?.price || new Price();
}

export function getPackagePriceFormatted(pkg: PaymentStatePackage): string {
  const price = getPackagePrice(pkg);

  return getFormattedCurrency(price.totalAmount, price.currency);
}

export function getNextPackagePrice(
  pkg: ExtractClassFields<PremiumPackage>,
): ExtractClassFields<Price> {
  return getPackagePrice(getNextPackage(pkg));
}

export function getNextPackage(
  pkg: ExtractClassFields<PremiumPackage>,
): ExtractClassFields<PremiumPackage> {
  return pkg.nextPackage || pkg;
}

export function getPackagePricePerWeek(
  pkg: ExtractClassFields<PremiumPackage>,
): ExtractClassFields<Price> {
  return pkg?.offer?.pricePerWeek || pkg.pricePerWeek || new Price();
}

export function getPackagePopular(packageList: ExtractClassFields<PremiumPackage>[]) {
  return (
    packageList.find((pkg) => {
      return pkg.isPopular;
    }) || packageList[0]
  );
}
