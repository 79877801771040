import { Duration } from '@bufbuild/protobuf';
import { createPromiseClient } from '@connectrpc/connect';
import { PackageIdentity } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PaymentAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_api_connect';
import {
  ActivateBoosterRequest,
  ActivateBoosterResponse,
  ActivateSubscriptionRequest,
  ActivateSubscriptionResponse,
  ApplyFreePackageRequest,
  ApplyFreePackageResponse,
  ChangeSubscriptionPackageRequest,
  ChangeSubscriptionPackageResponse,
  CompleteOffersRequest,
  CompleteOffersResponse,
  GetBillingHistoryRequest,
  GetBillingHistoryResponse,
  GetCreditsBalanceRequest,
  GetCreditsBalanceResponse,
  GetFreeCreditsFeaturesStatusRequest,
  GetFreeCreditsFeaturesStatusResponse,
  GetPackageRequest,
  GetPackageResponse,
  GetPaymentOrderStatusRequest,
  GetPaymentOrderStatusResponse,
  GetRecommendedSubscriptionPackageRequest,
  GetRecommendedSubscriptionPackageResponse,
  GetSolidAuthorizeFormRequest,
  GetSolidAuthorizeFormResponse,
  GetSolidPaymentFormRequest,
  GetSolidPaymentFormResponse,
  GetSubscriptionStatusRequest,
  GetSubscriptionStatusResponse,
  InitiatePayPalPaymentRequest,
  InitiatePayPalPaymentResponse,
  ListBoostersRequest,
  ListBoostersResponse,
  ListCreditsPackagesRequest,
  ListCreditsPackagesResponse,
  ListLimitedOfferPackagesRequest,
  ListLimitedOfferPackagesResponse,
  ListOffboardingPackagesRequest,
  ListOffboardingPackagesResponse,
  ListPackagesRequest,
  ListPackagesResponse,
  ListPaymentCardsRequest,
  ListPaymentCardsResponse,
  ListPaymentMethodsResponse,
  ListPopUpPromoPackagesRequest,
  ListPopUpPromoPackagesResponse,
  ListPremiumGoldPackagesRequest,
  ListPremiumGoldPackagesResponse,
  ListPremiumPackagesRequest,
  ListPremiumPackagesResponse,
  ListPromoCreditsPackagesRequest,
  ListPromoCreditsPackagesResponse,
  ListSuspensionPeriodsRequest,
  ListSuspensionPeriodsResponse,
  PayByCardIDRequest,
  PayByCardIDResponse,
  SetCreditsAutorefillRequest,
  SetCreditsAutorefillResponse,
  SetPreferredPaymentCardRequest,
  SetPreferredPaymentCardResponse,
  SuspendSubscriptionRequest,
  SuspendSubscriptionResponse,
  UnsubscribePremiumRequest,
  UnsubscribePremiumResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_api_pb';
import { PaymentGateway } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';
import { ExtractClassFields } from '@/type';

const client = createPromiseClient(PaymentAPI, getGrpcWebTransport());

export function getPackage(packageId: string, offerId: string): Promise<GetPackageResponse> {
  const identity = new PackageIdentity({
    id: packageId,
    offerId,
  });

  const request = new GetPackageRequest({
    package: identity,
  });

  return client.getPackage(request);
}

export function listPremiumPackages(): Promise<ListPremiumPackagesResponse> {
  const request = new ListPremiumPackagesRequest();

  return client.listPremiumPackages(request);
}

export function listPremiumGoldPackages(): Promise<ListPremiumGoldPackagesResponse> {
  const request = new ListPremiumGoldPackagesRequest();

  return client.listPremiumGoldPackages(request);
}

export function listCreditsPackages(): Promise<ListCreditsPackagesResponse> {
  const request = new ListCreditsPackagesRequest();

  return client.listCreditsPackages(request);
}

export function listPromoCreditsPackages(): Promise<ListPromoCreditsPackagesResponse> {
  const request = new ListPromoCreditsPackagesRequest();

  return client.listPromoCreditsPackages(request);
}

export function listPopUpPromoPackages(): Promise<ListPopUpPromoPackagesResponse> {
  const request = new ListPopUpPromoPackagesRequest();

  return client.listPopUpPromoPackages(request);
}

export function listOffboardingPackages(
  promoType: PromoType,
): Promise<ListOffboardingPackagesResponse> {
  const request = new ListOffboardingPackagesRequest({
    promoType,
  });

  return client.listOffboardingPackages(request);
}

export function listBoosters(): Promise<ListBoostersResponse> {
  const request = new ListBoostersRequest();

  return client.listBoosters(request);
}

export function activateBooster(boosterId: string): Promise<ActivateBoosterResponse> {
  const request = new ActivateBoosterRequest({
    boosterId,
  });

  return client.activateBooster(request);
}

export function listPaymentMethods(): Promise<ListPaymentMethodsResponse> {
  const request = new ListPaymentMethodsResponse();

  return client.listPaymentMethods(request);
}

export function getSolidPaymentForm({
  id,
  offerId,
}: ExtractClassFields<PackageIdentity>): Promise<GetSolidPaymentFormResponse> {
  const identity = new PackageIdentity({
    id,
    offerId,
  });

  const request = new GetSolidPaymentFormRequest({
    package: identity,
  });

  return client.getSolidPaymentForm(request);
}

export function payByCardID({
  cardId,
  pkg: { id, offerId },
}: {
  cardId: string;
  pkg: ExtractClassFields<PackageIdentity>;
}): Promise<PayByCardIDResponse> {
  const identity = new PackageIdentity({
    id,
    offerId,
  });

  const request = new PayByCardIDRequest({
    package: identity,
    cardId,
  });

  return client.payByCardID(request);
}

export function applyFreePackage({
  id,
  offerId,
}: ExtractClassFields<PackageIdentity>): Promise<ApplyFreePackageResponse> {
  const identity = new PackageIdentity({
    id,
    offerId,
  });

  const request = new ApplyFreePackageRequest({
    package: identity,
  });

  return client.applyFreePackage(request);
}

export function getCreditsBalance(): Promise<GetCreditsBalanceResponse> {
  const request = new GetCreditsBalanceRequest();

  return client.getCreditsBalance(request);
}

export function getBillingHistory({
  billingFrom,
  limit,
}: ExtractClassFields<GetBillingHistoryRequest>): Promise<GetBillingHistoryResponse> {
  const request = new GetBillingHistoryRequest({
    billingFrom,
    limit,
  });

  return client.getBillingHistory(request);
}

export function getSolidAuthorizeForm(): Promise<GetSolidAuthorizeFormResponse> {
  const request = new GetSolidAuthorizeFormRequest();

  return client.getSolidAuthorizeForm(request);
}

export function listPaymentCards(gateways: PaymentGateway[]): Promise<ListPaymentCardsResponse> {
  const request = new ListPaymentCardsRequest({
    gateways,
  });

  return client.listPaymentCards(request);
}

export function setPreferredPaymentCard(cardId: string): Promise<SetPreferredPaymentCardResponse> {
  const request = new SetPreferredPaymentCardRequest({
    cardId,
  });

  return client.setPreferredPaymentCard(request);
}

export function changeSubscriptionPackage({
  id,
  offerId,
}: ExtractClassFields<PackageIdentity>): Promise<ChangeSubscriptionPackageResponse> {
  const identity = new PackageIdentity({
    id,
    offerId,
  });

  const request = new ChangeSubscriptionPackageRequest({
    nextPackage: identity,
  });

  return client.changeSubscriptionPackage(request);
}

export function unsubscribePremium(): Promise<UnsubscribePremiumResponse> {
  const request = new UnsubscribePremiumRequest();

  return client.unsubscribePremium(request);
}

export function getSubscriptionStatus(): Promise<GetSubscriptionStatusResponse> {
  const request = new GetSubscriptionStatusRequest();

  return client.getSubscriptionStatus(request);
}

export function suspendSubscription(seconds: number): Promise<SuspendSubscriptionResponse> {
  const duration = new Duration({
    seconds: BigInt(seconds),
  });

  const request = new SuspendSubscriptionRequest({
    untilDuration: duration,
  });

  return client.suspendSubscription(request);
}

export function activateSubscription(): Promise<ActivateSubscriptionResponse> {
  const request = new ActivateSubscriptionRequest();

  return client.activateSubscription(request);
}

export function getPaymentOrderStatus(orderId: string): Promise<GetPaymentOrderStatusResponse> {
  const request = new GetPaymentOrderStatusRequest({
    orderId,
  });

  return client.getPaymentOrderStatus(request);
}

export function setCreditsAutorefill(isEnabled: boolean): Promise<SetCreditsAutorefillResponse> {
  const request = new SetCreditsAutorefillRequest({
    isEnabled,
  });

  return client.setCreditsAutorefill(request);
}

export function listPackages(identities: PackageIdentity[]): Promise<ListPackagesResponse> {
  const request = new ListPackagesRequest({
    packages: identities,
  });

  return client.listPackages(request);
}

export function completeOffers(offersIds: string[]): Promise<CompleteOffersResponse> {
  const request = new CompleteOffersRequest({
    completedOfferIds: offersIds,
  });

  return client.completeOffers(request);
}

export function listLimitedOfferPackages(): Promise<ListLimitedOfferPackagesResponse> {
  const request = new ListLimitedOfferPackagesRequest();

  return client.listLimitedOfferPackages(request);
}

export function getRecommendedSubscriptionPackage(): Promise<GetRecommendedSubscriptionPackageResponse> {
  const request = new GetRecommendedSubscriptionPackageRequest();

  return client.getRecommendedSubscriptionPackage(request);
}

export function getFreeCreditsFeaturesStatus(): Promise<GetFreeCreditsFeaturesStatusResponse> {
  const request = new GetFreeCreditsFeaturesStatusRequest();

  return client.getFreeCreditsFeaturesStatus(request);
}

export function listSuspensionPeriods(): Promise<ListSuspensionPeriodsResponse> {
  const request = new ListSuspensionPeriodsRequest();

  return client.listSuspensionPeriods(request);
}

export function initiatePayPalPayment({
  id,
  offerId,
}: ExtractClassFields<PackageIdentity>): Promise<InitiatePayPalPaymentResponse> {
  const identity = new PackageIdentity({
    id,
    offerId,
  });

  const request = new InitiatePayPalPaymentRequest({
    package: identity,
  });

  return client.initiatePayPalPayment(request);
}
