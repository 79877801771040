<template>
  <button
    :disabled="isLoadingValue || disabled"
    class="w-full h-12 flex items-center justify-center rounded-lg text-button1 font-semibold"
    :class="{
      '!bg-dark-300 !text-white !from-transparent !to-transparent': isLoadingValue || disabled,
    }"
    @click="click"
  >
    <FadeTransition>
      <div v-if="isLoadingValue" key="k1" class="flex items-center justify-center">
        <SpinnerLoader
          class="!m-0 !border-[3px] !border-white !border-b-white/30 !w-5 !h-5 !after:w-5 !after:h-5"
        />
        <span class="ml-3.5 text-button1 font-semibold">{{ t('processing') }}</span>
      </div>
      <div v-else key="k2"><slot></slot></div>
    </FadeTransition>
  </button>
</template>

<script lang="ts" setup>
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue';
import FadeTransition from '@/components/transition/FadeTransition.vue';
import { useModel } from '@/hooks/use-model/use-model';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loadingDurationMs: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:isLoading', 'click']);

const { t } = useI18n({ messages });

function click(event: MouseEvent) {
  isLoadingValue.value = true;
  emit('click', event);

  if (props.loadingDurationMs > 0) {
    setTimeout(() => {
      isLoadingValue.value = false;
    }, props.loadingDurationMs);
  }
}

const isLoadingValue = useModel<boolean>(props, 'isLoading', emit);
</script>
