<template>
  <div
    :class="{ popular: pkg.isPopular, 'active-popular': isSelected }"
    class="billing_card popular discount best_deal"
    @click="toggleSelect"
  >
    <div class="billing_card-check">
      <i class="icon icon-success"></i>
    </div>
    <!--  Red label top  -->
    <div v-if="pkg.savingsPercent >= 81" class="best_deal_label">
      {{ t('best') }}
    </div>
    <!--  Green label top  -->
    <div v-show="pkg.isPopular" class="popular_label">
      {{ t('popular') }}
    </div>

    <!--  Primary label top  -->
    <div v-if="isIdealBalance" class="primary_label">
      {{ t('idealBalance') }}
    </div>
    <div class="billing_card-body">
      <div class="billing_card-title">{{ pkg.label }}</div>

      <div v-if="packagePricePerWeek.price.totalAmount" class="billing_card-price-green">
        {{ t('pricePerWeek', { price: packagePricePerWeek.priceFormatted }) }}
        <span
          v-if="pkg.savingsPercent && packagePricePerWeek.price.taxAmount && isSelected"
          class="text-caption text-dark-200"
          >+ {{ t('tax') }} {{ packagePricePerWeek.priceTaxFormatted }}</span
        >
      </div>
      <div v-else class="billing_card-price">
        {{ packagePrice.priceFormatted }}
        <span
          v-if="pkg.savingsPercent && packagePrice.price.taxAmount && isSelected"
          class="text-caption text-dark-200"
          >+ {{ t('tax') }} {{ packagePrice.priceTaxFormatted }}</span
        >
      </div>
    </div>

    <template v-if="!pkg.savingsPercent">
      <div
        v-if="
          packagePricePerWeek.price.totalAmount && packagePricePerWeek.price.taxAmount && isSelected
        "
        class="text-caption text-dark-200 mt-6 text-right flex-1"
      >
        + {{ t('tax') }} {{ packagePricePerWeek.priceTaxFormatted }}
      </div>
      <div
        v-else-if="packagePrice.price.taxAmount && isSelected"
        class="text-caption text-dark-200 mt-6 text-right flex-1"
      >
        + {{ t('tax') }} {{ packagePrice.priceTaxFormatted }}
      </div>
    </template>

    <div v-if="pkg.savingsPercent" class="ml-auto triangle_label" :class="savingPercentClass">
      <div class="triangle"></div>
      <div class="triangle_label-body">
        <div class="text-save">{{ t('save') }}</div>
        <div class="text-value">{{ pkg.savingsPercent }}</div>
        <div class="text-percent">%</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType, reactive } from 'vue';

import { useModel } from '@/hooks/use-model/use-model';
import {
  usePackagePrice,
  usePackagePricePerWeek,
} from '@/pages/payment/hooks/use-package-price/use-package-price';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
  packages: {
    type: Array as PropType<ExtractClassFields<PremiumPackage>[]>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n({ messages });

const selectedModel = useModel<ExtractClassFields<PremiumPackage>>(props, 'modelValue', emit);

const packagePrice = reactive(usePackagePrice(props.pkg));
const packagePricePerWeek = reactive(usePackagePricePerWeek(props.pkg));

const isSelected = computed(() => {
  return selectedModel.value.id === props.pkg.id;
});

const sortedPackages = computed<ExtractClassFields<PremiumPackage>[]>(() => {
  return props.packages
    .filter((pkg) => pkg.savingsPercent !== 0)
    .sort((a, b) => b.savingsPercent - a.savingsPercent);
});

const isIdealBalance = computed(() => {
  return (
    sortedPackages.value.length > 1 &&
    sortedPackages.value[1].savingsPercent === props.pkg.savingsPercent
  );
});

const savingPercentClass = computed(() => {
  const sp = sortedPackages.value;
  if (sp.length) {
    switch (props.pkg.savingsPercent) {
      case sp?.[0].savingsPercent:
        return 'bg-green';
      case sp?.[1].savingsPercent:
        return 'bg-prem';
      case sp?.[2].savingsPercent:
        return 'bg-red';
    }
  }

  return '';
});

function toggleSelect(): void {
  selectedModel.value = props.pkg;
}
</script>
