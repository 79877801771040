<template>
  <div>
    <template v-if="state === 'loading'">
      <ModalSpinner></ModalSpinner>
    </template>
    <template v-else>
      <ModalHeaderContent>
        <template v-if="isCloseVisible" #left>
          <Close @click="close"></Close>
        </template>
        <template #default>
          <div v-if="state === 'complete'" class="user-select-none" v-html="html"></div>
          <div v-else class="text-center" v-html="t('error')"></div>
        </template>
      </ModalHeaderContent>
    </template>
  </div>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: false, isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import { replaceLegalVariables } from '@project-gd-x/shared-web/src/helpers/legal-formatter/legal-formatter';
import { PropType, ref, watch } from 'vue';

import Close from '@/components/layout/components/header/components/close/Close.vue';
import ModalHeaderContent from '@/components/modal/ModalHeaderContent.vue';
import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import { getUserId } from '@/helpers/auth/auth';
import { useCountry } from '@/hooks/use-country/use-country';
import { goBackOrGo } from '@/router';
import { GetTextPageArgumentPageId } from '@/services/api/page/page-api';
import { getLocale, Locale, useI18n } from '@/services/i18n';

import { messages } from './i18n';

type State = 'loading' | 'complete' | 'error';

const props = defineProps({
  pageId: {
    type: String as PropType<GetTextPageArgumentPageId>,
    required: true,
  },
  isCloseVisible: {
    type: Boolean,
    default: true,
  },
  isModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const state = ref<State>('loading');
const html = ref<string>('');

watch(
  () => props.pageId,
  () => {
    load();
  },
);

function close() {
  if (props.isModal) {
    emit('close');
  } else {
    goBackOrGo(getUserId() ? 'search' : 'homepage');
  }
}

function importPageHtml(pageId: GetTextPageArgumentPageId) {
  const locale = getLocale();

  const pageImportedList = {
    en: {
      // @ts-expect-error
      terms: () => import('./content/en/terms-en.html?raw'),
      // @ts-expect-error
      privacy: () => import('./content/en/privacy-en.html?raw'),
      // @ts-expect-error
      cookie: () => import('./content/en/cookie-en.html?raw'),
      // @ts-expect-error
      'anti-scam': () => import('./content/en/anti-scam-en.html?raw'),
      // @ts-expect-error
      membership: () => import('./content/en/membership-en.html?raw'),
    },

    es: {
      // @ts-expect-error
      terms: () => import('./content/es/terms-es.html?raw'),
      // @ts-expect-error
      privacy: () => import('./content/es/privacy-es.html?raw'),
      // @ts-expect-error
      cookie: () => import('./content/es/cookie-es.html?raw'),
      // @ts-expect-error
      'anti-scam': () => import('./content/es/anti-scam-es.html?raw'),
      // @ts-expect-error
      membership: () => import('./content/es/membership-es.html?raw'),
    },
  };

  switch (locale) {
    case Locale.Es:
      return pageImportedList.es[pageId]();
    default:
      return pageImportedList.en[pageId]();
  }
}

async function load() {
  try {
    state.value = 'loading';

    let { default: contentHtml } = await importPageHtml(props.pageId);

    const country = useCountry();
    await country.load();

    if (country.country.value) {
      html.value = replaceLegalVariables(contentHtml, {
        countryAlpha3: country.country.value?.country.isoCodeAlpha3,
        baseUrl: props.isCloseVisible ? '/text-page/' : '/text-page-raw/',
      });

      state.value = 'complete';
    }
  } catch (e) {
    console.error(e);
    state.value = 'error';
  }
}

load();
</script>
