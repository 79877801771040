import { computed, ref, watch } from 'vue';

import { useSupportBtn } from '@/components/support-btn/hooks/use-support-btn/use-support-btn';
import * as support from '@/services/support/support';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf() {
  // Ref -----------------------------------------------------------------------
  const isSupportChatLoading = ref(false);
  let supportIntervalId = 0;

  // Hook ----------------------------------------------------------------------
  const supportButton = useSupportBtn();

  // Computed ------------------------------------------------------------------
  const buttonPositionClassName = computed<string>(() => {
    if (supportButton.buttonPosition.value === 'top-right') {
      return 'right-1 top-1 rounded-[50%_8px_50%_50%]';
    } else if (supportButton.buttonPosition.value === 'bottom-right') {
      return 'right-1 bottom-1 rounded-[50%_50%_8px_50%]';
    }

    return '';
  });

  // Method --------------------------------------------------------------------
  function watchSupportButtonIsVisible() {
    watch(supportButton.isVisible, (isVisible) => {
      if (!isVisible) {
        stopSupportChatLoading();
      }
    });
  }

  async function openSupportChat() {
    isSupportChatLoading.value = true;
    await support.openWidget();
    supportIntervalId = window.setInterval(() => {
      if (support.isWidgetOpen()) {
        stopSupportChatLoading();
      }
    }, 500);
  }

  function stopSupportChatLoading() {
    isSupportChatLoading.value = false;
    if (supportIntervalId) {
      window.clearInterval(supportIntervalId);
      supportIntervalId = 0;
    }
  }

  function init() {
    watchSupportButtonIsVisible();
  }

  return {
    isSupportChatLoading,
    supportButton,
    buttonPositionClassName,
    openSupportChat,
    init,
  };
}
