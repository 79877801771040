import { PossibilityType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';

import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });

export const possibilityNotificationIcon = (type: PossibilityType): string => {
  switch (type) {
    case PossibilityType.TOP_PROFILE_PLUS:
      return 'top_profile_plus';
    case PossibilityType.TOP_PROFILE_COMMON:
      return 'top_profile';
    case PossibilityType.SEXUAL_CHECK:
      return 'sexual';
    case PossibilityType.ASTROLOGY_CHECK:
      return 'stars';
    case PossibilityType.POWER_LIKE:
      return 'power_like_solid';
    case PossibilityType.POWER_DONATION:
      return 'power_like_solid';
    case PossibilityType.FREE_GIFT:
      return 'gift';
    case PossibilityType.INVALID:
      return 'invalid possibility';
    default:
      return 'mma';
  }
};

export const possibilityNotificationText = (type: PossibilityType): string => {
  return t(`${type}`);
};
