<template>
  <div class="custom-control custom-checkbox auto-top-up">
    <input
      id="auto_credit"
      v-model="isAutoRefillEnabled"
      name="auto_credit-name"
      type="checkbox"
      class="custom-control-input"
    />
    <label for="auto_credit" class="custom-control-label">
      <slot name="auto-refill-text">{{ t('automaticallyTopUpMyCredits') }}</slot>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

import { useCreditsAutoTopUp } from '@/pages/payment/components/layout/components/purchase-auto-top-up/hooks/use-credits-auto-top-up/use-credits-auto-top-up';
import { useI18n } from '@/services/i18n';
import { dispatch } from '@/store/store-helper';

import { messages } from './i18n';

const props = defineProps({
  autoRefillValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['mounted']);

const isAutoRefillEnabled = ref(true);

const { t } = useI18n({ messages });

onMounted(async (): Promise<void> => {
  const { setCreditsAutoRefill, fetchCreditsBalance } = useCreditsAutoTopUp();
  const creditsBalance = await fetchCreditsBalance();

  emit('mounted', creditsBalance.isAutorefillEnabled);

  if (props.autoRefillValue && !creditsBalance.isAutorefillEnabled) {
    return setCreditsAutoRefill(props.autoRefillValue);
  } else {
    isAutoRefillEnabled.value = creditsBalance.isAutorefillEnabled;
  }
});

watch(
  isAutoRefillEnabled,
  () => {
    dispatch('actionPaymentSetCreditsAutorefill', isAutoRefillEnabled.value);
  },
  { immediate: false },
);
</script>

<style lang="scss" scoped src="./style.scss"></style>
