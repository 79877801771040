import { PossibilityType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';

export enum PossibilityPackage {
  ASTROLOGY_CHECK = 'pos-astrology-one',
  SEXUAL_CHECK = 'pos-sexual-one',
  POWER_LIKE = 'pos-powerlike-one',
  POWER_LIKE_DONATION = 'pos-powerdonation-one',
  TOP_PROFILE_COMMON = 'pos-top-profile-common-one',
  TOP_PROFILE_PLUS = 'pos-top-profile-plus-one',
}

export function resolvePackageId(type: PossibilityType) {
  switch (type) {
    case PossibilityType.ASTROLOGY_CHECK:
      return PossibilityPackage.ASTROLOGY_CHECK;
    case PossibilityType.POWER_LIKE:
      return PossibilityPackage.POWER_LIKE;
    case PossibilityType.SEXUAL_CHECK:
      return PossibilityPackage.SEXUAL_CHECK;
    case PossibilityType.TOP_PROFILE_COMMON:
      return PossibilityPackage.TOP_PROFILE_COMMON;
    case PossibilityType.POWER_DONATION:
      return PossibilityPackage.POWER_LIKE_DONATION;
    case PossibilityType.TOP_PROFILE_PLUS:
    default:
      return PossibilityPackage.TOP_PROFILE_PLUS;
  }
}
