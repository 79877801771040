import { createPromiseClient } from '@connectrpc/connect';
import { CommandsAPIService } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/commands_api_connect';
import {
  AcceptPowerLikeCommandRequest,
  AcceptPowerLikeCommandResponse,
  BlockUserCommandRequest,
  BlockUserCommandResponse,
  CancelPowerLikeCommandRequest,
  CancelPowerLikeCommandResponse,
  ReportUserCommandRequest,
  ReportUserCommandResponse,
  RequestPhotoCommandRequest,
  RequestPhotoCommandResponse,
  SendLikeCommandRequest,
  SendLikeCommandResponse,
  SendPowerLikeCommandRequest,
  SendPowerLikeCommandResponse,
  UnblockUserCommandRequest,
  UnblockUserCommandResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/commands_api_pb';
import {
  AcceptPowerLikeCommand,
  BlockUserCommand,
  CancelPowerLikeCommand,
  ReportUserCommand,
  RequestPhotoCommand,
  SendLikeCommand,
  SendPowerLikeCommand,
  UnblockUserCommand,
  UserReportSource,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/commands_pb';
import { ListPaginationRequest } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/pagination_pb';
import { ReadAPIService } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_connect';
import {
  BatchGetConnectionStateRequest,
  BatchGetConnectionStateResponse,
  ListBlockedUsersRequest,
  ListBlockedUsersResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_pb';
import { ConnectionAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/connection/v1/connection_api_connect';
import {
  GetEngagementProfilesRequest,
  GetEngagementProfilesResponse,
  GetNotificationProfileRequest,
  GetNotificationProfileResponse,
  GetOffboardingProfilesRequest,
  GetOffboardingProfilesResponse,
  LikeEngagementProfilesRequest,
  LikeEngagementProfilesRequest_EngagementLike,
  LikeEngagementProfilesResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/connection/v1/connection_api_pb';
import { NotificationCategory } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/connection/v1/notification_pb';

import { getUserId } from '@/helpers/auth/auth';
import { ProfileStateAbuseReasonNew } from '@/pages/profile/store/profile-state';
import { SignupStateEngagementProfile } from '@/pages/signup/store/signup-state';
import { getGrpcWebTransport } from '@/services/api/grpc-api';

export type AbuseUserArgument = {
  userId: string;
  reason: ProfileStateAbuseReasonNew;
  description: string;
  source: UserReportSource;
};

const connectionClient = createPromiseClient(ConnectionAPI, getGrpcWebTransport());
const commandsServiceClient = createPromiseClient(CommandsAPIService, getGrpcWebTransport());
const readServiceClient = createPromiseClient(ReadAPIService, getGrpcWebTransport());

export function notificationProfileRequest(
  category: NotificationCategory,
): Promise<GetNotificationProfileResponse> {
  const request = new GetNotificationProfileRequest({
    category,
  });

  return connectionClient.getNotificationProfile(request);
}

export function getConnectionState(userIdList: string[]): Promise<BatchGetConnectionStateResponse> {
  const request = new BatchGetConnectionStateRequest({
    ids: userIdList,
    userId: getUserId(),
  });

  return readServiceClient.batchGetConnectionState(request);
}

export function blockUser(userId: string): Promise<BlockUserCommandResponse> {
  const command = new BlockUserCommand({
    userId: getUserId(),
    subjectId: userId,
  });

  const request = new BlockUserCommandRequest({
    command,
  });

  return commandsServiceClient.blockUserCommand(request);
}

export function unblockUser(subjectId: string): Promise<UnblockUserCommandResponse> {
  const command = new UnblockUserCommand({
    userId: getUserId(),
    subjectId,
  });

  const request = new UnblockUserCommandRequest({
    command,
  });

  return commandsServiceClient.unblockUserCommand(request);
}

export function reportAbuse({
  userId,
  reason,
  description,
  source,
}: AbuseUserArgument): Promise<ReportUserCommandResponse> {
  const command = new ReportUserCommand({
    userId: getUserId(),
    subjectId: userId,
    reason,
    description,
    source,
  });

  const request = new ReportUserCommandRequest({
    command,
  });

  return commandsServiceClient.reportUserCommand(request);
}

export function sendLike(userId: string): Promise<SendLikeCommandResponse> {
  const command = new SendLikeCommand({
    userId: getUserId(),
    subjectId: userId,
  });

  const request = new SendLikeCommandRequest({
    command,
  });

  return commandsServiceClient.sendLikeCommand(request);
}

export function sendPowerLike(userId: string): Promise<SendPowerLikeCommandResponse> {
  const command = new SendPowerLikeCommand({
    userId: getUserId(),
    subjectId: userId,
  });

  const request = new SendPowerLikeCommandRequest({
    command,
  });

  return commandsServiceClient.sendPowerLikeCommand(request);
}

export function cancelPowerLike({
  userId,
  chatId,
}: {
  userId: string;
  chatId: string;
}): Promise<CancelPowerLikeCommandResponse> {
  const command = new CancelPowerLikeCommand({
    userId,
    connectionId: chatId,
  });

  const request = new CancelPowerLikeCommandRequest({
    command,
  });

  return commandsServiceClient.cancelPowerLikeCommand(request);
}

export function acceptPowerLike({
  userId,
  chatId,
}: {
  userId: string;
  chatId: string;
}): Promise<AcceptPowerLikeCommandResponse> {
  const command = new AcceptPowerLikeCommand({
    userId,
    connectionId: chatId,
  });

  const request = new AcceptPowerLikeCommandRequest({
    command,
  });

  return commandsServiceClient.acceptPowerLikeCommand(request);
}

export function requestUserPhoto(userId: string): Promise<RequestPhotoCommandResponse> {
  const command = new RequestPhotoCommand({
    userId: getUserId(),
    subjectId: userId,
  });

  const request = new RequestPhotoCommandRequest({
    command,
  });

  return commandsServiceClient.requestPhotoCommand(request);
}

export function listBlockedUsers({
  usersFrom = '',
  limit = 10,
  userId,
}: {
  usersFrom: string;
  limit: number;
  userId: string;
}): Promise<ListBlockedUsersResponse> {
  const pagination = new ListPaginationRequest({
    from: usersFrom,
    limit,
  });

  const request = new ListBlockedUsersRequest({
    userId,
    pagination,
  });

  return readServiceClient.listBlockedUsers(request);
}

export function getEngagementProfiles(limit = 18): Promise<GetEngagementProfilesResponse> {
  const request = new GetEngagementProfilesRequest({
    limit,
  });

  return connectionClient.getEngagementProfiles(request);
}

export function likeEngagementProfiles(
  profiles: SignupStateEngagementProfile[],
): Promise<LikeEngagementProfilesResponse> {
  const request = new LikeEngagementProfilesRequest();

  profiles.forEach((profile) => {
    const like = new LikeEngagementProfilesRequest_EngagementLike({
      isLiked: profile.isLiked,
      userId: profile.userId,
    });

    request.likes.push(like);
  });

  return connectionClient.likeEngagementProfiles(request);
}

export function getOffboardingProfiles(limit = 4): Promise<GetOffboardingProfilesResponse> {
  const request = new GetOffboardingProfilesRequest({
    limit,
  });

  return connectionClient.getOffboardingProfiles(request);
}
