import { Timestamp } from '@bufbuild/protobuf';
import { createPromiseClient } from '@connectrpc/connect';
import { ConversionAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/conversion/v1/conversion_api_connect';
import {
  ListUndeliveredConversionsRequest,
  ListUndeliveredConversionsResponse,
  UpdateLastDeliveredTimeRequest,
  UpdateLastDeliveredTimeResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/conversion/v1/conversion_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';
import { ExtractClassFields } from '@/type';

const client = createPromiseClient(ConversionAPI, getGrpcWebTransport());

export function listUndeliveredConversions(): Promise<ListUndeliveredConversionsResponse> {
  const request = new ListUndeliveredConversionsRequest();

  return client.listUndeliveredConversions(request);
}

export function updateLastDeliveredTime({
  seconds,
  nanos,
}: ExtractClassFields<Timestamp>): Promise<UpdateLastDeliveredTimeResponse> {
  const timestamp = new Timestamp({
    seconds,
    nanos,
  });

  const request = new UpdateLastDeliveredTimeRequest({
    lastDeliveredTime: timestamp,
  });

  return client.updateLastDeliveredTime(request);
}
