// https://www.geekstrick.com/snippets/how-to-parse-cookies-in-javascript/
export function parseCookie(str: string): { [key: string]: string } {
  return str
    .split(';')
    .map((v) => v.split('='))
    .filter((v) => v.length > 1)
    .reduce(
      (acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      },
      {} as { [key: string]: string },
    );
}

export function writeCookie(key: string, value: string, days: number = 365): void {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${key}=${value};expires=${date.toUTCString()};path=/`;
}
