<template>
  <div v-show="self.isLoading" class="h-[40px] overflow-hidden flex justify-center">
    <LoaderSpinnerSmall />
  </div>
  <div
    v-show="!self.isLoading"
    id="paypal-button"
    :ref="self.setButtonEl"
    class="min-h-[40px]"
  ></div>
</template>

<script lang="ts" setup>
import { PropType, reactive } from 'vue';

import LoaderSpinnerSmall from '@/components/loader/loader-spinner-small/LoaderSpinnerSmall.vue';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';

import { useSelf } from './hooks/use-self/use-self';

const props = defineProps({
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    required: true,
    default: () => ({}),
  },
});

const self = reactive(
  useSelf({
    pkg: props.pkg,
  }),
);

self.init();
</script>
