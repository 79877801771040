import { ProfileLocation } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { assignObject } from '@/helpers/assign/assign';
import { ExtractClassFields } from '@/type';

import { SearchState, SearchStateFilter, SearchStateResultProfile } from './search-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationSearchSetFilter: SearchStateFilter;
    mutationSearchAddProfiles: SearchStateResultProfile[];
    mutationSearchClearProfiles: never;
    mutationSearchLike: string;
    mutationSearchSetFilterHasPhotos: boolean;
    mutationSearchSetLocation: ExtractClassFields<ProfileLocation> | undefined;
  }
}

export function mutationSearchSetFilter(state: SearchState, filter: SearchStateFilter) {
  state.filter = filter;
}

export function mutationSearchSetFilterHasPhotos(state: SearchState, hasPhotos: boolean) {
  state.filter.hasPhotos = hasPhotos;
}

export function mutationSearchAddProfiles(
  state: SearchState,
  profiles: SearchStateResultProfile[],
) {
  state.profiles.push(...profiles);
}

export function mutationSearchClearProfiles(state: SearchState) {
  state.profiles = [];
}

export function mutationSearchSetLocation(
  state: SearchState,
  payload: ExtractClassFields<ProfileLocation>,
) {
  if (payload) {
    assignObject(state.filter.location, payload);
  }
}

export function mutationSearchLike(state: SearchState, userId: string) {
  const profile = state.profiles.find((profile) => profile.id === userId);
  if (profile) {
    profile.isLiked = true;
  }
}
