import { useMatch } from '@/hooks/use-match/use-match';
import { NotificationHandler } from '@/services/websocket';
import { commit, dispatch } from '@/store/store-helper';

export const matchNotificationHandler: NotificationHandler = async (notification) => {
  // if (notification.getNotificationCase() === NotificationCase.MATCH_FROM_ME) {
  if (notification.notification.case === 'matchFromMe') {
    const matchFromMe = notification.notification.value;

    if (matchFromMe && !matchFromMe.isPowerLike) {
      commit('mutationProfileUnlockChat', {
        chatId: matchFromMe.dialogId,
        userId: matchFromMe.userId,
      });

      const { callMatchPopup } = useMatch(matchFromMe);
      callMatchPopup();
      dispatch('actionProfileGetConnectionState', matchFromMe.userId);
    }
  }
};
