import { Ref } from 'vue';

import { hasPremium } from '@/helpers/feature/feature';

let hasBeenSet = false;

export function resolveAdSenseVisibility() {
  if (hasPremium() && !hasBeenSet) {
    hasBeenSet = true;
    const style = document.createElement('style');

    style.innerHTML = `ins.adsbygoogle {display: none !important;}`;
    document.head.appendChild(style);
  }
}

export function hideUnfilledAds(element: HTMLElement, isUnfilled: Ref<boolean>): void {
  const config = {
    attributes: true,
  };

  const listAttributes = (): void => {
    if (element) {
      for (let i = 0; i < element.attributes.length; i++) {
        const el = element.attributes.item(i);

        if (el?.value === 'unfilled') {
          isUnfilled.value = true;
        }
      }
    }
  };

  const callback = (mutations: MutationRecord[]): void => {
    for (const mutation of mutations) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-ad-status') {
        listAttributes();
      }
    }
  };

  if (element) {
    const observer = new MutationObserver(callback);

    observer.observe(element, config);
  }
}

let googleAdSensePromise: Promise<void>;

export function loadGoogleAdSense(): Promise<void> {
  if (!googleAdSensePromise) {
    googleAdSensePromise = new Promise((resolve) => {
      const script = document.createElement('script');
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3433820135897012';
      script.onload = () => {
        resolve();
      };
      document.head.appendChild(script);
    });
  }

  return googleAdSensePromise;
}
