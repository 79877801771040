export const userAgent = (navigator.userAgent || navigator.vendor || window.opera).toLowerCase();

// enum BrowserNames {
//   Messenger = 'messenger',
//   Facebook = 'facebook',
//   Instagram = 'instagram',
//   Chrome = 'chrome',
//   Safari = 'safari',
//   Firefox = 'firefox',
// }

// const browser = {
//   [BrowserNames.Messenger]: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
//   [BrowserNames.Facebook]: /\bFB[\w_]+/,
//   [BrowserNames.Instagram]: /\bInstagram/i,
//   [BrowserNames.Chrome]: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/,
//   [BrowserNames.Chrome]: /Version.*Mobile.*Safari|Safari.*Mobile|MobileSafari/,
//   [BrowserNames.Firefox]: /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
// };

export const generalRules = [
  // if it says it's a webview, let's go with that
  'WebView',
];
export const androidRules = [
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  'Android.*(wv)',
  // old chrome android webview agent
  'Linux; U; Android',
];
export const iosRules = [
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
];

// adSense crawler useragent pattern
export const adSenseCrawlerUa = ['GoogleAdSenseInfeed', 'Google Web Preview'];

export function generateRegExp(rules: string[]): RegExp {
  return new RegExp('(' + rules.join('|') + ')', 'ig');
}

export function isIosWebView(ua: string = userAgent): boolean {
  return !!ua.match(generateRegExp(iosRules));
}

export function isWebView(ua: string = userAgent): boolean {
  const mixedRules = [...generalRules, ...iosRules, ...androidRules];

  return !!ua.match(generateRegExp(mixedRules));
}

export function isInFacebookApp(): boolean {
  // Facebook sign keys.
  const keys = ['FBAN', 'FBAV'];
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return keys.some((key) => userAgent.includes(key));
}

export function isInFacebookAndroidApp(): boolean {
  return isInFacebookApp() && !userAgent.toLowerCase().includes('iphone');
}

export function isInAndroidWebviewOrFacebook(): boolean {
  const mixedRules = [...generalRules, ...androidRules];

  return isInFacebookAndroidApp() || !!userAgent.match(generateRegExp(mixedRules));
}

export function isAdSenseCrawler(ua: string = userAgent): boolean {
  return !!ua.match(generateRegExp(adSenseCrawlerUa));
}
