<template>
  <button class="relative overflow-hidden" @click="click">
    <span
      :class="{ animate: isLoadingModel }"
      class="h-[3px] absolute top-0 right-0 left-0"
      :style="{
        'transition-duration': (isLoadingModel ? loadingDurationMs : 0) + 'ms',
        'background-color': isLoadingModel ? loadingColor : 'transparent',
      }"
    ></span>

    <LoaderSpinnerSmall v-if="isLoadingModel" :color="loadingColor" class="!mx-auto" />
    <slot v-else></slot>
  </button>
</template>

<script lang="ts" setup>
import { onUnmounted } from 'vue';

import LoaderSpinnerSmall from '@/components/loader/loader-spinner-small/LoaderSpinnerSmall.vue';
import { useModel } from '@/hooks/use-model/use-model';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  loadingDurationMs: {
    type: Number,
    default: 0,
  },
  loadingColor: {
    type: String,
    default: '#00909E',
  },
});

const emit = defineEmits(['update:isLoading', 'click']);

const isLoadingModel = useModel<boolean>(props, 'isLoading', emit);

function click(event: MouseEvent) {
  if (isLoadingModel.value) {
    return;
  }

  isLoadingModel.value = true;
  emit('click', event);

  if (props.loadingDurationMs > 0) {
    setTimeout(() => {
      isLoadingModel.value = false;
    }, props.loadingDurationMs);
  }
}

onUnmounted(() => {
  isLoadingModel.value = false;
});
</script>

<style lang="scss" scoped>
.animate {
  transition: left linear;
  left: 100%;
}
</style>
