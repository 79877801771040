import { computed, ref } from 'vue';

import {
  loadCards,
  paymentPurchase,
} from '@/components/payment/helpers/payment-method/payment-method';
import { getUrlSearchParam } from '@/helpers/navigator/navigator';
import { usePromoTimer } from '@/hooks/use-promo-timer/use-promo-timer';
import { setWelcomeTrialPopupUnavailable } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/helpers/trial-popup/trial-popup';
import { useSubscriptionPromoTrial } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/hooks/use-subscription-promo-trial/use-subscription-promo-trial';
import {
  TrialProsperiLookingFor,
  TrialProsperiType,
} from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/helpers/type/type';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { getState } from '@/store/store-helper';

const TIMER_DURATION_SECONDS = 172800;
const TIMER_DURATION_RESTART_SECONDS = -345600;

type UseSelfArg = {
  type: TrialProsperiType;
};
export function useSelf({ type }: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const lookingFor = ref<TrialProsperiLookingFor>('skip');
  const cardDefault = ref<PaymentStateCard>();
  const isButtonLoading = ref(false);

  // Hook ----------------------------------------------------------------------

  const promoTimer = usePromoTimer({
    durationSec: TIMER_DURATION_SECONDS,
    durationRestartSec: TIMER_DURATION_RESTART_SECONDS,
    timerName: 'trial-welcome',
  });

  const promoTrial = useSubscriptionPromoTrial(() => {});

  // Computed ------------------------------------------------------------------
  const isFromLanding = computed((): boolean => {
    return getState().common.index.isFromLanding;
  });

  // Method --------------------------------------------------------------------
  function initLookingFor() {
    const landingUrl = getState().common.index.landingUrl;
    const searchParamLookingFor = getUrlSearchParam(landingUrl, 'relationships');
    if (searchParamLookingFor) {
      lookingFor.value = searchParamLookingFor as TrialProsperiLookingFor;
    }
  }

  function purchasePkg() {
    paymentPurchase({
      pkg: promoTrial.pkg.value,
    });
  }

  function init() {
    initLookingFor();

    promoTimer.init();

    if (type === 'welcome') {
      setWelcomeTrialPopupUnavailable();
    }

    loadCards().then((cardList) => {
      cardDefault.value = cardList[0];
    });
  }

  function destroy() {
    promoTimer.destroy();
  }

  return {
    lookingFor,
    cardDefault,
    isButtonLoading,
    promoTrial,
    destroy,
    isFromLanding,
    promoTimer,
    purchasePkg,
    init,
  };
}
