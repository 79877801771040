import { GetCreditsBalanceResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_api_pb';
import { reactive } from 'vue';

import { assignObject } from '@/helpers/assign/assign';
import { dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export type CreditsBalance = Pick<
  ExtractClassFields<GetCreditsBalanceResponse>,
  'isAutorefillAllowed' | 'isAutorefillEnabled'
>;

export function useCreditsAutoTopUp() {
  const creditsBalance = reactive<CreditsBalance>({
    isAutorefillAllowed: false,
    isAutorefillEnabled: false,
  });

  async function setCreditsAutoRefill(value: boolean): Promise<void> {
    await dispatch('actionPaymentSetCreditsAutorefill', value);
  }

  async function fetchCreditsBalance(): Promise<CreditsBalance> {
    const response = await dispatch('actionPaymentGetCreditsBalance');

    assignObject(creditsBalance, response);

    return creditsBalance;
  }

  return {
    setCreditsAutoRefill,
    fetchCreditsBalance,
  };
}
