<template>
  <ModalPortal v-if="!isLoading" :is-teleport-enabled="false">
    <div class="extra_content modal-backdrop web_push_modal welcome_trial modification-1 active">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="swiper-slide-body" data-test-id="welcome_popup_page1">
                    <div class="extra_content-title" data-test-id="title_content_welcome_page1">
                      <div
                        class="web_push_modal-avatar relative"
                        data-test-id="avatar_welcome_page1"
                      >
                        <div class="overflow-hidden rounded-full">
                          <RetinaPhoto
                            :photo-id="profile.avatar.id"
                            :width="80"
                            :height="80"
                          ></RetinaPhoto>
                        </div>
                        <div class="web_push_modal-icon">🎉</div>
                      </div>

                      <div class="color-premium">{{ t('welcome', { name: profile.name }) }}</div>
                      {{ t('beginYourJourney') }}
                    </div>
                    <div class="extra_content-text" data-test-id="body_content_welcome_page1">
                      <div class="trial-block-benefits">
                        <div class="web_push_modal-content text-left">
                          {{ t('meetNewNicePeopleOnline') }}
                        </div>
                      </div>

                      <button
                        type="button"
                        class="btn btn-premium btn-block mb-0"
                        data-test-id="next_btn_welcome_page1"
                        @click.stop="nextSlide"
                      >
                        {{ t('next') }}
                      </button>

                      <ul class="slider-indicators welcome_trial-indicators">
                        <li class="bullet active"></li>
                        <li class="bullet"></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" data-test-id="welcome_popup_page2">
                  <div class="swiper-slide-body">
                    <div class="extra_content-title" data-test-id="title_content_welcome">
                      <a
                        v-if="isCloseVisible"
                        href="#"
                        class="web_push_modal-close"
                        data-test-id="btn_close"
                        @click.prevent="close"
                      >
                        <i class="icon icon-close"></i>
                      </a>

                      <div class="web_push_modal-avatar relative" data-test-id="avatar_welcome">
                        <div class="overflow-hidden rounded-full">
                          <RetinaPhoto
                            :photo-id="profile.avatar.id"
                            :width="80"
                            :height="80"
                          ></RetinaPhoto>
                        </div>
                        <div class="web_push_modal-icon icon_text_free">{{ t('free') }}</div>
                      </div>
                      <div>{{ t('rightNowYouAreOn') }}</div>

                      <span class="color-gray-1">{{ t('freeSubscription') }}</span>
                    </div>
                    <div class="extra_content-text" data-test-id="body_content_welcome">
                      <div class="web_push_modal-content">
                        <div class="welcome_column flex justify-between">
                          <div class="welcome_column-item free" data-test-id="free_column">
                            <div class="welcome_column-title">
                              {{ t('titleFreeSubscription') }}
                            </div>
                            <div class="welcome_column-list">
                              <div class="welcome_column-text" data-test-id="free_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('seeBasicProfilesOfOtherSingles') }}
                              </div>
                              <div class="welcome_column-text" data-test-id="free_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('limitedChatting') }}
                              </div>
                              <div class="welcome_column-text" data-test-id="free_item">
                                <i class="icon icon-check_mark_solid"></i> {{ t('limitedLikes') }}
                              </div>
                            </div>
                          </div>
                          <div class="welcome_column-item trial" data-test-id="trial_column">
                            <div class="welcome_column-title">
                              {{ t('trial') }}
                            </div>
                            <div class="welcome_column-list">
                              <div class="welcome_column-text" data-test-id="trial_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('seeFullProfilesOfOtherSingles') }}
                              </div>
                              <div class="welcome_column-text" data-test-id="trial_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('unlimitedChatting') }}
                              </div>
                              <div class="welcome_column-text" data-test-id="trial_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('unlimitedLikes') }}
                              </div>
                              <div class="welcome_column-text" data-test-id="trial_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('unlimitedFilters') }}
                              </div>
                              <div class="welcome_column-text" data-test-id="trial_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('seeWhoVisitsAndLikesYou') }}
                              </div>
                              <div class="welcome_column-text" data-test-id="trial_item">
                                <i class="icon icon-check_mark_solid"></i>
                                {{ t('getPremiumSearchBoost') }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <SpinnerButton
                        v-if="cardDefault"
                        :loading-duration-ms="10000"
                        class="btn btn-premium btn-block mb-0"
                        @click="next"
                      >
                        {{ t('continue') }}
                      </SpinnerButton>

                      <button
                        v-else
                        type="button"
                        class="btn btn-premium btn-block mb-0"
                        data-test-id="try_trial_welcome"
                        @click="next"
                      >
                        {{ t('continuePerWeek', { price }) }}
                      </button>
                    </div>
                    <div v-if="!isFromLanding" class="text-overline text-dark-200 text-center mt-3">
                      <PaymentMethodPolicyTrial
                        :price-next="nextPackagePrice"
                        :price="price"
                        :btn-text="'&quot;' + t('continuePerWeek', { price }) + '&quot;'"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ul :class="'swiper-pagination'" style="opacity: 0; left: -200px"></ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalPortal>
</template>

<script lang="ts" setup>
import 'swiper/swiper-bundle.css';

import Swiper, { EffectFade } from 'swiper';
import { computed, nextTick, PropType, ref } from 'vue';

import SpinnerButton from '@/components/inputs/spinner-button/SpinnerButton.vue';
import ModalPortal from '@/components/modal/ModalPortal.vue';
import PaymentMethodPolicyTrial from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/PaymentMethodPolicyTrial.vue';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { setWelcomeTrialPopupUnavailable } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/helpers/trial-popup/trial-popup';
import { useSubscriptionPromoTrial } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/hooks/use-subscription-promo-trial/use-subscription-promo-trial';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';
import { getState } from '@/store/store-helper';

import { messages } from './i18n';

Swiper.use([EffectFade]);

defineProps({
  cardDefault: {
    type: Object as PropType<PaymentStateCard | undefined>,
    default: undefined,
  },
});

const emit = defineEmits(['next', 'close']);
const isLoading = ref(true);
let swiper: Swiper;
const { t } = useI18n({ messages });

const isFromLanding = computed((): boolean => {
  return getState().common.index.isFromLanding;
});

function nextSlide() {
  swiper?.slideNext();
}

const { profile, price, isCloseVisible, close, next, handleDataLoading, nextPackagePrice } =
  useSubscriptionPromoTrial(emit);

handleDataLoading().then(() => {
  isLoading.value = false;
  nextTick(() => {
    swiper = new Swiper(`.swiper-container`, {
      loop: false,
      initialSlide: 0,
      effect: 'fade',
    });
  });
});

setWelcomeTrialPopupUnavailable();
</script>
