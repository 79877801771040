import { ActivityItem, ActivityState } from './activity-state';

type MutationActivityIncomingSetDialogIdPayload = {
  userId: string;
  dialogId: string;
};

declare module '@/store/store-helper' {
  interface Mutation {
    mutationActivityAddIncoming: ActivityItem[];
    mutationActivityAddOutgoing: ActivityItem[];
    mutationActivityClearIncoming: never;
    mutationActivityClearOutgoing: never;
    mutationActivityLike: string;
    mutationActivityIncomingSetDialogId: MutationActivityIncomingSetDialogIdPayload;
    mutationActivitySetHasUnread: boolean;
    mutationActivityMakeIncomingUniqByUserId: never;
  }
}

export function mutationActivityAddIncoming(state: ActivityState, incoming: ActivityItem[]) {
  state.incoming.push(...incoming);
}

export function mutationActivityAddOutgoing(state: ActivityState, outgoing: ActivityItem[]) {
  state.outgoing.push(...outgoing);
}

export function mutationActivityClearIncoming(state: ActivityState) {
  state.incoming = [];
}

export function mutationActivityClearOutgoing(state: ActivityState) {
  state.outgoing = [];
}

export function mutationActivityLike(state: ActivityState, userId: string) {
  function activityLike(activities: ActivityItem[]) {
    activities
      .filter((activity) => activity.userId === userId)
      .forEach((activity) => {
        if (activity.data.type === 'visit' || activity.data.type === 'like') {
          // eslint-disable-next-line no-param-reassign
          activity.data.isLiked = true;
        }
      });
  }

  activityLike(state.incoming);
  activityLike(state.outgoing);
}

export function mutationActivityIncomingSetDialogId(
  state: ActivityState,
  { userId, dialogId }: MutationActivityIncomingSetDialogIdPayload,
) {
  state.incoming
    .filter((activity) => activity.userId === userId)
    .forEach((activity) => {
      if (activity.data.type === 'like') {
        activity.data.dialogId = dialogId;
      }
    });
}

export function mutationActivitySetHasUnread(state: ActivityState, hasUnreadActivities: boolean) {
  state.hasUnreadActivities = hasUnreadActivities;
}

export function mutationActivityMakeIncomingUniqByUserId(state: ActivityState) {
  const userIdList = new Set<string>();

  state.incoming = state.incoming.filter((activity) => {
    if (userIdList.has(activity.userId)) {
      return false;
    }

    userIdList.add(activity.userId);
    return true;
  });
}
