import { GetShortProfileResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';
import {
  ProfileBodyType,
  ProfileChildren,
  ProfileDrinking,
  ProfileEducation,
  ProfileEthnicity,
  ProfileEyeColor,
  ProfileGender,
  ProfileHairColor,
  ProfileLocation,
  ProfileLookingFor,
  ProfileMaritalStatus,
  ProfilePhoto,
  ProfileReligion,
  ProfileSexualOrientation,
  ProfileSmoking,
  ProfileSphereOfWork,
  ProfileSport,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { ExtractClassFields } from '@/type';

export type MyProfileStateShort = Omit<
  ExtractClassFields<GetShortProfileResponse>,
  'boosterEndsDuration' | 'boosterEndsTime' | 'avatar'
> & {
  boosterEndsDurationMs: number;
  boosterEndsTimeMs: number;
  avatar: ExtractClassFields<ProfilePhoto>;
};

export type MyProfileStateFull = {
  id: string;
  gender: ProfileGender;
  about: string;
  sphereOfWork: ProfileSphereOfWork;
  position: string;
  interests: string[];
  birthPlace: string;
  education: ProfileEducation;
  religion: ProfileReligion;
  sexualOrientation: ProfileSexualOrientation;
  maritalStatus: ProfileMaritalStatus;
  ethnicity: ProfileEthnicity;
  heightValue: number;
  bodyType: ProfileBodyType;
  hairColor: ProfileHairColor;
  children: ProfileChildren;
  drinking: ProfileDrinking;
  smoking: ProfileSmoking;
  location: ExtractClassFields<ProfileLocation>;
  isLocationUpdateAllowed: boolean;
  isLocationDetectionAllowed: boolean;
  name: string;
  age: number;
  matchGender: ProfileGender;
  lookingFor: ProfileLookingFor[];
  photos: ExtractClassFields<ProfilePhoto>[];
  eyeColor: ProfileEyeColor;
  sport: ProfileSport;
  educationalInstitution: string;
  zodiac: string;
  matchLocation: ExtractClassFields<ProfileLocation>;
  timeCreated: number;
};

export type MyProfileState = {
  short: MyProfileStateShort;
  full?: MyProfileStateFull;
};

export const state: MyProfileState = {
  short: {
    id: '',
    avatar: {
      id: '',
      isDummy: false,
      hasBlur: false,
      isDeclined: false,
      declineReason: '',
      tags: [],
    },
    name: '',
    age: 0,
    creditsCount: 0n,
    boosterEndsDurationMs: 0,
    boosterEndsTimeMs: 0,
    zodiac: '',
    topProfileMode: [],
  },
  full: undefined,
};
