import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import { useModal } from '@/services/modal/modal';

const spinnerModal = useModal(() => ({
  component: ModalSpinner,
}));

export function showModalSpinner() {
  spinnerModal.showModal();
}

export function hideModalSpinner() {
  spinnerModal.hideModal();
}
