import { GetEngagementProfilesResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/connection/v1/connection_api_pb';

import { SignupStateEngagementProfile } from '@/pages/signup/store/signup-state';

export function getEngagementProfilesFrom(
  response: GetEngagementProfilesResponse,
): SignupStateEngagementProfile[] {
  const profiles: SignupStateEngagementProfile[] = [];

  response.profiles.forEach((profile) => {
    const avatar = profile.avatar;
    profiles.push({
      avatar: {
        id: avatar?.id ?? '',
        hasBlur: avatar?.hasBlur ?? false,
        declineReason: avatar?.declineReason ?? '',
        isDeclined: avatar?.isDeclined ?? false,
        isDummy: avatar?.isDummy ?? false,
        tags: avatar?.tags ?? [],
      },
      userId: profile.userId,
      isLiked: true,
    });
  });

  return profiles;
}
