let registration: ServiceWorkerRegistration | null = null;

export async function initServiceWorker(): Promise<ServiceWorkerRegistration | null> {
  if (registration === null) {
    if ('serviceWorker' in navigator) {
      try {
        registration = await navigator.serviceWorker.register('/sw.js');
      } catch (err) {
        console.log('ServiceWorker error:', err);
      }
    }
  }
  return registration;
}
