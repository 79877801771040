<template>
  <NotificationActivity :category="'like'" @action="goPage">
    <template #icon>
      <NotificationActivityIcon :name="'like_solid'" />
    </template>
    <template #avatar>
      <NotificationActivityAvatar :is-locked="true" :form="'square'" :photo-id="info.avatar.id" />
    </template>
    <template #header>{{ t('newLike', { name: info.name }) }}</template>
    <template #description>
      {{ t('youHaveSomeThings') }}
    </template>
    <template #action>{{ t('meet', { name: info.name }) }}</template>
  </NotificationActivity>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';

import NotificationActivityAvatar from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-avatar/NotificationActivityAvatar.vue';
import NotificationActivityIcon from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-icon/NotificationActivityIcon.vue';
import NotificationActivity from '@/components/notification/components/notification-redesign/notification-activity/NotificationActivity.vue';
import { NotificationDataLike } from '@/components/notification/helpers/notification-shower/notification-shower';
import { hasPremium } from '@/helpers/feature/feature';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { hideAllModals } from '@/services/modal/modal';

import { messages } from './i18n';

const props = defineProps({
  info: {
    type: Object as PropType<NotificationDataLike>,
    required: true,
  },
});

const { t } = useI18n({ messages });

const isWhoLikedYouAllowed = computed(() => {
  return hasPremium();
});

function goPage() {
  if (isWhoLikedYouAllowed.value) {
    pushRoute('profileView', { userId: props.info.userId });
  } else {
    pushRoute('activity', {
      source: 'incoming',
    });
    customEventTrigger({
      event: 'activity_notification_like_click',
      user_id: props.info.userId,
      page_type: window.location.pathname,
    });
  }
  hideAllModals();
  customEventTrigger({
    event: 'notification_like_click',
  });
}
</script>
