import { createPromiseClient } from '@connectrpc/connect';
import { CommandsAPIService } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/commands_api_connect';
import {
  DeleteMessagesCommandRequest,
  DeleteMessagesCommandResponse,
  MakeDialogReadCommandRequest,
  MakeDialogReadCommandResponse,
  RemoveDialogsCommandRequest,
  RemoveDialogsCommandResponse,
  SendMessageCommandRequest,
  SendMessageCommandResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/commands_api_pb';
import {
  DeleteMessagesCommand,
  MakeDialogReadCommand,
  RemoveDialogsCommand,
  SendMessageCommand,
  SendMessageCommand_Gift,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/commands_pb';
import { ListPaginationRequest } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/pagination_pb';
import { ReadAPIService } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_connect';
import {
  GetDialogRequest,
  GetDialogResponse,
  ListDialogMessagesRequest,
  ListDialogMessagesResponse,
  ListDialogsRequest,
  ListDialogsResponse,
  ListMatchesRequest,
  ListMatchesResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/read_api_pb';
import { ChatAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/chat_api_connect';
import {
  GetGiftsRequest,
  GetGiftsResponse,
  ListIceBreakersRequest,
  ListIceBreakersResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/chat_api_pb';

import { getUserId } from '@/helpers/auth/auth';
import { ChatStateDialogMessageContent } from '@/pages/chat/store/chat-state';
import { getGrpcWebTransport } from '@/services/api/grpc-api';

export type GetDialogMessagesPayload = {
  userId: string;
  chatId: string;
  messagesFrom: string;
  limit: number;
};

export type GetDialogsPayload = {
  userId: string;
  dialogsFrom: string;
  limit: number;
  onlyActual: boolean;
};

export type GetMatchesPayload = {
  matchesFrom: string;
  limit: number;
};

const commandsServiceClient = createPromiseClient(CommandsAPIService, getGrpcWebTransport());
const readServiceClient = createPromiseClient(ReadAPIService, getGrpcWebTransport());
const chatClient = createPromiseClient(ChatAPI, getGrpcWebTransport());

export function ListMatches({
  matchesFrom,
  limit,
}: GetMatchesPayload): Promise<ListMatchesResponse> {
  const pagination = new ListPaginationRequest({
    from: matchesFrom,
    limit,
  });

  const request = new ListMatchesRequest({
    pagination: pagination,
    userId: getUserId(),
  });

  return readServiceClient.listMatches(request);
}

export function listDialogs({
  userId,
  dialogsFrom,
  limit,
  onlyActual,
}: GetDialogsPayload): Promise<ListDialogsResponse> {
  const pagination = new ListPaginationRequest({
    from: dialogsFrom,
    limit,
  });

  const request = new ListDialogsRequest({
    pagination,
    userId,
    onlyActual,
  });

  return readServiceClient.listDialogs(request);
}

export function getDialog({
  chatId,
  userId,
}: {
  chatId: string;
  userId: string;
}): Promise<GetDialogResponse> {
  const request = new GetDialogRequest({
    connectionId: chatId,
    userId,
  });

  return readServiceClient.getDialog(request);
}

export function listDialogMessages({
  userId,
  chatId,
  messagesFrom,
  limit = 10,
}: GetDialogMessagesPayload): Promise<ListDialogMessagesResponse> {
  const pagination = new ListPaginationRequest({
    from: messagesFrom,
    limit,
  });

  const request = new ListDialogMessagesRequest({
    pagination,
    connectionId: chatId,
    userId,
  });

  return readServiceClient.listDialogMessages(request);
}

export function getGifts(): Promise<GetGiftsResponse> {
  const request = new GetGiftsRequest();

  return chatClient.getGifts(request);
}

export function removeDialogs(chatIds: string[]): Promise<RemoveDialogsCommandResponse> {
  const command = new RemoveDialogsCommand({
    userId: getUserId(),
    connectionIds: chatIds,
  });

  const request = new RemoveDialogsCommandRequest({
    command,
  });

  return commandsServiceClient.removeDialogsCommand(request);
}

export function makeDialogRead(
  dialogId: string,
  userId: string,
): Promise<MakeDialogReadCommandResponse> {
  const command = new MakeDialogReadCommand({
    connectionId: dialogId,
    userId,
  });

  const request = new MakeDialogReadCommandRequest({
    command,
  });

  return commandsServiceClient.makeDialogReadCommand(request);
}

export function deleteMessages(
  chatId: string,
  messageIds: string[],
): Promise<DeleteMessagesCommandResponse> {
  const command = new DeleteMessagesCommand({
    connectionId: chatId,
    messageIds,
    userId: getUserId(),
  });

  const request = new DeleteMessagesCommandRequest({
    command,
  });

  return commandsServiceClient.deleteMessagesCommand(request);
}

export function sendMessage({
  chatId,
  message,
  messageId,
  userId,
}: {
  chatId: string;
  message: ChatStateDialogMessageContent;
  messageId: string;
  userId: string;
}): Promise<SendMessageCommandResponse> {
  const command = new SendMessageCommand({
    connectionId: chatId,
    userId,
  });

  switch (message.type) {
    case 'text':
      // command.setText(message.text);
      command.content.case = 'text';
      command.content.value = message.text;
      break;
    case 'image':
      command.content.case = 'photoId';
      command.content.value = message.imageId;
      break;
    case 'gift':
      {
        const gift = new SendMessageCommand_Gift({
          giftId: message.giftId,
          text: message.giftText,
        });
        command.content.case = 'gift';
        command.content.value = gift;
      }
      break;
    default:
      throw new Error("Message can't be empty.");
  }

  command.messageId = messageId;

  const request = new SendMessageCommandRequest({
    command,
  });

  return commandsServiceClient.sendMessageCommand(request);
}

export function listIceBreakers(userId: string): Promise<ListIceBreakersResponse> {
  const request = new ListIceBreakersRequest({
    userId,
  });

  return chatClient.listIceBreakers(request);
}
