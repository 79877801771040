<template>
  <FontIcon :class="iconClass">
    <slot></slot>
  </FontIcon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { getStore } from '@/store/store-helper';

import FontIcon from './FontIcon.vue';

const props = defineProps({
  className: {
    type: String,
    default: 'icon-like_solid',
  },
  likedClassName: {
    type: String,
    default: '',
  },
  isLiked: {
    type: Boolean,
    default: false,
  },
});

const isFromLanding = computed((): boolean => {
  return getStore().state.common.index.isFromLanding;
});

const iconClass = computed((): string => {
  if (isFromLanding.value) {
    return props.isLiked ? props.likedClassName : props.className;
  } else {
    return props.isLiked ? 'icon-check_mark_solid' : 'icon-mma';
  }
});
</script>

<style lang="scss" scoped src="./style.scss"></style>
