<template>
  <NotificationText :content="content" :icon="icon" @close="close" />
</template>

<script lang="ts" setup>
import NotificationText from '@/components/notification/components/notification-redesign/notification-text/NotificationText.vue';

defineProps({
  content: {
    type: String,
    required: true,
  },
  icon: {
    type: [Array, String],
    required: true,
  },
});

const emit = defineEmits(['close']);

function close() {
  emit('close');
}
</script>
