<template>
  <ModalPortal
    :is-teleport-enabled="isTeleportEnabled"
    :background-scroll="backgroundScroll"
    @click="close"
  >
    <div class="extra_content modal-backdrop active" :class="className">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" :data-test-id="dataTestId" @click.stop>
          <div class="modal-body text-center">
            <slot name="image"></slot>

            <div class="extra_content-title">
              <slot name="title"></slot>
            </div>
            <div class="extra_content-text">
              <slot name="text"></slot>
            </div>
            <slot name="body"></slot>
          </div>
          <div class="modal-footer" :class="footerClassName">
            <slot></slot>
          </div>
        </div>
        <slot name="after-footer"></slot>
      </div>
    </div>
  </ModalPortal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import ModalPortal from '@/components/modal/ModalPortal.vue';

const props = defineProps({
  isTeleportEnabled: {
    type: Boolean,
    default: false,
  },
  backgroundScroll: {
    type: Boolean,
    default: false,
  },
  canBeClosed: {
    type: Boolean,
    default: true,
  },
  className: {
    type: String,
    default: '',
  },
  footerClassName: {
    type: String,
    default: '',
  },
  dataTestId: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);

const openTime = ref(+new Date());

function close() {
  if (!props.canBeClosed) {
    return;
  }

  // When we release a finger after hold, it can be considered as a click on overlay,
  // so, menu could be immediately closed. That's why we need this delay
  // before we can start emitting `close` events.
  const delay = 1000;

  if (+new Date() - openTime.value > delay) {
    emit('close');
  }
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
