import { NotificationHandler } from '@/services/websocket';
import { commit, dispatch } from '@/store/store-helper';

export const profileNotificationHandler: NotificationHandler = async (notification) => {
  if (notification.notification.case === 'requestPhotoRequested') {
    const data = notification.notification.value;

    commit('mutationProfileSetIsPhotoRequested', {
      userId: data.subjectId,
      state: true,
    });
  }

  if (notification.notification.case === 'compatibilitySuccess') {
    const id = notification.notification.value.userId;

    dispatch('actionCompatibilityGetStatus', id ?? '');
  }

  if (notification.notification.case === 'sexualCompatibilitySuccess') {
    const id = notification.notification.value.userId;

    dispatch('actionSexualCompatibilityGetStatus', id ?? '');
  }

  if (notification.notification.case === 'blockedByUser') {
    const blockedByUser = notification.notification.value;

    if (blockedByUser) {
      const userId = blockedByUser.userId;

      commit('mutationProfileBlockedByUser', { userId, isBlocked: true });
    }
  }
};
