import { ExternalUserInfoProvider } from '@/services/api/my-profile/helpers/type/type';

export type SigninStateExternalCredential = {
  type: 'external';
  provider: ExternalUserInfoProvider;
  sessionId: string;
};

export type SigninStateEmailCredential = {
  type: 'email';
  email: string;
  password: string;
};

export type SigninStateAutologinCredential = {
  type: 'autologin';
  hash: string;
};

export type SigninStateRefreshTokenCredential = {
  type: 'refreshToken';
  refreshToken: string;
};

export type SigninStateCredential = (
  | SigninStateExternalCredential
  | SigninStateEmailCredential
  | SigninStateAutologinCredential
  | SigninStateRefreshTokenCredential
) & {
  landingUrl?: string;
};

export type SigninState = Record<string, unknown>;

export const state: SigninState = {};
