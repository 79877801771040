import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { computed } from 'vue';

import { isAllowedCreditsAction } from '@/components/payment/helpers/payment/payment';
import { hasPremium } from '@/helpers/feature/feature';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { ChatStateDialogMessageContent } from '@/pages/chat/store/chat-state';
import { resolveCreditsLastAction } from '@/pages/payment/helpers/credits-last-action/credits-last-action';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { commit, dispatch, getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export function useChatFooter(action?: () => void) {
  const freeGiftsCount = computed<number>(() => getStore().state.payment.freeGiftsCount ?? 0);
  const areFreeGiftsAvailable = computed(() => freeGiftsCount.value > 0);
  const runAction = () => {
    if (action) {
      action();
    }
  };

  function isFreeGift(gift: ExtractClassFields<DialogGift>): boolean {
    return (gift.isFree && !gift.availableInDuration?.seconds) || freeGiftsCount.value > 0;
  }

  async function sendGiftMessage(
    {
      gifts,
      giftId,
      dialogId,
    }: {
      gifts: ExtractClassFields<DialogGift>[];
      giftId: string;
      dialogId: string;
    },
    userId: string,
  ): Promise<void> {
    const selected = gifts.find((item: ExtractClassFields<DialogGift>) => item.id === giftId);
    resolveCreditsLastAction('chatRequest', userId);

    if (selected) {
      const { priceCredits } = selected;

      const send = async () => {
        await sendMessage({
          content: {
            type: 'gift',
            giftId,
            giftPhotoId: selected.photoId,
            giftText: '',
          },
          dialogId,
        });

        commit('mutationPaymentSetFreeGiftsCount', freeGiftsCount.value - 1);
        customEventTrigger({
          event: 'chat-send-gift-message',
        });
      };

      if (selected.isFree) {
        if (hasPremium()) {
          return send();
        } else if (!selected.availableInDuration) {
          // 24 hours in seconds
          const seconds = 86_400n;

          selected.availableInDuration = {
            seconds,
            nanos: 0,
          };

          return send();
        }
      }

      if (await isAllowedCreditsAction(priceCredits)) {
        send();
      }
    }
  }

  async function sendTextMessage({
    messageText,
    dialogId,
  }: {
    messageText: string;
    dialogId: string;
  }): Promise<void> {
    commit('mutationChatAddDraftsMessage', {
      dialogId,
      message: messageText,
    });

    await sendMessage({
      content: {
        type: 'text',
        text: messageText,
      },
      dialogId,
    });

    customEventTrigger({
      event: 'chat-send-text-message',
    });
  }

  async function sendMessage({
    content,
    dialogId,
  }: {
    content: ChatStateDialogMessageContent;
    dialogId: string;
  }): Promise<void> {
    try {
      runAction();
      await dispatch('actionChatSendMessage', {
        chatId: dialogId,
        content,
      });
    } catch (error) {
      sentryCaptureMessage({
        message: `Failed to send ${content.type} message`,
        captureContext: { extra: { error } },
      });
    }

    commit('mutationChatDecrementFreeMessagesCount', dialogId);
  }

  return {
    sendGiftMessage,
    sendTextMessage,
    sendMessage,
    freeGiftsCount,
    areFreeGiftsAvailable,
    isFreeGift,
  };
}
