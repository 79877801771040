import WebPushModal from '@/components/web-push-modal/WebPushModal.vue';
import { getCache, setCache } from '@/helpers/persistent-cache';
import { isWebPushAvailableOnDevice } from '@/services/firebase/firebase';
import { useModal } from '@/services/modal/modal';

const WEB_PUSH_MODAL_COOLDOWN_MS = 3600 * 1000;
const WEB_PUSH_MODAL_CACHE_KEY = 'wpm-last-shown-ms';

type ShowWebPushModalArg = {
  userName: string;
  userAvatarId: string;
};
export function showWebPushModal(arg: ShowWebPushModalArg) {
  const lastShownMs = +(getCache(WEB_PUSH_MODAL_CACHE_KEY) ?? 0);
  const nowMs = +new Date();
  const isTimeAllowed = nowMs - lastShownMs > WEB_PUSH_MODAL_COOLDOWN_MS;
  const isEnvAllowed = isWebPushAvailableOnDevice() && Notification.permission === 'default';

  if (isTimeAllowed && isEnvAllowed) {
    setCache(WEB_PUSH_MODAL_CACHE_KEY, nowMs.toString());
    useModal(() => {
      return {
        component: WebPushModal,
        props: {
          userName: arg.userName,
          userAvatarId: arg.userAvatarId,
        },
      };
    }).showModal();
  }
}
