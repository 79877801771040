import { ConnectError, Interceptor } from '@connectrpc/connect';

type SendDevtoolsGrpcMessageArg = {
  method: string;
  request?: any;
  response?: any;
  error?: any;
};
export function sendDevtoolsGrpcMessage({
  method,
  request,
  response,
  error,
}: SendDevtoolsGrpcMessageArg) {
  window.postMessage(
    {
      type: '__GRPCWEB_DEVTOOLS__',
      method,
      methodType: 'unary',
      request:
        request &&
        JSON.parse(
          JSON.stringify(request, (key, value) =>
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/BigInt_not_serializable
            typeof value === 'bigint' ? Number(value) : value,
          ),
        ),
      response:
        response &&
        JSON.parse(
          JSON.stringify(response, (key, value) =>
            typeof value === 'bigint' ? Number(value) : value,
          ),
        ),
      error:
        error &&
        JSON.parse(
          JSON.stringify(error, (key, value) =>
            typeof value === 'bigint' ? Number(value) : value,
          ),
        ),
    },
    '*',
  );
}

export const handleInterceptorDevtools: Interceptor = (next) => async (req) => {
  try {
    const res = await next(req);
    sendDevtoolsGrpcMessage({
      method: req.url,
      request: req.message,
      response: res.message,
    });

    return res;
  } catch (e) {
    const connectErr = ConnectError.from(e);
    try {
      console.warn(
        'GRPC_ERROR',
        req.url,
        // @ts-expect-error
        req.message?.toJson?.(),
        connectErr.code,
        connectErr.message,
      );
    } catch (e) {
      //
    }

    sendDevtoolsGrpcMessage({
      method: req.url,
      request: req.message,
      error: connectErr,
    });

    throw e;
  }
};
