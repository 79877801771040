import { SurveyType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/client_events_pb';
import { ActionContext } from 'vuex';

import { getUserId } from '@/helpers/auth/auth';
import {
  BLOCKED_USERS_LIMIT,
  getBlockedUsersFrom,
  getLastBlockedUserPosition,
} from '@/pages/settings/store/helpers/settings-helper';
import {
  SettingsStateAccount,
  SettingsState,
  SettingsBlockedUser,
} from '@/pages/settings/store/settings-state';
import { listBlockedUsers } from '@/services/api/connection/connection-api';
import {
  deleteAccount,
  getAccount,
  signOut,
  submitOffboardingSurvey,
  SubmitOffboardingSurveyReason,
} from '@/services/api/my-profile/my-profile-api';
import { commit, ActionFunction } from '@/store/store-helper';

export type ActionSettingsSubmitOffboardingSurveyPayload = {
  reason: SubmitOffboardingSurveyReason;
  type: SurveyType;
  reasonText: string;
};

declare module '@/store/store-helper' {
  interface Action {
    actionSettingsGetAccount: ActionFunction<never, SettingsStateAccount>;
    actionSettingsSignOut: ActionFunction<never, void>;
    actionSettingsDeleteAccount: ActionFunction<never, void>;
    actionSettingsGetBlockedUsers: ActionFunction<never, SettingsBlockedUser[]>;
    actionSettingsSubmitOffboardingSurvey: ActionFunction<
      ActionSettingsSubmitOffboardingSurveyPayload,
      void
    >;
  }
}

export async function actionSettingsGetAccount(): Promise<SettingsStateAccount> {
  const response = await getAccount();
  commit('mutationSettingsSetAccount', response);

  return response;
}

export async function actionSettingsSignOut(): Promise<void> {
  await signOut();
}

export async function actionSettingsDeleteAccount(): Promise<void> {
  await deleteAccount();
}

export async function actionSettingsGetBlockedUsers({
  state,
}: ActionContext<SettingsState, unknown>): Promise<SettingsBlockedUser[]> {
  const lastItemPosition = getLastBlockedUserPosition(state.blockedUsers);
  const response = await listBlockedUsers({
    usersFrom: lastItemPosition,
    limit: BLOCKED_USERS_LIMIT,
    userId: getUserId(),
  });
  const users = getBlockedUsersFrom(response);
  commit('mutationSettingsAddBlockedUsers', users);

  return users;
}

export async function actionSettingsSubmitOffboardingSurvey(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SettingsState, unknown>,
  { reason, type, reasonText }: ActionSettingsSubmitOffboardingSurveyPayload,
): Promise<void> {
  await submitOffboardingSurvey({
    response: reason,
    responseDescription: reasonText,
    type,
  });
}
