<template>
  <!--  This wrapper `<div>` needs to remove this warning:-->
  <!--  [Vue warn]: Extraneous non-props attributes (name) were passed to component but could not be-->
  <!--  automatically inherited because component renders fragment or text root nodes.-->
  <div>
    <teleport v-if="isTeleportEnabled" to="body">
      <div
        :class="className"
        class="modal-wrapper modal-backdrop h-full spinner_wrapper"
        @click="click"
      >
        <slot></slot>
      </div>
    </teleport>
    <div
      v-else
      :class="className"
      class="modal-wrapper modal-backdrop h-full spinner_wrapper"
      @click="click"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted } from 'vue';

import { disableBodyScroll, enableBodyScroll } from '@/helpers/scroll/scroll';

let openedModals = 0;

const props = defineProps({
  isTeleportEnabled: {
    type: Boolean,
    default: true,
  },
  className: {
    type: String,
    default: '',
  },
  backgroundScroll: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['click']);

function click() {
  emit('click');
}

onUnmounted(() => {
  if (props.isTeleportEnabled) {
    openedModals -= 1;
    // Restore body scrolling when all modals are closed.
    if (openedModals === 0) {
      enableBodyScroll();
    }
  } else {
    enableBodyScroll();
  }
});

if (props.isTeleportEnabled) {
  openedModals += 1;
}

if (!props.backgroundScroll) {
  // Disable body scrolling when modal is opened.
  disableBodyScroll();
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
