import {
  RetentionActionResultEvent,
  RetentionRewardReceivedEvent_RewardType,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/events/v1/events_pb';
import { Notification } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';

import { showNotification } from '@/components/notification/helpers/notification-shower/notification-shower';
import {
  possibilityNotificationIcon,
  possibilityNotificationText,
} from '@/components/notification/helpers/notification-websocket/helpers/possibility-data-helper/possibility-data-helper';
import { retentionService } from '@/pages/retention/retention-service/retention-service';
import { useI18n } from '@/services/i18n';
import { NotificationHandler } from '@/services/websocket';
import { commit, dispatch } from '@/store/store-helper';

import { messages } from './i18n';

const { t } = useI18n({ messages });

function resolveRewardIcon(type: RetentionRewardReceivedEvent_RewardType) {
  switch (type) {
    case RetentionRewardReceivedEvent_RewardType.ASTROLOGICAL_COMPATIBILITY:
      return 'stars';
    case RetentionRewardReceivedEvent_RewardType.CREDITS:
      return 'mma';
    case RetentionRewardReceivedEvent_RewardType.POWER_LIKE:
      return 'power_like_solid';
    case RetentionRewardReceivedEvent_RewardType.SEXUAL_COMPATIBILITY:
      return 'sexual';
    case RetentionRewardReceivedEvent_RewardType.DAILY_FREE_GIFT:
    case RetentionRewardReceivedEvent_RewardType.FREE_GIFT:
      return 'gift_solid';
    case RetentionRewardReceivedEvent_RewardType.TOP_PROFILE:
    default:
      return 'top_profile';
  }
}

export const retentionNotificationHandler: NotificationHandler = (notification: Notification) => {
  if (notification.notification.case === 'retentionActionResult') {
    const result = notification.notification.value;

    retentionService(result as RetentionActionResultEvent);
  }

  if (notification.notification.case === 'possibilitiesUpdated') {
    const result = notification.notification.value;
    const possibilities = result.possibilities;

    if (possibilities?.length) {
      possibilities.forEach((item) => {
        commit('mutationPossibilitySetPossibilityCount', item);
      });
    }
  }

  if (notification.notification.case === 'possibilitiesPurchasedSuccessfully') {
    dispatch('actionCommonGetFreeCreditsFeaturesStatus');

    const result = notification.notification.value;
    const possibilities = result.possibilities;

    if (possibilities?.length) {
      possibilities.forEach((item) => {
        showNotification(
          {
            type: 'text',
            text: possibilityNotificationText(item.type),
            icon: possibilityNotificationIcon(item.type),
          },
          { force: true },
        );
      });
    }
  }

  if (notification.notification.case === 'retentionRewardReceivedEvent') {
    const result = notification.notification.value;
    const rewards = result.rewards;

    if (result && rewards?.length) {
      const icon = rewards?.map((r) => resolveRewardIcon(r.type));

      showNotification({
        type: 'reward',
        text: t('rewardClaimed'),
        icon: icon ?? 'mma',
      });
    }
  }
};
