<template>
  <div :class="['notification-common', `notification-common_${icons[0]}`]">
    <SvgIcon v-if="isSvg" :name="icons[0]" :width="24" :height="24" />
    <template v-else>
      <FontIcon v-for="ico in icons" :key="ico" :class="`icon-${ico}`" />
    </template>
    <div class="notification-common__content">{{ content }}</div>
    <NotificationClose class="notification-common__close" @click="close" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import { IconType } from '@/components/inputs/icon/svg-icon/types';
import NotificationClose from '@/components/notification/components/notification-redesign/notification-close/NotificationClose.vue';
const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  icon: {
    type: [Array, String],
    required: true,
  },
  isSvg: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close']);

const icons = computed<IconType[]>(() => {
  if (typeof props.icon === 'object') {
    return props.icon as IconType[];
  } else {
    return [props.icon as IconType];
  }
});
function close(): void {
  emit('close');
}
</script>
