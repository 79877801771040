import { getCache, setCache } from '@/helpers/persistent-cache';

const REREG_KEY = 'rr';

export function setRereg(userId: string): void {
  setCache(REREG_KEY, userId);
}

export function getRereg(): string | null {
  return getCache(REREG_KEY);
}
