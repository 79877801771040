import { computed } from 'vue';

import { UseSnackButton } from '@/components/snack-button/hooks/use-snack-button/type/type';
import { useUnusedCreditsModal } from '@/components/unused-credits-email-modal/hooks/use-unused-credits-modal/use-unused-credits-modal';
import { setCache } from '@/helpers/persistent-cache';

const CACHE_KEY = 'unused_credits_button_hidden';

export function useSnackButtonCredits(emit: (prop: 'hide', arg: boolean) => void): UseSnackButton {
  const action = (): void => {
    showModal();
  };

  const close = (): void => {
    hidePromo();
    emit('hide', true);
    setCache(CACHE_KEY, '1');
  };

  const isVisible = computed<boolean>((): boolean => {
    return isPromoButtonVisible.value;
  });

  const { showModal, isPromoButtonVisible, hidePromo } = useUnusedCreditsModal();

  return {
    action,
    isVisible,
    close,
  };
}
