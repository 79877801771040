<template>
  <FadeTransition>
    <div v-if="isVisible" class="relative mb-4">
      <button
        :class="`snack_btn btn-block ${buttonStyle}`"
        type="button"
        data-id="webview-bar"
        @click="action"
      >
        <div class="snack_btn-icon-wrapper">
          <slot name="icon">
            <div class="snack_btn-img">
              <i :class="`icon icon-${props.iconName}`"></i>
            </div>
          </slot>
        </div>
        <div class="snack_btn_click">
          <slot></slot>
        </div>
        <slot name="button-right">
          <div class="snack_btn-close" @click.stop="close">
            <i :class="`icon icon-${props.iconAction}`"></i>
          </div>
        </slot>
      </button>
    </div>
  </FadeTransition>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import { UseSnackType } from '@/components/snack-button/hooks/use-snack-button/type/type';
import { useSnackButton } from '@/components/snack-button/hooks/use-snack-button/use-snack-button';
import FadeTransition from '@/components/transition/FadeTransition.vue';

type ButtonStyle =
  | 'alert'
  | 'premium'
  | 'premium-gold'
  | 'date'
  | 'warning'
  | 'moderation'
  | 'age-verification'
  | 'web-view'
  | 'info';
type IconAction = 'close' | 'plus' | 'add_circle';

const props = defineProps({
  iconName: {
    type: String,
    default: 'bell',
  },
  iconAction: {
    type: String as PropType<IconAction>,
    default: 'close',
  },
  buttonType: {
    type: String as PropType<UseSnackType>,
    default: 'premium',
  },
  buttonStyle: {
    type: String as PropType<ButtonStyle>,
    default: 'alert',
  },
});

const emit = defineEmits(['hide']);

const { action, close, isVisible } = useSnackButton(props.buttonType, emit);
</script>

<style lang="scss" src="./style.scss"></style>
