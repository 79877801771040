<template>
  {{ t('charged') }} {{ price }}.
  <span>
    {{ t('cancel') }}
    <span v-html="btnText"></span>{{ t('agree') }} {{ label }} {{ t('period') }} {{ priceNext }}
    {{ t('every') }} {{ label }}. {{ t('write') }}
    <a class="" href="mailto:support@meetmyage.com">support@meetmyage.com</a>. {{ t('visit') }}
  </span>
  <a @click.prevent="self.membershipModal.showModal()"> {{ t('policy') }} </a>
  {{ t('details') }}
</template>

<script lang="ts" setup>
import { useSelf } from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/hooks/use-self/use-self';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  label: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  priceNext: {
    type: String,
    required: true,
  },
  btnText: {
    type: String,
    default: 'Submit',
  },
});

const { t } = useI18n({ messages });

const self = useSelf({});
</script>

<style scoped>
a {
  font-size: inherit;
}
</style>
