import { getUserId } from '@/helpers/auth/auth';
import { PossibilityPackage } from '@/pages/my-profile/components/my-profile-view/components/my-profile-boosters-grid/components/profile-card/helpers/package-id-helper/package-id-helper';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import { CreditsActionType } from '@/pages/payment/store/payment-state';
import { commit, getStore } from '@/store/store-helper';

export function resolveCreditsLastAction(
  type: CreditsActionType | PossibilityPackage,
  id = getUserId(),
): void {
  const profile =
    getStore().state.profile.profiles[id] ||
    Object.values(getStore().state.chat.dialogUsers).find((profile) => profile.id === id) ||
    getStore().state.search.profiles.find((item) => item.id === id);
  const myProfile = getStore().state.myProfile.full as MyProfileStateFull;

  const resolvedType = (): CreditsActionType => {
    switch (type as PossibilityPackage) {
      case PossibilityPackage.TOP_PROFILE_COMMON:
        return 'top';
      case PossibilityPackage.TOP_PROFILE_PLUS:
        return 'topPlus';
      case PossibilityPackage.POWER_LIKE:
        return 'chatRequest';
      case PossibilityPackage.SEXUAL_CHECK:
        return 'romantic';
      case PossibilityPackage.ASTROLOGY_CHECK:
      default:
        return 'astro';
    }
  };
  const creditsActionType = type.includes('pos') ? resolvedType() : (type as CreditsActionType);

  const data = {
    type: creditsActionType,
    profile: id === getUserId() ? myProfile : profile,
  };

  commit('mutationPaymentSetCreditsLastAction', data);
}
