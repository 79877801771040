import { Booster } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/booster_pb';
import {
  CreditsPackage,
  PremiumPackage,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import {
  ActivateSubscriptionResponse,
  GetSolidAuthorizeFormResponse,
  GetSolidPaymentFormResponse,
  GetSubscriptionStatusResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_api_pb';
import {
  PaymentCard,
  PaymentOrderStatusFail,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_pb';
import { SearchProfile } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/search_pb';

import { Modify } from '@/helpers/type/type';
import { ChatStateDialogUser } from '@/pages/chat/store/chat-state';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import { ProfileStateProfile } from '@/pages/profile/store/profile-state';
import { SearchStateResultProfile } from '@/pages/search/store/search-state';
import { ExtractClassFields } from '@/type';

export type ProfilesWithPhotos =
  | ProfileStateProfile
  | MyProfileStateFull
  | SearchStateResultProfile;
export type CreditsActionType = 'romantic' | 'astro' | 'gift' | 'chatRequest' | 'top' | 'topPlus';
export type CreditsLastAction = {
  type: CreditsActionType;
  profile: ProfilesWithPhotos | ChatStateDialogUser;
};

export type PaymentStateOffboardingProfile = ExtractClassFields<SearchProfile>;
export type PaymentStateSubscriptionState = 'active' | 'inactive' | 'suspended' | 'pending';
export type PaymentStateSubscription = Required<
  ExtractClassFields<GetSubscriptionStatusResponse>
> & {
  status: PaymentStateSubscriptionState;
};

export type PaymentActivateSubscription = ActivateSubscriptionResponse;

export type PaymentStateCardBrand =
  | 'VISA'
  | 'MASTERCARD'
  | 'DINERS CLUB'
  | 'DISCOVER'
  | 'AMEX'
  | 'MAESTRO'
  | 'PayPal';

export type PaymentStateCardSource = 'google-pay' | 'apple-pay' | 'card';

export type PaymentStateCard = Modify<
  ExtractClassFields<PaymentCard>,
  {
    brand: PaymentStateCardBrand;
    source: PaymentStateCardSource;
  }
>;

export type PaymentStatePackagePremium = ExtractClassFields<PremiumPackage>;
export type PaymentStatePackageCredits = ExtractClassFields<CreditsPackage>;

export type PaymentStatePackage = PaymentStatePackagePremium | PaymentStatePackageCredits;

export type PaymentStateSolidPaymentForm =
  | ExtractClassFields<GetSolidPaymentFormResponse>
  | ExtractClassFields<GetSolidAuthorizeFormResponse>;

export type PaymentStateSuccess = {
  descriptorText: string;
  price: number;
  currency: string;
  expiresTime?: number;
};

export type PaymentStateSubscribed = {
  nextChargeDateMs: number;
  price: number;
  currency: string;
};

export type PaymentStateFail = ExtractClassFields<PaymentOrderStatusFail>;

export type PaymentStateBillingHistoryItemType =
  | 'premium'
  | 'add_credits'
  | 'spend_credits'
  | 'premium_refund';

export type PaymentStateBillingHistoryItem = {
  type: PaymentStateBillingHistoryItemType;
  title: string;
  description: string;
  createdTimeMs: number;
  billingPosition: string;
};

export type PaymentStatePaymentMethod<T = undefined> = {
  isAvailable: boolean;
  data?: T;
};

export type PaymentStateBooster = Omit<ExtractClassFields<Booster>, 'totalDuration'> & {
  totalDurationMs: number;
};

export type PaymentMethods = {
  card: PaymentStatePaymentMethod;
  solidPaypal: PaymentStatePaymentMethod;
  googlePay: PaymentStatePaymentMethod;
  applePay: PaymentStatePaymentMethod;
};

export type PaymentState = {
  premiumPackages: ExtractClassFields<PremiumPackage>[];
  creditsPackages: ExtractClassFields<CreditsPackage>[];
  promoCreditsPackages: ExtractClassFields<CreditsPackage>[];
  solidPaymentForm: PaymentStateSolidPaymentForm;
  solidAuthorizeForm: PaymentStateSolidPaymentForm;
  paymentSuccess: PaymentStateSuccess;
  paymentFail: PaymentStateFail;
  subscribed: PaymentStateSubscribed;
  billingHistory: PaymentStateBillingHistoryItem[];
  creditsCount: number;
  lastPurchasedPackage?: PaymentStatePackage;
  paymentMethods: PaymentMethods;
  isPaymentMethodsLoading: boolean;
  paymentCards: PaymentStateCard[];
  subscriptionStatus: PaymentStateSubscription;
  isRenewed: boolean;
  freeGiftsCount: number;
  creditsLastAction: CreditsLastAction | null;
};

export const state: PaymentState = {
  creditsLastAction: null,
  premiumPackages: [],
  creditsPackages: [],
  solidPaymentForm: {
    merchant: '',
    paymentIntent: '',
    signature: '',
  },
  solidAuthorizeForm: {
    merchant: '',
    paymentIntent: '',
    signature: '',
  },
  paymentSuccess: {
    descriptorText: '',
    price: 0,
    currency: '',
    expiresTime: 0,
  },
  paymentFail: {
    code: '',
    message: '',
    recommendation: '',
    declinedRetryAttempt: 0,
    isDeclineRetry: false,
  },
  subscribed: {
    nextChargeDateMs: 0,
    price: 0,
    currency: '',
  },
  billingHistory: [],
  creditsCount: 0,
  lastPurchasedPackage: undefined,
  paymentMethods: {
    card: {
      isAvailable: false,
    },
    solidPaypal: {
      isAvailable: false,
    },
    googlePay: {
      isAvailable: false,
    },
    applePay: {
      isAvailable: false,
    },
  },
  isPaymentMethodsLoading: false,
  paymentCards: [],
  subscriptionStatus: {} as PaymentStateSubscription,
  isRenewed: false,
  freeGiftsCount: 0,
  promoCreditsPackages: [],
};
