import { NotificationHandler } from '@/services/websocket';
import { commit } from '@/store/store-helper';

export const layoutNotificationHandler: NotificationHandler = (notification) => {
  if (notification.notification.case === 'likesCountViewUpdated') {
    const likesCountViewUpdated = notification.notification.value;

    if (likesCountViewUpdated) {
      const likesCount = likesCountViewUpdated.likesCount;
      commit('mutationCommonSetStateNewLikesCount', likesCount);
    }
  } else if (notification.notification.case === 'unreadMessagesCountViewUpdated') {
    const unreadMessagesCountViewUpdated = notification.notification.value;

    if (unreadMessagesCountViewUpdated) {
      const unreadMessagesCount = unreadMessagesCountViewUpdated.unreadMessagesCount;
      commit('mutationCommonSetStateNewChatMessagesCount', unreadMessagesCount);
    }
  }
};
