<template>
  <div
    :class="{ popular: pkg.isPopular, 'active-popular': isSelected }"
    class="billing_card popular discount best_deal shadow-md shadow-dark/20"
    @click="toggleSelect"
  >
    <div class="billing_card-check">
      <i class="icon icon-success"></i>
    </div>
    <div v-if="isBonus" class="best_deal_label !bg-success !px-6">{{ t('bonus') }}</div>
    <div v-else class="best_deal_label">{{ t('best') }}</div>
    <div class="billing_card-img">
      <img
        v-if="icon === 1"
        src="../../../../../../../../assets/img/payments/credits-1.png"
        width="64"
        alt=""
      />
      <img
        v-else-if="icon === 2"
        src="../../../../../../../../assets/img/payments/credits-2.png"
        width="64"
        alt=""
      />
      <img
        v-else-if="icon === 3"
        src="../../../../../../../../assets/img/payments/credits-3.png"
        width="64"
        alt=""
      />
      <img
        v-else-if="icon === 4"
        src="../../../../../../../../assets/img/payments/credits-4.png"
        width="64"
        alt=""
      />
    </div>
    <div class="billing_card-body">
      <div class="billing_card-title">
        <span class="billing_card-title__credits">{{ pkg.creditsCount }}</span>
        credits
      </div>

      <div v-if="pkg?.offer?.price?.totalAmount || isBonus" class="billing_card-price line-through">
        {{ oldPrice }}
      </div>
      <div v-if="!isBonus" class="billing_card-price-green">
        {{ packagePrice.priceFormatted }}
        <span
          v-if="isSaveVisible && packagePrice.price.taxAmount && isSelected"
          class="text-caption text-dark-200"
          >+ tax {{ packagePrice.priceTaxFormatted }}</span
        >
      </div>
      <div v-else class="billing_card-price-green !font-semibold">{{ t('free') }}</div>
    </div>

    <div
      v-if="!isSaveVisible && packagePrice.price.taxAmount && isSelected"
      class="text-caption text-dark-200 mt-2 flex-1 text-right"
    >
      + tax {{ packagePrice.priceTaxFormatted }}
    </div>

    <div v-if="isSaveVisible" class="ml-auto triangle_label bg-red">
      <div class="triangle"></div>
      <div class="triangle_label-body">
        <div class="text-save">{{ t('save') }}</div>
        <div class="text-value">{{ pkg?.offer?.discountPercent }}</div>
        <div class="text-percent">%</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CreditsPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType, reactive } from 'vue';

import { getFormattedCurrency } from '@/helpers/number';
import { useModel } from '@/hooks/use-model/use-model';
import { usePackagePrice } from '@/pages/payment/hooks/use-package-price/use-package-price';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<ExtractClassFields<CreditsPackage>>,
    required: true,
  },
  icon: {
    type: Number,
    required: true,
  },
  isBonus: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Object as PropType<ExtractClassFields<CreditsPackage>>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

// Hooks -----------------------------------------------------------------------
const { t } = useI18n({ messages });
const selected = useModel<ExtractClassFields<CreditsPackage>>(props, 'modelValue', emit);

const packagePrice = reactive(usePackagePrice(props.pkg));

// Computed --------------------------------------------------------------------
const isSaveVisible = computed(() => {
  return !!props.pkg.offer?.discountPercent && !props.isBonus;
});

const oldPrice = computed(() => {
  return getFormattedCurrency(
    props.pkg.price?.totalAmount ?? 0n,
    props.pkg.price?.currency ?? 'USD',
  );
});

const isSelected = computed(() => {
  if (selected.value?.id && selected.value?.offer) {
    return selected.value.id === props.pkg.id && !!selected.value.offer;
  }

  return false;
});

// Method ----------------------------------------------------------------------
function toggleSelect(): void {
  selected.value = props.pkg;
}

// Lifecycle -------------------------------------------------------------------
toggleSelect();
</script>

<style scoped lang="scss" src="./style.scss"></style>
