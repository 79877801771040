import {
  RetentionActionResultEvent,
  RetentionActionStatus,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/events/v1/events_pb';

import { pushRoute } from '@/router';

export function retentionService(result: RetentionActionResultEvent) {
  const status = result.status;
  let connectionId = '';
  if (result.action.case === 'connection') {
    connectionId = result.action.value.connectionId;
  }

  switch (status) {
    case RetentionActionStatus.SUCCESS:
      return retentionServiceSuccess(connectionId, status);
    case RetentionActionStatus.FAILED:
    case RetentionActionStatus.UNABLE_TO_PERFORM:
    case RetentionActionStatus.ALREADY_USED:
      return retentionServiceFailed(connectionId ?? '', status);
  }
}

function retentionServiceSuccess(id: string, status: number): void {
  pushRoute('chatView', { chatId: id, status });
}

function retentionServiceFailed(id: string, status: number): void {
  pushRoute('chatView', { chatId: id, status });
}
