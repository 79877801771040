import { RouteLocationRaw } from 'vue-router';

import { hasPremium } from '@/helpers/feature/feature';
import { RouteName } from '@/router';
import { getStore } from '@/store/store-helper';

export function getRedirectRouteFromRoot(): RouteLocationRaw {
  let route;
  const { lastVisitedRoute, hasNewActivity, newChatMessagesCount } = getStore().state.common.index;

  // If premium
  if (hasPremium()) {
    if (lastVisitedRoute === '') {
      route = { name: 'search' as RouteName, replace: true };
    } else {
      route = { path: lastVisitedRoute, replace: true };
    }
  } else {
    if (newChatMessagesCount) {
      route = { name: 'chatList' as RouteName, replace: true };
    } else if (hasNewActivity) {
      route = { name: 'activity' as RouteName, params: { source: 'incoming' }, replace: true };
    } else {
      route = { name: 'search' as RouteName, replace: true };
    }
  }

  return route;
}
