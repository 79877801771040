<template>
  <div class="relative border-accent-100 rounded-lg border-2 bg-white">
    <div class="absolute right-0 top-0 bg-accent-100 p-2 rounded-bl-lg text-white text-overline">
      {{ t('bestValue') }}
    </div>
    <div class="absolute -left-3.5 h-full flex items-center">
      <img src="./assets/img/check.svg?url" width="24" height="24" alt="" />
    </div>
    <div class="flex">
      <div class="flex-grow m-4 flex flex-col justify-center">
        <div class="text-headline7 text-dark">{{ t('unlimitedAccess') }}</div>
        <div class="mt-1">
          <span class="text-button1 text-success font-semibold">{{
            packagePrice.priceFormatted
          }}</span
          ><span
            v-if="packagePrice.price.taxAmount"
            class="text-button2 text-dark-200 font-semibold"
          >
            + {{ t('tax') }} {{ packagePrice.priceTaxFormatted }}</span
          >
        </div>
        <div class="text-caption text-dark-200">{{ t('billedMonthly') }}</div>
      </div>
      <div v-if="pkg.savingsPercent" class="flex items-end m-4 mt-12">
        <div class="triangle_label bg-gold">
          <div class="triangle"></div>
          <div class="triangle_label-body">
            <div class="text-save">Save</div>
            <div class="text-value">{{ pkg.savingsPercent }}</div>
            <div class="text-percent">%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PropType, reactive, toRef } from 'vue';

import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';

const props = defineProps({
  pkg: {
    required: true,
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
  },
});

const { t } = useI18n({ messages });

const self = useSelf({
  pkg: toRef(props, 'pkg'),
});

const packagePrice = reactive(self.packagePrice);

self.init();
</script>
