import * as amplitudeAnalytics from '@amplitude/analytics-browser';

type InitArg = {
  userId: string;
};
function init({ userId }: InitArg) {
  if (+import.meta.env.VUE_APP_IS_GTM_ENABLED) {
    amplitudeAnalytics.init('197a8e84c01b796d9626f5a9520ddfee', {
      autocapture: false,
      userId,
    });
  } else {
    console.log('Amplitude: init disabled');
  }
}

type TrackFn = typeof amplitudeAnalytics.track;
function track(...args: Parameters<TrackFn>) {
  if (+import.meta.env.VUE_APP_IS_GTM_ENABLED) {
    if (import.meta.env.VUE_APP_ENV === 'dev') {
      args[0] += '_dev';
    }
    amplitudeAnalytics.track(...args);
  } else {
    console.log('Amplitude: track', ...args);
  }
}

export const amplitude = {
  ...amplitudeAnalytics,
  init,
  track,
};
