const loadedScripts: Record<string, Promise<void>> = {};

// Load script once or return resolved promise ( cache ).
export function loadScript(src: string, attributeList?: Record<string, string>): Promise<void> {
  if (loadedScripts[src] !== undefined) {
    return loadedScripts[src];
  } else {
    const promise = new Promise<void>((resolve, reject) => {
      const script = document.createElement<'script'>('script');
      script.setAttribute('src', src);

      if (attributeList !== undefined) {
        Object.entries(attributeList).forEach(([key, value]) => {
          script.setAttribute(key, value);
        });
      }

      script.onload = () => {
        resolve();
      };

      script.onerror = (e) => {
        reject(e);
      };

      document.body.appendChild(script);
    });
    loadedScripts[src] = promise;

    return promise;
  }
}
