import * as vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { compatibility } from '@/components/astrology/store/compatibility-store';
import { sexualCompatibility } from '@/components/sexual-compatibility/store/sexual-compatibility-store';
import { CACHE_PREFIX } from '@/helpers/persistent-cache';
import { activity } from '@/pages/activity/store/activity-store';
import { chat } from '@/pages/chat/store/chat-store';
import { myProfile } from '@/pages/my-profile/store/my-profile-store';
import { payment, paymentPersisted } from '@/pages/payment/store/payment-store';
import { profile } from '@/pages/profile/store/profile-store';
import { search } from '@/pages/search/store/search-store';
import { settings } from '@/pages/settings/store/settings-store';
import { signin } from '@/pages/signin/store/signin-store';
import { signup, signupPersisted } from '@/pages/signup/store/signup-store';
import { CACHE_KEY_PERSISTED_STATE } from '@/settings';
import { possibility } from '@/store/modules/possibility/possibility-store';
import { retention } from '@/store/modules/retention/retention-store';

import { common } from './modules/common/common-store';

export const persistedState = createPersistedState({
  paths: [...signupPersisted, ...paymentPersisted],
  key: CACHE_PREFIX + CACHE_KEY_PERSISTED_STATE,
});

export function createStore() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return vuex.createStore<any>({
    modules: {
      retention,
      signup,
      signin,
      search,
      profile,
      myProfile,
      chat,
      payment,
      common,
      settings,
      activity,
      compatibility,
      sexualCompatibility,
      possibility,
    },
    plugins: [persistedState],
  });
}
