import { MirrorAbilities, MirrorConfig } from '@/helpers/mirror/type';

declare global {
  interface Window {
    _mirrorConfig: MirrorConfig;
    _mirrorAbilities: MirrorAbilities;
  }
}

type LinkParams = {
  rel: string;
  href: string;
  type?: string;
  sizes?: string;
  color?: string;
};

function createLink(params: LinkParams) {
  const link = window.document.createElement('link');
  link.setAttribute('rel', params.rel);
  link.setAttribute('href', params.href);

  if (params.type) {
    link.setAttribute('type', params.type);
  }

  if (params.sizes) {
    link.setAttribute('sizes', params.sizes);
  }

  if (params.color) {
    link.setAttribute('color', params.color);
  }

  window.document.head.append(link);
}

export function initializeMirror(config: MirrorConfig, abilities: MirrorAbilities) {
  window._mirrorConfig = config;
  window._mirrorAbilities = abilities;

  // Apply mirror theme.
  window.document.body.classList.add(config.themeClass);

  createLink({
    rel: 'icon',
    href: '/' + config.id + '/favicon.ico',
  });

  createLink({
    rel: 'apple-touch-icon',
    href: '/' + config.id + '/apple-touch-icon.png',
    sizes: '180x180',
  });

  createLink({
    rel: 'icon',
    href: '/' + config.id + '/favicon-32x32.png',
    type: 'image/png',
    sizes: '32x32',
  });

  createLink({
    rel: 'icon',
    href: '/' + config.id + '/favicon-16x16.png',
    type: 'image/png',
    sizes: '16x16',
  });

  createLink({
    rel: 'manifest',
    href: '/' + config.id + '/site.webmanifest',
  });

  createLink({
    rel: 'mask-icon',
    href: '/' + config.id + '/safari-pinned-tab.svg',
    color: '#5bbad5',
  });
}

export function hasAbility<K extends keyof MirrorAbilities>(
  key: K,
  value: MirrorAbilities[K],
): boolean {
  if (!window._mirrorAbilities) {
    throw Error('Abilities are not initialized');
  }

  const ability = window._mirrorAbilities[key];
  if (!ability) {
    return false;
  }

  return ability === value;
}

export function getConfig(): MirrorConfig {
  if (!window._mirrorConfig) {
    throw Error('Config is not initialized');
  }

  return window._mirrorConfig;
}
