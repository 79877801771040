import { computed, reactive, ref } from 'vue';

import { getGrowthBookFeatureValue, GrowthBookFeatures } from '@/services/growth-book/growth-book';

export function useSplitActivity2() {
  const variant = ref<GrowthBookFeatures['activity2']>('def');
  const isInited = ref(false);

  const isSplitActivity2 = computed(() => variant.value === 'v2' || variant.value === 'v3');

  async function init() {
    try {
      variant.value = await getGrowthBookFeatureValue('activity2', 'def');
    } finally {
      isInited.value = true;
    }
  }

  return reactive({
    isSplitActivity2,
    variant,
    isInited,
    init,
  });
}
