import { I18n } from '@/services/i18n';

type Message = {
  processing: string;
};

const messages: I18n<Message> = {
  en: {
    processing: 'Processing...',
  },
  es: {
    processing: 'Procesando...',
  },
};

export { messages };
