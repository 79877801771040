import { computed } from 'vue';

import { MatchStateNotification } from '@/hooks/use-match/use-match';
import { ChatStateDialogUser } from '@/pages/chat/store/chat-state';

export function useMatchDialogUser(match: MatchStateNotification) {
  const dialogUser = computed<ChatStateDialogUser>(() => {
    return {
      id: match.userId,
      age: match.age,
      name: match.name,
      avatarId: match.avatar?.id ?? '',
      isBlocked: false,
      isDeleted: false,
      isBlockedBySubject: false,
      isOnline: match.isOnline,
      latestOnlineTimestampMs: 0,
    };
  });

  return {
    dialogUser,
  };
}
