import {
  BlockUserCommandResponse,
  ReportUserCommandResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/commands_api_pb';
import { PowerLikeRequest_Status } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import { GetProfileRequest } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_api_pb';
import { ActionContext } from 'vuex';

import { getUserId } from '@/helpers/auth/auth';
import { incrementLikeCounter } from '@/helpers/like/like';
import { extractProfileFrom } from '@/pages/profile/store/helpers/profile-helper';
import {
  ProfileStateProfile,
  ProfileState,
  ProfileConnectionState,
} from '@/pages/profile/store/profile-state';
import {
  reportAbuse,
  AbuseUserArgument,
  blockUser,
  sendLike,
  requestUserPhoto,
  unblockUser,
  sendPowerLike,
  cancelPowerLike,
  acceptPowerLike,
  getConnectionState,
} from '@/services/api/connection/connection-api';
import { getProfile } from '@/services/api/profile/profile-api';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { commit, ActionFunction, dispatch } from '@/store/store-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionProfilePowerLikeAccept: ActionFunction<
      {
        userId: string;
        chatId: string;
      },
      void
    >;
    actionProfilePowerLikeCancel: ActionFunction<
      {
        userId: string;
        chatId: string;
      },
      void
    >;
    actionProfilePowerLike: ActionFunction<string, void>;
    actionProfileGetProfile: ActionFunction<string, ProfileStateProfile>;
    actionProfileBlock: ActionFunction<string, BlockUserCommandResponse>;
    actionProfileUnblock: ActionFunction<string, void>;
    actionProfileAbuse: ActionFunction<AbuseUserArgument, ReportUserCommandResponse>;
    actionProfileLike: ActionFunction<string, void>;
    actionProfileRequestPhoto: ActionFunction<string, void>;
    actionProfileGetConnectionState: ActionFunction<string | string[], ProfileConnectionState>;
  }
}

export async function actionProfilePowerLikeCancel(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  { userId, chatId }: { userId: string; chatId: string },
): Promise<void> {
  try {
    await cancelPowerLike({ userId: getUserId(), chatId });
    commit('mutationProfileSetPowerLikeStatus', {
      userId,
      status: PowerLikeRequest_Status.DECLINED,
    });
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to cancel Chat Request',
      captureContext: { extra: { error } },
    });
  }
}

export async function actionProfilePowerLikeAccept(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  { userId, chatId }: { userId: string; chatId: string },
): Promise<void> {
  try {
    await acceptPowerLike({ userId: getUserId(), chatId });
    commit('mutationProfileSetPowerLikeStatus', {
      userId,
      status: PowerLikeRequest_Status.ACCEPTED,
    });
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to accept Chat Request',
      captureContext: { extra: { error } },
    });
  }
}

export async function actionProfileGetProfile(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  userId: string,
): Promise<ProfileStateProfile> {
  const request = new GetProfileRequest({
    userId,
  });

  const response = await getProfile(request);
  const profile = extractProfileFrom(response);

  commit('mutationProfileSet', profile);

  return profile;
}

export async function actionProfileBlock(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  userId: string,
): Promise<BlockUserCommandResponse> {
  const result = await blockUser(userId);

  commit('mutationProfileBlock', { userId, isBlocked: true });

  return result;
}

export async function actionProfileUnblock(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  userId: string,
): Promise<void> {
  await unblockUser(userId);

  commit('mutationProfileBlock', { userId, isBlocked: false });
}

export async function actionProfileGetConnectionState(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  userId: string | string[],
): Promise<ProfileConnectionState> {
  const connections: ProfileConnectionState = {};
  const id = Array.isArray(userId) ? userId : [userId];

  const response = await getConnectionState(id);
  const itemMap = response.items;

  const itemList = Object.entries(itemMap);

  if (itemList.length) {
    itemList.forEach(([userId, item]) => {
      connections[userId] = item;
    });

    commit('mutationProfileSetConnections', connections);
  }

  return connections;
}

export async function actionProfileAbuse(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  payload: AbuseUserArgument,
): Promise<ReportUserCommandResponse> {
  const result = await reportAbuse(payload);

  commit('mutationProfileBlock', {
    userId: payload.userId,
    isBlocked: true,
  });

  return result;
}

export async function actionProfilePowerLike(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  userId: string,
): Promise<void> {
  commit('mutationProfileLike', userId);

  await sendPowerLike(userId);
  await dispatch('actionProfileGetConnectionState', userId);
}

export async function actionProfileLike(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  userId: string,
): Promise<void> {
  commit('mutationProfileLike', userId);
  commit('mutationSearchLike', userId);
  commit('mutationActivityLike', userId);
  commit('mutationCommonDecreaseRemainingLikesCount');

  incrementLikeCounter();

  await sendLike(userId);

  commit('mutationProfileChangeConnectionState', {
    userId,
    field: 'isLikeAllowed',
    value: false,
  });
}

export async function actionProfileRequestPhoto(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ProfileState, unknown>,
  userId: string,
) {
  commit('mutationProfileRequestPhoto', userId);
  await requestUserPhoto(userId);
}
