import { I18n } from '@/services/i18n';

type Message = {
  popular: string;
};

const messages: I18n<Message> = {
  en: {
    popular: 'Most popular',
  },
  es: {
    popular: 'Más popular',
  },
};

export { messages };
