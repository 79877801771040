import { ProfilePhoto } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { showNotification } from '@/components/notification/helpers/notification-shower/notification-shower';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

export async function notificationSexualCompatibilityPromo(
  userId: string,
  avatar: ExtractClassFields<ProfilePhoto>,
): Promise<void> {
  const photoId = avatar?.id;
  const { t } = useI18n({ messages });

  showNotification({
    type: 'mutual-activity',
    category: 'sexual',
    photoId,
    isLocked: true,
    avatarForm: 'square',
    header: t('header'),
    description: t('description'),
    actionText: t('action'),
    iconName: 'sexual',
    action: {
      click: () => {
        pushRoute('profileView', { userId, scroll: 'sexual' });
      },
    },
  });
}
