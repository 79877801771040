<template>
  <span>
    {{ t('cancel') }} <span class="text-tag">{{ price }}</span
    >. {{ t('renew') }} <span class="text-tag">{{ price }}</span
    >. {{ t('write') }}
    <a href="mailto:support@meetmyage.com" class="text-caption">support@meetmyage.com</a>.
    {{ t('visit') }}
  </span>
  <a class="form-membership" @click.prevent="emit('open-membership-policy')">
    {{ t('policy') }}
  </a>
  {{ t('details') }}
</template>

<script lang="ts" setup>
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  price: {
    type: String,
    required: true,
  },
});

const { t } = useI18n({ messages });

const emit = defineEmits(['open-membership-policy']);
</script>
