import { showSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { showWebPushModal } from '@/components/web-push-modal/helpers/web-push-modal/web-push-modal';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { LIKE_COUNTER_CACHE_KEY } from '@/helpers/like/like-const';
import { getCache, setCache } from '@/helpers/persistent-cache';
import { dispatch, getStore } from '@/store/store-helper';

export function getLikeCounter(): number {
  return Number(getCache(LIKE_COUNTER_CACHE_KEY) ?? '');
}

export function incrementLikeCounter(): void {
  setCache(LIKE_COUNTER_CACHE_KEY, String(getLikeCounter() + 1));
}

type LikePayload = {
  userId: string;
  name: string;
  avatarId: string;
  source: PromoShowPurchaseSource;
};

export async function likeProfile({ avatarId, name, userId, source }: LikePayload) {
  if (getStore().getters.getterCommonAreLikesAvailable) {
    await dispatch('actionProfileLike', userId);

    showWebPushModal({
      userName: name,
      userAvatarId: avatarId,
    });
  } else {
    await showSubscriptionPromoModal(SubscriptionFeature.UNLIMITED_LIKES, source, {
      userReceiver: {
        avatarId,
      },
    });
  }
}
