import { trackPaymentResult } from '@/helpers/track/track-payment/track-payment';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { commit, dispatch } from '@/store/store-helper';

export type MessageSubmit = {
  type: 'submit';
  entity: 'applebtn' | 'googlebtn' | 'paypal' | 'form' | 'resign'; // one of listed values, indicates how payment was processed
};

export type MessageInteraction = {
  type: 'interaction';
  target: {
    // Indicates source of interaction
    type: 'button' | 'input'; // one of the listed
    name:
      | 'submit'
      | 'applePay'
      | 'googlePay'
      | 'paypal'
      | 'cardNumber'
      | 'cardCvv'
      | 'cardExpiry'
      | 'cardHolder'; // It could be one of the listed; furthermore, Solidgate might extend the list.
    interaction: 'click' | 'change' | 'focus' | 'blur' | 'enterKeyDown'; // one of the listed
  };
  cardForm: {
    // Indicates current card form state
    fields: {
      cardNumber: {
        isValid: boolean;
        isTouched: boolean;
      };
      cardCvv: {
        isValid: boolean;
        isTouched: boolean;
      };
      cardExpiry: {
        isValid: boolean;
        isTouched: boolean;
      };
      // The rest of the fields are optional, including, but not limited to: the `cardHolder` field
    };
    isValid: boolean;
    isTouched: boolean;
  };
};

export type MessageSuccess = {
  type: 'success';
  entity: 'applebtn' | 'googlebtn' | 'paypal' | 'form' | 'resign'; // one of listed values, indicates how payment was processed
  order: {
    // an optional order object
    status: string; // an optional order status field
    currency: string; // an optional order currency field
    amount: number; // an optional order amount field
    subscription_id: string; // an optional subscription id field
    order_id: string; // an optional order id field
  };
};

export type MessageFail = {
  type: 'fail';
  entity: 'applebtn' | 'googlebtn' | 'form'; // one of listed values
  code: string; // an optional error code field
  message: string; // an optional error message field
  order: {
    // an optional order object
    status: string; // an optional order status field
    currency: string; // an optional order currency field
    amount: number; // an optional order amount field
    subscription_id: string; // an optional subscription id field
    order_id: string; // an optional order id field
  };
};

export async function handleFormSuccess({
  data,
  price,
  currency,
}: {
  data: MessageSuccess;
  price: number;
  currency: string;
}) {
  let descriptorText = '';

  if (data.order?.order_id) {
    try {
      const resp = await dispatch('actionPaymentGetOrderStatus', data.order.order_id);
      if (resp.statusDetails?.status.case === 'success') {
        descriptorText = resp.statusDetails?.status.value.descriptorText ?? '';
      }
    } catch (e) {
      sentryCaptureMessage({
        message: 'Solid (success): Failed to get order status',
        captureContext: {
          extra: {
            error: e,
          },
        },
      });
    }
    trackPaymentResult({
      type: 'success-page',
      orderId: data.order.order_id,
    });
  }

  commit('mutationPaymentSetPaymentSuccess', {
    descriptorText,
    price,
    currency,
  });
}

export async function handleFormFail({ data }: { data: MessageFail }) {
  let recommendation = '';

  if (data.order?.order_id) {
    try {
      const resp = await dispatch('actionPaymentGetOrderStatus', data.order.order_id);
      if (resp.statusDetails?.status.case === 'fail') {
        recommendation = resp.statusDetails?.status.value.recommendation ?? '';
      }
    } catch (e) {
      sentryCaptureMessage({
        message: 'Solid (fail): Failed to get order status',
        captureContext: {
          extra: {
            error: e,
          },
        },
      });
    }
    trackPaymentResult({
      type: 'fail-page',
      orderId: data.order.order_id,
    });
  }

  commit('mutationPaymentSetPaymentFail', {
    code: data.code,
    message: data.message,
    recommendation,
    declinedRetryAttempt: 0,
  });
}
