import { pushRoute } from '@/router';

export function useMatchNavigation(chatId: string, emit: (param: 'close') => void) {
  function close(): void {
    emit('close');
  }

  function goChatPage(): void {
    pushRoute('chatView', { chatId });
    close();
  }

  return {
    close,
    goChatPage,
  };
}
