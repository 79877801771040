import {
  PromotionType,
  Stage,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/rewards_pb';
import { ActionContext } from 'vuex';

import { claimReward, getRewards } from '@/services/api/retention/retention-api';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { RetentionState } from '@/store/modules/retention/retention-state';
import { ActionFunction } from '@/store/store-helper';

export type ActionRetentionClaimRewardPayload = {
  stageId: number;
  promotionType: PromotionType;
};

declare module '@/store/store-helper' {
  interface Action {
    actionRetentionGetRewards: ActionFunction<PromotionType, Stage[]>;
    actionRetentionClaimReward: ActionFunction<ActionRetentionClaimRewardPayload, never>;
  }
}

export async function actionRetentionGetRewards(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<RetentionState, unknown>,
  promotionType: PromotionType,
): Promise<Stage[]> {
  try {
    const response = await getRewards(promotionType);

    return response.promotion?.stages ?? [];
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to load retention rewards',
      captureContext: { extra: { error } },
    });
  }

  return [];
}

export async function actionRetentionClaimReward(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<RetentionState, unknown>,
  { stageId, promotionType }: ActionRetentionClaimRewardPayload,
): Promise<void> {
  try {
    await claimReward(promotionType, stageId);
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to load claim reward',
      captureContext: { extra: { error } },
    });
  }
}
