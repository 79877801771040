<template>
  <NotificationActivity :category="'hit'" @action="goChatPage(info.dialogId)">
    <template #icon>
      <NotificationActivityIcon :name="'like_solid'" />
    </template>
    <template #avatar>
      <NotificationActivityAvatar :is-locked="true" :form="'square'" :photo-id="info.avatar.id" />
    </template>
    <template #header>{{ t('thereIsHit') }}</template>
    <template #description>
      {{ t('likedEachOther', { name: info.name }) }}
    </template>
    <template #action>{{ t('sayHello') }}</template>
  </NotificationActivity>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import NotificationActivityAvatar from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-avatar/NotificationActivityAvatar.vue';
import NotificationActivityIcon from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-icon/NotificationActivityIcon.vue';
import NotificationActivity from '@/components/notification/components/notification-redesign/notification-activity/NotificationActivity.vue';
import { NotificationDataMatch } from '@/components/notification/helpers/notification-shower/notification-shower';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { hideAllModals } from '@/services/modal/modal';

import { messages } from './i18n';

defineProps({
  info: {
    type: Object as PropType<NotificationDataMatch>,
    required: true,
  },
});

const { t } = useI18n({ messages });

function goChatPage(chatId: string): void {
  pushRoute('chatView', { chatId });
  hideAllModals();
  customEventTrigger({
    event: 'notification_match_click',
  });
}
</script>
