<template>
  <PurchaseSliderLayout>
    <template #default>
      <div class="slider-item swiper-slide">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.gift.header') }}</div>
          {{ t('slider.gift.text') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-gift_solid"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.boost.header') }}</div>
          {{ t('slider.boost.text') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-top_profile"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide">
        <div class="slider-text">
          <div class="slider_billing-title">
            {{ t('slider.chatRequest.header') }}
          </div>
          {{ t('slider.chatRequest.text') }}
        </div>
        <div class="slider-icon">
          <FontIcon class="icon-instant-chat_solid" />
        </div>
      </div>
      <div class="slider-item swiper-slide">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.astrology.header') }}</div>
          {{ t('slider.astrology.text') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-stars"></i>
        </div>
      </div>
      <div class="slider-item swiper-slide">
        <div class="slider-text">
          <div class="slider_billing-title">{{ t('slider.sexual.header') }}</div>
          {{ t('slider.sexual.text') }}
        </div>
        <div class="slider-icon">
          <i class="icon icon-sexual"></i>
        </div>
      </div>
    </template>
  </PurchaseSliderLayout>
</template>

<script lang="ts" setup>
import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import PurchaseSliderLayout from '@/pages/payment/components/layout/components/purchase-slider-layout/PurchaseSliderLayout.vue';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });
</script>
