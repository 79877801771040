import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  premium: string;
  premiumGold: string;
  fewerPlans: string;
  morePlans: string;
  tax: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Activate Premium',
    premium: 'Premium',
    premiumGold: 'Premium Gold',
    fewerPlans: 'Fewer plans',
    morePlans: 'More plans',
    tax: 'You can cancel your subscription anytime you want in account settings. By tapping Continue, we will charge your card for {packageSelectedPriceFormatted}, inclusive of taxes imposed according to the laws of your country, which constitute 20% of the total. Your subscription will automatically renew for the same package length you selected at the package price of {packageSelectedNextPriceFormatted}. Our rules on refunds are provided in Membership Policy',
  },
  es: {
    header: 'Activar Premium',
    premium: 'Premium',
    premiumGold: 'Premium Gold',
    fewerPlans: 'Menos planes',
    morePlans: 'Más planes',
    tax: 'Puedes cancelar tu suscripción en cualquier momento en la configuración de la cuenta. Al tocar Continuar, cobraremos tu tarjeta por {packageSelectedPriceFormatted}, incluidos los impuestos impuestos según las leyes de tu país, que constituyen el 20% del total. Tu suscripción se renovará automáticamente por la misma duración de paquete que seleccionaste al precio de paquete de {packageSelectedNextPriceFormatted}. Nuestras reglas sobre reembolsos se proporcionan en la Política de Membresía',
  },
};

export { messages };
