import {
  SubType,
  Tier,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/subscription_pb';
import { computed, watch } from 'vue';

import { showPremiumGoldModal } from '@/components/premium-gold-promo-modal/helpers/premium-gold-promo-modal-helper/premium-gold-promo-modal-helper';
import { UseSnackButton } from '@/components/snack-button/hooks/use-snack-button/type/type';
import { getStore } from '@/store/store-helper';

export function useSnackButtonPremiumGold(
  emit: (prop: 'hide', arg: boolean) => void,
): UseSnackButton {
  const action = (): void => {
    showPremiumGoldModal({
      source: 'premium-gold-search-snack',
    });
  };

  const isVisible = computed((): boolean => {
    const subscription = getStore().state.payment.subscriptionStatus?.subscription;
    const tier = subscription?.tier;
    const subType = subscription?.subType;

    return tier === Tier.PREMIUM && (subType === SubType.DEFAULT || subType === SubType.TRIAL);
  });

  watch(isVisible, (value) => {
    if (!value) {
      emit('hide', true);
    }
  });

  return {
    action,
    isVisible,
  };
}
