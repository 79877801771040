import { reactive, ref } from 'vue';

import { hasCache, removeCache, setCache } from '@/helpers/persistent-cache';

const HAS_ACTIVITY_CACHE_KEY = 'has_act';

const hasActivity = ref(false);

export function useActivityIncomingDot() {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function setHasActivity(hasActivityArg: boolean) {
    hasActivity.value = hasActivityArg;

    if (hasActivityArg) {
      setCache(HAS_ACTIVITY_CACHE_KEY, '1');
    } else {
      removeCache(HAS_ACTIVITY_CACHE_KEY);
    }
  }

  function init() {
    hasActivity.value = hasCache(HAS_ACTIVITY_CACHE_KEY);
  }

  return reactive({
    hasActivity,
    setHasActivity,
    init,
  });
}
