<template>
  <ModalClose>
    <template #default>
      <TrustpilotPrereview
        v-if="!self.rating"
        @rating-updated="(rating) => self.setRating(rating)"
      />
      <template v-else-if="self.rating === 'wow'">
        <iframe v-if="self.url" class="h-[512px] w-80" :src="self.url" frameborder="0"></iframe>
      </template>
      <div v-else-if="self.rating === 'poor' || self.rating === 'great'" class="w-80 p-4">
        <iframe
          id="freshwidget-embedded-form"
          title="Feedback Form"
          class="freshwidget-embedded-form"
          src="https://support.meetmyage.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feedback&submitTitle=Send+feedback&submitThanks=Thank+you+for+your+feedback&searchArea=no"
          scrolling="no"
          height="500px"
          width="100%"
          frameborder="0"
        ></iframe>
      </div>
    </template>
  </ModalClose>
</template>

<script lang="ts" setup>
import ModalClose from '@/components/modal/modal-close/ModalClose.vue';
import TrustpilotPrereview from '@/components/trustpilot-modal/components/trustpilot-prereview/TrustpilotPrereview.vue';

import { useSelf } from './hooks/use-self/use-self';

const self = useSelf({});

self.init();
</script>
