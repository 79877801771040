import { replaceLegalVariables } from '@project-gd-x/shared-web/src/helpers/legal-formatter/legal-formatter';
import { computed, reactive, ref } from 'vue';

import { getIsApplePayAvailable } from '@/components/payment/helpers/payment/payment';
import { getFormattedCurrency } from '@/helpers/number';
import { useCountry } from '@/hooks/use-country/use-country';
import { getPackagePrice } from '@/pages/payment/helpers/package/package';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { getState, getStore } from '@/store/store-helper';

type UseSelfArg = {
  pkg: PaymentStatePackage;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const isCardFormVisible = ref(false);

  // Hook ----------------------------------------------------------------------
  const country = useCountry();

  // Computed ------------------------------------------------------------------

  const price = computed<string>(() => {
    const pkgPrice = getPackagePrice(arg.pkg);

    return getFormattedCurrency(
      pkgPrice.totalAmount ?? 0n,
      pkgPrice.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const priceOld = computed<string>(() => {
    return getFormattedCurrency(
      arg.pkg.price?.baseAmount ?? 0n,
      arg.pkg.price?.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const savedAmount = computed<string>(() => {
    const pkgPrice = getPackagePrice(arg.pkg);

    const totalPrice = 9996 + (Number(arg.pkg.price?.baseAmount) - Number(pkgPrice.totalAmount));

    return getFormattedCurrency(
      BigInt(totalPrice),
      arg.pkg.price?.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const isGooglePayAvailable = computed(() => {
    return getState().payment.paymentMethods.googlePay.isAvailable;
  });

  const isApplePayAvailable = computed(() => {
    return getIsApplePayAvailable() && getState().payment.paymentMethods.applePay.isAvailable;
  });

  const isPaypalAvailable = computed<boolean>(() => {
    return getStore().state.payment.paymentMethods.solidPaypal.isAvailable;
  });

  const legalAddress = computed<string>(() => {
    if (country.country) {
      return replaceLegalVariables('{{company}}, {{address}}', {
        countryAlpha3: country.country.value?.country.isoCodeAlpha3 ?? 'USA',
      });
    }
    return '';
  });

  // Method --------------------------------------------------------------------
  function setIsCardFormVisible(value: boolean) {
    isCardFormVisible.value = value;
  }

  return reactive({
    price,
    priceOld,
    savedAmount,
    isGooglePayAvailable,
    isApplePayAvailable,
    isPaypalAvailable,
    isCardFormVisible,
    setIsCardFormVisible,
    legalAddress,
  });
}
