import ProlongationExpiredModal from '@/components/prolongation-modal/components/prolongation-expired-modal/ProlongationExpiredModal.vue';
import { hasPremium } from '@/helpers/feature/feature';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { useModal } from '@/services/modal/modal';

export function showExpiredModal(subscription: PaymentStateSubscription) {
  useModal(() => ({
    component: ProlongationExpiredModal,
    props: {
      subscription,
    },
  })).showModal();
}

export function getIsExpiredModalAvailable() {
  return !hasPremium();
}
