import { computed } from 'vue';

import { showSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { hasPremium } from '@/helpers/feature/feature';

export function useSearchPremium() {
  function unlock(): void {
    showSubscriptionPromoModal(SubscriptionFeature.ADVANCED_SEARCH, 'search-filters-unlock');
  }

  const areFieldsLocked = computed(() => {
    return !hasPremium();
  });

  return {
    unlock,
    areFieldsLocked,
  };
}
