import { PowerLikeRequest_Status } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import {
  NotificationSendPowerLikeFail,
  NotificationSendPowerLikeSuccess,
  NotificationSendPowerLikeSuccess_Source,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';
import { ref } from 'vue';

import PowerMatchModal from '@/components/power-match-modal/PowerMatchModal.vue';
import { getUserId } from '@/helpers/auth/auth';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { useModal } from '@/services/modal/modal';
import { pushNotificationManagerPowerLike } from '@/services/notification-manager/helpers/push/push';
import { commit, dispatch, getStore } from '@/store/store-helper';

export function handlePowerLikeFailEvent(notification: NotificationSendPowerLikeFail): void {
  const id = notification.subjectId;

  if (id !== getUserId()) {
    dispatch('actionProfileGetConnectionState', id);
  }
}

export const source = ref<NotificationSendPowerLikeSuccess_Source | null>();

export function handlePowerLikeSuccessEvent(notification: NotificationSendPowerLikeSuccess): void {
  source.value = notification.source;

  // need this to hide premium gold offer on engagement page
  setTimeout(() => (source.value = null), 5000);
  const dialogId = notification.connectionId;
  const dialog = getStore().state.chat.dialogs.find((item) => item.id === dialogId);
  const userId = notification.subjectId;
  const userFromId = notification.initiatorId;

  const showNotificationForMe = async (): Promise<void> => {
    if (dialog) {
      const response = await dispatch('actionProfileGetConnectionState', dialog.userId);
      const connection = response[dialog.userId];

      if (connection && !connection.powerLikeRequest) {
        commit('mutationProfileSetPowerLikeStatus', {
          userId: dialog.userId,
          status: PowerLikeRequest_Status.ACTIVE,
        });
      }
    }

    pushNotificationManagerPowerLike({ type: 'power-like', dialogId: dialogId ?? '', userFromId });
  };

  const showMatchPopup = async (): Promise<void> => {
    const interlocutorProfile = await dispatch('actionProfileGetProfile', notification.subjectId);
    const myProfile = await dispatch('actionMyProfileGetShort');

    const match = {
      dialogId: notification.connectionId,
      name: interlocutorProfile?.name,
      age: interlocutorProfile?.age,
      isOnline: interlocutorProfile?.isOnline,
      userId: interlocutorProfile?.id,
      avatar: interlocutorProfile?.photos?.[0],
      myAvatar: myProfile?.avatar,
    };

    commit('mutationProfileUnlockChat', {
      chatId: match?.dialogId || '',
      userId: match?.userId || '',
    });

    if (dialog) {
      await dispatch('actionChatGetDialogUser', { chatId: dialogId ?? '', userId: getUserId() });
    }

    await dispatch('actionProfileGetConnectionState', match?.userId || '');

    const { showModal } = useModal(() => ({
      component: PowerMatchModal,
      props: {
        match,
      },
    }));

    showModal();
    commit('mutationProfileSetPowerLikeRequestStatus', userId);
    customEventTrigger({
      event: 'powerlike_sent',
      user_id: notification.subjectId,
    });
  };

  if (notification.subjectId === getUserId()) {
    showNotificationForMe();
  } else if (source.value !== NotificationSendPowerLikeSuccess_Source.ENGAGEMENT) {
    showMatchPopup();
  }
}
