import { NotificationSendPowerLikeSuccess_Source } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';
import { PaymentGateway } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_pb';
import {
  SubType,
  Tier,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/subscription_pb';
import { FeatureType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/market/v1/package_pb';

import ModalOutOfCredits from '@/components/modal/modal-out-of-credits/ModalOutOfCredits.vue';
import { showNotification } from '@/components/notification/helpers/notification-shower/notification-shower';
import { source } from '@/components/power-like/helpers/power-like-websocket/helpers/power-like-websocket-handlers/power-like-websocket-handlers';
import { showPremiumGoldModal } from '@/components/premium-gold-promo-modal/helpers/premium-gold-promo-modal-helper/premium-gold-promo-modal-helper';
import { navigate } from '@/helpers/navigator/navigator';
import { getCache, setCache } from '@/helpers/persistent-cache';
import { trackPaymentResult } from '@/helpers/track/track-payment/track-payment';
import { showCreditsOffer } from '@/pages/payment/helpers/credits-offer/credits-offer';
import { handleConversions } from '@/pages/payment/helpers/payment-websocket/helpers/conversion/conversion';
import { PaymentStateFail } from '@/pages/payment/store/payment-state';
import { router } from '@/router';
import { useI18n } from '@/services/i18n';
import { useModal } from '@/services/modal/modal';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { NotificationHandler } from '@/services/websocket';
import { commit, dispatch, getStore } from '@/store/store-helper';

import { messages } from './i18n';

const { t } = useI18n({ messages });

export const paymentNotificationHandler: NotificationHandler = async (notification) => {
  if (notification.notification.case === 'subscriptionUpdated') {
    // We have to clear subscription status cache because this socket messages means
    // that status has been changed on server and needs to be loaded again
    const status = await dispatch('actionPaymentGetSubscriptionStatus');

    if (status.subscription?.tier === Tier.GOLD && status.status === 'active') {
      showNotification(
        {
          type: 'text',
          text: t('activated', {
            tier: status.subscription?.subType === SubType.DEFAULT ? 'Premium Gold' : 'Trial Gold',
          }),
          icon: 'icon-premium-gold-2',
          isSvg: true,
        },
        { force: true },
      );
    }
  } else if (notification.notification.case === 'paymentOrderStatus') {
    const orderStatus = notification.notification.value;
    const orderId = orderStatus.orderId;

    if (orderStatus) {
      const details = orderStatus.statusDetails;
      if (details) {
        const statusCase = details.status.case;
        if (statusCase === 'success') {
          const success = details.status.value;
          if (success) {
            commit('mutationPaymentSetPaymentSuccess', {
              descriptorText: success.descriptorText,
              price: Number(success.price),
              currency: success.currency,
            });
            trackPaymentResult({
              type: 'success-page',
              orderId,
            });
          }
        } else if (statusCase === 'fail') {
          const fail = details.status.value;
          if (fail) {
            commit('mutationPaymentSetPaymentFail', {
              code: fail.code,
              message: fail.message,
              recommendation: fail.recommendation,
              declinedRetryAttempt: fail.declinedRetryAttempt,
              isDeclineRetry: fail.isDeclineRetry,
              card: {
                brand: fail.card?.brand ?? '',
                number: fail.card?.number ?? '',
                source: fail.card?.source ?? '',
                gateway: fail.card?.gateway ?? PaymentGateway.INVALID,
              } as PaymentStateFail['card'],
            });
            trackPaymentResult({
              type: 'fail-page',
              orderId,
            });
          }
        } else if (statusCase === 'verification3ds') {
          const verification3ds = details.status.value;
          if (verification3ds) {
            const url = verification3ds.verificationUrl;
            navigate(url);
          }
        }
      }
    }
  } else if (notification.notification.case === 'creditCardAdded') {
    const creditCards = getStore().state.payment.paymentCards;
    const moreThanOneCreditCard = creditCards.length >= 1;
    const notificationContent = moreThanOneCreditCard ? t('cardAdded') : t('profileVerified');
    const notificationIcon = moreThanOneCreditCard ? 'credit_card' : 'verification';

    showNotification(
      {
        type: 'text',
        text: notificationContent,
        icon: notificationIcon,
      },
      { force: true },
    );

    dispatch('actionPaymentListPaymentCards');
  } else if (notification.notification.case === 'newUndeliveredConversions') {
    handleConversions();
  } else if (notification.notification.case === 'creditsFeatureSuccess') {
    const success = notification.notification.value;
    if (success) {
      const featureType = success.featureType;

      if (!getStore().state.common.index.isPremiumGoldActive || featureType !== FeatureType.GIFT) {
        commit('mutationCommonDecreaseFeature', featureType);
      }
      const CACHE_KEY = 'credits_feature_last_time';
      const INTERVAL_MS = 1000 * 60 * 60;
      if (!getStore().state.common.index.isPremiumGoldActive) {
        const cache = getCache(CACHE_KEY);
        const time = new Date(+(cache ?? 0));
        if (
          +new Date() >= +time &&
          source.value !== NotificationSendPowerLikeSuccess_Source.ENGAGEMENT
        ) {
          showPremiumGoldModal({
            source: 'premium-gold-credits-success-websocket',
          });
          setCache(CACHE_KEY, +new Date() + INTERVAL_MS + '');
        }
      }
    }
  }

  if (notification.notification.case === 'creditsRefillFail') {
    showCreditsOffer();
  }

  if (notification.notification.case === 'creditsFeatureFail') {
    showCreditsOffer();
  }

  if (notification.notification.case === 'creditsUpdated') {
    try {
      // const creditsCount = (await notification.getCreditsUpdated()?.getCurrentAmount()) ?? 0;
      const creditsCount = Number(notification.notification.value.currentAmount);

      commit('mutationPaymentSetCreditsCount', creditsCount);

      if (creditsCount > 0 && creditsCount <= 49) {
        const { showModal } = useModal(() => ({
          component: ModalOutOfCredits,
          props: {
            source: router.currentRoute?.value?.path,
          },
        }));
        showModal();
      }
    } catch (error) {
      sentryCaptureMessage({
        message: 'Failed to load current credits balance',
        captureContext: { extra: { error } },
      });
    }
  }
};
