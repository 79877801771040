<template>
  <div class="footer_panel">
    <div class="ice_breaker_msg flex flex-col">
      <div class="ice_breaker_msg-title">
        {{ t('hint') }}
      </div>
      <div v-if="iceBreakers.length" ref="iceBreaker">
        <div v-for="ice in iceBreakers" :key="ice" class="ice_breaker_msg-col" @click="select(ice)">
          {{ ice }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref, watch } from 'vue';

import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  iceBreakers: {
    type: Array as PropType<string[]>,
    required: true,
  },
});
const emit = defineEmits(['select']);
const { t } = useI18n({ messages });
const iceBreaker = ref<HTMLDivElement>();

function select(ice: string) {
  emit('select', ice);
}

watch(iceBreaker, (value) => {
  if (value) {
    iceBreaker.value?.scrollTo({ top: 9999999 });
  }
});
</script>
