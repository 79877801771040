import { ListActivitiesResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/activity/v1/activity_api_pb';

import {
  ActivityItem,
  ActivityItemDataAstrologyCompatibility,
  ActivityItemDataLike,
  ActivityItemDataRequestPhoto,
  ActivityItemDataRequestPhotoUploaded,
  ActivityItemDataSexualCompatibility,
  ActivityItemDataUnmatch,
  ActivityItemDataVisit,
  ActivityItemDataPowerLike,
} from '@/pages/activity/store/activity-state';

export const ACTIVITIES_LIMIT = 20;

export function getActivitiesFrom(response: ListActivitiesResponse): ActivityItem[] {
  const activities: ActivityItem[] = [];

  response.activities.forEach((activity) => {
    const profile = activity.profile;

    let data:
      | ActivityItemDataPowerLike
      | ActivityItemDataVisit
      | ActivityItemDataLike
      | ActivityItemDataUnmatch
      | ActivityItemDataRequestPhoto
      | ActivityItemDataRequestPhotoUploaded
      | ActivityItemDataSexualCompatibility
      | ActivityItemDataAstrologyCompatibility
      | undefined;

    switch (activity.type.case) {
      case 'visit': {
        data = {
          type: 'visit',
          dialogId: activity.type.value.dialogId,
          isLiked: activity.type.value.isLiked,
        };

        break;
      }

      case 'requestPhoto': {
        data = { type: 'request-photo' };

        break;
      }

      case 'requestPhotoUploaded': {
        data = { type: 'request-photo-uploaded' };

        break;
      }

      case 'unmatch': {
        data = { type: 'unmatch' };

        break;
      }

      case 'like': {
        data = {
          type: 'like',
          isLiked: false,
          dialogId: activity.type.value.dialogId,
        };

        break;
      }

      case 'powerLike': {
        data = {
          type: 'power-like',
        };

        break;
      }

      case 'sexualCompatibility': {
        data = {
          type: 'request-sexual-compatibility',
        };

        break;
      }

      case 'zodiacCompatibility': {
        data = {
          type: 'request-astrology-compatibility',
        };

        break;
      }

      default:
        break;
    }

    if (data) {
      activities.push({
        id: activity.id,
        age: profile?.age ?? 0,
        avatar: {
          fileId: profile?.avatar?.fileId ?? '',
          isDummy: profile?.avatar?.isDummy ?? false,
          tags: profile?.avatar?.tags ?? [],
        },
        createdTimeMs: Number(activity.createdTime?.seconds ?? 0) * 1000,
        isOnline: profile?.isOnline ?? false,
        isLocked: profile?.isLocked ?? false,
        listPosition: activity.listPosition,
        name: profile?.name ?? '',
        userId: profile?.id ?? '',
        locationName: profile?.locationName ?? '',
        data,
      });
    }
  });

  return activities;
}

export function getLastActivitiesPosition(items: ActivityItem[]): string {
  if (items.length) {
    return items[items.length - 1].listPosition;
  }

  return '';
}
