import { onUnmounted } from 'vue';

import { hideModalSpinner } from '@/components/modal/helpers/modal/modal';
import { PaymentStateFail, PaymentStateSuccess } from '@/pages/payment/store/payment-state';
import { dispatch, Mutation, getStore } from '@/store/store-helper';

export function usePayment({
  paymentSuccess = () => ({}),
  paymentFail = () => ({}),
  loaded = () => ({}),
}: {
  paymentSuccess?: (payload: PaymentStateSuccess) => void;
  paymentFail?: (payload: PaymentStateFail) => void;
  loaded?: () => void;
}): { listPaymentMethodsPromise: Promise<void> } {
  const storeUnsubscribe = getStore().subscribe(
    ({ type, payload }: { type: keyof Mutation; payload: unknown }) => {
      if (type === 'mutationPaymentSetPaymentSuccess') {
        paymentSuccess(payload as PaymentStateSuccess);
        hideModalSpinner();
      } else if (type === 'mutationPaymentSetPaymentFail') {
        paymentFail(payload as PaymentStateFail);
        hideModalSpinner();
      }
    },
  );

  onUnmounted(storeUnsubscribe);

  // Load fresh data from server or get it from cache, cause it wont change until user's session.
  // So we shouldn't make additional requests to retrieve payment methods data, cache is fine here.
  const listPaymentMethodsPromise = dispatch('actionPaymentListPaymentMethods');
  listPaymentMethodsPromise.then(loaded);

  return { listPaymentMethodsPromise };
}
