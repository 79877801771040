<template>
  <ModalMenu class-name="web_push_modal welcome_trial">
    <template #title>
      <div class="web_push_modal-avatar relative">
        <div class="overflow-hidden rounded-full">
          <RetinaPhoto :photo-id="profile.avatar.id" :width="80" :height="80"></RetinaPhoto>
        </div>
        <div class="web_push_modal-icon">💎</div>
      </div>

      <div class="color-premium">{{ t('hello', { name: profile.name }) }}</div>
      {{ t('premiumExpired') }}
    </template>
    <template #text>
      <p class="renew-text">
        {{ t('continue') }}
      </p>
      <button type="button" class="btn btn-premium btn-block mb-0" @click="renew">
        {{ t('renew') }}
      </button>
    </template>
  </ModalMenu>
</template>
<script lang="ts" setup>
import ModalMenu from '@/components/modal/modal-menu/ModalMenu.vue';
import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import { renewSubscription } from '@/components/payment/helpers/subscription/subscription';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import { useI18n } from '@/services/i18n';
import { useModal } from '@/services/modal/modal';
import { dispatch, getStore } from '@/store/store-helper';

import { messages } from './i18n';

const emit = defineEmits(['close']);
const { t } = useI18n({ messages });
const profile: MyProfileStateShort = getStore().state.myProfile.short;

async function renew(): Promise<void> {
  await renewSubscription();
  emit('close');
}

async function loadShortProfile(): Promise<void> {
  showModal();
  await dispatch('actionMyProfileGetShort');
  hideModal();
}

const { showModal, hideModal } = useModal(() => ({
  component: ModalSpinner,
}));

loadShortProfile();
</script>

<style lang="scss" src="./style.scss"></style>
