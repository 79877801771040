import { AccountStatus } from '@project-gd-x/dating-site-contracts/src/gen/gdx/common/v1/profile_pb';
import { LifecycleProgress } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/lifecycle_pb';
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { FeatureType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/market/v1/package_pb';

import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { PaymentStateBooster } from '@/pages/payment/store/payment-state';
import { LangCode } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

export type CommonStateIndex = {
  newLikesCount: number;
  newChatMessagesCount: number;
  isEmailVerified: boolean;
  interfaceLanguage: LangCode;
  isPremiumActive: boolean;
  likesLimitRemainCount: number;
  hasNewActivity: boolean;
  lastVisitedRoute: string;
  isFromLanding: boolean;
  status: AccountStatus;
  isPremiumGoldActive: boolean;
  landingUrl: string;
  lifecycle: ExtractClassFields<LifecycleProgress>;
  isOrganic: boolean;
  isHiddenFromSearch: boolean;
};

export type CommonStatePromoModal = {
  isModalVisible: boolean;
  type: PromoType;
  packages: ExtractClassFields<PremiumPackage>[];
  feature: SubscriptionFeature | undefined;
  isBackgroundScrollable: boolean;
};

export type CommonStateBoosterPromoModal = {
  isModalVisible: boolean;
  shownTimeMs: number;
  booster?: PaymentStateBooster;
};

export type CommonStateWebView = {
  isModalVisible: boolean;
  isBarAvailable: boolean;
};

export type CommonStateCreditsFeatures = Partial<Record<FeatureType, number>>;

export type CommonState = {
  index: CommonStateIndex;

  promoModal: CommonStatePromoModal;

  boosterPromoModal: CommonStateBoosterPromoModal;

  webView: CommonStateWebView;

  creditsFeatures: CommonStateCreditsFeatures;
};

export const state: CommonState = {
  index: {
    newLikesCount: 0,
    newChatMessagesCount: 0,
    isEmailVerified: true,
    interfaceLanguage: LangCode.en,
    isPremiumActive: false,
    likesLimitRemainCount: 0,
    hasNewActivity: false,
    lastVisitedRoute: '',
    isFromLanding: true,
    status: AccountStatus.INVALID,
    isPremiumGoldActive: false,
    landingUrl: '',
    lifecycle: {
      isOnboardingComplete: false,
      onboardingPhotosUploadedCount: 0,
    },
    isOrganic: true,
    isHiddenFromSearch: false,
  },
  promoModal: {
    isModalVisible: false,
    type: PromoType.INVALID,
    packages: [],
    feature: undefined,
    isBackgroundScrollable: false,
  },
  boosterPromoModal: {
    isModalVisible: false,
    shownTimeMs: 0,
    booster: undefined,
  },
  webView: {
    isModalVisible: false,
    isBarAvailable: false,
  },
  creditsFeatures: {},
};
