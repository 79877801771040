<template>
  <ModalPortal>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <SpinnerLoader class="!border-white !border-b-dark-100"></SpinnerLoader>
    </div>
  </ModalPortal>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue';
import ModalPortal from '@/components/modal/ModalPortal.vue';
</script>
