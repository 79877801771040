<template>
  <TitleLayout class="card_list">
    <template #header-left>
      <Back @click="back"></Back>
    </template>

    <template #header-center>
      {{ t('header') }}
    </template>

    <template #default>
      <div class="h-full flex flex-col">
        <PaymentMethodListItems :cards="cards" :pkg="pkg" @next="nextCard" @add="addMethod">
          <template #button>
            <span>{{ t('continue') }}</span>
          </template>
        </PaymentMethodListItems>
        <div v-if="!isSubscription" class="flex-1 flex items-end justify-center -mb-11">
          <div v-if="pkg.price?.taxAmount" class="text-caption text-dark-200 w-full">
            <div class="flex mb-1">
              <div class="">Your {{ pkg.title }} package:</div>
              <div class="flex-1 text-right text-dark">{{ packagePrice.priceFormatted }}</div>
            </div>
            <div class="flex mb-1 pb-1 border-b-[1px] border-dark-500">
              <div class="">Value added tax:</div>
              <div class="flex-1 text-right text-dark">
                {{ packagePrice.priceTaxFormatted }}
              </div>
            </div>
            <div class="flex text-dark">
              <div class="">Total:</div>
              <div class="flex-1 text-right">{{ packagePrice.priceFormatted }}</div>
            </div>
          </div>
          <div v-else class="payment-of">
            {{ t('hint') }} {{ packagePrice.priceTotalFormatted }}
          </div>
        </div>
      </div>
    </template>
  </TitleLayout>
</template>

<script lang="ts" setup>
import { onMounted, PropType, reactive } from 'vue';

import Back from '@/components/layout/components/header/components/back/Back.vue';
import TitleLayout from '@/components/layout/TitleLayout.vue';
import { payWithCard } from '@/components/payment/helpers/payment-method/payment-method';
import { trackPaymentFlow } from '@/helpers/track/track-payment/track-payment';
import PaymentMethodListItems from '@/pages/payment/components/payment-method/components/payment-method-list/components/payment-method-list-items/PaymentMethodListItems.vue';
import { usePackagePrice } from '@/pages/payment/hooks/use-package-price/use-package-price';
import { PaymentStateCard, PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });
const emit = defineEmits(['back', 'add']);
const props = defineProps({
  cards: {
    type: Array as PropType<PaymentStateCard[]>,
    required: true,
  },
  isSubscription: {
    type: Boolean,
    required: true,
  },
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    required: true,
  },
});

const packagePrice = reactive(usePackagePrice(props.pkg));

function back(): void {
  emit('back');
}

function addMethod(): void {
  emit('add');
}

function nextCard(card: PaymentStateCard): void {
  payWithCard({
    cardId: card.id,
    pkg: props.pkg,
  });
}

onMounted(() => {
  trackPaymentFlow({
    step: 'list',
  });
});
</script>

<style lang="scss" src="./style.scss"></style>
