import { hasCache, setCache } from '@/helpers/persistent-cache';
import TrialUpsaleModal from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-upsale-modal/TrialUpsaleModal.vue';
import { listLimitedOfferPackages } from '@/services/api/payment/payment-api';
import { useModal } from '@/services/modal/modal';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { dispatch, getStore } from '@/store/store-helper';

export async function showTrialUpsaleModal(): Promise<void> {
  await dispatch('actionPaymentListPaymentCards');

  const cards = getStore().state.payment.paymentCards;

  if (hasTrialUpsaleSeenCache() || !cards.length) return;

  try {
    const response = await listLimitedOfferPackages();
    const packages = response.premiumPackages;

    if (packages.length) {
      const [pkgOne, pkgTwo] = packages;

      const { showModal } = useModal(() => ({
        component: TrialUpsaleModal,
        props: {
          pkgOne: pkgOne ?? {},
          pkgTwo: pkgTwo ?? {},
        },
      }));

      // Payment success event will close all modals include this one,
      // so we need to wait for a second to prevent this modal to be closed
      setTimeout(() => {
        showModal();
      }, 1000);
    }
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to load limited offer premium packages',
      captureContext: { extra: { error } },
    });
  }
}

const TRIAL_UPSALE_SEEN = 'one_time_offer_trial_seen';

export function setTrialUpsaleSeenCache() {
  return setCache(TRIAL_UPSALE_SEEN, '1');
}

export function hasTrialUpsaleSeenCache() {
  return hasCache(TRIAL_UPSALE_SEEN);
}
