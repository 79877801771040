<template>
  <TitleLayout>
    <template #header-left>
      <slot name="close-icon">
        <Close @click="close"></Close>
      </slot>
    </template>

    <template #header-center>
      <slot name="title"></slot>
    </template>

    <template #slider-content>
      <slot name="slider-content"></slot>
    </template>

    <template #default>
      <div class="billing_wrap">
        <slot></slot>
        <slot name="footer"></slot>
      </div>

      <slot v-if="isContinueVisible" name="continue-btn">
        <PurchaseContinue
          :pkg="pkg"
          :is-autorefill-visible="isAutorefillVisible"
          @select="select"
          @next="next"
        />
      </slot>
    </template>
  </TitleLayout>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import Close from '@/components/layout/components/header/components/close/Close.vue';
import TitleLayout from '@/components/layout/TitleLayout.vue';
import PurchaseContinue from '@/pages/payment/components/layout/components/purchase-continue/PurchaseContinue.vue';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';

defineProps({
  isAutorefillVisible: {
    type: Boolean,
    default: false,
  },
  isContinueVisible: {
    type: Boolean,
    default: true,
  },
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    required: true,
  },
});

const emit = defineEmits(['select', 'close', 'next']);

function select(): void {
  emit('select');
}

function close(): void {
  emit('close');
}

function next(): void {
  emit('next');
}

// async function listPaymentMethods(): Promise<void> {
//   const { listPaymentMethodsPromise } = usePayment({});
//   await listPaymentMethodsPromise;
// }
//
// onMounted(async () => {
//   await listPaymentMethods();
// });
</script>
