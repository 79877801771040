import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';

import { ExtractClassFields } from '@/type';

export type SubscriptionPromoModalOptionsUser = {
  avatarId: string;
};

export type SubscriptionPromoModalOptions = {
  defaultPackage?: ExtractClassFields<PremiumPackage>;
  userReceiver?: SubscriptionPromoModalOptionsUser;
};

export type SubscriptionPromoModalData = {
  userReceiver?: SubscriptionPromoModalOptionsUser;
};

export const enum SubscriptionFeature {
  ADVANCED_INFORMATION = 'advancedInformation',
  UNLOCK_LOCATION = 'unlockLocation',
  UNLIMITED_LIKES = 'unlimitedLikes',
  ADVANCED_SEARCH = 'advancedSearch',
  UNBLOCK_USERS = 'unblockUsers',
  UNLIMITED_MESSAGES = 'unlimitedMessages',
  DELETE_OWN_MESSAGES = 'deleteOwnMessages',
  ACTIVITY = 'activity',
  ADS = 'ads',
}
