import { notificationAstroCompatibility } from '@/components/notification/components/notification-activity/helpers/notification-astro-compatibility/notification-astro-compatibility';
import { notificationSexualCompatibility } from '@/components/notification/components/notification-activity/helpers/notification-sexual-compatibility/notification-sexual-compatibility';
import {
  NotificationDataLike,
  NotificationDataMatch,
  NotificationDataMessage,
  NotificationDataPowerLike,
  NotificationDataVisit,
  showNotification,
} from '@/components/notification/helpers/notification-shower/notification-shower';
import { playSound } from '@/helpers/sound/sound';
import { pushRoute } from '@/router';
import { pushNotificationManager } from '@/services/notification-manager/notification-manager';

export function pushNotificationManagerVisit(notification: NotificationDataVisit) {
  pushNotificationManager({
    name: 'visit',
    rulesArgs: [
      {
        ruleName: 'prioritize',
        userFromId: notification.visitorUserId,
      },
      {
        ruleName: 'stack',
        showStackedNotification(stackCount: number) {
          showNotification({
            type: 'stack-activity',
            stackCount,
            notificationType: 'visit',
            action() {
              pushRoute('activity', { source: 'incoming' });
            },
          });
          playSound('notification');
        },
      },
    ],
    showNotification() {
      showNotification(notification);
      playSound('notification');
    },
  });
}

export function pushNotificationManagerLike(notification: NotificationDataLike) {
  pushNotificationManager({
    name: 'like',
    rulesArgs: [
      {
        ruleName: 'prioritize',
        userFromId: notification.userId,
      },
      {
        ruleName: 'stack',
        showStackedNotification(stackCount: number) {
          showNotification({
            type: 'stack-activity',
            stackCount,
            notificationType: 'like',
            action() {
              pushRoute('activity', { source: 'incoming' });
            },
          });
          playSound('notification');
        },
      },
    ],
    showNotification() {
      showNotification(notification);
      playSound('notification');
    },
  });
}

export function pushNotificationManagerIncomingMatch(notification: NotificationDataMatch) {
  pushNotificationManager({
    name: 'incoming-match',
    rulesArgs: [
      {
        ruleName: 'prioritize',
        userFromId: notification.userId,
      },
      {
        ruleName: 'stack',
        showStackedNotification(stackCount: number) {
          showNotification({
            type: 'stack-activity',
            stackCount,
            notificationType: 'incoming-match',
            action() {
              pushRoute('chatList');
            },
          });
        },
      },
    ],
    showNotification() {
      showNotification(notification);
    },
  });
}

export function pushNotificationManagerPowerLike(notification: NotificationDataPowerLike) {
  pushNotificationManager({
    name: 'power-like',
    rulesArgs: [
      {
        ruleName: 'prioritize',
        userFromId: notification.userFromId,
      },
      {
        ruleName: 'stack',
        showStackedNotification(stackCount: number) {
          showNotification({
            type: 'stack-activity',
            stackCount,
            notificationType: 'power-like',
            action() {
              pushRoute('chatList');
            },
          });
        },
      },
    ],
    showNotification() {
      showNotification(notification);
    },
  });
}

export function pushNotificationManagerChatMessage(notification: NotificationDataMessage) {
  pushNotificationManager({
    name: 'chat-message',
    rulesArgs: [
      {
        ruleName: 'prioritize',
        userFromId: notification.message.senderId,
      },
      {
        ruleName: 'stack',
        showStackedNotification(stackCount: number) {
          showNotification({
            type: 'stack-activity',
            stackCount,
            notificationType: 'chat-message',
            action() {
              pushRoute('chatList');
            },
          });
          playSound('message');
        },
      },
    ],
    showNotification() {
      showNotification(notification);
      playSound('message');
    },
  });
}

export function pushNotificationManagerAstroVerification(
  notification: Parameters<typeof notificationAstroCompatibility>[0],
) {
  pushNotificationManager({
    name: 'astro',
    rulesArgs: [
      {
        ruleName: 'combine',
        userFromId: notification.userId,
        showCombinedNotification() {
          showNotification({
            type: 'astro-romantic',
            ...notification,
          });
        },
      },
    ],
    showNotification() {
      notificationAstroCompatibility(notification);
    },
  });
}

export function pushNotificationManagerRomanticVerification(
  notification: Parameters<typeof notificationSexualCompatibility>[0],
) {
  pushNotificationManager({
    name: 'romantic',
    rulesArgs: [
      {
        ruleName: 'combine',
        userFromId: notification.userId,
        showCombinedNotification() {
          showNotification({
            type: 'astro-romantic',
            ...notification,
          });
        },
      },
    ],
    showNotification() {
      notificationSexualCompatibility(notification);
    },
  });
}
