<template>
  <div data-id="notification-report" :class="['alert-info', 'flex', mode]">
    <div class="alert-info-icon upload flex items-center justify-center">
      <div class="img-section">
        <FontIcon :class="`icon-${icon}`" />
      </div>
    </div>
    <div class="alert-info-body grow flex items-center">
      <div class="alert-info-section">
        {{ t(text) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  text: {
    type: String as PropType<'rating'>,
    default: '',
  },
  icon: {
    type: String,
    default: 'report',
  },
  mode: {
    type: String as PropType<'success' | 'warning' | 'error'>,
    default: 'success',
  },
});

const { t } = useI18n({ messages });
</script>
