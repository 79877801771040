import { NotificationMatchFromMe } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';
import {
  Package,
  Possibility,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';

import { ExtractClassFields } from '@/type';

export type MatchStateNotification = ExtractClassFields<NotificationMatchFromMe>;

export type PossibilityState = {
  notification?: MatchStateNotification;
  possibilities: ExtractClassFields<Possibility>[];
  packages: ExtractClassFields<Package>[];
};

export const state: PossibilityState = {
  possibilities: [],
  packages: [],
};
