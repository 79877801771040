import { ProfilePhoto } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';
import { computed, ref } from 'vue';

import { UseSnackButton } from '@/components/snack-button/hooks/use-snack-button/type/type';
import { memoize } from '@/helpers/memoize/memoize';
import { getCache, setCache } from '@/helpers/persistent-cache';
import { pushRoute } from '@/router';
import { dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

const KEY_PHOTO_MODERATION_CLOSED = 'photo_moderation_closed';

function setAsClosed() {
  setCache(KEY_PHOTO_MODERATION_CLOSED, '1');
}

function hasBeenClosed(): string | null {
  return getCache(KEY_PHOTO_MODERATION_CLOSED);
}

export function useSnackButtonModeration(): UseSnackButton {
  const photos = ref<ExtractClassFields<ProfilePhoto>[]>([]);
  const localVisibility = ref(true);

  const isVisible = computed((): boolean => {
    if (photos.value.length) {
      const areAllDeclined = photos.value.every((photo) => photo.isDeclined);
      return areAllDeclined && !hasBeenClosed() && localVisibility.value;
    }
    return false;
  });

  const action = (): void => {
    pushRoute('myProfileEdit');
  };

  const close = (): void => {
    setAsClosed();
    localVisibility.value = false;
  };

  memoize(dispatch)('actionMyProfileGetFull').then((profile) => {
    photos.value = profile.photos;
  });

  return {
    isVisible,
    action,
    close,
  };
}
