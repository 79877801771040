<template>
  <NotificationActivity category="astro" @action="goPage">
    <template #icon>
      <NotificationAstroRomanticIcon />
    </template>
    <template #avatar>
      <NotificationActivityAvatar
        :is-locked="true"
        :form="'square'"
        :photo-id="content.avatar.id"
      />
    </template>
    <template #header>{{ t('header') }}</template>
    <template #description>{{ t('description', { name: content.name }) }}</template>
    <template #action-button="{ action }">
      <NotificationAstroRomanticButton @click="action">
        {{ t('actionButtonText') }}
      </NotificationAstroRomanticButton>
    </template>
  </NotificationActivity>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import NotificationAstroRomanticButton from '@/components/notification/components/notification-astro-romantic/components/notification-astro-romantic-button/NotificationAstroRomanticButton.vue';
import NotificationAstroRomanticIcon from '@/components/notification/components/notification-astro-romantic/components/notification-astro-romantic-icon/NotificationAstroRomanticIcon.vue';
import NotificationActivityAvatar from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-avatar/NotificationActivityAvatar.vue';
import NotificationActivity from '@/components/notification/components/notification-redesign/notification-activity/NotificationActivity.vue';
import { NotificationDataAstroRomantic } from '@/components/notification/helpers/notification-shower/notification-shower';
import { hasPremium } from '@/helpers/feature/feature';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { hideAllModals } from '@/services/modal/modal';

import { messages } from './i18n';

const props = defineProps({
  content: {
    type: Object as PropType<NotificationDataAstroRomantic>,
    required: true,
  },
});

const { t } = useI18n({ messages });

function goPage(): void {
  if (hasPremium()) {
    pushRoute('profileView', { userId: props.content.userId });
  } else {
    pushRoute('profileView', { userId: props.content.userId, scroll: 'astro' });
  }
  hideAllModals();
}
</script>
