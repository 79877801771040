<template>
  <div
    data-test-id="premium-gold-promo-modal"
    class="modal_default explanatory_slider fifty_discount gold-bg"
    style="display: block"
  >
    <div class="modal_default-dialog">
      <div class="modal_default-content">
        <div class="modal_default-body">
          <div class="modal_default-trial">
            <div class="modal_default-close">
              <slot name="close-icon">
                <FontIcon class="icon-close" data-test-id="btn_close" @click="close" />
              </slot>
            </div>

            <div class="explanatory_slider-sub_title text-tag font-medium text-white-5 text-center">
              {{ t('premiumGold') }}
            </div>

            <div class="explanatory_slider-avatar">
              <RetinaImage class="img" :image-id="profile.avatar.id" :width="72" :height="72" />
            </div>

            <PurchaseSliderPremiumGold />

            <div class="wrapper">
              <div class="text-headline5 font-semibold text-white text-center mb-4">
                {{ t('getPremiumGold') }}
              </div>

              <PromoCard :pkg="pkg" />
            </div>
            <button
              type="button"
              class="premium-gold-btn btn btn-warning mb-0 mt-auto flex items-center justify-center h-12"
              :disabled="isLoading"
              @click="action"
            >
              <SpinnerLoader
                v-if="isLoading"
                class="!m-0 !border-[3px] !border-white !border-b-white/30 !w-5 !h-5 !after:w-5 !after:h-5"
              />
              <template v-else>
                {{ t('continue') }}
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true };
</script>
<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PropType, ref } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue';
import { paymentPurchase } from '@/components/payment/helpers/payment-method/payment-method';
import PromoCard from '@/components/premium-gold-promo-modal/components/promo-card/PromoCard.vue';
import RetinaImage from '@/components/retina-image/RetinaImage.vue';
import PurchaseSliderPremiumGold from '@/pages/payment/components/layout/components/purchase-slider-premium-gold/PurchaseSliderPremiumGold.vue';
import { useSubscriptionPromoTrial } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/hooks/use-subscription-promo-trial/use-subscription-promo-trial';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    required: true,
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
  },
});

const emit = defineEmits(['close']);
const isLoading = ref(false);

// const price = computed<ExtractClassFields<Price>>(() => {
//   return getPackagePrice(props.pkg);
// });
//
// const priceFormatted = computed<string>(() => {
//   return getFormattedCurrency(
//     price.value.totalAmount - price.value.taxAmount,
//     price.value.currency,
//   );
// });
//
// const priceTaxFormatted = computed<string>(() => {
//   return getFormattedCurrency(price.value.taxAmount, price.value.currency);
// });

// const pricePerWeekFormatted = computed<string>(() => {
//   const price = getPackagePricePerWeek(props.pkg);
//   return getFormattedCurrency(price.totalAmount, price.currency);
// });

function action(): void {
  if (!isLoading.value) {
    isLoading.value = true;

    paymentPurchase({ pkg: props.pkg });
  }
}

function close(): void {
  emit('close');
}

const { t } = useI18n({ messages });
const { profile, handleDataLoading } = useSubscriptionPromoTrial(emit);
handleDataLoading();
</script>
