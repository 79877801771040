<template>
  <!--   Alternative text for iOS webView (Safari)    -->
  <ModalMenu v-if="isIosWebView">
    <template #title>
      {{ t('whyActivateAccount') }}
    </template>
    <template #text>
      <span>{{ t('activationEmail') }}</span>
    </template>
    <template #default>
      <PositiveModalMenuButton @click="closeWebViewModal">
        {{ t('gotIt') }}
      </PositiveModalMenuButton>
    </template>
  </ModalMenu>

  <!--   Alternative text for Android webView (Google Chrome)    -->
  <ModalMenu v-else>
    <template #title>{{ t('howOpenGoogleChrome') }}</template>
    <template #text>
      <span v-html="t('inOrderChromeText1')"></span>
      “<i class="icon icon-more_v"></i>”
      <span>{{ t('inOrderChromeText2') }}</span>
    </template>
    <template #default>
      <PositiveModalMenuButton @click="closeWebViewModal">OK</PositiveModalMenuButton>
    </template>
  </ModalMenu>
</template>

<script lang="ts" setup>
import PositiveModalMenuButton from '@/components/modal/modal-menu/components/PositiveModalMenuButton.vue';
import ModalMenu from '@/components/modal/modal-menu/ModalMenu.vue';
import * as webview from '@/helpers/webview/webview';
import { useI18n } from '@/services/i18n';
import { commit } from '@/store/store-helper';

import { messages } from './i18n';

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const isIosWebView = webview.isIosWebView();

function closeWebViewModal() {
  emit('close');
  commit('mutationCommonSetWebViewModalVisibility', false);
}
</script>
