import { createPromiseClient } from '@connectrpc/connect';
import { ProfileAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_api_connect';
import {
  GetProfileRequest,
  GetProfileResponse,
  SearchRequest,
  SearchResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';

const client = createPromiseClient(ProfileAPI, getGrpcWebTransport());

export function search(request: SearchRequest): Promise<SearchResponse> {
  return client.search(request);
}

export function getProfile(request: GetProfileRequest): Promise<GetProfileResponse> {
  return client.getProfile(request);
}
