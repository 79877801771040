import { NotificationsOptions, notify } from '@kyvg/vue3-notification';
import { NotificationDialogMessageV2_DialogMessage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';
import {
  ProfileGender,
  ProfilePhoto,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { IconType } from '@/components/inputs/icon/svg-icon/types';
import { NotificationActivityCategory } from '@/components/notification/hooks/use-notification-activity/helpers/type/type';
import { isNotificationAllowed } from '@/services/modal/modal';
import { ExtractClassFields } from '@/type';

export type NotificationDataActivity = {
  type: 'mutual-activity';
  category: NotificationActivityCategory;
  photoId: string;
  header: string;
  iconName: string;
  actionText: string;
  action: {
    click: () => void;
    close?: () => void;
    disappear?: () => void;
  };
  isLocked?: boolean;
  avatarForm?: 'rounded' | 'square';
  description?: string;
};

export type NotificationDataMatch = {
  type: 'match';
  dialogId: string;
  userId: string;
  name: string;
  age: number;
  isOnline: boolean;
  avatar: ExtractClassFields<ProfilePhoto>;
};

export type NotificationDataLike = {
  type: 'like';
  userId: string;
  gender: ProfileGender;
  avatar: ExtractClassFields<ProfilePhoto>;
  age: number;
  name: string;
};

export type NotificationDataPowerLike = {
  type: 'power-like';
  dialogId: string;
  userFromId: string;
  name?: string;
  age?: number;
  isOnline?: boolean;
  avatar?: ExtractClassFields<ProfilePhoto>;
};

export type NotificationDataVisit = {
  type: 'visit';
  visitorUserId: string;
  avatar: ExtractClassFields<ProfilePhoto>;
  age: number;
  name: string;
};

export type NotificationDataRequestPhoto = {
  type: 'request-photo';
  userId: string;
  avatar: ExtractClassFields<ProfilePhoto>;
  age: number;
  name: string;
};

export type NotificationDataRequestPhotoUploaded = {
  type: 'request-photo-uploaded';
  userId: string;
  avatar: ExtractClassFields<ProfilePhoto>;
  age: number;
  name: string;
};

export type NotificationDataMessage = {
  type: 'message';
  dialogId: string;
  message: ExtractClassFields<NotificationDialogMessageV2_DialogMessage>;
};

export type NotificationDataBoosterActivated = {
  type: 'booster-activated';
};

export type NotificationDataReport = {
  type: 'report';
  isAbuseAction: boolean;
};

export type NotificationCreditsAdded = {
  type: 'credits-added';
};

export type NotificationDataSubscriptionPaymentSuccess = {
  type: 'subscription-payment-success';
};

export type NotificationDataSubscriptionPaymentFailed = {
  type: 'subscription-payment-failed';
};

export type NotificationDataAccountActivated = {
  type: 'account-activated';
};

export type NotificationDataAccountDeactivated = {
  type: 'account-deactivated';
};

export type NotificationDataNewVersion = {
  type: 'new-version';
};

export type NotificationDataGeneral = {
  type: 'general';
  text: string;
  icon?: string;
  mode?: 'warning' | 'success' | 'error';
};

type TextNotificationIcon = IconType | string;

export type NotificationText = {
  type: 'text';
  text: string;
  icon: TextNotificationIcon | TextNotificationIcon[];
  isSvg?: boolean;
};

export type NotificationReward = {
  type: 'reward';
  text: string;
  icon: string | string[];
};

export type NotificationDataAstroRomantic = {
  type: 'astro-romantic';
  userId: string;
  avatar: ExtractClassFields<ProfilePhoto>;
  name: string;
};

export type NotificationDataStackActivity = {
  type: 'stack-activity';
  notificationType: 'visit' | 'like' | 'incoming-match' | 'power-like' | 'chat-message';
  stackCount: number;
  action: () => void;
};

export type NotificationCommon = { force?: boolean };

export type NotificationDataType =
  | NotificationText
  | NotificationReward
  | NotificationDataActivity
  | NotificationDataMatch
  | NotificationDataLike
  | NotificationDataVisit
  | NotificationDataMessage
  | NotificationDataBoosterActivated
  | NotificationDataRequestPhoto
  | NotificationDataReport
  | NotificationCreditsAdded
  | NotificationDataSubscriptionPaymentSuccess
  | NotificationDataSubscriptionPaymentFailed
  | NotificationDataRequestPhotoUploaded
  | NotificationDataAccountActivated
  | NotificationDataAccountDeactivated
  | NotificationDataGeneral
  | NotificationDataPowerLike
  | NotificationDataNewVersion
  | NotificationDataAstroRomantic
  | NotificationDataStackActivity;

export const showNotification = (
  data: NotificationDataType,
  options?: NotificationsOptions & NotificationCommon,
) => {
  if (!isNotificationAllowed(data?.type ?? '', options?.force)) return;

  notify({
    group: 'topNotification',
    data,
    ...options,
  });
};

export function hideNotificationAll() {
  notify({
    group: 'topNotification',
    clean: true,
  });
}
