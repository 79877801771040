import { applyShowRules } from '@/services/notification-manager/helpers/rule/rule';
import {
  NotificationManagerGroup,
  NotificationManagerIncoming,
  NotificationManagerIncomingNotification,
} from '@/services/notification-manager/helpers/type/type';

const NOTIFICATION_TIMEOUT = 5000;

export function startNotificationTimer(
  groupIncoming: NotificationManagerIncoming,
  groupConfig: NotificationManagerGroup,
  notification: NotificationManagerIncomingNotification,
) {
  const timerKey = groupIncoming.groupName + '_' + notification.name;
  if (groupIncoming.notificationTimeoutIds[timerKey]) {
    return;
  }

  groupIncoming.notificationTimeoutIds[timerKey] = window.setTimeout(() => {
    applyShowRules(groupIncoming, groupConfig, notification);

    delete groupIncoming.notificationTimeoutIds[timerKey];

    clearNotificationTimerIfEmpty(groupIncoming);
  }, NOTIFICATION_TIMEOUT);
  clearNotificationTimerIfEmpty(groupIncoming);
}

export function clearNotificationTimerIfEmpty(groupIncoming: NotificationManagerIncoming) {
  Object.keys(groupIncoming.notificationTimeoutIds).forEach((key) => {
    const notificationTimeoutId = groupIncoming.notificationTimeoutIds[key];
    const [, notificationName] = key.split('_');
    const notification = groupIncoming.notifications.find((n) => n.name === notificationName);
    if (!notification) {
      window.clearTimeout(notificationTimeoutId);
      delete groupIncoming.notificationTimeoutIds[key];
    }
  });
}
