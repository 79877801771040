import { I18n } from '@/services/i18n';

type Message = {
  header: string;
};

const messages: I18n<Message> = {
  en: {
    header: "You're on the top!",
  },
  es: {
    header: '¡Estás en la cima!',
  },
};

export { messages };
