import { I18n } from '@/services/i18n';

type Message = {
  rating: string;
};

const messages: I18n<Message> = {
  en: {
    rating: 'Thank you for rating us!',
  },
  es: {
    rating: '¡Gracias por calificarnos!',
  },
};

export { messages };
