<template>
  <div :class="['notification-activity', `notification-activity_${category}`]">
    <slot name="icon"></slot>
    <NotificationClose @click="close" />
    <div class="notification-activity_gallery">
      <slot name="gallery"></slot>
    </div>
    <div class="notification-activity_wrapper">
      <slot name="avatar"></slot>
      <div class="notification-activity_txt">
        <div class="notification-activity_title">
          <slot name="header"></slot>
        </div>
        <div class="notification-activity_description">
          <slot name="description"></slot>
        </div>
      </div>
    </div>
    <slot name="action-button" v-bind="{ action }">
      <NotificationActivityButton @action="action">
        <slot name="action"></slot>
      </NotificationActivityButton>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { PropType, onUnmounted, ref } from 'vue';

import { NotificationActivityCategory } from '@/components/notification/hooks/use-notification-activity/helpers/type/type';

import NotificationActivityButton from './components/notification-activity-button/NotificationActivityButton.vue';
import NotificationClose from '../notification-close/NotificationClose.vue';

const emit = defineEmits(['action', 'close', 'disappear']);
const isActionDone = ref(false);

defineProps({
  category: {
    type: String as PropType<NotificationActivityCategory>,
    default: 'default',
  },
});

function close(): void {
  isActionDone.value = true;
  emit('close');
}

function action(): void {
  isActionDone.value = true;
  emit('action');
}

onUnmounted(() => {
  if (!isActionDone.value) {
    emit('disappear');
  }
});
</script>
