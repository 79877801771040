export type Sound = 'message' | 'notification';

const sounds: Partial<Record<Sound, HTMLAudioElement>> = {};

// Chrome: works after any page interaction:
// https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
// Safari: does not work, works only if it's user's event handler, such as click, ...
// https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
export function playSound(sound: Sound) {
  try {
    if (sounds[sound]) {
      sounds[sound]?.play().catch(() => {
        console.log('Play is not supported here');
      });
    } else {
      import(`../../assets/audio/${sound}.mp3`).then(({ default: mp3 }) => {
        sounds[sound] = new Audio(mp3);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sounds[sound].volume = 0.5;
        sounds[sound]?.play().catch(() => {
          // do nothing
        });
      });
    }
  } catch (e) {
    console.log('Play:', e);
  }
}
