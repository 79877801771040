import {
  ProfileBodyType,
  ProfileChildren,
  ProfileDrinking,
  ProfileEducation,
  ProfileEthnicity,
  ProfileEyeColor,
  ProfileGender,
  ProfileHairColor,
  ProfileHeightUnits,
  ProfileLocation,
  ProfileLookingFor,
  ProfileMaritalStatus,
  ProfilePhoto,
  ProfileReligion,
  ProfileSexualOrientation,
  ProfileSmoking,
  ProfileSphereOfWork,
  ProfileSport,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import {
  SETTINGS_MY_PROFILE_DEFAULT_HEIGHT,
  SETTINGS_PROFILE_DEFAULT_AGE_FROM,
  SETTINGS_PROFILE_DEFAULT_AGE_TO,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
} from '@/settings';
import { ExtractClassFields } from '@/type';

export type SignupStateAuthData = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

export type SignupStateBirthdate = {
  day: number;
  month: number;
  year: number;
};

export type SignupStateLocation = ExtractClassFields<ProfileLocation>;

export type SignupStateMyProfile = {
  gender: ProfileGender;
  name: string;
  birthdate: SignupStateBirthdate;
  lookingFor: ProfileLookingFor[];
  about: string;
  sphereOfWork: ProfileSphereOfWork;
  position: string;
  interests: string[];
  birthPlace: string;
  education: ProfileEducation;
  religion: ProfileReligion;
  sexualOrientation: ProfileSexualOrientation;
  maritalStatus: ProfileMaritalStatus;
  ethnicity: ProfileEthnicity;
  heightValue: number;
  heightUnits: ProfileHeightUnits;
  bodyType: ProfileBodyType;
  hairColor: ProfileHairColor;
  children: ProfileChildren;
  drinking: ProfileDrinking;
  smoking: ProfileSmoking;
  eyeColor: ProfileEyeColor;
  sport: ProfileSport;
  educationalInstitution: string;
};

export type SignupStateMatchProfile = {
  gender: ProfileGender;
  lookingFor: ProfileLookingFor[];
  religions: ProfileReligion[];
  maritalStatuses: ProfileMaritalStatus[];
  ethnicities: ProfileEthnicity[];
  spheresOfWork: ProfileSphereOfWork[];
  sexualOrientations: ProfileSexualOrientation[];
  bodyTypes: ProfileBodyType[];
  hairColors: ProfileHairColor[];
  children: ProfileChildren;
  drinking: ProfileDrinking[];
  smoking: ProfileSmoking[];
  eyeColors: ProfileEyeColor[];
  sport: ProfileSport[];
  interests: string[];
  education: ProfileEducation[];
  ageFrom: number;
  ageTo: number;
};

export type SignupStateEngagementProfile = {
  avatar: ExtractClassFields<ProfilePhoto>;
  userId: string;
  isLiked: boolean;
  key?: number;
};

export type SignupState = {
  myProfile: SignupStateMyProfile;
  matchProfile: SignupStateMatchProfile;
  email: string;
  password: string;
  isLocationUpdateAllowed: boolean;
  isLocationDetectionAllowed: boolean;
  location: SignupStateLocation;
  photoIds: string[];
  isEngagementLikeUsed: boolean;
};

export const state: SignupState = {
  myProfile: {
    gender: ProfileGender.INVALID,
    name: '',
    birthdate: {
      day: 0,
      month: 0,
      year: 0,
    },
    lookingFor: [],
    about: '',
    sphereOfWork: ProfileSphereOfWork.INVALID,
    position: '',
    interests: [],
    birthPlace: '',
    education: ProfileEducation.INVALID,
    religion: ProfileReligion.INVALID,
    sexualOrientation: ProfileSexualOrientation.INVALID,
    maritalStatus: ProfileMaritalStatus.INVALID,
    ethnicity: ProfileEthnicity.INVALID,
    heightValue: SETTINGS_MY_PROFILE_DEFAULT_HEIGHT,
    heightUnits: ProfileHeightUnits.INVALID,
    bodyType: ProfileBodyType.INVALID,
    hairColor: ProfileHairColor.INVALID,
    children: ProfileChildren.INVALID,
    drinking: ProfileDrinking.INVALID,
    smoking: ProfileSmoking.INVALID,
    eyeColor: ProfileEyeColor.INVALID,
    sport: ProfileSport.INVALID,
    educationalInstitution: '',
  },
  matchProfile: {
    gender: ProfileGender.INVALID,
    lookingFor: [],
    religions: [],
    maritalStatuses: [],
    ethnicities: [],
    spheresOfWork: [],
    sexualOrientations: [],
    bodyTypes: [],
    hairColors: [],
    children: ProfileChildren.INVALID,
    drinking: [],
    smoking: [],
    eyeColors: [],
    sport: [],
    interests: [],
    education: [],
    ageFrom: SETTINGS_PROFILE_DEFAULT_AGE_FROM,
    ageTo: SETTINGS_PROFILE_DEFAULT_AGE_TO,
  },
  email: '',
  password: '',
  isLocationDetectionAllowed: false,
  isLocationUpdateAllowed: false,
  location: {
    country: '',
    city: '',
    state: '',
    latitude: SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
    longitude: SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
  },
  photoIds: [],
  isEngagementLikeUsed: false,
};
