<template>
  <div class="h-full w-full max-w-screen-xs mx-auto relative">
    <NtfWelcomeV2
      v-if="self.isWelcomePopupAvailable"
      :card-default="self.cardDefault"
      :source="source"
      @next="self.setIsWelcomePopupAvailable(false)"
      @close="emit('close')"
    />
    <NtfPremiumV2
      v-else
      :source="source"
      :feature="feature"
      @close="emit('close')"
      @next="self.purchasePkg"
      @select="self.selectPkg"
    />
  </div>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import { PropType } from 'vue';

import NtfPremiumV2 from '@/components/ntf-modal/components/ntf-premium-v2/NtfPremiumV2.vue';
import NtfWelcomeV2 from '@/components/ntf-modal/components/ntf-welcome-v2/NtfWelcomeV2.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { useHistoryChange } from '@/hooks/use-history-change/use-history-change';

import { useSelf } from './hooks/use-self/use-self';

const emit = defineEmits(['close']);

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  source: {
    type: String as PropType<PromoShowPurchaseSource>,
    required: true,
  },
});

const self = useSelf({
  source: props.source,
});

function close() {
  emit('close');
}

useHistoryChange(close).init();

self.init();
</script>
