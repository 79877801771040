import { NotificationDataStackActivity } from '@/components/notification/helpers/notification-shower/notification-shower';
import { I18n } from '@/services/i18n';

type Message = {
  actionButtonText: string;
  header: Record<NotificationDataStackActivity['notificationType'], string>;
};

const messages: I18n<Message> = {
  en: {
    actionButtonText: 'Check profiles',
    header: {
      visit: 'New visits from {count} users',
      like: 'New likes from {count} users',
      'incoming-match': 'New matches with {count} users',
      'power-like': 'New power likes from {count} users',
      'chat-message': 'New messages from {count} users',
    },
  },
  es: {
    actionButtonText: 'Ver perfiles',
    header: {
      visit: 'Nuevas visitas de {count} usuarios',
      like: 'Nuevos "me gusta" de {count} usuarios',
      'incoming-match': 'Nuevos matches con {count} usuarios',
      'power-like': 'Nuevos "me gusta" intensos de {count} usuarios',
      'chat-message': 'Nuevos mensajes de {count} usuarios',
    },
  },
};

export { messages };
