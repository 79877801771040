import { Transport } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';

import { handleInterceptorAuth } from './interceptor/interceptor-auth/interceptor-auth';
import { handleInterceptorDevtools } from './interceptor/interceptor-devtools/interceptor-devtools';
import { handleInterceptorUserAgent } from './interceptor/interceptor-user-agent/interceptor-user-agent';

const host = window.location.host + '/api';

export const GRPC_ENDPOINT = `https://${import.meta.env.VUE_APP_GRPC_HOST || host}`;

let grpcWebTransport: Transport;
export function getGrpcWebTransport() {
  if (!grpcWebTransport) {
    grpcWebTransport = createGrpcWebTransport({
      baseUrl: GRPC_ENDPOINT,
      interceptors: [handleInterceptorUserAgent, handleInterceptorAuth, handleInterceptorDevtools],
    });
  }

  return grpcWebTransport;
}
