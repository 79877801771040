import { ref, Ref, toRef, watch, watchEffect } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useModel<T, X extends keyof any = keyof any>(
  props: Record<X, unknown>,
  eventName: X,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit: (...args: any[]) => void,
): Ref<T> {
  const propRef = toRef(props, eventName);
  const model = ref<T>();

  watchEffect(() => {
    model.value = propRef.value;
  });

  watch(model, () => {
    emit(`update:${String(eventName)}`, model.value);
  });

  return model as Ref<T>;
}
