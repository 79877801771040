import {
  PremiumPackage,
  Price,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed } from 'vue';

import { getFormattedCurrency } from '@/helpers/number';
import { getPackagePrice, getPackagePricePerWeek } from '@/pages/payment/helpers/package/package';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { ExtractClassFields } from '@/type';

export function usePackagePrice(pkg: ExtractClassFields<PaymentStatePackage>) {
  const price = computed<ExtractClassFields<Price>>(() => {
    return getPackagePrice(pkg);
  });

  const priceFormatted = computed<string>(() => {
    return getFormattedCurrency(
      price.value.totalAmount - price.value.taxAmount,
      price.value.currency,
    );
  });

  const priceTotalFormatted = computed<string>(() => {
    return getFormattedCurrency(price.value.totalAmount, price.value.currency);
  });

  const priceTaxFormatted = computed<string>(() => {
    return getFormattedCurrency(price.value.taxAmount, price.value.currency);
  });

  return {
    price,
    priceFormatted,
    priceTotalFormatted,
    priceTaxFormatted,
  };
}

export function usePackagePricePerWeek(pkg: ExtractClassFields<PremiumPackage>) {
  const price = computed<ExtractClassFields<Price>>(() => {
    return getPackagePricePerWeek(pkg);
  });

  const priceFormatted = computed<string>(() => {
    return getFormattedCurrency(
      price.value.totalAmount - price.value.taxAmount,
      price.value.currency,
    );
  });

  const priceTaxFormatted = computed<string>(() => {
    return getFormattedCurrency(price.value.taxAmount, price.value.currency);
  });

  return {
    price,
    priceFormatted,
    priceTaxFormatted,
  };
}
