import {
  EmailCredential,
  ExternalCredential,
  OneTimeHashCredential,
  RefreshTokenCredential,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/auth_pb';
import { SignInRequest } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';
import { ActionContext } from 'vuex';

import { setUserAuthData } from '@/helpers/auth/auth';
import { SigninState, SigninStateCredential } from '@/pages/signin/store/signin-state';
import { getOneTimeHash, signIn } from '@/services/api/my-profile/my-profile-api';
import { ActionFunction } from '@/store/store-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionSignin: ActionFunction<SigninStateCredential, void>;
    actionSigninGetOneTimeHash: ActionFunction<never, string>;
  }
}

export async function actionSignin(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SigninState, unknown>,
  credential: SigninStateCredential,
) {
  const request = new SignInRequest();

  if (credential.type === 'email') {
    const emailCredential = new EmailCredential({
      email: credential.email,
      password: credential.password,
    });
    request.credential = {
      case: 'emailCredential',
      value: emailCredential,
    };
  } else if (credential.type === 'external') {
    const externalCredential = new ExternalCredential({
      provider: credential.provider,
      sessionId: credential.sessionId,
    });
    request.credential = {
      case: 'externalCredential',
      value: externalCredential,
    };
  } else if (credential.type === 'autologin') {
    const oneTimeHashCredential = new OneTimeHashCredential({
      hash: credential.hash,
    });
    request.credential = {
      case: 'onetimeCredential',
      value: oneTimeHashCredential,
    };
  } else if (credential.type === 'refreshToken') {
    const refreshTokenCredential = new RefreshTokenCredential({
      token: credential.refreshToken,
    });
    request.credential = {
      case: 'refreshToken',
      value: refreshTokenCredential,
    };
  }

  // Needs to track in here https://gdx.myjetbrains.com/youtrack/issue/GDP-1930
  request.landingUrl = credential.landingUrl ? credential.landingUrl : window.location.href;

  const response = await signIn(request);
  setUserAuthData({
    accessToken: response.accessToken?.token ?? '',
    refreshToken: response.refreshToken?.token ?? '',
    userId: response.userId,
  });
}

export async function actionSigninGetOneTimeHash(): Promise<string> {
  const response = await getOneTimeHash();
  return response.hash;
}
