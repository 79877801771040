import { parseCookie } from '@/helpers/cookie/cookie';

export let webCrawlerAccessToken = '';
export let webCrawlerRefreshToken = '';
export let webCrawlerUserId = '';

export function webCrawlerAuthResolver(): {
  accessToken: string;
  refreshToken: string;
  userId: string;
} {
  const { access_token, refresh_token, user_id } = parseCookie(document.cookie);

  webCrawlerAccessToken = access_token;
  webCrawlerRefreshToken = refresh_token;
  webCrawlerUserId = user_id;

  return {
    accessToken: access_token,
    refreshToken: refresh_token,
    userId: user_id,
  };
}
