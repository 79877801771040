import { ProfileLocation } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { ExtractClassFields } from '@/type';

export function locationGetState(location: ExtractClassFields<ProfileLocation>): string {
  return location.country === 'United States' ? location.state : '';
}

// TODO write unit-test before commit
export function isLocationEmpty(location: ExtractClassFields<ProfileLocation>): boolean {
  return !location.country && !location.state && !location.city;
}

export function isLocalhost(): boolean {
  return ['localhost', '127.0.0.1'].includes(window.location.hostname);
}
