<template>
  <div class="h-full">
    <!--    Discount Popup -->
    <template v-if="isDiscountVisible">
      <SubscriptionPromoDiscountBlue :card-default="defaultPaymentCard" @next="next" />
    </template>

    <template v-else-if="isTrialVisible">
      <!--      Welcome Popups -->
      <SubscriptionPromoColumnWelcomeTrial
        v-if="isWelcomePackageAvailable"
        :card-default="defaultPaymentCard"
        @next="next"
      />

      <!--   Trial 7 Days Premium Popup    -->
      <SubscriptionPromoPaidTrialRedesign v-else :card-default="defaultPaymentCard" @next="next" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { computed, PropType, ref } from 'vue';

import { payWithCard } from '@/components/payment/helpers/payment-method/payment-method';
import SubscriptionPromoColumnWelcomeTrial from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-column-welcome-trial/SubscriptionPromoColumnWelcomeTrial.vue';
import SubscriptionPromoDiscountBlue from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-discount/components/subscription-promo-discount-blue/SubscriptionPromoDiscountBlue.vue';
import SubscriptionPromoPaidTrialRedesign from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-paid-trial-redesign/SubscriptionPromoPaidTrialRedesign.vue';
import { isWelcomeTrialPopupAvailable } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/helpers/trial-popup/trial-popup';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

const emit = defineEmits(['select']);
const props = defineProps({
  cards: {
    type: Array as PropType<PaymentStateCard[]>,
    required: true,
  },
});

const isPurchaseAvailable = ref(true);

const isWelcomePackageAvailable = computed<boolean>(() => isWelcomeTrialPopupAvailable());

const isDiscountVisible = computed<boolean>(
  () => getStore().state.common.promoModal.type === PromoType.DISCOUNT,
);

const isTrialVisible = computed<boolean>(
  () => getStore().state.common.promoModal.type === PromoType.TRIAL,
);

const defaultPaymentCard = computed<PaymentStateCard | undefined>(() => props.cards[0]);

function next(pkg: ExtractClassFields<PremiumPackage>): void {
  if (!isPurchaseAvailable.value) return;

  if (defaultPaymentCard.value) {
    payWithCard({
      cardId: defaultPaymentCard.value.id,
      pkg,
    });
  } else {
    emit('select', pkg);
  }

  isPurchaseAvailable.value = false;

  setTimeout(() => {
    isPurchaseAvailable.value = true;
  }, 1000);
}
</script>
