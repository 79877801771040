import { GetCompatibilityStatusResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_pb';

import { CompatibilityState } from '@/components/astrology/store/compatibility-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationCompatibilitySetStatus: {
      compatibility: GetCompatibilityStatusResponse;
      idUser: string;
    };
  }
}

export function mutationCompatibilitySetStatus(
  state: CompatibilityState,
  {
    compatibility,
    idUser,
  }: {
    compatibility: GetCompatibilityStatusResponse;
    idUser: string;
  },
) {
  state.compatibilityStatuses[idUser] = compatibility;
}
