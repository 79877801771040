<template>
  <SubscriptionPromoLayoutRedesign
    background-class="bg-trial-3 limited promo-redesign slider_vertical_mode"
    data-test-id="trial_popup"
  >
    <template #title>
      <div class="modal_default-sub_title">{{ t('trialOfferUnlocked') }}</div>
      <div class="modal_default-title_1">
        <span v-html="t('headerPerWeek', { price })"></span>
      </div>
    </template>

    <template #default>
      <SpinnerButton
        v-if="cardDefault"
        :loading-duration-ms="10000"
        class="btn btn-block btn-primary mb-0"
        @click="next"
      >
        {{ t('continue') }}
      </SpinnerButton>

      <button v-else type="button" class="btn btn-block btn-primary mb-0" @click="next">
        {{ t('continue') }}
      </button>

      <div v-if="!isFromLanding" class="text-overline text-dark-200 text-center mt-3">
        <PaymentMethodPolicyTrial
          :price-next="priceNext"
          :price="price"
          :btn-text="t('continue')"
        />
      </div>
    </template>
  </SubscriptionPromoLayoutRedesign>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType } from 'vue';

import SpinnerButton from '@/components/inputs/spinner-button/SpinnerButton.vue';
import PaymentMethodPolicyTrial from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/PaymentMethodPolicyTrial.vue';
import { getFormattedCurrency } from '@/helpers/number';
import SubscriptionPromoLayoutRedesign from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-layout-redesign/SubscriptionPromoLayoutRedesign.vue';
import { PaymentStateCard } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { getState, getStore } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const { t } = useI18n({ messages });

defineProps({
  cardDefault: {
    type: Object as PropType<PaymentStateCard | undefined>,
    required: true,
  },
});

const emit = defineEmits(['next']);

const isFromLanding = computed((): boolean => {
  return getState().common.index.isFromLanding;
});

const pkg = computed<ExtractClassFields<PremiumPackage>>(
  () => getStore().state.common.promoModal.packages[0],
);

const price = computed<string>(() => {
  return getFormattedCurrency(
    pkg.value.price?.totalAmount ?? 0n,
    pkg.value.price?.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
});

const priceNext = computed<string>(() => {
  const pkgNextPrice = pkg.value.nextPackage?.price;
  return getFormattedCurrency(
    pkgNextPrice?.totalAmount ?? 0n,
    pkgNextPrice?.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
});

function next(): void {
  emit('next', pkg.value);
}
</script>

<style lang="scss" src="./style.scss"></style>
