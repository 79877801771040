import { createPromiseClient } from '@connectrpc/connect';
import { NotificationPushAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_push_api_connect';
import {
  RegisterPushTokenRequest,
  RegisterPushTokenResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_push_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';

const client = createPromiseClient(NotificationPushAPI, getGrpcWebTransport());

export function registerPushToken(token: string): Promise<RegisterPushTokenResponse> {
  const request = new RegisterPushTokenRequest({
    token,
  });

  return client.registerPushToken(request);
}
