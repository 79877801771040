import { Subscription } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/subscription_pb';

import ProlongationEarlyUpgradeModal from '@/components/prolongation-modal/components/prolongation-early-upgrade-modal/ProlongationEarlyUpgradeModal.vue';
import { getCache, setCache } from '@/helpers/persistent-cache';
import {
  getSubscriptionDaysLeft,
  isSubscriptionTrial,
} from '@/helpers/subscription/subscription-type/subscription-type';
import { PaymentStateSubscription } from '@/pages/payment/store/payment-state';
import { useModal } from '@/services/modal/modal';
import { ExtractClassFields } from '@/type';

export function showEarlyUpgradeModal(subscription: PaymentStateSubscription) {
  useModal(() => ({
    component: ProlongationEarlyUpgradeModal,
    props: {
      subscription,
    },
  })).showModal();
}

type GetIsEarlyUpgradeModalAvailableArg = {
  hasTimeRestriction: boolean;
  subscription: ExtractClassFields<Subscription>;
};
export async function getIsEarlyUpgradeModalAvailable({
  hasTimeRestriction,
  subscription,
}: GetIsEarlyUpgradeModalAvailableArg) {
  if (hasTimeRestriction) {
    if (!getIsTimeRestrictionEnded()) {
      return false;
    }
  }

  const daysLeft = getSubscriptionDaysLeft(subscription);
  const isTrial = isSubscriptionTrial(subscription);

  return isTrial && daysLeft > 0 && daysLeft <= 7;
}

const CACHE_KEY = 'early_premium_promo';

function getIsTimeRestrictionEnded(): boolean {
  const lastShownMs = +(getCache(CACHE_KEY) ?? 0);
  const delayMs = 2 * 60 * 60 * 1000;
  const nowMs = +new Date();

  return nowMs - lastShownMs >= delayMs;
}

export function setEarlyUpgradeModalTimeLastShown(): void {
  setCache(CACHE_KEY, `${+new Date()}`);
}
