<template>
  <div class="flex flex-col gap-2 pb-2 border-b border-dark-400">
    <div class="text-button1 font-semibold text-dark flex justify-between">
      <p>{{ t('total') }}</p>
      <p>{{ price }}</p>
    </div>
    <div class="text-strongcaption font-medium text-error flex justify-between">
      <p>{{ t('introductoryDiscount') }}</p>

      <p>{{ t('justSaved') }} {{ savedAmount }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/services/i18n';

import { messages } from '../../i18n';

const { t } = useI18n({ messages });

defineProps({
  price: {
    type: String,
    required: true,
  },
  savedAmount: {
    type: String,
    required: true,
  },
});
</script>

<style scoped lang="scss"></style>
