import { Store } from 'vuex';

import { CompatibilityState } from '@/components/astrology/store/compatibility-state';
import { SexualCompatibilityState } from '@/components/sexual-compatibility/store/sexual-compatibility-state';
import { ActivityState } from '@/pages/activity/store/activity-state';
import { ChatState } from '@/pages/chat/store/chat-state';
import { MyProfileState } from '@/pages/my-profile/store/my-profile-state';
import { PaymentState } from '@/pages/payment/store/payment-state';
import { ProfileState } from '@/pages/profile/store/profile-state';
import { SearchState } from '@/pages/search/store/search-state';
import { SettingsState } from '@/pages/settings/store/settings-state';
import { SigninState } from '@/pages/signin/store/signin-state';
import { SignupState } from '@/pages/signup/store/signup-state';
import { CommonState } from '@/store/modules/common/common-state';
import { PossibilityState } from '@/store/modules/possibility/possibility-state';
import { RetentionState } from '@/store/modules/retention/retention-state';

export type ActionFunction<A, R> = {
  argumentType: A;
  returnType: R;
};

export interface Action {
  [key: string]: ActionFunction<unknown, unknown>;
}

type Commit<T> = {
  <M extends keyof T>(mutation: M, ...payload: T[M] extends never ? [] : [T[M]]): void;
};

type Dispatch<T extends Action> = {
  <A extends keyof T>(
    mutation: A,
    ...payload: T[A]['argumentType'] extends never ? [] : [T[A]['argumentType']]
  ): Promise<T[A]['returnType']>;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Mutation {}

export const commit: Commit<Mutation> = (mutation, payload?) => {
  getStore().commit(mutation, payload);
};

export const dispatch: Dispatch<Action> = (action, payload?) =>
  getStore().dispatch(action as string, payload);

type StoreData = {
  signup: SignupState;
  signin: SigninState;
  search: SearchState;
  profile: ProfileState;
  myProfile: MyProfileState;
  chat: ChatState;
  payment: PaymentState;
  common: CommonState;
  settings: SettingsState;
  activity: ActivityState;
  compatibility: CompatibilityState;
  sexualCompatibility: SexualCompatibilityState;
  retention: RetentionState;
  possibility: PossibilityState;
};

let store: Store<StoreData>;

export function setStore(s: Store<StoreData>) {
  store = s;
}

export function getStore(): Store<StoreData> {
  return store;
}

export function getState(): StoreData {
  return store.state;
}
