import { hasFreeCreditsFeature } from '@/components/payment/helpers/payment/payment';
import { dispatch } from '@/store/store-helper';

export function useCreditsFeature() {
  dispatch('actionCommonGetFreeCreditsFeaturesStatus');

  return {
    hasFreeCreditsFeature,
  };
}
