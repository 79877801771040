import { NotificationMatchFromMe } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';

import MatchModal from '@/components/match-modal/MatchModal.vue';
import { useModal } from '@/services/modal/modal';
import { ExtractClassFields } from '@/type';

export type MatchStateNotification = ExtractClassFields<NotificationMatchFromMe>;

export function useMatch(match: MatchStateNotification) {
  function callMatchPopup(props: Record<string, unknown> = {}): void {
    const { showModal, hideModal } = useModal((params: Record<string, unknown>) => ({
      component: MatchModal,
      props: {
        match,
        ...params,
      },
      on: {
        close: () => hideModal(),
      },
    }));

    showModal(props);
  }

  return { callMatchPopup };
}
