import { SettingsBlockedUser, SettingsState, SettingsStateAccount } from './settings-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationSettingsSetAccount: SettingsStateAccount;
    mutationSettingsAddBlockedUsers: SettingsBlockedUser[];
    mutationSettingsClearBlockedUsers: never;
  }
}

export function mutationSettingsSetAccount(state: SettingsState, account: SettingsStateAccount) {
  state.account = account;
}

export function mutationSettingsAddBlockedUsers(
  state: SettingsState,
  users: SettingsBlockedUser[],
) {
  state.blockedUsers.push(...users);
}

export function mutationSettingsClearBlockedUsers(state: SettingsState) {
  state.blockedUsers = [];
}
