<template>
  <ModalMenu
    :background-scroll="false"
    :is-teleport-enabled="true"
    class-name="vertical-footer modal_buy_free_gift"
    @close="closeMenu"
  >
    <template #title
      >{{ t('availableAfter') }} <span class="timer">{{ timeLeftFormatted }}</span></template
    >
    <template #text><span v-html="t('youCanSend')"></span></template>
    <PositiveModalMenuButton @click="upgrade">
      {{ t('upgrade') }}
    </PositiveModalMenuButton>
    <PositiveModalMenuButton @click="sendAnyWay">
      {{ t('sendAnyway') }} <SvgIcon class="icon-coin" name="coin" :width="16" :height="16" />
      <span class="price">{{ freeGift.value.priceCredits }}</span>
    </PositiveModalMenuButton>
    <NeutralModalMenuButton @click="closeMenu">{{ t('cancel') }}</NeutralModalMenuButton>
  </ModalMenu>
</template>

<script lang="ts" setup>
import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { onMounted, onUnmounted, PropType, Ref } from 'vue';

import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import NeutralModalMenuButton from '@/components/modal/modal-menu/components/NeutralModalMenuButton.vue';
import PositiveModalMenuButton from '@/components/modal/modal-menu/components/PositiveModalMenuButton.vue';
import ModalMenu from '@/components/modal/modal-menu/ModalMenu.vue';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { removeTimerByName, useTimer } from '@/hooks/use-timer/use-timer';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  freeGift: {
    type: Object as PropType<Ref<ExtractClassFields<DialogGift>>>,
    required: true,
  },
});

const emit = defineEmits(['close', 'send', 'upgrade']);

const { t } = useI18n({ messages });

function closeMenu(): void {
  emit('close');
}

function sendAnyWay(): void {
  emit('send', props.freeGift.value);
}

function upgrade(): void {
  emit('upgrade');
}

const { startTimer, timeLeftFormatted } = useTimer('freeGift');
startTimer(Number(props.freeGift.value.availableInDuration?.seconds) || 0);

onMounted(() => {
  trackPaymentPromo({
    from: 'chat-free-gift',
    popup: 'chat-buy-free-gift-modal',
    type: 'premium',
  });
});

onUnmounted(() => {
  removeTimerByName('freeGift');
});
</script>
