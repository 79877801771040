import { I18n } from '@/services/i18n';

type Message = {
  expires: string;
  min: string;
  sec: string;
};

const messages: I18n<Message> = {
  en: {
    expires: 'Discount expires in',
    min: 'min',
    sec: 'sec',
  },
  es: {
    expires: 'El descuento expira en',
    min: 'min',
    sec: 'seg',
  },
};

export { messages };
