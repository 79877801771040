<template>
  <template v-if="areMethodsLoaded">
    <PremiumTimer />

    <div class="flex mb-4">
      <div
        class="w-1/2 p-3 mr-2 flex flex-col items-center justify-center rounded-xl border transition-all"
        :class="
          self.isCardFormVisible
            ? 'cursor-pointer border-dark-400'
            : 'bg-success-500 border-success'
        "
        @click="self.setIsCardFormVisible(false)"
      >
        <img src="../../images/paypal.svg?url" alt="Paypal" />
      </div>
      <div
        class="w-1/2 p-3 ml-2 flex flex-col items-center rounded-xl border transition-all"
        :class="
          self.isCardFormVisible
            ? 'bg-success-500 border-success'
            : 'cursor-pointer border-dark-400'
        "
        @click="self.setIsCardFormVisible(true)"
      >
        <div class="mb-1 text-button2 font-semibold">{{ t('creditCard') }}</div>
        <div v-if="self.isGooglePayAvailable || self.isApplePayAvailable" class="flex gap-2">
          <span class="text-button2 font-semibold text-dark-200">{{ t('or') }}</span>
          <img v-if="self.isApplePayAvailable" src="../../images/apple-pay.svg?url" alt="" />
          <img v-if="self.isGooglePayAvailable" src="../../images/google-pay.svg?url" alt="" />
        </div>

        <div v-else class="flex gap-2">
          <img src="../../images/credit-card.svg?url" alt="Credit card" />
        </div>
      </div>
    </div>

    <CostTotal :price="self.price" :saved-amount="self.savedAmount" class="mb-2" />

    <PremiumBenefitList class="pt-2 pb-3 mb-[22px] border-b border-dark-400">
      <PremiumBenefit
        :price-new="self.price"
        :price-old="self.priceOld"
        :name="`${pkg?.label ?? ''} ${t('plan')}`"
      />
    </PremiumBenefitList>
    <div class="overflow-hidden relative mb-4">
      <transition name="payment" mode="out-in">
        <div v-show="!self.isCardFormVisible" class="payment-express">
          <div v-if="!isSubscription">
            <div class="payment_btn_wrap">
              <div class="payment_btn_wrap-label">{{ t('expressCheckout') }}</div>
              <SolidPaypalButton v-if="self.isPaypalAvailable" :pkg="pkg"></SolidPaypalButton>
            </div>
          </div>
        </div>
      </transition>
      <transition name="payment" mode="out-in">
        <div v-show="self.isCardFormVisible" class="relative">
          <div class="flex gap-4 mb-5 split-ti-bo">
            <div :id="solidGooglePayElId" class="w-full"></div>
            <div v-if="self.isApplePayAvailable" :id="solidApplePayElId" class="w-full"></div>
          </div>

          <span class="text-tag font-medium text-dark-200 uppercase flex justify-center mb-3">{{
            t('or')
          }}</span>

          <div :id="solidCardElId" class="solid-payment-form-container mb-4"></div>
          <div class="text-caption text-dark-200 mb-3 flex justify-center text-center">
            {{ t('hint') }} {{ self.price }}<br />
            {{ self.legalAddress }}
          </div>
          <div
            class="text-strongcaption font-medium text-success flex items-center justify-center gap-2"
          >
            <i class="icon icon-lock !text-[16px]"></i>{{ t('secureCheckout') }}
          </div>
        </div>
      </transition>
    </div>
  </template>
  <div v-else>
    <SpinnerLoader />
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import SpinnerLoader from '@/components/loader/SpinnerLoader.vue';
import PremiumBenefit from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-checkout/components/premium-benefit/PremiumBenefit.vue';
import PremiumBenefitList from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-checkout/components/premium-benefit-list/PremiumBenefitList.vue';
import PremiumTimer from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-timer/PremiumTimer.vue';
import SolidPaypalButton from '@/components/payment/components/payment-method/components/solid/solid-paypal-button/SolidPaypalButton.vue';
import CostTotal from '@/pages/payment/components/payment-method/components/payment-method-add-paypal-first/components/cost-total/CostTotal.vue';
import { useSelf } from '@/pages/payment/components/payment-method/components/payment-method-add-paypal-first/hooks/use-self/use-self';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';

import { messages } from '../../i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<PaymentStatePackagePremium>,
    required: true,
  },
  solidGooglePayElId: {
    type: String,
    required: true,
  },
  solidApplePayElId: {
    type: String,
    required: true,
  },
  solidCardElId: {
    type: String,
    required: true,
  },
  areMethodsLoaded: {
    type: Boolean,
    required: true,
  },
  isSubscription: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n({ messages });

const self = useSelf({ pkg: props.pkg });
</script>

<style scoped lang="scss" src="../../style.scss"></style>
