import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { I18n } from '@/services/i18n';

type Message = {
  slider: Record<
    Exclude<SubscriptionFeature, SubscriptionFeature.UNLOCK_LOCATION>,
    {
      header: string;
      content: string;
    }
  >;

  advancedSearchChatRequestHeader: string;
  advancedSearchChatRequestContent: string;
};

const messages: I18n<Message> = {
  en: {
    slider: {
      unlimitedLikes: {
        header: 'Daily Top Profile',
        content: 'More likes = more chances to meet someone special',
      },
      advancedSearch: {
        header: '3 Daily Power-Likes',
        content: 'Power-Like people of your interest and stay in touch with them',
      },
      unblockUsers: {
        header: '2 Dates Daily',
        content: 'Donate subscription to stay in touch with the person that you like',
      },
      unlimitedMessages: {
        header: 'Everyday 200 Credits Bonus',
        content: 'Spend credits by sending gifts to people you like or get TOP profile',
      },
      deleteOwnMessages: {
        header: '24/7 Support',
        content: 'Get instant Support and get answers to all of your questions immediately',
      },
      advancedInformation: {
        header: 'Personal Dating-Assistant',
        content: 'Unlock advanced profiles description and see all photos of the profile',
      },
      activity: {
        header: 'Full Premium',
        content: 'Get unlimited access to all profile info of other singles and more',
      },
      ads: {
        header: 'No Ads',
        content: 'Tired of ads? Upgrade and don’t get bothered by advertising',
      },
    },
    advancedSearchChatRequestHeader: '3 Chat Requests every day',
    advancedSearchChatRequestContent:
      'Send Chat Requests to people of your interest and stay in touch with them',
  },
  es: {
    slider: {
      unlimitedLikes: {
        header: 'Perfil Superior Diario',
        content: 'Más me gusta = más oportunidades de conocer a alguien especial',
      },
      advancedSearch: {
        header: '3 Super Me Gusta Diarios',
        content:
          'Dale un Super Me Gusta a las personas que te interesan y mantente en contacto con ellas',
      },
      unblockUsers: {
        header: '2 Citas Diarias',
        content: 'Dona una suscripción para mantenerte en contacto con la persona que te gusta',
      },
      unlimitedMessages: {
        header: 'Bono de 200 Créditos Diarios',
        content:
          'Gasta créditos enviando regalos a las personas que te gustan o consíguete un perfil TOP',
      },
      deleteOwnMessages: {
        header: 'Soporte 24/7',
        content: 'Obtén soporte instantáneo y recibe respuestas a todas tus preguntas de inmediato',
      },
      advancedInformation: {
        header: 'Asistente Personal de Citas',
        content: 'Desbloquea descripciones avanzadas de perfiles y ve todas las fotos del perfil',
      },
      activity: {
        header: 'Full Premium',
        content: 'Obtén acceso ilimitado a toda la información de perfiles de otros solteros y más',
      },
      ads: {
        header: 'Sin anuncios',
        content: '¿Cansado de los anuncios? Mejora tu cuenta y no te molestarán las publicidades',
      },
    },
    advancedSearchChatRequestHeader: '3 Solicitudes de Chat cada día',
    advancedSearchChatRequestContent:
      'Envía Solicitudes de Chat a personas de tu interés y mantente en contacto con ellas',
  },
};

export { messages };
