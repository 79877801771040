import { I18n } from '@/services/i18n';

type Message = {
  reportSuccess: string;
  blockSuccess: string;
};

const messages: I18n<Message> = {
  en: {
    reportSuccess: 'Thank you for your report!',
    blockSuccess: 'User successfully blocked!',
  },
  es: {
    reportSuccess: '¡Gracias por tu reporte!',
    blockSuccess: '¡Usuario bloqueado exitosamente!',
  },
};

export { messages };
