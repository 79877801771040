import { ListActivitiesRequest_ActivityDirection } from '@project-gd-x/dating-site-contracts/src/gen/gdx/activity/v1/activity_api_pb';
import { ActivityType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/activity/v1/activity_pb';
import { ActionContext } from 'vuex';

import { ActivityItem, ActivityState } from '@/pages/activity/store/activity-state';
import {
  ACTIVITIES_LIMIT,
  getActivitiesFrom,
  getLastActivitiesPosition,
} from '@/pages/activity/store/helpers/activity-helper';
import {
  getActivityStatus,
  listActivities,
  makeActivitiesRead,
} from '@/services/api/activity/activity-api';
import { ActionFunction, commit, getStore } from '@/store/store-helper';

type ActionActivityListActivitiesArg = {
  direction: ListActivitiesRequest_ActivityDirection;
  types?: ActivityType[];
};

declare module '@/store/store-helper' {
  interface Action {
    actionActivityMakeRead: ActionFunction<never, void>;
    actionActivityGetActivityStatus: ActionFunction<never, boolean>;
    actionActivityListActivities: ActionFunction<ActionActivityListActivitiesArg, ActivityItem[]>;
  }
}

export async function actionActivityMakeRead(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ActivityState, unknown>,
): Promise<void> {
  await makeActivitiesRead();
}

export async function actionActivityGetActivityStatus(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ActivityState, unknown>,
): Promise<boolean> {
  const response = await getActivityStatus();
  commit('mutationActivitySetHasUnread', response.hasUnreadActivities);

  return response.hasUnreadActivities;
}

export async function actionActivityListActivities(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<ActivityState, unknown>,
  { direction, types = [] }: ActionActivityListActivitiesArg,
): Promise<ActivityItem[]> {
  const searchFrom = getLastActivitiesPosition(
    direction === ListActivitiesRequest_ActivityDirection.IN
      ? getStore().state.activity.incoming
      : getStore().state.activity.outgoing,
  );

  const response = await listActivities({
    direction,
    searchFrom,
    limit: ACTIVITIES_LIMIT,
    types,
  });
  const activities = getActivitiesFrom(response);

  if (direction === ListActivitiesRequest_ActivityDirection.IN) {
    commit('mutationActivityAddIncoming', activities);
  } else if (direction === ListActivitiesRequest_ActivityDirection.OUT) {
    commit('mutationActivityAddOutgoing', activities);
  }

  return activities;
}
