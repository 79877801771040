import { connectToWebsocketServer, disconnectFromWebsocketServer } from '@/services/websocket';
import { dispatch } from '@/store/store-helper';

let refreshTokenPromise: Promise<void> | null = null;

export function getRefreshTokenPromise(): Promise<void> | null {
  return refreshTokenPromise;
}

export function removeRefreshTokenPromise(): void {
  refreshTokenPromise = null;
}

export function updateRefreshToken(refreshToken: string) {
  refreshTokenPromise = new Promise<void>((resolve, reject) => {
    dispatch('actionSignin', {
      type: 'refreshToken',
      refreshToken,
    })
      .then(resolve)
      .then(() => {
        // Reconnect to websocket server after refresh token.
        disconnectFromWebsocketServer();
        connectToWebsocketServer();
      })
      .catch(reject);
  });

  return refreshTokenPromise;
}
