import { ConnectionState } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';

import {
  ProfileConnectionPowerLikeStatus,
  ProfileConnectionState,
  ProfileState,
  ProfileStateProfile,
} from '@/pages/profile/store/profile-state';
import { ExtractClassFields } from '@/type';

type MutationProfileBlock = {
  userId: string;
  isBlocked: boolean;
};

type MutationProfileUnlockChat = {
  userId: string;
  chatId: string;
};

type ChangeConnectionStatePayload = {
  userId: string;
  field: keyof ExtractClassFields<ConnectionState>;
  value: any;
};

declare module '@/store/store-helper' {
  interface Mutation {
    mutationProfileSet: ProfileStateProfile;
    mutationProfileBlock: MutationProfileBlock;
    mutationProfileBlockedByUser: MutationProfileBlock;
    mutationProfileLike: string;
    mutationProfileRequestPhoto: string;
    mutationProfileUnlockChat: MutationProfileUnlockChat;
    mutationProfileSetConnections: ProfileConnectionState;
    mutationProfileSetPowerLikeStatus: ProfileConnectionPowerLikeStatus;
    mutationProfileChangeConnectionState: ChangeConnectionStatePayload;
    mutationProfileSetPowerLikeRequestStatus: string;
    mutationProfileSetIsPhotoRequested: {
      userId: string;
      state: boolean;
    };
  }
}

export function mutationProfileSetPowerLikeRequestStatus(state: ProfileState, id: string) {
  const currentStatus = state.connectionsLoading[id];

  if (!currentStatus) {
    state.connectionsLoading[id] = !state.connectionsLoading[id];
  } else {
    delete state.connectionsLoading[id];
  }
}

export function mutationProfileSetPowerLikeStatus(
  state: ProfileState,
  payload: ProfileConnectionPowerLikeStatus,
) {
  const status = state?.connections?.[payload.userId]?.powerLikeRequest?.status;

  if (status) {
    // @ts-expect-error: any
    state.connections[payload.userId].powerLikeRequest.status = payload.status;
  }
}

export function mutationProfileSetIsPhotoRequested(
  state: ProfileState,
  payload: {
    userId: string;
    state: boolean;
  },
): void {
  state.connections[payload.userId].isPhotoRequested = payload.state;
}

export function mutationProfileSetConnections(
  state: ProfileState,
  connections: ProfileConnectionState,
) {
  state.connections = { ...state.connections, ...connections };
}

export function mutationProfileChangeConnectionState(
  state: ProfileState,
  payload: ChangeConnectionStatePayload,
) {
  if (state.connections[payload.userId]) {
    // @ts-expect-error: any
    state.connections[payload.userId][payload.field] = payload.value;
  }
}

export function mutationProfileSet(state: ProfileState, profile: ProfileStateProfile): void {
  state.profiles[profile.id] = profile;
  // Vue.set(state.profiles, profile.id, profile);
}

export function mutationProfileBlock(state: ProfileState, profile: MutationProfileBlock): void {
  if (state.profiles[profile.userId]) {
    state.profiles[profile.userId].isBlocked = profile.isBlocked;
  }
}

export function mutationProfileBlockedByUser(state: ProfileState, profile: MutationProfileBlock) {
  if (state.profiles[profile.userId]) {
    state.profiles[profile.userId].isBlocked = profile.isBlocked;
  }
}

export function mutationProfileLike(state: ProfileState, userId: string): void {
  if (state.profiles[userId]) {
    state.profiles[userId].isLiked = true;
  }
}

export function mutationProfileRequestPhoto(state: ProfileState, userId: string): void {
  if (state.profiles[userId]) {
    state.profiles[userId].isPhotoRequested = true;
  }
}

export function mutationProfileUnlockChat(
  state: ProfileState,
  { chatId, userId }: MutationProfileUnlockChat,
): void {
  const profile = state.profiles[userId];

  if (profile) {
    profile.chatId = chatId;
  }
}
