import {
  NotificationManagerGroup,
  NotificationManagerIncoming,
  NotificationManagerIncomingNotification,
  NotificationManagerRuleCombineArgs,
  NotificationManagerRuleFields,
  NotificationManagerRulePrioritize,
  NotificationManagerRulePrioritizeArgs,
  NotificationManagerRuleStack,
  NotificationManagerRuleStackArgs,
} from '@/services/notification-manager/helpers/type/type';
import { sentryCaptureMessage } from '@/services/sentry/sentry';

export function applyImmediateRules(
  groupIncoming: NotificationManagerIncoming,
  groupConfig: NotificationManagerGroup,
): void {
  const immediateRules = groupConfig.rules.filter((rule) => rule.apply === 'immediate');

  immediateRules.forEach((rule) => {
    switch (rule.ruleName) {
      case 'prioritize':
        applyRulePrioritize(groupIncoming, rule);
        break;
    }
  });
}

export function applyShowRules(
  groupIncoming: NotificationManagerIncoming,
  groupConfig: NotificationManagerGroup,
  notification: NotificationManagerIncomingNotification,
): void {
  const showRules = groupConfig.rules.filter((rule) => rule.apply === 'show');

  showRules.forEach((rule) => {
    switch (rule.ruleName) {
      case 'stack':
        applyRuleStack(groupIncoming, rule, notification);
        break;
      case 'combine':
        applyRuleCombine(groupIncoming, notification);
        break;
    }
  });
}

function removeNotificationFromGroupIncoming(
  groupIncoming: NotificationManagerIncoming,
  notificationsToRemove: NotificationManagerIncomingNotification[],
) {
  notificationsToRemove.forEach((notification) => {
    const index = groupIncoming.notifications.indexOf(notification);
    if (index > -1) {
      groupIncoming.notifications.splice(index, 1);
    }
  });
}

export function groupByUserId(
  groupIncoming: NotificationManagerIncoming,
  ruleName: NotificationManagerRuleFields['ruleName'],
): Record<string, NotificationManagerIncomingNotification[]> {
  const groupedByUserId: Record<string, NotificationManagerIncomingNotification[]> = {};

  groupIncoming.notifications.forEach((notification) => {
    const ruleArgs = notification.rulesArgs.find((ruleArg) => ruleArg.ruleName === ruleName);

    if (!ruleArgs) {
      sentryCaptureMessage({
        message: 'NOTIFICATION_MANAGER: ' + ruleName + ' / ruleArgs not found',
      });
      return;
    }

    const userFromId = (ruleArgs as NotificationManagerRulePrioritizeArgs).userFromId;
    if (!userFromId) {
      sentryCaptureMessage({
        message: 'NOTIFICATION_MANAGER: ' + ruleName + ' / userFromId not found',
      });
      return;
    }

    if (!groupedByUserId[userFromId]) {
      groupedByUserId[userFromId] = [];
    }

    groupedByUserId[userFromId].push(notification);
  });

  return groupedByUserId;
}

export function applyRuleCombine(
  groupIncoming: NotificationManagerIncoming,
  originalNotification: NotificationManagerIncomingNotification,
): void {
  const groupedByUserId = groupByUserId(groupIncoming, 'combine');
  Object.values(groupedByUserId).forEach((group) => {
    if (group.length === 1 && group[0].name === originalNotification.name) {
      group[0].showNotification();
      removeNotificationFromGroupIncoming(groupIncoming, group);
    } else if (group.length > 1) {
      const ruleArg = group[0].rulesArgs.find((ruleArg) => ruleArg.ruleName === 'combine');
      if (ruleArg) {
        (ruleArg as NotificationManagerRuleCombineArgs).showCombinedNotification();
      }
      removeNotificationFromGroupIncoming(groupIncoming, group);
    }
  });
}

export function applyRuleStack(
  groupIncoming: NotificationManagerIncoming,
  rule: NotificationManagerRuleStack,
  originalNotification: NotificationManagerIncomingNotification,
): void {
  const leftNotifications: NotificationManagerIncomingNotification[] = [];
  const ruleNotifications: NotificationManagerIncomingNotification[] = [];

  // Stack rule is applied only for concrete notifications ( e.g. visit, like, ... ) at the same time,
  // so we need to filter out just notifications we need to show, others we put back to groupIncoming.notifications
  groupIncoming.notifications.forEach((notification) => {
    if (notification.name === originalNotification.name) {
      ruleNotifications.push(notification);
    } else {
      leftNotifications.push(notification);
    }
  });

  const stackedCount = ruleNotifications.length;

  if (stackedCount === 0) {
    return;
  }

  groupIncoming.notifications = leftNotifications;

  // We have just 1 notification, so we don't need to stack it
  // Or this notification is not stackable by config rules ( stackNotificationNames arr ).
  if (
    stackedCount === 1 ||
    !rule.ruleOptions.stackNotificationNames.includes(originalNotification.name)
  ) {
    ruleNotifications.forEach((notification) => {
      notification.showNotification();
    });
  } else {
    // We have more than 1 notification, so we need to stack it
    const ruleArg = ruleNotifications[0].rulesArgs.find((ruleArg) => ruleArg.ruleName === 'stack');
    if (ruleArg) {
      (ruleArg as NotificationManagerRuleStackArgs).showStackedNotification(stackedCount);
    }
  }
}

export function applyRulePrioritize(
  groupIncoming: NotificationManagerIncoming,
  rule: NotificationManagerRulePrioritize,
): void {
  const groupedByUserId = groupByUserId(groupIncoming, 'prioritize');

  // Sort grouped notifications by priority from order in rule.ruleOptions.prioritizeNotificationNames
  const groupSorted = Object.values(groupedByUserId).map((notifications) => {
    return notifications.sort((a, b) => {
      const priorityA = rule.ruleOptions.prioritizeNotificationNames.indexOf(a.name);
      const priorityB = rule.ruleOptions.prioritizeNotificationNames.indexOf(b.name);

      return priorityB - priorityA;
    });
  });

  // Remove notification with higher priority
  const groupRemove = groupSorted
    .map((notifications) => {
      return notifications.slice(1);
    })
    .filter((notifications) => notifications.length);

  // Remove notifications from groupIncoming
  groupRemove.forEach((notifications) => {
    removeNotificationFromGroupIncoming(groupIncoming, notifications);
    // notifications.forEach((notification) => {
    //   const index = groupIncoming.notifications.indexOf(notification);
    //   groupIncoming.notifications.splice(index, 1);
    // });
  });
}
