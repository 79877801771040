import { Promotion } from '@project-gd-x/dating-site-contracts/src/gen/gdx/retention/public/v1/rewards_pb';

import { ExtractClassFields } from '@/type';

export type RetentionState = {
  rewards: ExtractClassFields<Promotion>;
};

export const state: RetentionState = {
  rewards: {} as ExtractClassFields<Promotion>,
};
