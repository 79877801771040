import { initFirebase } from '@/services/firebase/firebase';
import { pushRealtimeTrackingCounter } from '@/services/realtime-tracking/realtime-tracking';

type UseSelfArg = {
  close: () => void;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function close() {
    arg.close();

    pushRealtimeTrackingCounter('web-push-modal', {
      action: 'close',
    });
  }

  function subscribe() {
    initFirebase();
    arg.close();

    pushRealtimeTrackingCounter('web-push-modal', {
      action: 'subscribe',
    });
  }

  function init() {
    pushRealtimeTrackingCounter('web-push-modal', {
      action: 'show',
    });
  }

  return {
    close,
    subscribe,
    init,
  };
}
