import { createPromiseClient } from '@connectrpc/connect';
import { CompatibilityAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_connect';
import {
  ActivateSexualCompatibilityStatusRequest,
  ActivateSexualCompatibilityStatusResponse,
  GetSexualCompatibilityStatusRequest,
  GetSexualCompatibilityStatusResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/compatibility/v1/compatibility_api_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';

const client = createPromiseClient(CompatibilityAPI, getGrpcWebTransport());

export function activateSexualCompatibilityStatus(
  userId: string,
): Promise<ActivateSexualCompatibilityStatusResponse> {
  const request = new ActivateSexualCompatibilityStatusRequest({
    userId,
  });

  return client.activateSexualCompatibilityStatus(request);
}

export function getSexualCompatibilityStatus(
  userId: string,
): Promise<GetSexualCompatibilityStatusResponse> {
  const request = new GetSexualCompatibilityStatusRequest({
    userId,
  });

  return client.getSexualCompatibilityStatus(request);
}
