import dayjs from 'dayjs';
import { computed, reactive } from 'vue';

import { getCache, setCache } from '@/helpers/persistent-cache';
import { TimerName, useTimer } from '@/hooks/use-timer/use-timer';

type PromoTimer = {
  durationSec: number;
  durationRestartSec: number;
  timerName: TimerName;
};

type TimeLeftFormatted = {
  hh: string;
  mm: string;
  ss: string;
};

export function usePromoTimer({ durationSec, durationRestartSec, timerName }: PromoTimer) {
  // Ref -----------------------------------------------------------------------
  let tickId: number = 0;

  // Hook ----------------------------------------------------------------------
  const timer = useTimer(timerName);

  // Computed ------------------------------------------------------------------
  const timeLeftFormatted = computed<TimeLeftFormatted>(() => {
    const duration = dayjs.duration(timer.timerSecondsLeft.value, 'seconds');
    return {
      hh: Math.trunc(duration.asHours()).toString(),
      mm: duration.format('mm'),
      ss: duration.format('ss'),
    };
  });

  const isActive = computed(() => {
    return timer.timerSecondsLeft.value > 0;
  });

  function resetTimerCache() {
    const timerEnds = dayjs().add(durationSec, 'seconds').unix();
    setCache(timerName, timerEnds.toString());

    return timerEnds;
  }

  // Method --------------------------------------------------------------------
  function getTimerSecondsLeft() {
    let timerEnds = Number(getCache(timerName));
    if (timerEnds === 0) {
      timerEnds = resetTimerCache();
    }

    return timerEnds - dayjs().unix();
  }

  function tick() {
    const secondsLeft = getTimerSecondsLeft();
    if (secondsLeft < durationRestartSec) {
      console.log('timer expired');
      resetTimerCache();
      timer.startTimer(durationSec, true);
    }
  }

  function destroy() {
    if (tickId) {
      clearInterval(tickId);
      tickId = 0;
    }
  }

  function init() {
    timer.startTimer(getTimerSecondsLeft());
    tickId = window.setInterval(tick, 1000);
  }

  return reactive({ init, destroy, timeLeftFormatted, isActive });
}
