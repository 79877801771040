import { Dialog } from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';

import { ExtractClassFields } from '@/type';

export type ChatStateDialogMessageContentText = {
  type: 'text';
  text: string;
};

export type ChatStateDialogMessageContentImage = {
  type: 'image';
  imageId: string;
};

export type ChatStateDialogMessageContentGift = {
  type: 'gift';
  giftId: string;
  giftPhotoId: string;
  giftText: string;
};

export type ChatStateDialogMessageContent =
  | ChatStateDialogMessageContentText
  | ChatStateDialogMessageContentImage
  | ChatStateDialogMessageContentGift;

export type PowerLikeState = {
  expiresAt: number;
  expireDuration: number;
  messagesCount: number;
};

export type ChatStateDialogPreview = {
  id: string;
  photoId: string;
  userName: string;
  userAge: number;
  isUserOnline: boolean;
  isUserDeleted: boolean;
  latestMessage: ChatStateDialogMessageContent | undefined;
  isLatestMessageLocked: boolean;
  createdTimestampMs: number;
  latestMessageTimeMs: number;
  dialogPosition: string;
  unreadMessagesCount: number;
  isBlocked: boolean;
  isBlockedBySubject: boolean;
  userId: string;
  powerLike: PowerLikeState | undefined;
  readOnly: boolean;
  isVerified: boolean;
};

export type ChatStateDialogMessage = {
  id: string;
  userIdFrom: string;
  isNew: boolean;
  content: ChatStateDialogMessageContent | undefined;
  createdTimestampMs: number;
  messagePosition: string;
  isSystem: boolean;
  isSuspiciousContent: boolean;
  isLocked: boolean;
};

export type ChatStateDialogUser = {
  id: string;
  avatarId: string;
  isOnline: boolean;
  latestOnlineTimestampMs: number;
  isDeleted: boolean;
  isBlocked: boolean;
  isBlockedBySubject: boolean;
  name: string;
  age: number;
};

export type ChatState = {
  dialogs: ChatStateDialogPreview[];
  matches: ExtractClassFields<Dialog>[];
  dialogUsers: { [key: string]: ChatStateDialogUser };
  messages: { [key: string]: ChatStateDialogMessage[] };
  gifts: ExtractClassFields<DialogGift>[];
  drafts: { [key: string]: string };
  activeDrafts: { [key: string]: string };
  lastDialogPosition: string;
  lastMatchPosition: string;
};

export const state: ChatState = {
  dialogs: [],
  matches: [],
  dialogUsers: {},
  messages: {},
  gifts: [],
  drafts: {},
  activeDrafts: {},
  lastDialogPosition: '',
  lastMatchPosition: '',
};
