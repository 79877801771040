<template>
  <div class="modal-wrapper modal-backdrop modal_row" style="display: block">
    <div class="modal-dialog modal-dialog-full h-full" role="document">
      <div class="modal-content h-full" data-test-id="modal_content" :class="classNameContent">
        <div class="modal-header flex items-center justify-center" :class="classNameHeader">
          <div class="header-left flex items-center justify-center absolute top-0 h-14 left-[15px]">
            <slot name="header-left"></slot>
          </div>
          <div class="header-title" data-test-id="page_header_title">
            <slot name="header-center"></slot>
          </div>
          <div
            class="header-right flex items-center justify-center absolute top-0 h-14 right-[15px]"
          >
            <slot name="header-right"></slot>
          </div>
        </div>
        <div class="modal-body panel-bottom">
          <div v-if="hasTitle" class="title-h" data-test-id="title">
            <slot name="title"></slot>
          </div>

          <slot name="slider-content"></slot>
          <slot></slot>
        </div>

        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';

defineProps({
  classNameContent: {
    type: String,
    default: '',
  },
  classNameHeader: {
    type: String,
    default: '',
  },
});

const hasTitle = computed(() => {
  const slots = useSlots();

  return !!slots.title?.().length;
});
</script>

<style scoped lang="scss" src="./style.scss"></style>
