import { ValidateEmailResponseCode } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/auth_pb';
import { ProfileLocation } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';
import { ProfileField } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/update_pb';
import { ActionContext } from 'vuex';

import {
  getFullProfileFrom,
  getShortProfileFrom,
} from '@/pages/my-profile/store/helpers/my-profile-helper';
import {
  MyProfileStateShort,
  MyProfileStateFull,
  MyProfileState,
} from '@/pages/my-profile/store/my-profile-state';
import {
  checkEmail,
  getFullProfile,
  getShortProfile,
  updateAvatar,
  updateProfile,
  validateEmail,
} from '@/services/api/my-profile/my-profile-api';
import { commit, ActionFunction } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Action {
    actionMyProfileGetShort: ActionFunction<never, MyProfileStateShort>;
    actionMyProfileGetFull: ActionFunction<never, MyProfileStateFull>;
    actionMyProfileSetLocation: ActionFunction<ExtractClassFields<ProfileLocation>, void>;
    actionMyProfileValidateEmail: ActionFunction<
      ActionMyProfileValidateEmailPayload,
      ValidateEmailResponseCode
    >;
    actionMyProfileUpdateAvatar: ActionFunction<string, void>;
  }
}

export async function actionMyProfileGetShort(): Promise<MyProfileStateShort> {
  const response = await getShortProfile();
  const shortProfile = getShortProfileFrom(response);

  commit('mutationMyProfileSetShort', shortProfile);

  return shortProfile;
}

export async function actionMyProfileGetFull(): Promise<MyProfileStateFull> {
  const response = await getFullProfile();
  const profile = getFullProfileFrom(response);

  commit('mutationMyProfileSetFull', profile);

  return profile;
}

export async function actionMyProfileSetLocation(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<MyProfileState, unknown>,
  location: ExtractClassFields<ProfileLocation>,
): Promise<void> {
  const profileLocation = new ProfileLocation({
    city: location.city,
    country: location.country,
    state: location.state,
    latitude: location.latitude,
    longitude: location.longitude,
  });

  const locationField = new ProfileField({
    field: {
      case: 'location',
      value: profileLocation,
    },
  });

  await updateProfile(locationField);
}

type ActionMyProfileValidateEmailPayload = {
  email: string;
  isExtended?: boolean;
};
export async function actionMyProfileValidateEmail(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<MyProfileState, unknown>,
  { email, isExtended = false }: ActionMyProfileValidateEmailPayload,
): Promise<ValidateEmailResponseCode> {
  if (isExtended) {
    return (await checkEmail(email)).code;
  } else {
    return (await validateEmail(email)).code;
  }
}

export async function actionMyProfileUpdateAvatar(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<MyProfileState, unknown>,
  newPhotoId: string,
): Promise<void> {
  await updateAvatar(newPhotoId);

  commit('mutationMyProfileSetShortAvatarId', newPhotoId);
  commit('mutationMyProfileFullSetAvatar', newPhotoId);
}
