<template>
  <div
    class="fixed max-w-screen-xs mx-auto left-0 right-0 text-center h-14 flex items-center justify-center bg-premium z-10"
  >
    <a
      href="#"
      data-test-id="btn_close"
      class="text-white absolute left-6 opacity-50"
      @click.prevent="emit('close')"
      ><i class="icon icon-close"></i
    ></a>
    <span class="text-tag text-white opacity-50">{{ t('activate') }}</span>
  </div>

  <div class="px-6" data-test-id="slider-ntf-purchase">
    <PremiumSlider :feature="feature" />
  </div>

  <div class="bg-white flex-grow rounded-t-2xl px-6">
    <PremiumTimer />

    <!-- Premium items start -->
    <div v-if="packageSelected">
      <PremiumItem v-for="pkg in packageList" :key="pkg.id" v-model="packageSelected" :pkg="pkg" />
    </div>
    <!-- Premium items end -->

    <div class="text-body1 text-dark-200 text-center my-2">
      <img src="./assets/img/ic_security.svg?url" alt="" /> {{ t('paySafe') }}
    </div>
    <div class="text-center">
      <img class="w-[250px]" src="./assets/img/pay-safe.png" width="500" alt="" />
    </div>

    <div class="text-overline text-dark-200 font-medium text-center mt-2 mb-28">
      {{
        t('hint', {
          label: packageSelected?.label ?? '',
          packageSelectedPriceFormatted: self.packageSelectedPriceFormatted,
          packageSelectedPriceNextFormatted: self.packageSelectedPriceNextFormatted,
        })
      }}
      <a
        class="text-overline text-dark-200 font-medium underline"
        href="mailto:support@meetmyage.com"
        >support@meetmyage.com</a
      >.
      <a
        class="text-overline text-dark-200 font-medium underline"
        href="#"
        @click="textPageModal.showModal('membership')"
        >{{ t('policy') }}</a
      >.
    </div>
  </div>

  <div
    class="fixed max-w-screen-xs mx-auto left-0 right-0 bottom-0 h-[72px] bg-white px-6 pt-2 border-t"
  >
    <PurchaseContinue
      :is-autorefill-visible="false"
      btn-class="!bg-premium text-white"
      @select="emit('select')"
      @next="emit('next')"
    >
      <template #continue> {{ t('getPremium') }} </template>
    </PurchaseContinue>
  </div>
</template>

<script lang="ts" setup>
import { PropType, Ref } from 'vue';

import PremiumItem from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/components/premium-item/PremiumItem.vue';
import PremiumSlider from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/components/premium-slider/PremiumSlider.vue';
import { useSelf } from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/hooks/use-self/use-self';
import PremiumTimer from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-timer/PremiumTimer.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { useModel } from '@/hooks/use-model/use-model';
import PurchaseContinue from '@/pages/payment/components/layout/components/purchase-continue/PurchaseContinue.vue';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { getTextPageModal } from '@/pages/text-page/helpers/text-page/text-page';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  packageList: {
    type: Array as PropType<PaymentStatePackagePremium[]>,
    required: true,
  },
  packageSelected: {
    type: Object as PropType<PaymentStatePackagePremium>,
    required: true,
  },
});

const emit = defineEmits(['next', 'close', 'select', 'update:packageSelected']);

const textPageModal = getTextPageModal();

const { t } = useI18n({ messages });

const packageSelected: Ref<PaymentStatePackagePremium> = useModel(props, 'packageSelected', emit);

const self = useSelf({ packageSelected });
</script>
