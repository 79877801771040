import {
  Package,
  Possibility,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';

import { PossibilityState } from '@/store/modules/possibility/possibility-state';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationPossibilitySetPossibilityCount: ExtractClassFields<Possibility>;
    mutationPossibilitySetPackage: ExtractClassFields<Package>;
    mutationPossibilitySetPackagesList: ExtractClassFields<Package>[];
  }
}

export function mutationPossibilitySetPackage(
  state: PossibilityState,
  pkg: ExtractClassFields<Package>,
) {
  if (!state.packages.some((item) => item.id === pkg.id)) {
    state.packages.push(pkg);
  }
}

export function mutationPossibilitySetPossibilityCount(
  state: PossibilityState,
  possibility: ExtractClassFields<Possibility>,
) {
  const index = state.possibilities.findIndex(
    (item: ExtractClassFields<Possibility>) => item.type === possibility.type,
  );

  if (index !== -1) {
    state.possibilities[index].count = possibility.count;
  } else {
    state.possibilities.push(possibility);
  }
}

export function mutationPossibilitySetPackagesList(
  state: PossibilityState,
  packagesList: ExtractClassFields<Package>[],
) {
  state.packages = packagesList;
}
